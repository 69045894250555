import { type SagaIterator } from 'redux-saga';
import { put, select, takeLeading } from 'redux-saga/effects';

import { TopBarNotificationType } from 'constants/notifications';
import { QueryKey } from 'constants/query-key';
import { isNonProfit } from 'helpers/is-non-profit';
import { PartnerType } from 'interfaces/entities/current-license';
import { type ISubscription } from 'interfaces/subscription';
import {
  BillingProperty,
  CoreProperty,
  PlatformNamespace,
} from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { getSubscriptionManagersLogins } from 'store/entities/accounts/selectors';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getCanManageSubscription, getIsOnTrial, getTrialDaysLeft } from 'store/features/session/selectors';

function* toggleTrialExpiringTopBar(): SagaIterator {
  const isTrial = yield select(getIsOnTrial);
  const subscription: ISubscription = yield select(getSubscription);
  const paymentRecurrentEnabled: boolean = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
  )?.[BillingProperty.PaymentRecurrentEnabled];
  const partnerType: PartnerType = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.CORE),
  )?.[CoreProperty.PartnerType];
  const isReseller = partnerType === PartnerType.Reseller;
  const isTrialWithCreatedSubscription = isTrial && paymentRecurrentEnabled;

  const isNonProfitLicense = isNonProfit();
  const isSubscriber = subscription && subscription.subscriber;

  if (isReseller || !isTrial || isSubscriber || isTrialWithCreatedSubscription || isNonProfitLicense) {
    yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.TrialWarningImportant));
    yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.TrialWarning));

    return;
  }

  const canManageSubscription = yield select(getCanManageSubscription);
  const viceOwnersEmails = yield select(getSubscriptionManagersLogins);
  const daysLeft: number = yield select(getTrialDaysLeft);

  const isTrialEnding = daysLeft <= 3;

  yield put(
    NotificationsBarActions.showNotificationsBar({
      name: isTrialEnding ? TopBarNotificationType.TrialWarningImportant : TopBarNotificationType.TrialWarning,
      props: { daysLeft, canManageSubscription, viceOwnersEmails },
    }),
  );
}

export function* toggleTrialExpiringTopBarSaga(): SagaIterator {
  yield takeLeading(
    [SubscriptionActionNames.SUBSCRIPTION_CHANGED, SubscriptionActionNames.CREATE_SUBSCRIPTION_SUCCESS],
    toggleTrialExpiringTopBar,
  );
}
