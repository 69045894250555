import { CopilotEntitiesActionNames, type CopilotEntitiesAction } from './actions';
import {
  getStateForAddNewCopilotEvent,
  getStateForAddCopilotError,
  getStateForCanceCopilotRequest,
  getStateForClearCopilotError,
  getStateForClearCopilotFailedMessageContent,
  getStateForCopilotLatestAgentEventId,
  getStateForCopilotMessageFromSpotlight,
  getStateForSetCopilotFailedMessageContent,
  getStateForSetCopilotSessionId,
  getStateForUpdateCopilotActionButtons,
} from './helpers';
import { type CopilotEntitiesState } from './interfaces';

export const initialState: CopilotEntitiesState = {
  events: {
    byIds: {},
    groupedIds: [[]],
    latestAgentEventId: null,
  },
  currentSessionId: null,
  isMessageFromSpotlight: false,
};

export const copilotEntitiesReducer = (state = initialState, action: CopilotEntitiesAction): CopilotEntitiesState => {
  switch (action.type) {
    case CopilotEntitiesActionNames.ADD_COPILOT_MESSAGE: {
      return getStateForAddNewCopilotEvent(state, action.payload);
    }

    case CopilotEntitiesActionNames.ADD_COPILOT_ERROR: {
      return getStateForAddCopilotError(state, action.payload);
    }

    case CopilotEntitiesActionNames.CLEAR_COPILOT_ERROR: {
      return getStateForClearCopilotError(state);
    }

    case CopilotEntitiesActionNames.SET_COPILOT_SESSION_ID: {
      return getStateForSetCopilotSessionId(state, action.payload);
    }

    case CopilotEntitiesActionNames.CANCEL_COPILOT_REQUEST: {
      return getStateForCanceCopilotRequest(state);
    }

    case CopilotEntitiesActionNames.SET_FAILED_COPILOT_MESSAGE: {
      return getStateForSetCopilotFailedMessageContent(state, action.payload);
    }

    case CopilotEntitiesActionNames.CLEAR_FAILED_COPILOT_MESSAGE: {
      return getStateForClearCopilotFailedMessageContent(state);
    }

    case CopilotEntitiesActionNames.UPDATED_COPILOT_ACTION_BUTTONS: {
      return getStateForUpdateCopilotActionButtons(state, action.payload);
    }

    case CopilotEntitiesActionNames.COPILOT_MESSAGE_SPOTLIGHT: {
      return getStateForCopilotMessageFromSpotlight(state, action.payload);
    }

    case CopilotEntitiesActionNames.SET_LATEST_AGENT_EVENT_ID: {
      return getStateForCopilotLatestAgentEventId(state, action.payload);
    }

    default:
      return state;
  }
};
