import { RecurlyFieldType } from 'interfaces/recurly';

export const INPUT_LABEL_ID = 'input-label';
export const INPUT_FIELD_ID = 'input-field';

export const CARD_FIELD_LABELS = {
  [RecurlyFieldType.FirstName]: 'First name',
  [RecurlyFieldType.LastName]: 'Last name',
  [RecurlyFieldType.PostalCode]: 'Card zip / postal code',
  [RecurlyFieldType.Company]: 'Company name',
  [RecurlyFieldType.VatNumber]: 'VAT ID',
  [RecurlyFieldType.Address]: 'Address',
  [RecurlyFieldType.City]: 'City',
  [RecurlyFieldType.State]: 'State',
};

export enum SubscriptionCardInputFieldSize {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
}
