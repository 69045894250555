/**
 * if you want to have a typed `payload` interface feel free to add your own payload interface to the file:
 * source/tsx/interfaces/globalModal.tsx
 */
export const enum GlobalModal {
  AddCannedResponse = 'add-canned-response',
  BotEngineDisabled = 'botengine-disabled',
  BotEngineEnabled = 'botengine-enabled',
  BotEngineRestart = 'botengine-restart',
  ChatbotExpired = 'chatbot-expired',
  ChatbotToggle = 'chatbot-toggle',
  CompanyDetails = 'company-details',
  ConnectChatbot = 'connect-chatbot',
  ConnectChatbotSuccess = 'connect-chatbot-success',
  ContactUs = 'contact-us',
  CustomerEdit = 'customer-edit',
  DevelopersPlatformChatButton = 'developers-platform-chat-button',
  Info = 'info',
  InstallCodeMail = 'install-code-mail',
  KeyboardShortcuts = 'keyboard-shortcuts',
  NewTickets = 'redesign-new-tickets',
  Notifications = 'notifications',
  OneTimeReportSended = 'one-time-report-sended',
  QueueAbandonmentMessage = 'queue-abandonment-message',
  ReportBug = 'report-bug',
  RichGreetingTourInvitation = 'rich-greeting-tour-invitation',
  RoutingRulesTourInvitation = 'routing-rules-tour-invitation',
  SharePayment = 'share-payment',
  SomethingWentWrong = 'something-went-wrong',
  StatusReminder = 'status-reminder',
  StopChat = 'stop-chat',
  SuperviseChat = 'supervise-chat',
  TargetedMessagesTourInvitation = 'targeted-messages-tour-invitation',
  TrialExpiring = 'trial-expiring',
  PastDueNotification = 'past-due-notification',
  NewsAndAlerts = 'news-and-alerts',
  KnowledgeSourceAdded = 'knowledge-source-added',
  InviteAgents = 'invite-agents',
  ExceededSessionsLimit = 'exceeded-sessions-limit',
  TestChat = 'test-chat',
  AddCreditCardDetails = 'add-credit-card-details',
  IntegrationsOrderSettings = 'integrations-order-settings',
  Spotlight = 'spotlight',
  RemoveTrustedDomain = 'remove-trusted-domain',
  RemoveWebhook = 'remove-webhook',
  AddWebhook = 'add-webhook',
  Logout = 'logout',
  CustomStatusModal = 'custom-status',
  BanCustomer = 'ban-customer',
  TransferChat = 'transfer-chat',
  CreateTicket = 'create-ticket',
  SendTranscript = 'send-transcript',
  HelpDeskUpsell = 'help-desk-upsell',
  CloseChat = 'close-chat',
}
