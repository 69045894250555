import { MARKETPLACE_IFRAME_ID } from 'constants/apps/marketplace';
import type { PostMessageEvent } from 'constants/post-message-event';
import type { PostMessage } from 'interfaces/post-message';

import { getConfig } from './config';
const marketplaceDomain = getConfig().devMarketplace;

export const sendPostMessageToMarketplace = (eventName: PostMessageEvent, data?: PostMessage['event_data']): void => {
  const marketplaceIframe = document.getElementById(MARKETPLACE_IFRAME_ID) as HTMLIFrameElement;

  const message = JSON.stringify({
    /* eslint-disable @typescript-eslint/naming-convention */
    event_name: eventName,
    event_data: data || null,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  marketplaceIframe?.contentWindow?.postMessage(message, marketplaceDomain);
};

export const sendPostMessageToIframe = <EventName, Data>({
  eventName,
  data,
  iframeId,
  targetOrigin,
}: {
  eventName: EventName;
  data?: Data;
  iframeId: string;
  targetOrigin: string;
}): void => {
  const element = document.getElementById(iframeId) as HTMLIFrameElement;

  const message = JSON.stringify({
    /* eslint-disable @typescript-eslint/naming-convention */
    event_name: eventName,
    event_data: data || null,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  element?.contentWindow?.postMessage(message, targetOrigin);
};
