// @ts-strict-ignore
import type { IKeyValue, ParamValue } from 'helpers/url';

export type LocalStorageItem = ParamValue | ParamValue[] | IKeyValue;
export enum LocalStorageKey {
  BeamerHidden = 'beamer-hidden',
  CustomersIPFilter = 'customers_ip_filter',
  DarkModeOnAllSections = 'dark_mode_on_all_sections',
  DarkModeSwitchEnabled = 'dark_mode_switch_enabled',
  Debug = 'debug',
  DevTools = 'dev-tools',
  /**
   * This key is designed for situations where a license has expired, yet we still permit agent login. Under these circumstances, WebSocket functionality is disabled, and many RESTful APIs may return error responses. However, endpoints related to subscriptions will continue to operate, enabling actions such as license renewal through payment.
   */
  EmergencySignIn = 'emergencySignIn',
  Environment = 'environment',
  HomeEnableKbBoosterPromoBannerClosed = 'home-enable-kb-booster-promo-banner-closed',
  HomeStartKbTrialPromoBannerClosed = 'home-start-kb-trial-promo-banner-closed',
  MockCodeNotInstalled = 'mock-code-not-installed',
  NewCustomerDetailsOnArchives = 'new-customer-details-on-archives',
  OnboardingDirectLinkAccessEnabled = 'allow-onboarding-direct-link-access',
  RichTextEditor = 'rich-text-editor',
  RedirectAfterInstallCode = 'redirect-after-install-code',
  ReportsDiscoverKbTrialPromoBannerClosed = 'reports-discover-kb-trial-promo-banner-closed',
  ReportsStartKbTrialPromoBannerClosed = 'reports-start-kb-trial-promo-banner-closed',
  SprigHidden = 'sprig-hidden',
  SubscriptionBetaEnabled = 'subscription-beta',
  TimeFormat = 'lc_time_format_24',
  AISuggestions = 'ai-suggestions',
  PriorityChat = 'priority-chat',
  UserTheme = 'user-theme',
}

type LocalStorageKeyType = LocalStorageKey | string;

const supportsLocalStorage = (): boolean => !!window.localStorage;

/**
 * Retrieves an item from local storage.
 * @param {LocalStorageKeyType} name - The name of the item to retrieve.
 * @returns {LocalStorageItem | null} The retrieved item or null if not found or on error.
 */
export function getItem<T = LocalStorageItem>(name: LocalStorageKeyType): T | null {
  if (!supportsLocalStorage()) return null;

  let value: string | null;
  try {
    value = window.localStorage.getItem(name);
  } catch {
    return null;
  }

  if (value === null) return null;

  try {
    return JSON.parse(value) as T;
  } catch {
    return value as T;
  }
}

/**
 * Saves an item to local storage.
 * @param {LocalStorageKeyType} name - The name of the item to save.
 * @param {LocalStorageItem} value - The value of the item to save.
 * @returns {boolean} True if the item was saved successfully, false otherwise.
 */
export function saveItem(name: LocalStorageKeyType, value: LocalStorageItem): boolean {
  if (!supportsLocalStorage()) return false;

  try {
    const item: string = typeof value === 'string' ? value : JSON.stringify(value);
    window.localStorage.setItem(name, item);

    return true;
  } catch {
    return false;
  }
}

/**
 * Removes an item from local storage.
 * @param {LocalStorageKeyType} name - The name of the item to remove.
 * @returns {boolean} True if the item was removed successfully, false otherwise.
 */
export function removeItem(name: LocalStorageKeyType): boolean {
  if (!supportsLocalStorage()) return false;

  try {
    window.localStorage.removeItem(name);

    return true;
  } catch {
    return false;
  }
}
