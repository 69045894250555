import { css, keyframes } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { NOTIFICATIONS_BAR_HEIGHT } from 'constants/notifications-bar';
import { SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { mobileMediaQuery } from 'helpers/media-queries';
import { resetLegacyLcBorderBox } from 'styles/reset-border-box';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { TOP_BAR_HEIGHT, MOBILE_TOP_BAR_HEIGHT } from './constants';

const rotateAndPulse = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  20% {
    transform: rotate(72deg);
    opacity: 0.8;
  }
  40% {
    transform: rotate(144deg);
    opacity: 0.6;
  }
  60% {
    transform: rotate(216deg);
    opacity: 0.6;
  }
  80% {
    transform: rotate(288deg);
    opacity: 0.8;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
`;

export const whiteIcon = css`
  color: var(${DesignToken.ContentWhiteLocked});
`;

export const containerWarning = css`
  color: var(${DesignToken.ContentLockedBlack});
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighWarning});
  line-height: 1.3;
  border-radius: var(${RadiusToken.Radius2});

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const centerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const containerSuccess = css`
  color: var(${DesignToken.ColorWhite});
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighPositive});
  border-radius: var(${RadiusToken.Radius2});
  line-height: 1.3;

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const trialExpiringBar = css`
  justify-content: center;
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighInfo});
  border-radius: var(${RadiusToken.Radius2});

  span {
    color: var(${DesignToken.ContentLockedWhite});
  }

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const numberCard = (warning: boolean): string => css`
  width: 20px;
  height: 25px;
  line-height: 25px;
  border-radius: var(${RadiusToken.Radius1});
  margin-right: 4px;
  color: ${warning ? '#fff' : '#424d57'};
  background-color: ${warning ? '#d64646' : '#f1f6f8'};
  ${mobileMediaQuery} {
    width: 16px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
  }
`;

export const linkStyles = css`
  display: none;

  ${mobileMediaQuery} {
    display: block;

    color: var(${DesignToken.ContentLockedWhite});

    &:hover {
      color: var(${DesignToken.ContentLockedWhite});
    }
  }
`;

export const buttonStyles = css`
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const contentContainer = css`
  position: relative;
  top: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-evenly;
  color: var(${DesignToken.Background});

  /**
   * I am disabling CSS transitions for the notifications until we fully migrate from Backbone to React.
   */
  /*
    height: 0;
    padding: 0;
  */
  height: ${NOTIFICATIONS_BAR_HEIGHT};
  padding: 8px 0;

  line-height: 0;
  overflow: hidden;

  ${mobileMediaQuery} {
    font-size: 12px;
    height: ${MOBILE_TOP_BAR_HEIGHT};
  }

  & a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const notificationBarContainer = css`
  height: 0;
  padding: 0;
  line-height: 0;

  .animate-enter {
    height: 0;
    padding: 0;
  }

  .animate-enter-done {
    height: ${NOTIFICATIONS_BAR_HEIGHT};
    padding: 8px 0;
    border-radius: var(${SpacingToken.Spacing2});

    @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
      border-radius: 0;
    }
  }

  .animate-enter-active {
    height: ${NOTIFICATIONS_BAR_HEIGHT};
    padding: 8px 0;
    transition: all 0.4s cubic-bezier(0.33, 0, 0.67, 1);
    transition-delay: 0.4s;
  }

  .animate-exit {
    height: ${NOTIFICATIONS_BAR_HEIGHT};

    padding: 8px 0;
  }

  .animate-exit-done {
    height: 0;
    padding: 0;
  }

  .animate-exit-active {
    height: 0;
    padding: 0;
    transition: all 0.4s cubic-bezier(0.33, 0, 0.67, 1);
    transition-delay: 4s;
  }

  ${mobileMediaQuery} {
    .animate-enter {
      height: 0;
    }

    .animate-enter-done {
      height: ${MOBILE_TOP_BAR_HEIGHT};
    }

    .animate-enter-active {
      height: ${MOBILE_TOP_BAR_HEIGHT};
    }

    .animate-exit {
      height: ${MOBILE_TOP_BAR_HEIGHT};
    }

    .animate-exit-active {
      height: 0px;
    }
  }
`;

export const button = css`
  margin: 0 8px 0 18px;
  color: var(${DesignToken.ContentLockedDefault});
  background-color: var(${DesignToken.SurfaceLockedDefault});
  border: none;
  @media (max-width: 830px) {
    line-height: 1.3;
  }

  &:hover {
    color: var(${DesignToken.ContentLockedBlack});
    background-color: var(${DesignToken.SurfaceLockedHover});
    cursor: pointer;
  }
`;

export const blackButton = css`
  margin: 0 8px 0 18px;
  background-color: var(${DesignToken.ActionLockDefault});
  color: var(${DesignToken.ContentLockedWhite});
  border: none;

  @media (max-width: 830px) {
    line-height: 1.3;
  }

  &:hover {
    background-color: var(${DesignToken.ActionLockHover});
    color: var(${DesignToken.ContentLockedWhite});
    cursor: pointer;
  }

  &:focus {
    color: var(${DesignToken.ContentLockedWhite});
    background-color: var(${DesignToken.ActionLockActive});
  }
`;

export const iconButton = css`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

export const warningContainer = css`
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighWarning});
  color: var(${DesignToken.ContentLockedBlack});
  border-radius: var(${RadiusToken.Radius2});

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const infoContainer = css`
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighInfo});
  border-radius: var(${RadiusToken.Radius2});
  color: var(${DesignToken.ContentLockedWhite});

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const errorContainer = css`
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighNegative});
  color: var(${DesignToken.ContentLockedWhite});
  border-radius: var(${RadiusToken.Radius2});

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const stayAwayContainer = css`
  justify-content: center;
  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    display: flex;
    flex-direction: column;

    line-height: 14px;
    font-size: 14px;

    button {
      margin-top: 2px;
      color: var(${DesignToken.ContentLockedWhite});
    }
  }
`;

export const skippableContent = css`
  width: calc(100% - 50px);
  display: flex;
  flex: 0 1 auto;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  ${mobileMediaQuery} {
    width: calc(100% - 20px);
  }
`;

export const textStyles = css`
  ${ellipsis()};
`;

export const unblockNotificationTextStyles = css`
  margin-left: 8px;
`;

export const codeNotInstalledBarContent = css`
  width: calc(100% - 50px);
  display: flex;
  flex: 0 1 auto;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  span {
    line-height: 16px;
  }

  @media (max-width: 810px) {
    flex-direction: column;
  }
`;

export const codeNotInstalledBarText = css`
  min-width: 350px;
`;

export const codeNotInstalledDesktopButtons = css`
  display: flex;
  margin-left: var(${SpacingToken.Spacing2});
  button {
    margin: 0 var(${SpacingToken.Spacing1});
  }

  @media (max-width: 810px) {
    display: none;
  }
`;

export const codeNotInstalledMobileButtons = css`
  display: none;

  button {
    color: var(${DesignToken.ContentLockedBlack});

    &:hover {
      color: var(${DesignToken.ContentLockedBlack});
    }
  }
  @media (max-width: 810px) {
    display: flex;
    gap: var(${SpacingToken.Spacing2});
  }
`;

export const topBarWrapper = css`
  ${resetLegacyLcBorderBox};
  height: 100%;
  background-color: var(${DesignToken.NavbarBackground});
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  overflow: hidden;
  position: relative;
`;

export const alertWrapper = css`
  position: fixed;
  right: 54px;
  top: 7.5px;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  transform: translateX(24px);
  opacity: 0;
  transition-delay: 0.5s;

  & > div {
    margin-left: 4px;
  }

  &.slide-in {
    transform: translateX(0);
    opacity: 1;
  }

  &.slide-out {
    transform: translateX(100%);
    opacity: 0;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-done {
    opacity: 1;
  }

  .fade-enter-active {
    transition: all 0.4s cubic-bezier(0.33, 0, 0.67, 1);
    transition-delay: 1s;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-done {
    opacity: 0;
  }

  .fade-exit-active {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.33, 0, 0.67, 1);
    transition-delay: 4s;
  }
`;

export const contentWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const triggerBackground = (isDarkMode: boolean): string => css`
  background-color: var(${DesignToken.DecorGray700});
  opacity: ${isDarkMode ? 0.5 : 0.7};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
`;

export const spotlightWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1030px) {
    justify-content: flex-start;
  }
`;

export const spotlightTrigger = (isDarkMode: boolean): string => css`
  ${resetLegacyLcBorderBox};
  width: 100%;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: var(${DesignToken.ContentLockedWhite});
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width 0.5s ease;
  padding: 5px var(${SpacingToken.Spacing1}) 5px var(${SpacingToken.Spacing3});

  &:hover {
    cursor: pointer;
  }

  &:hover .spotlightTrigger {
    background-color: var(${DesignToken.DecorGray700});
    opacity: ${isDarkMode ? 0.75 : 0.85};
  }

  @media (max-width: 750px) {
    // preventing spotlight collapse with InviteAgents component
    max-width: 300px;
  }
`;

export const searchWrapper = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  flex-shrink: 0;
  opacity: 1;
  z-index: 1;
`;

export const shortcutWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(${SpacingToken.Spacing05});
  opacity: 1;
`;

export const blackTag = css`
  background-color: var(${DesignToken.NavbarBackground});
  color: var(${DesignToken.ContentLockedWhite});
`;

export const copilotLogoContainer = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 3px;
  width: 36px;
  height: 36px;
  top: 6px;
`;

export const imageContainer = css`
  position: relative;
  display: contents;
`;

export const copilotUnderlayer = css`
  position: absolute;
  width: var(${SpacingToken.Spacing12});
  height: var(${SpacingToken.Spacing12});
`;

export const copilotAssistant = css`
  position: relative;
  width: var(${SpacingToken.Spacing7});
  height: var(${SpacingToken.Spacing7});
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`;

export const borderAnimation = css`
  animation: ${rotateAndPulse} 1s cubic-bezier(0.015, 0.19, 0.09, 1.01);
`;

export const copilotBadge = css`
  position: absolute;
  top: 0px;
  right: 0px;
  border: var(${SpacingToken.Spacing05}) solid var(${DesignToken.NavbarBackground});

  span {
    max-width: var(${SpacingToken.Spacing1});
    max-height: var(${SpacingToken.Spacing1});
  }
`;

export const copilotPopoverShortcuts = css`
  ${shortcutWrapper};
  margin-top: var(${SpacingToken.Spacing1});
`;

export const shortcut = css`
  border: none;
  margin-right: var(${SpacingToken.Spacing1});
`;

export const actionsOnboardingTooltip = css`
  position: fixed !important;
  z-index: ${StackingContextLevel.NavigationTooltip};
  background: var(${DesignToken.OneTooltipBackground});
  padding: var(${SpacingToken.Spacing4});
`;

export const actionsOnboardingHeader = css`
  display: flex;
  align-items: center;

  span {
    margin-right: var(${SpacingToken.Spacing2});
    margin-right: var(${SpacingToken.Spacing2});
    border: 1px solid transparent;
    border-radius: 50px;
    background-image: var(${DesignToken.OnePopoverBorderActive});
    background-origin: border-box;
  }
`;

export const actionsOnboardingText = css`
  margin: var(${SpacingToken.Spacing2}) 0 var(${SpacingToken.Spacing2});
`;

export const actionsOnboardingShortcutWrapper = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing05});
  opacity: 1;
  margin-bottom: var(${SpacingToken.Spacing3});
`;

export const actionsOnboardingBlackTag = css`
  background-color: var(${DesignToken.SurfaceOpacityBasicActive});
  color: var(${DesignToken.ContentBasicPrimary});
`;

export const closeBtn = css`
  position: absolute;
  top: 8px;
  right: 0px;
`;

export const topBarContainer = css`
  height: ${TOP_BAR_HEIGHT};
`;

export const joinEventButton = css`
  min-width: 166px;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const joinEventButtonMobile = css`
  display: none;

  @media (max-width: 450px) {
    display: block;
  }
`;

export const joinEventText = css`
  padding-right: var(${SpacingToken.Spacing4});
  padding-left: var(${SpacingToken.Spacing1});
  @media (max-width: 450px) {
    display: none;
  }
`;

export const joinEventTextMobile = css`
  display: none;

  @media (max-width: 450px) {
    padding: 0 var(${SpacingToken.Spacing1});
    display: block;
  }
`;

export const joinEventSkippableContent = css`
  width: 100%;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const eventCloseButton = css`
  color: var(${DesignToken.ContentBasicPrimary});
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: var(${DesignToken.ContentBasicPrimary});
  }
`;
