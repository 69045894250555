import { memo, useMemo, type FC } from 'react';

import { Loader } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { OneClickPayment } from 'components/one-click-payment/OneClickPayment';
import { SafeIframe } from 'components/safe-iframe/SafeIframe';
import { MARKETPLACE_IFRAME_ID } from 'constants/apps/marketplace';
import { getWidgetDomain } from 'helpers/url';
import { getFullMarketplaceUrl } from 'routes/apps/hooks/use-marketplace-post-messages/helpers';
import { useMarketplacePostMessages } from 'routes/apps/hooks/use-marketplace-post-messages/use-marketplace-post-messages';
import { getHasVisitedMarketplace } from 'store/views/navigation/selectors';

import { IFRAME_SCOPES } from './constants';

import * as styles from './styles';

export const Marketplace: FC = memo(() => {
  const { isMarketplaceReady } = useMarketplacePostMessages();
  const hasVisitedMarketplace = useSelector(getHasVisitedMarketplace);
  // only set marketplaceUrl once it has been visited - properly handles navigating for the first time to MP subpaths
  const marketplaceUrl = useMemo(() => (hasVisitedMarketplace ? getFullMarketplaceUrl() : ''), [hasVisitedMarketplace]);
  const marketplaceDomain = getWidgetDomain(marketplaceUrl);

  return (
    <div data-testid="marketplace-iframe-container" className={styles.container}>
      {!isMarketplaceReady && (
        <div className={styles.loader}>
          <Loader size="large" />
        </div>
      )}

      {hasVisitedMarketplace && (
        <>
          <OneClickPayment targetOrigin={marketplaceDomain} iframeId={MARKETPLACE_IFRAME_ID} />
          <SafeIframe
            id={MARKETPLACE_IFRAME_ID}
            aria-label="Marketplace"
            src={marketplaceUrl}
            className={styles.iframe}
            allow={IFRAME_SCOPES}
            data-testid="marketplace-iframe"
          />
        </>
      )}
    </div>
  );
});
