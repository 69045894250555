import type { StateSnapshot } from 'react-virtuoso';

import { type CopilotViewState } from './interfaces';

export function getStateForResetCopilotUnreadMessagesCount(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    unreadMessagesCount: 0,
  };
}

export function getStateForCopilotIsLoading(state: CopilotViewState, isLoading: boolean): CopilotViewState {
  return {
    ...state,
    isLoading,
  };
}

export function getStateForIncreaseCopilotUnreadMessagesCount(
  state: CopilotViewState,
  count: number,
): CopilotViewState {
  return {
    ...state,
    unreadMessagesCount: state.unreadMessagesCount + count,
  };
}

export function getStateForSetCopilotListStateSnapshot(
  state: CopilotViewState,
  listStateSnapshot: StateSnapshot | null,
): CopilotViewState {
  return {
    ...state,
    listStateSnapshot,
  };
}

export function getStateForSaveCopilotDraftMessage(state: CopilotViewState, draftMessage: string): CopilotViewState {
  return {
    ...state,
    draftMessage,
  };
}

export function getStateForRemoveCopilotDraftMessage(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    draftMessage: '',
  };
}

export function getStateForCancelCopilotRequest(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    isLoading: false,
  };
}

export function getStateForShowCopilotModal(state: CopilotViewState, isExpanded?: boolean): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: true,
      isExpanded: isExpanded ?? state.modal.isExpanded,
    },
  };
}

export function getStateForHideCopilotModal(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: false,
    },
  };
}

export function getStateForToggleCopilotModalExpand(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isExpanded: !state.modal.isExpanded,
    },
  };
}

export function getStateForToggleCopilotModal(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: !state.modal.isOpened,
    },
  };
}

export function getStateForOpenCopilotDetails(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isDetailsOpened: true,
    },
  };
}

export function getStateForCloseCopilotDetails(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isDetailsOpened: false,
    },
  };
}

export function getStateForSetScrollPosition(state: CopilotViewState, scrollPosition: number | null): CopilotViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      scrollPosition,
    },
  };
}

export function getStateForShowCopilotNewMessageTooltip(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    isNewMessageToastVisible: true,
  };
}

export function getStateForHideCopilotNewMessageTooltip(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    isNewMessageToastVisible: false,
  };
}

export function getStateForOpenCommandSuggestions(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    isCommandSuggestionsOpened: true,
  };
}

export function getStateForCloseCommandSuggestions(state: CopilotViewState): CopilotViewState {
  return {
    ...state,
    isCommandSuggestionsOpened: false,
  };
}

export function getStateForWidgetWebsite(state: CopilotViewState, widgetWebsite: string): CopilotViewState {
  return {
    ...state,
    widgetWebsite,
  };
}
