// @ts-strict-ignore
import { type ChatEntitiesAction, ChatsEntitiesActionNames } from 'store/entities/chats/actions';
import { type CopilotEntitiesAction } from 'store/entities/copilot/actions';

import { type ChatsViewAction, ChatsViewActionsNames } from './actions';
import {
  getStateForChangeTagReminderVisibility,
  getStateForClearAwaitingNavigation,
  getStateForClearKnowledgeSuggestionsToolbarState,
  getStateForFetchChatHistory,
  getStateForFetchChatHistoryCompleted,
  getStateForFetchThreadDetailsFailure,
  getStateForInaccessibleChatId,
  getStateForIncomingChatThread,
  getStateForMarkChatAsNew,
  getStateForMarkCustomerAsAwaitingNavigation,
  getStateForRemoveDraftMessage,
  getStateForRemovePrivateMode,
  getStateForRemoveScollPosition,
  getStateForRemoveTextEnhancementsToolbarStates,
  getStateForRemoveThreadTextEnhancementsToolbarState,
  getStateForSaveDraftMessage,
  getStateForSaveKnowledgeSuggestionsToolbarState,
  getStateForSavePrivateMode,
  getStateForSaveTextEnhancementsToolbarState,
  getStateForSendMessage,
  getStateForSetChatTagFailure,
  getStateForSetChatTagRequest,
  getStateForSetChatTagSuccess,
  getStateForSetData,
  getStateForSetKnowledgeSuggestionRequestId,
  getStateForSetLoadMoreUnassignedChats,
  getStateForSetScrollPosition,
  getStateForSetSelectedId,
  getStateForSetTextEnhancementRequestId,
  getStateForChatTransfer,
  getStateForUnmarkChatAsNew,
  getStateForUnsetLoadMoreUnassignedChats,
  getStateFormNotificationsCountUpdate,
  getStateforSetChatsSortOrder,
  getStateForSetCurrentMessageboxValue,
  getStateForToggleTextEnhancementsToolbar,
  getStateForOpenTextEnhancementsToolbar,
  getStateForCloseTextEnhancementsToolbar,
  getStateForSetThreadsEvents,
} from './helpers/reducer';
import { type IChatsViewState } from './interfaces';

export const initialState: IChatsViewState = {
  customerIdAwatingNavigation: null,
  draftMessages: {},
  privateMode: {},
  textEnhancements: {
    toolbarState: {},
    currentThreadSessions: {},
    isOpen: false,
  },
  newChatThreadIds: [],
  visitedThreadIds: [],
  inaccessibleChatIds: [],
  isFetchingHistory: {},
  isFetchingMoreUnassignedChats: false,
  isTransferringChat: false,
  selectedId: null,
  scrollPosition: {},
  tagsOperations: {},
  sortOrderMap: {},
  notificationsCount: 0,
  replySuggestions: {
    toolbarState: {},
    currentThreadSessions: {},
  },
  currentMessageboxValue: '',
};

export const chatsReducer = (
  state: IChatsViewState = initialState,
  action: ChatsViewAction | ChatEntitiesAction | CopilotEntitiesAction,
): IChatsViewState => {
  switch (action.type) {
    case ChatsViewActionsNames.MARK_CHAT_AS_NEW: {
      return getStateForMarkChatAsNew(state, action.payload);
    }
    case ChatsViewActionsNames.UNMARK_CHAT_AS_NEW: {
      return getStateForUnmarkChatAsNew(state, action.payload);
    }
    case ChatsViewActionsNames.MARK_CUSTOMER_AS_AWAITING_NAVIGATION: {
      return getStateForMarkCustomerAsAwaitingNavigation(state, action.payload);
    }
    case ChatsViewActionsNames.CLEAR_AWAITING_NAVIGATION: {
      return getStateForClearAwaitingNavigation(state);
    }
    case ChatsViewActionsNames.SET_SELECTED_ID: {
      return getStateForSetSelectedId(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_THREADS_EVENTS: {
      return getStateForSetThreadsEvents(state, action.payload);
    }
    case ChatsViewActionsNames.SET_SCROLL_POSITION: {
      return getStateForSetScrollPosition(state, action.payload);
    }
    case ChatsViewActionsNames.CLEAR_SCROLL_POSITION: {
      return getStateForRemoveScollPosition(state, action.payload);
    }
    case ChatsViewActionsNames.SET_CHAT_TAG_REQUEST: {
      return getStateForSetChatTagRequest(state, action.payload);
    }
    case ChatsViewActionsNames.SET_CHAT_TAG_SUCCESS: {
      return getStateForSetChatTagSuccess(state, action.payload);
    }
    case ChatsViewActionsNames.SET_CHAT_TAG_FAILURE: {
      return getStateForSetChatTagFailure(state, action.payload);
    }
    case ChatsViewActionsNames.UNSET_CHAT_TAG_REQUEST: {
      return getStateForSetChatTagRequest(state, action.payload);
    }
    case ChatsViewActionsNames.UNSET_CHAT_TAG_SUCCESS: {
      return getStateForSetChatTagSuccess(state, action.payload);
    }
    case ChatsViewActionsNames.UNSET_CHAT_TAG_FAILURE: {
      return getStateForSetChatTagFailure(state, action.payload);
    }
    case ChatsViewActionsNames.UPDATE_NOTIFICATIONS_COUNT: {
      return getStateFormNotificationsCountUpdate(state, action.payload);
    }
    case ChatsViewActionsNames.SEND_MESSAGE: {
      return getStateForSendMessage(state, action.payload);
    }
    case ChatsViewActionsNames.CHANGE_TAG_REMINDER_VISIBILITY: {
      return getStateForChangeTagReminderVisibility(state, action.payload);
    }
    case ChatsViewActionsNames.LOAD_MORE_UNASSIGNED_CHATS: {
      return getStateForSetLoadMoreUnassignedChats(state);
    }
    case ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS: {
      return getStateForUnsetLoadMoreUnassignedChats(state);
    }
    case ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY: {
      return getStateForSetLoadMoreUnassignedChats(state);
    }
    case ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS: {
      return getStateForUnsetLoadMoreUnassignedChats(state);
    }
    case ChatsViewActionsNames.SAVE_DRAFT_MESSAGE: {
      return getStateForSaveDraftMessage(state, action.payload);
    }
    case ChatsViewActionsNames.REMOVE_DRAFT_MESSAGE: {
      return getStateForRemoveDraftMessage(state, action.payload);
    }
    case ChatsEntitiesActionNames.FETCH_CHAT_HISTORY: {
      return getStateForFetchChatHistory(state, action.payload);
    }
    case ChatsEntitiesActionNames.FETCH_CHAT_HISTORY_COMPLETED: {
      return getStateForFetchChatHistoryCompleted(state, action.payload);
    }
    case ChatsViewActionsNames.SAVE_PRIVATE_MODE: {
      return getStateForSavePrivateMode(state, action.payload);
    }
    case ChatsViewActionsNames.REMOVE_PRIVATE_MODE: {
      return getStateForRemovePrivateMode(state, action.payload);
    }
    case ChatsViewActionsNames.SET_CHATS_SORT_ORDER: {
      return getStateforSetChatsSortOrder(state, action.payload);
    }
    case ChatsEntitiesActionNames.SET_DATA: {
      return getStateForSetData(state);
    }
    case ChatsEntitiesActionNames.FETCH_CHAT_THREAD_DETAILS_FAILURE: {
      return getStateForFetchThreadDetailsFailure(state, action.payload);
    }
    case ChatsViewActionsNames.SET_INACCESSIBLE_CHAT_ID: {
      return getStateForInaccessibleChatId(state, action.payload);
    }
    case ChatsEntitiesActionNames.INCOMING_CHAT_THREAD: {
      return getStateForIncomingChatThread(state, action.payload);
    }
    case ChatsViewActionsNames.SAVE_TEXT_ENHANCEMENTS_TOOLBAR_STATE: {
      return getStateForSaveTextEnhancementsToolbarState(state, action.payload);
    }
    case ChatsViewActionsNames.REMOVE_THREAD_TEXT_ENHANCEMENTS_TOOLBAR_STATE: {
      return getStateForRemoveThreadTextEnhancementsToolbarState(state, action.payload);
    }
    case ChatsViewActionsNames.REMOVE_TEXT_ENHANCEMENTS_TOOLBAR_STATES: {
      return getStateForRemoveTextEnhancementsToolbarStates(state);
    }
    case ChatsViewActionsNames.SET_TEXT_ENHANCEMENTS_REQUEST_ID: {
      return getStateForSetTextEnhancementRequestId(state, action.payload);
    }
    case ChatsViewActionsNames.SAVE_REPLY_SUGGESTIONS_TOOLBAR_STATE: {
      return getStateForSaveKnowledgeSuggestionsToolbarState(state, action.payload);
    }
    case ChatsViewActionsNames.REMOVE_REPLY_SUGGESTIONS_TOOLBAR_STATE: {
      return getStateForClearKnowledgeSuggestionsToolbarState(state, action.payload);
    }
    case ChatsViewActionsNames.SET_REPLY_SUGGESTIONS_REQUEST_ID: {
      return getStateForSetKnowledgeSuggestionRequestId(state, action.payload);
    }
    case ChatsViewActionsNames.CANCEL_REPLY_SUGGESTION_REPLY: {
      return getStateForClearKnowledgeSuggestionsToolbarState(state, action.payload);
    }
    case ChatsViewActionsNames.TRANSFER_CHAT: {
      return getStateForChatTransfer(state, true);
    }
    case ChatsViewActionsNames.TRANSFER_CHAT_SUCCESSFUL:
    case ChatsViewActionsNames.TRANSFER_CHAT_FAILURE: {
      return getStateForChatTransfer(state, false);
    }
    case ChatsViewActionsNames.SET_CURRENT_MESSAGEBOX_VALUE: {
      return getStateForSetCurrentMessageboxValue(state, action.payload);
    }
    case ChatsViewActionsNames.TOGGLE_TEXT_ENAHANCEMENTS_TOOLBAR: {
      return getStateForToggleTextEnhancementsToolbar(state);
    }

    case ChatsViewActionsNames.OPEN_TEXT_ENHANCEMENTS_TOOLBAR: {
      return getStateForOpenTextEnhancementsToolbar(state);
    }

    case ChatsViewActionsNames.CLOSE_TEXT_ENHANCEMENTS_TOOLBAR: {
      return getStateForCloseTextEnhancementsToolbar(state);
    }

    default:
      return state;
  }
};
