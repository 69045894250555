import { App } from 'config/setup';
import { BusinessModel } from 'constants/business-model';
import { type PlanType } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { LicenseType, type PartnerType } from 'interfaces/entities/current-license';
import { getLastActivityInDays } from 'services/activity/website-last-activity';
import { AppStateProvider } from 'services/app-state-provider';
import {
  BillingProperty,
  CoreProperty,
  PlatformNamespace,
} from 'services/connectivity/configuration-api/properties/constants';
import { markUserAsLoggedIn } from 'services/mark-user-as-logged-in';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { handleLogin as handleLoginForChats } from 'services/socket-lc3/chat/event-handling/login';
import type { ILoginResponsePushEvent } from 'services/web-socket/interfaces';
import { CodeInstallationActions } from 'store/features/code-installation/actions';
import { SessionActions } from 'store/features/session/actions';

const queryClient = getQueryClient();

function getLicenseProperty<T>(namespace: PlatformNamespace, property: BillingProperty | CoreProperty): T | undefined {
  return queryClient.getQueryData(QUERY_KEYS[QueryKey.LicenseProperties](namespace))?.[property] as T;
}

export function handleLoginSuccessful(payload: ILoginResponsePushEvent): void {
  handleLoginForChats(payload);

  AppStateProvider.dispatch(
    CodeInstallationActions.parseLicenseInfo({
      daysSinceLastActivity: getLastActivityInDays(),
    }),
  );

  const { email: login } = payload.my_profile;

  AppStateProvider.dispatch(
    SessionActions.saveAuthenticationCredentials({
      login,
    }),
  );

  if (App.SmartClient) {
    App.SmartClient.agentLoggedIn({
      login,
      appUrl: `${window.location.origin}/`,
    });
  }

  const expirationTimestamp = payload.license.expiration_timestamp;
  const licenseType = payload.license.in_trial ? LicenseType.Trial : LicenseType.Paid;

  const partnerType = getLicenseProperty<PartnerType>(PlatformNamespace.CORE, CoreProperty.PartnerType);
  const salesPlan = getLicenseProperty<PlanType>(PlatformNamespace.BILLING, BillingProperty.SalesPlan);
  const businessModel =
    getLicenseProperty<BusinessModel>(PlatformNamespace.BILLING, BillingProperty.PaymentBusinessModel) ||
    BusinessModel.PayPerSeat;

  AppStateProvider.dispatch(
    SessionActions.saveCurrentLicense({
      expiresAt: expirationTimestamp,
      type: licenseType,
      plan: salesPlan,
      partnerType,
      businessModel,
      licenseId: parseInt(payload.license.id, 10),
      createdAt: payload.license.creation_timestamp,
    }),
  );

  App.platformProtocolParser?.handleSuccessfulLogin(payload.chats_summary);
  void markUserAsLoggedIn();
}
