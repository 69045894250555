/*
  TextEnhancement enum used by useTextEnhancement
  Each value in this enum should match name of method on TextEnhancementsClient:
  `source/tsx/services/connectivity/ml-gateway-api/text-enhancements/client.ts`
*/
export enum MLGatewayTextEnhancement {
  TextTranslation = 'createTextTranslation',
  TextSentiment = 'createTextSentiment',
  TextGrammar = 'createTextGrammarCorrection',
  TextReword = 'createTextReword',
}
