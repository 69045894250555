import { type FC } from 'react';

import { Badge } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import oneActive from 'assets/img/automation/One-icon-active.png';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalOpened, getCopilotUndreadMessageCount } from 'store/views/copilot/selectors';

import * as styles from './styles';

export const MobileNavigationOne: FC = () => {
  const dispatch = useDispatch();

  const isOneOpened = useSelector(getIsCopilotModalOpened);
  const unreadOneMessages = useSelector(getCopilotUndreadMessageCount);

  const shouldDisplayOneBadge = unreadOneMessages > 0 && !isOneOpened;

  const toggleOneModal = (): void => {
    if (isOneOpened) {
      dispatch(CopilotViewActions.hideModal());

      return;
    }

    dispatch(CopilotViewActions.showModal());
  };

  return (
    <div className={styles.mobileNavigationItem} onClick={toggleOneModal}>
      <div className={styles.contentWrapper(isOneOpened)}>
        <img className={styles.oneImage} src={oneActive} alt="Copilot AI assistant" />
        {shouldDisplayOneBadge && <Badge type="dot" size="compact" className={styles.badge} />}
      </div>
    </div>
  );
};
