/* eslint-disable @typescript-eslint/naming-convention */
export const COPILOT_POPOVER_RELEASE_DATE = '2024-07-04';
export const KNOWLEDGE_HUB_RELEASE_DATE = '2024-09-26';
export const COPILOT_WELCOME_MESSAGE_DELAY = 2000;

/* Based on source/tsx/_constants/settings/navigation-items.ts due to
store access used in NavigationItems which crashes agent app if we use this in `one` sagas.
*/
export const SETTINGS_NAVIGATION_PATHS = {
  Channels: [
    '/settings/overview',
    '/settings/code',
    '/settings/email',
    '/settings/helpdesk',
    '/settings/facebook-messenger',
    '/settings/apple-messages-for-business',
  ],
  'Chat page': ['/settings/chat-page'],
  'Website widget': ['/settings/theme', '/settings/language', '/settings/availability', '/settings/welcome-screen'],
  Forms: ['/settings/pre-chat-form', '/settings/ask-for-email', '/settings/post-chat-form', '/settings/ticket-form'],
  Engagement: [
    '/settings/targeted-messages/greetings',
    '/settings/eye-catcher',
    '/settings/chat-buttons',
    '/settings/quality-badge',
  ],
  'Reply suggestions': ['/settings/reply-suggestions'],
  'Canned responses': ['/settings/canned-responses', '/settings/suggested-responses'],
  Tags: ['/settings/tags'],
  'Sales tracker': ['/settings/sales-tracker'],
  'Chat settings': [
    '/settings/chats-assignment',
    '/settings/groups-routing',
    '/settings/archives-forwarding',
    '/settings/file-sharing',
    '/settings/inactivity-timeouts',
  ],
  'Ticket settings': ['/settings/automated-emails'],
  Security: [
    '/settings/trusted-domains',
    '/settings/two-step-verification',
    '/settings/banned-customers',
    '/settings/access-restriction',
    '/settings/credit-cards-masking',
    '/settings/login-settings',
  ],
};

const KNOWLEDGE_HUB_HELP_CENTER_URL = 'https://www.livechat.com/help'; //TODO update help center link

export const KNOWLEDGE_HUB_TELL_ME_MORE_MESSAGE = `The Knowledge Hub is your go-to place for storing all your important business info. Here, you can upload PDFs and add links to websites that contain valuable knowledge about your business.\n\n \n\nI’ll use the info you store here to help suggest accurate replies when you're chatting with customers. Plus, if there's something you can't quite remember, I've got your back! Just ask me from anywhere in the app, and I'll pull up the information you need from the knowledge you added.\n\n[Here’s a great article](${KNOWLEDGE_HUB_HELP_CENTER_URL})  that explains it all in detail.`;

export const KNOWLEDGE_HUB_EXPLORE_KNOWLEDGE_HUB_MESSAGE =
  'Knowledge hub stores all your business knowledge in one place to equip your agents with instant answers and smarter replies. Add your public websites and upload PDF brochures or manuals.';
