import { type FC } from 'react';

import { BodyClickHandler } from 'components/BodyClickHandler';
import { HistoryRouter } from 'components/HistoryRouter';
import { StoreProvider } from 'components/StoreProvider';
import { ConnectivityStatusProvider } from 'hooks/connectivity/status/ConnectivityStatusContext';
import MainRouter from 'routes/MainRouter';
import { browserHistory } from 'services/browser-history';
import { connectivityStatusClient } from 'services/connectivity/status/client';

export const Application: FC = () => (
  <HistoryRouter history={browserHistory.browserHistoryObject}>
    <StoreProvider>
      <BodyClickHandler>
        <ConnectivityStatusProvider instance={connectivityStatusClient}>
          <MainRouter />
        </ConnectivityStatusProvider>
      </BodyClickHandler>
    </StoreProvider>
  </HistoryRouter>
);
