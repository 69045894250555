import { AccountStatus } from 'constants/account-status';
import { LoginStatus } from 'constants/login-status';
import { Permission } from 'constants/permission';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import type { Group } from 'interfaces/groups';

import { type IAgent } from './interfaces';

// GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
// GLOBAL ACCOUNTS TODO: NEED TO REFACTOR

export function isOwner(agent: IAgentBase): agent is IAgent {
  return agent.permission === Permission.Owner;
}

export function isActivated(agent: IAgentBase): boolean {
  const isAgentLoggedIn = [LoginStatus.Online, LoginStatus.Away].includes(agent.status);

  return !!agent.lastLogout || isAgentLoggedIn;
}

export function isAwaitingApproval(agent: IAgent): boolean {
  return agent.accountStatus === AccountStatus.AwaitingApproval;
}

export function shouldIncludeAgentInGroup(agent: IAgentBase, currentAgent: string, group: Pick<Group, 'id'>): boolean {
  return agent.groups.includes(group.id) && agent.login !== currentAgent && agent.status === LoginStatus.Online;
}

export function isAgent(agent: IAgentBase): boolean {
  return agent.permission === Permission.Normal;
}
