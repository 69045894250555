import { useDispatch } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { GlobalModal } from 'constants/global-modal';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { copyTextToClipboard } from 'helpers/copy';
import { removeMarkdownFromText } from 'helpers/markdown';
import { showToast } from 'helpers/toast';
import { useSendAnnotation } from 'hooks/api/use-send-annotation';
import { AnnotateReplyType } from 'interfaces/knowledge-sources';
import type { IAIAssistantReplyFeedbackEvent } from 'services/api/ml-gateway/interfaces/event-collector';
import { trackEvent } from 'services/event-tracking';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';
import { type CopilotMessageEvent } from 'store/entities/copilot/interfaces';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';

import { useSendFeedbackEvent } from '../hooks/use-send-feedback-event';

type UseMessageBottomBar = {
  handleCopyClick: () => Promise<void>;
  handleAddCannedResponseClick: () => Promise<void>;
  handleRateMessageClick: () => void;
};

export const useMessageBottomBar = (event: CopilotMessageEvent): UseMessageBottomBar => {
  const { text, sessionId, eventId, type, properties, traceId } = event;
  const dispatch = useDispatch();

  const handleCopyClick = async (): Promise<void> => {
    const textWithoutMarkdown = await removeMarkdownFromText(text);
    const isCopied = await copyTextToClipboard(textWithoutMarkdown);

    const toast = isCopied
      ? { content: ToastContent.MESSAGE_COPIED_SUCCESS, kind: ToastVariant.Success }
      : { content: ToastContent.MESSAGE_COPIED_ERROR, kind: ToastVariant.Error };
    showToast(toast.kind, toast.content, { autoHideDelayTime: ToastAutoHideDelay.Long });

    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, {
      action: 'copy',
    });
  };

  const handleAddCannedResponseClick = async (): Promise<void> => {
    const textWithoutMarkdown = await removeMarkdownFromText(text);

    dispatch(
      GlobalModalActions.showModal(GlobalModal.AddCannedResponse, {
        text: textWithoutMarkdown,
        groupId: '0',
      }),
    );

    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, {
      action: 'create canned responses',
    });
  };

  const onSendFeedbackSuccess = (payload: IAIAssistantReplyFeedbackEvent): void => {
    dispatch(
      CopilotEntitiesActions.updateCopilotEvent({
        eventId,
        type,
        properties: {
          ...properties,
          rated: payload.data.thumbsdown ? 'bad' : undefined,
        },
      }),
    );
  };

  const onSendFeedbackError = (): void => {
    showToast(ToastVariant.Error, ToastContent.SEND_AI_ASSISTANT_MESSAGE_FEEDBACK_ERROR);
  };

  const { sendFeedbackEvent } = useSendFeedbackEvent({
    onSuccess: onSendFeedbackSuccess,
    onError: onSendFeedbackError,
  });

  const { sendAnnotation } = useSendAnnotation();

  const handleRateMessageClick = (): void => {
    if (!sessionId) {
      return;
    }

    sendFeedbackEvent({ requestId: sessionId });

    if (traceId) {
      sendAnnotation({ traceId, type: AnnotateReplyType.ConversationRate, value: 0 });
    }
  };

  return {
    handleCopyClick,
    handleAddCannedResponseClick,
    handleRateMessageClick,
  };
};
