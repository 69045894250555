import type { FC } from 'react';

import { Alert, Heading } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import * as styles from './styles';

export const CannotBuyAlert: FC = () => {
  const { paymentIntent } = useOneClickPaymentContext();
  const isExternalTransaction = paymentIntent?.metadata?.isExternalTransaction;

  return (
    <Alert kind="warning" className={styles.alert}>
      <Heading size="xs">This purchase requires billing access</Heading>
      {isExternalTransaction
        ? 'This action requires billing access. Please consult your admin for further assistance.'
        : 'You can add this app to cart instead. We’ll let your subscription admins know about your purchase request.'}
    </Alert>
  );
};
