import { type ICardState } from '../interfaces';

export function getValidationMessage(fieldState: ICardState): string {
  if (fieldState.cvv?.empty && fieldState.expiry?.empty && fieldState.number?.empty) {
    return 'Card details are required';
  }

  if (!fieldState.number?.valid) {
    return 'Please enter a valid card number';
  }

  if (!fieldState.expiry?.valid) {
    return 'Please enter a valid expiry date';
  }

  if (!fieldState.cvv?.valid) {
    return 'Please enter a valid security code';
  }

  return '';
}
