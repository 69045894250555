import { useMemo, useState, type FC } from 'react';

import { ScriptURL, useScript } from 'hooks/use-script';

import { OneClickPaymentContext } from './hooks/use-one-click-payment-context';
import { useOneClickPaymentPostMessageEvents } from './hooks/use-one-click-payment-post-messages';
import { PaymentModal } from './modals/PaymentModal';

type OneClickPaymentProps = {
  targetOrigin: string;
  iframeId: string;
};

export const OneClickPayment: FC<OneClickPaymentProps> = ({ targetOrigin, iframeId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRecurlyLoaded = useScript(ScriptURL.Recurly);

  const {
    isLoading,
    isUpdating,
    error,
    paymentIntent,
    charge,
    isProcessable,
    updateBillingCycle,
    closeModal,
    onPaymentModalClose,
    sendPostMessage,
  } = useOneClickPaymentPostMessageEvents({
    iframeId,
    targetOrigin,
    onModalOpen: () => setIsModalOpen(true),
    onModalClose: () => setIsModalOpen(false),
  });

  const value = useMemo(
    () => ({
      paymentIntent,
      charge,
      error,
      isLoading,
      isUpdating,
      isProcessable,
      closeModal,
      onPaymentModalClose,
      updateBillingCycle,
      sendPostMessage,
    }),
    [
      isLoading,
      paymentIntent,
      charge,
      error,
      isUpdating,
      isProcessable,
      closeModal,
      onPaymentModalClose,
      updateBillingCycle,
      sendPostMessage,
    ],
  );

  return (
    <OneClickPaymentContext.Provider value={value}>
      {isModalOpen && isRecurlyLoaded && <PaymentModal />}
    </OneClickPaymentContext.Provider>
  );
};
