// @ts-strict-ignore
import { useState, useCallback, useMemo, type FC, type ChangeEvent } from 'react';

import { cx } from '@emotion/css';
import { FormField, Input } from '@livechat/design-system-react-components';

import { type RecurlyFieldType } from 'interfaces/recurly';

import { type SubscriptionCardInputFieldSize, CARD_FIELD_LABELS, INPUT_FIELD_ID, INPUT_LABEL_ID } from './constants';
import { getValidationMessage } from './helpers';

import * as styles from './styles';

interface IProps {
  type: RecurlyFieldType;
  className?: string;
  error?: string;
  size?: SubscriptionCardInputFieldSize;
  hidden?: boolean;
  required?: boolean;
  initialValue?: string;
  validationEnabled?: boolean;
  autoFocus?: boolean;
  onBlur?(field: RecurlyFieldType): void;
  onFocus?(field: RecurlyFieldType): void;
  onChange?(value: string): void;
}

export const InputField: FC<IProps> = ({
  type,
  className,
  size,
  hidden,
  required,
  error,
  initialValue = '',
  validationEnabled,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
}) => {
  const label = useMemo(() => `${CARD_FIELD_LABELS[type]}${required ? '' : ' (optional)'}`, [type, required]);

  const [value, setValue] = useState<string>(initialValue || '');
  const [validationError, setValidationError] = useState(getValidationMessage(label, initialValue));

  const combinedError = useMemo(
    () => (validationEnabled ? validationError : error),
    [validationEnabled, validationError, error]
  );
  const preparedClassName = cx(styles.inputField(hidden, size), className);

  const handleFocus = useCallback(() => onFocus && onFocus(type), [onFocus, type]);
  const handleBlur = useCallback(() => onBlur && onBlur(type), [onBlur, type]);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      if (onChange) {
        onChange(event.target.value);
      }

      if (!required) {
        return;
      }

      setValidationError(getValidationMessage(label, event.target.value));
    },
    [required, label, onChange]
  );

  return (
    <div className={preparedClassName}>
      <FormField labelText={label} labelFor={type} error={combinedError} data-testid={INPUT_LABEL_ID}>
        <Input
          id={type}
          data-recurly={type}
          value={value}
          error={!!combinedError}
          data-testid={`${INPUT_FIELD_ID}-${type}`}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoFocus={autoFocus}
        />
      </FormField>
    </div>
  );
};
