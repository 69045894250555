import { type FC, useCallback, useState, useContext, useEffect } from 'react';

import { Modal, ModalPortal } from '@livechat/design-system-react-components';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';

import { CardFormContext } from 'components/card-form/CardFormContext';
import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ChargeType } from 'constants/one-click-payment';
import { getConfig } from 'helpers/config';
import { useAcceptDirectCharge } from 'hooks/api/billing/use-accept-direct-charge';
import { useAcceptRecurrentCharge } from 'hooks/api/billing/use-accept-recurrent-charge';
import { useParamSelector } from 'hooks/use-param-selector';
import { isApplicationInstalled } from 'store/entities/applications/selectors';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { NoCreditCardModalContent } from './Content';
import { NoCreditCardModalFooter } from './Footer';
import { NoCreditCardModalHeader } from './Header';

import * as styles from './styles';

const { recurlyKey } = getConfig();

export const NoCreditCardModal: FC = () => {
  const { closeModal, onPaymentModalClose, charge, paymentIntent } = useOneClickPaymentContext();
  const cardFormContext = useContext(CardFormContext);
  const [isActivationRequired, setIsActivationRequired] = useState(false);
  const { acceptDirectCharge, isProcessing: isProcessingDirectCharge } = useAcceptDirectCharge();
  const { acceptRecurrentCharge, isProcessing: isProcessingRecurrentCharge } = useAcceptRecurrentCharge();
  const isInstalled = useParamSelector(isApplicationInstalled, paymentIntent?.metadata?.itemId);

  const isProcessingCharge = isProcessingDirectCharge || isProcessingRecurrentCharge;

  const acceptCharge = useCallback(() => {
    if (!charge) return;

    if (paymentIntent?.metadata.type === ChargeType.DirectCharge) {
      return acceptDirectCharge({
        chargeId: charge.id,
        itemId: paymentIntent.metadata?.itemId,
      });
    }

    if (paymentIntent?.metadata.type === ChargeType.RecurrentCharge) {
      return acceptRecurrentCharge({
        chargeId: charge.id,
        itemId: paymentIntent.metadata?.itemId,
      });
    }
  }, [acceptDirectCharge, acceptRecurrentCharge, charge, paymentIntent]);

  useEffect(() => {
    if (isInstalled && paymentIntent?.metadata?.directInstallUrl) {
      setIsActivationRequired(true);
    } else if (isInstalled) {
      onPaymentModalClose();
    }
  }, [isInstalled, paymentIntent?.metadata?.directInstallUrl, onPaymentModalClose]);

  return (
    <ModalPortal zIndex={StackingContextLevel.FullOverlay}>
      <CardFormContext.Provider value={cardFormContext}>
        <RecurlyProvider publicKey={recurlyKey}>
          <Elements>
            <Modal
              className={styles.modal}
              fullSpaceContent
              onClose={closeModal}
              heading={<NoCreditCardModalHeader isActivationRequired={isActivationRequired} />}
              footer={
                <NoCreditCardModalFooter
                  isProcessing={isProcessingCharge}
                  isActivationRequired={isActivationRequired}
                />
              }
              contentClassName={styles.content}
            >
              <NoCreditCardModalContent
                isActivationRequired={isActivationRequired}
                onCreditCardSaveSuccess={acceptCharge}
              />
            </Modal>
          </Elements>
        </RecurlyProvider>
      </CardFormContext.Provider>
    </ModalPortal>
  );
};
