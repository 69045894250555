import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

export const wrapper = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: var(${SpacingToken.Spacing05}) var(${SpacingToken.Spacing3});
  overflow: visible;

  h4 {
    margin: 0;
  }
`;

export const price = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
