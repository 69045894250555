import { AbsoluteAutomateRoutes } from 'constants/automate/constants';

import { type Message } from './copilot-onboarding-config';

export type KnowledgeHubOnboardingConfig = ((contextVariables: { name: string }) => Message)[];

export const KNOWLEDGE_HUB_ONBOARDING_CONFIG: ((contextVariables: { name: string }) => Message)[] = [
  ({ name }) => ({
    text: `Hi ${name}! 👋 \n\n Check out the new and improved **Knowledge Hub** (previously called Reply suggestions). You can now manage sources more easily and add PDF sources, too.`,
    actionButtons: [
      {
        content: 'Explore Knowledge Hub',
        type: 'onboarding-button',
        props: {
          responseType: 'explore-knowledge-hub',
          navigateUrl: AbsoluteAutomateRoutes.KnowledgeHubAllSources,
          kind: 'primary',
          delay: 2000,
        },
      },
      {
        content: 'Tell me more',
        type: 'onboarding-button',
        props: {
          responseType: 'knowledge-hub-info',
          kind: 'text',
          delay: 2000,
        },
      },
    ],
  }),
];
