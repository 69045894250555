// @ts-strict-ignore
import {
  getChatWindowGreetingClickedPropertyName,
  getChatWindowGreetingSkippedPropertyName,
} from 'helpers/agent-custom-properties';
import { EventPlace } from 'helpers/analytics';
import { browserMajorVersion, browserName } from 'helpers/browser';
import { getNpsFirstChat } from 'helpers/get-nps-first-chat';
import { isGhostLogin } from 'helpers/ghost';
import { type KeyMap } from 'helpers/interface';
import { navigate } from 'helpers/routing';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getChatWindowClickedGreetingCount,
  getChatWindowSkippedGreetingCount,
  getChatWindowSkippedGreetingsCount,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';

import { AppStateProvider } from './app-state-provider';
import { trackEvent } from './event-tracking';

interface IChatEventData {
  visibility: 'maximized' | 'minimized' | 'hidden';
  status: 'chatting' | 'browsing' | 'invited';
}

export interface IChatWidget {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _h: any;
  init(): void;
  call(eventName: string, args?: any): void;
  on(eventName: string, handler: (eventData: any) => void): void;
  off(eventName: string, handler: (eventData: any) => void): void;
  get(propName: string): KeyMap;
}
interface IGreetingPayload {
  id: number;
  uniqueId: string;
}

interface IRichGreetingActionPayload {
  eventId: string;
  greeting: IGreetingPayload;
  postbackId: string;
}

type ISessionVariables = KeyMap<string>;

export function openChatWidget(): void {
  // eslint-disable-next-line no-underscore-dangle
  if (!window.LiveChatWidget._h && !isGhostLogin()) {
    window.LiveChatWidget.init();
  }
  window.LiveChatWidget.call('maximize');
}

export function setSessionVariables(variables: ISessionVariables): void {
  // eslint-disable-next-line no-underscore-dangle
  if (!window.LiveChatWidget._h && !isGhostLogin()) {
    window.LiveChatWidget.init();
  }

  window.LiveChatWidget.call('set_session_variables', variables);
}

export function updateFirstChatDate(firstChatDate: Date | null): void {
  window.LiveChatWidget.call('update_session_variables', {
    /* eslint-disable @typescript-eslint/naming-convention */
    og_cv_browser_name: browserName,
    og_cv_browser_version: browserMajorVersion,
    og_cv_nps_first_chat: getNpsFirstChat(firstChatDate),
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}

class ChatWidget {
  lastCustomerStatus: string;

  setUpCallbacks = (): void => {
    window.LiveChatWidget.on('visibility_changed', (data: IChatEventData) => {
      if (data.visibility === 'maximized') {
        this.onChatWindowOpened();
      }
    });

    window.LiveChatWidget.on('customer_status_changed', (data: IChatEventData) => {
      if (data.status === 'chatting') {
        this.onChatStarted();
      }

      if (this.lastCustomerStatus === 'chatting' && ['browsing', 'invited'].includes(data.status)) {
        this.onChatEnded();
      }

      this.lastCustomerStatus = data.status;
    });

    window.LiveChatWidget.on('greeting_hidden', (greeting: IGreetingPayload) => {
      const state = AppStateProvider.getState();
      const isFetched = hasFetchedAgentCustomProperties(state);
      const greetingId = greeting.id.toString();

      if (isFetched) {
        const allGreetingsSkippedSoFar = getChatWindowSkippedGreetingsCount(state);

        AppStateProvider.dispatch(
          AgentCustomPropertiesActions.setAgentCustomProperty({
            [AgentCustomPropertyName.ChatWindowGreetingsSkipped]: allGreetingsSkippedSoFar + 1,
          }),
        );

        const greetingSkippedSoFar = getChatWindowSkippedGreetingCount(state, greetingId);
        const greetingSkippedPropertyName = getChatWindowGreetingSkippedPropertyName(greetingId);

        AppStateProvider.dispatch(
          AgentCustomPropertiesActions.setAgentCustomProperty({
            [greetingSkippedPropertyName]: greetingSkippedSoFar + 1,
          }),
        );
      }

      trackEvent('Greeting dismissed', EventPlace.OnboardingGreetings, {
        greetingId,
      });
    });

    window.LiveChatWidget.on('rich_message_button_clicked', (richMessage: IRichGreetingActionPayload) => {
      const { greeting, postbackId } = richMessage;
      if (greeting) {
        const greetingId = greeting.id.toString();
        const greetingClickedSoFar = AppStateProvider.selectFromStore(getChatWindowClickedGreetingCount, greetingId);
        const greetingClickedPropertyName = getChatWindowGreetingClickedPropertyName(greetingId);

        AppStateProvider.dispatch(
          AgentCustomPropertiesActions.setAgentCustomProperty({
            [greetingClickedPropertyName]: greetingClickedSoFar + 1,
          }),
        );

        trackEvent('Greeting action clicked', EventPlace.OnboardingGreetings, {
          greetingId,
          actionId: postbackId,
        });
      }
    });

    window.LiveChatWidget.on('ready', () => {
      const customerData = window.LiveChatWidget.get('customer_data');

      window.LiveChatWidget.call('update_session_variables', {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        og_cv_is_returning: customerData.isReturning,
      });

      window.LiveChatWidget.call('set_navigation_handler', (path: string) => {
        navigate(path);
      });
    });
  };

  updateCustomerDetails = (): void => {
    if (isGhostLogin()) {
      return;
    }

    const agent = AppStateProvider.selectFromStore(getLoggedInAgent);

    if (agent) {
      window.LiveChatWidget.call('set_customer_name', agent.name);
      window.LiveChatWidget.call('set_customer_email', agent.login);
    }
  };

  onChatWindowOpened = (): void => {
    AppStateProvider.dispatch({ type: 'FEATURES/CHAT_WINDOW/CHAT_WINDOW_OPENED' });
  };

  onChatStarted = (): void => {
    AppStateProvider.dispatch({ type: 'FEATURES/CHAT_WINDOW/CHAT_STARTED' });
  };

  onChatEnded = (): void => {
    AppStateProvider.dispatch({ type: 'FEATURES/CHAT_WINDOW/CHAT_ENDED' });
  };
}

const widget = new ChatWidget();

export default widget;
