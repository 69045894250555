import type { ActivityFilter, IpFilter, IntentFilter } from 'components/filters/interfaces';
import type { StringFilter } from 'interfaces/filter';

import { type AgentAssignmentFilter } from './agent-assignment-filter';
import { type AgentResponseFilter } from './agent-response-filter';
import { type AvailabilityFilter } from './availability-filter';
import { type CustomDateFilter } from './date-filter';
import { type DateRangeFilter, type CustomDateRange } from './date-range-filter';
import { type OfflineMessageFilter } from './offline-message-filter';
import { type PriorityFilter } from './priority-filter';
import { type RatingFilter } from './rating-filter';
import { type RepliedFilter } from './replied-filter';
import { type SentimentFilter } from './sentiment-filter';
import { type SurveyFilter } from './survey-filter';
import { type SurveyType as SurveyTypeFilter } from './survey-type-filter';
import { type Weekday as WeekdayFilter } from './weekday-filter';

export const enum Filter {
  Activity = 'activity',
  Agent = 'agent',
  AgentAssignment = 'agentAssignment',
  AgentResponse = 'agentResponse',
  AssignedAgent = 'assigned-agent',
  Availability = 'availability',
  CameFrom = 'came-from',
  Channel = 'channel',
  ChatSatisfaction = 'chat-satisfaction',
  City = 'city',
  CreationDateRange = 'creationDateRange',
  CustomerSince = 'customerSince',
  Date = 'date',
  DateRange = 'dateRange',
  Email = 'email',
  Goal = 'goal',
  Greeting = 'greeting',
  Group = 'group',
  IP = 'ip',
  LastContacted = 'lastContacted',
  LastPageTitle = 'last-page-title',
  Name = 'name',
  NumberOfVisits2 = 'number-of-visits', // temporarily
  OfflineMessage = 'offlineMessage',
  Rating = 'rating',
  RatingDateRange = 'ratingDateRange',
  Replied = 'replied',
  ResolutionDateRange = 'resolutionDateRange',
  ResponseAuthor = 'responseAuthor',
  ResponseDateRange = 'responseDateRange',
  ReturningCustomer = 'returning-customer',
  SaleGoal = 'saleGoal',
  State = 'state',
  Survey = 'survey',
  SurveyType = 'surveyType',
  Tag = 'tag',
  TicketStatus = 'ticketStatus',
  WebCrawlers = 'web-crawlers',
  Weekday = 'weekday',
  CountryISO = 'countryISO',
  Topic = 'topic',
  Sentiment = 'sentiment',
  Intent = 'intent',
  Priority = 'priority',
}

export interface Filters {
  [Filter.Activity]?: ActivityFilter;
  [Filter.Agent]?: string[];
  [Filter.AgentAssignment]?: AgentAssignmentFilter[];
  [Filter.AgentResponse]?: AgentResponseFilter;
  [Filter.Availability]?: AvailabilityFilter;
  [Filter.Channel]?: string[];
  [Filter.CreationDateRange]?: DateRangeFilter;
  [Filter.CustomerSince]?: CustomDateRange;
  [Filter.Date]?: CustomDateFilter;
  [Filter.DateRange]?: CustomDateRange;
  [Filter.Email]?: StringFilter;
  [Filter.Goal]?: string[];
  [Filter.Greeting]?: string[];
  [Filter.Group]?: string[];
  [Filter.LastContacted]?: CustomDateRange;
  [Filter.Name]?: StringFilter;
  [Filter.OfflineMessage]?: OfflineMessageFilter;
  [Filter.Rating]?: RatingFilter[];
  [Filter.RatingDateRange]?: DateRangeFilter;
  [Filter.Replied]?: RepliedFilter;
  [Filter.ResolutionDateRange]?: DateRangeFilter;
  [Filter.ResponseAuthor]?: string[];
  [Filter.ResponseDateRange]?: DateRangeFilter;
  [Filter.SaleGoal]?: string[];
  [Filter.Survey]?: SurveyFilter;
  [Filter.SurveyType]?: SurveyTypeFilter;
  [Filter.Tag]?: string[];
  [Filter.TicketStatus]?: string[];
  [Filter.Weekday]?: WeekdayFilter;
  [Filter.CountryISO]?: string[];
  [Filter.IP]?: IpFilter;
  [Filter.Topic]?: string[];
  [Filter.Sentiment]?: SentimentFilter;
  [Filter.Intent]?: IntentFilter;
  [Filter.Priority]?: PriorityFilter;
}

export const enum FilterOperator {
  And = 'and',
  Or = 'or',
  Exclude = 'exclude',
}

export const enum OperatorQueryParam {
  And = 'op:and',
  Or = 'op:or',
  Exclude = 'op:exclude',
}

export type FiltersOperators = Partial<{ [key in Filter]: FilterOperator }>;
