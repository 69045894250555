import type { FC } from 'react';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import { BilledAfterTrial } from './BilledAfterTrial';
import { BilledNow } from './BilledNow';

export const PaymentSummary: FC = () => {
  const { charge } = useOneClickPaymentContext();

  if (!charge) {
    return null;
  }

  const trialDays = charge.trial_days || 0;

  if (trialDays) {
    return <BilledAfterTrial trialDays={trialDays} />;
  }

  return <BilledNow />;
};
