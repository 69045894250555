import { useRef, type FC } from 'react';

import { cx } from '@emotion/css';
import { Badge, type ITooltipProps, Tag, Text, Tooltip } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import copilotActive from 'assets/img/automation/One-icon-active.png';
import copilotHover from 'assets/img/automation/One-icon-animation.png';
import copilotDefault from 'assets/img/automation/One-icon-default.png';
import { COPILOT_LOGO } from 'constants/copilot';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { isWindows } from 'helpers/browser';
import { getModKey } from 'helpers/keyboard';
import { useOnHover } from 'hooks/use-on-hover';
import { trackEvent } from 'services/event-tracking';
import { MAX_COPILOT_ONBOARDING_OPENED_COUNT } from 'store/entities/copilot/onboarding/constants';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getCopilotOpenedCount } from 'store/features/agent-custom-properties/selectors';
import { CopilotViewActions } from 'store/views/copilot/actions';
import {
  getIsCopilotModalOpened,
  getCopilotIsLoading,
  getCopilotUndreadMessageCount,
} from 'store/views/copilot/selectors';

import { COPILOT_TRIGGER_TEST_ID } from './constants';

import * as styles from './styles';

const tooltipHooksConfig: Pick<ITooltipProps, 'useClickHookProps' | 'useDismissHookProps'> = {
  useClickHookProps: {
    ignoreMouse: true,
  },
  useDismissHookProps: {
    referencePress: true,
    referencePressEvent: 'click',
  },
};

const isMac = !isWindows();

export const CopilotWithBadge: FC = () => {
  const dispatch = useDispatch();

  const isCopilotModalOpened = useSelector(getIsCopilotModalOpened);
  const isCopilotTyping = useSelector(getCopilotIsLoading);
  const unreadCopilotMessages = useSelector(getCopilotUndreadMessageCount);
  const copilotOpenedCount = useSelector(getCopilotOpenedCount);

  const logoRef = useRef<HTMLDivElement>(null);

  const { isHovered, handleMouseOut, handleMouseOver } = useOnHover();

  const shouldDisplayCopilotBadge = unreadCopilotMessages > 0 && !isCopilotModalOpened;
  const shouldAnimate = isHovered || (isCopilotTyping && !isCopilotModalOpened);

  const toggleCopilotModal = (): void => {
    if (isCopilotModalOpened) {
      dispatch(CopilotViewActions.hideModal());

      return;
    }

    dispatch(CopilotViewActions.showModal());
    trackEvent(CopilotTrackEvent.ChatOpened, EventPlace.One, { type: 'click' });

    if (copilotOpenedCount < MAX_COPILOT_ONBOARDING_OPENED_COUNT) {
      dispatch(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [AgentCustomPropertyName.OneOpenedCount]: copilotOpenedCount + 1,
        }),
      );
    }
  };

  const getCopilotImageSrc = (): string => {
    if (isHovered) {
      return copilotHover;
    }

    if (isCopilotModalOpened) {
      return copilotActive;
    }

    return copilotDefault;
  };

  return (
    <Tooltip
      placement="bottom-end"
      hoverOnDelay={300}
      floatingStrategy="fixed"
      kind="invert"
      referenceElement={logoRef?.current ?? undefined}
      triggerRenderer={
        <div
          ref={logoRef}
          className={styles.copilotLogoContainer}
          onClick={toggleCopilotModal}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          data-testid={COPILOT_TRIGGER_TEST_ID}
        >
          <div className={styles.imageContainer}>
            <img
              src={getCopilotImageSrc()}
              alt="Copilot Default"
              className={cx(styles.copilotUnderlayer, { [styles.borderAnimation]: shouldAnimate })}
            />
            <img src={COPILOT_LOGO} alt="COPILOT_LOGO" className={styles.copilotAssistant} />
          </div>
          {shouldDisplayCopilotBadge && <Badge type="dot" size="compact" className={styles.copilotBadge} />}
        </div>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tooltipHooksConfig}
    >
      <Text>Chat with Copilot</Text>
      <div className={cx(styles.copilotPopoverShortcuts, 'lc-dark-theme')}>
        <Tag className={styles.shortcut} iconOnly={isMac}>
          {getModKey(true)}
        </Tag>
        <Tag className={styles.shortcut}>G</Tag>
      </div>
    </Tooltip>
  );
};
