import { useEffect, useState, useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { QueryKey } from 'constants/query-key';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import { type Notification } from 'interfaces/copilot';
import { ApiManager } from 'services/api/api-manager';
import { type NotificationListDTO } from 'services/api/ml-gateway/interfaces/ai-agents';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { getLoggedInAgentAccountId } from 'store/entities/agents/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { getToast } from 'store/features/toasts/selectors';

import { useAnnotateNotifications } from './use-acknowledge-notifications';
import { useParamSelector } from './use-param-selector';
import { usePrevious } from './use-previous';
import { useReplySuggestionsSourcesList } from './use-reply-suggestions-list';

const SUCCESS_NOTIFICATION_TOAST_ID = `success-notification-${uniqueId()}`;
const NOTIFICATION_REFETCH_INTERVAL = 30000;
const TOAST_HIDE_DELAY = 30000;
const TRACK_EVENT_NAME = 'Toast preview clicked';

const deserializeListNotifications = (payload: NotificationListDTO): Notification[] =>
  payload.notifications.map((item) => ({
    id: item.id,
    skillId: item.metadata.skill_id,
    type: item.type,
  }));

const listNotifications = async (): Promise<Notification[]> => {
  const { result, error } = await ApiManager.mlGatewayApi.listNotifications();

  if (error) {
    throw error;
  }

  return deserializeListNotifications(result);
};

const getMultipleSourcesNavigationPath = (notifications: Notification[]): string => {
  const areAllSourcesFiles = !notifications.some(({ type }) => type === 'URL_ADDED');
  const areAllSourcesUrls = !notifications.some(({ type }) => type === 'FILE_ADDED');

  if (areAllSourcesFiles) {
    return AbsoluteAutomateRoutes.KnowledgeHubPDF;
  } else if (areAllSourcesUrls) {
    return AbsoluteAutomateRoutes.KnowledgeHubWebsites;
  }

  return AbsoluteAutomateRoutes.KnowledgeHubAllSources;
};

type UseListNotifications = {
  data?: Notification[];
  isLoading: boolean;
  isError: boolean;
};

export const useListNotifications = (): UseListNotifications => {
  const dispatch = useDispatch();
  const [displayedNotificationIds, setDisplayedNotificationIds] = useState<string[]>([]);
  const { acknowledgeNotifications } = useAnnotateNotifications();

  const {
    data: sources,
    isLoading: isLoadingSources,
    isError: isErrorSources,
  } = useReplySuggestionsSourcesList({
    refetchOnMount: true,
  });

  const currentAccountId = useSelector(getLoggedInAgentAccountId);
  const canFetchNotifications = sources?.length > 0 && sources.some(({ authorId }) => authorId === currentAccountId);

  const {
    data: notifications,
    isLoading,
    isError,
  } = useQuery([QueryKey.CopilotNotifications], listNotifications, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    refetchInterval: NOTIFICATION_REFETCH_INTERVAL,
    enabled: canFetchNotifications,
  });

  const toast = useParamSelector(getToast, SUCCESS_NOTIFICATION_TOAST_ID);
  const prevToast = usePrevious(toast);

  const filteredNotifications = notifications?.filter(({ id }) => !displayedNotificationIds.includes(id));

  const createToast = useCallback(
    async (content: string, action: { label: string; onClick: () => void }) => {
      dispatch(
        ToastsActions.createToast({
          id: SUCCESS_NOTIFICATION_TOAST_ID,
          content,
          kind: ToastVariant.Success,
          action: {
            ...action,
            closeOnClick: true,
          },
          autoHideDelayTime: TOAST_HIDE_DELAY,
        }),
      );
      await getQueryClient().invalidateQueries({ queryKey: [QueryKey.KnowledgeSourcesList] });
    },
    [dispatch],
  );

  const sourceAddedNotifications = filteredNotifications?.filter(
    (notification) => notification.type === 'URL_ADDED' || notification.type === 'FILE_ADDED',
  );

  const triggerSourceAddedToast = useCallback(async () => {
    if (sourceAddedNotifications.length === 1) {
      const [notification] = sourceAddedNotifications;
      const source = sources?.find((source) => source.id === notification.skillId);

      if (!source) {
        return;
      }

      await createToast(`"${source.name}" source was added successfully`, {
        label: 'Preview',
        onClick: () => {
          navigate(`${AbsoluteAutomateRoutes.KnowledgeHubWebsites}/${notification.skillId}`);
          dispatch(ToastsActions.removeToast({ id: SUCCESS_NOTIFICATION_TOAST_ID }));
          trackEvent(TRACK_EVENT_NAME, null, { origin: 'sources-added' });
        },
      });
    } else {
      await createToast(`${sourceAddedNotifications.length} sources added successfully`, {
        label: 'See all',
        onClick: () => {
          navigate(getMultipleSourcesNavigationPath(sourceAddedNotifications));
          dispatch(ToastsActions.removeToast({ id: SUCCESS_NOTIFICATION_TOAST_ID }));
          trackEvent(TRACK_EVENT_NAME, null, { origin: 'sources-added' });
        },
      });
    }
  }, [createToast, dispatch, sourceAddedNotifications, sources]);

  const shouldTriggerToast =
    !isError &&
    !isLoading &&
    !isLoadingSources &&
    !isErrorSources &&
    notifications?.length &&
    filteredNotifications?.length;

  useEffect(() => {
    if (!shouldTriggerToast) {
      return;
    }

    if (sourceAddedNotifications?.length) {
      void triggerSourceAddedToast();
    }

    setDisplayedNotificationIds(notifications.map((alert) => alert.id));
  }, [triggerSourceAddedToast, notifications, shouldTriggerToast, sourceAddedNotifications?.length]);

  useEffect(() => {
    if (prevToast && !toast && notifications) {
      acknowledgeNotifications({ notificationIds: notifications.map((notification) => notification.id) });
    }
  }, [toast, prevToast, acknowledgeNotifications, notifications]);

  return { data: notifications, isLoading, isError };
};
