import { type KeyMap } from 'helpers/interface';

import { type CopilotEvent, type WithCopilotEntitiesState } from './interfaces';

const EMPTY_ARRAY_OF_ARRAYS: string[][] = [];
const EMPTY_OBJECT = {};

export function getCopilotAllEvents(state: WithCopilotEntitiesState): KeyMap<CopilotEvent> {
  return state.entities.copilot.events.byIds || EMPTY_OBJECT;
}

export function getCopilotGroupedEventsIds(state: WithCopilotEntitiesState): string[][] {
  return state.entities.copilot.events.groupedIds || EMPTY_ARRAY_OF_ARRAYS;
}
export function getIsCopilotError(state: WithCopilotEntitiesState): boolean | null {
  return state.entities.copilot.isError || null;
}

export function getCopilotCurrentSessionId(state: WithCopilotEntitiesState): string | null {
  return state.entities.copilot.currentSessionId;
}

export function getFailedCopilotMessage(state: WithCopilotEntitiesState): string {
  return state.entities.copilot.failedMessageContent ?? '';
}

export function getCopilotLatestAgentEventId(state: WithCopilotEntitiesState): string | null {
  return state.entities.copilot.events.latestAgentEventId;
}

export function getCopilotLatestEventId(state: WithCopilotEntitiesState): string | null {
  const events = Object.values(state.entities.copilot.events.byIds);

  return events[events.length - 1]?.eventId ?? null;
}
