import { type MutableRefObject, useCallback, useRef, useEffect } from 'react';

import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';

import { UserType } from 'constants/user-type';
import { useEffectOnce } from 'hooks/use-effect-once';
import { usePrevious } from 'hooks/use-previous';
import { type CopilotEvent } from 'store/entities/copilot/interfaces';
import { CopilotViewActions } from 'store/views/copilot/actions';
import {
  getIsCopilotModalExpanded,
  getCopilotScrollPosition,
  getCopilotUndreadMessageCount,
} from 'store/views/copilot/selectors';

import { COPILOT_SCROL_TIMEOUT } from '../constants';

interface IUseScrollProps {
  copilotChatFeedRef: MutableRefObject<HTMLDivElement | null>;
  groupedEvents: CopilotEvent[][];
  additionalEvents: boolean;
}

export const useScroll = ({
  copilotChatFeedRef: copilotChatFeedRef,
  groupedEvents,
  additionalEvents,
}: IUseScrollProps) => {
  const dispatch = useDispatch();
  const copilotScrollPosition = useSelector(getCopilotScrollPosition);
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const copilotUndreadMessageCount = useSelector(getCopilotUndreadMessageCount);
  const previousExpanded = usePrevious(isExpanded);
  const currentScrollPosition = useRef<number | null>(copilotScrollPosition);
  const isScrolledToBottom = useRef<boolean>(copilotScrollPosition === null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentLength = groupedEvents.length;
  const previousLength = usePrevious(currentLength, 0);

  const scrollToBottom = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      copilotChatFeedRef?.current?.scrollTo({ top: copilotChatFeedRef?.current?.scrollHeight });
      dispatch(CopilotViewActions.setScrollPosition(copilotChatFeedRef?.current?.scrollHeight || 1));
    }, COPILOT_SCROL_TIMEOUT);
  }, [dispatch]);

  useEffectOnce(() => {
    return () => {
      dispatch(CopilotViewActions.setScrollPosition(isScrolledToBottom.current ? null : currentScrollPosition.current));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });

  useEffectOnce(() => {
    if (copilotScrollPosition === null) {
      scrollToBottom();

      return;
    }

    copilotChatFeedRef?.current?.scrollTo({ top: copilotScrollPosition });
    currentScrollPosition.current = copilotScrollPosition;
  });

  useEffect(() => {
    if (additionalEvents && isScrolledToBottom.current) {
      scrollToBottom();
    }
  }, [groupedEvents, dispatch, scrollToBottom, additionalEvents]);

  useEffect(() => {
    const [lastGroupEvent] = groupedEvents.slice(-1)[0];
    const isLastMessageFromAgent = lastGroupEvent?.authorType === UserType.Agent;

    if (isLastMessageFromAgent || isScrolledToBottom.current) {
      scrollToBottom();

      return;
    }

    if (previousLength && currentLength > previousLength && !isScrolledToBottom.current) {
      dispatch(CopilotViewActions.increaseCopilotUnreadMessagesCount(1));
    }
  }, [dispatch, groupedEvents, scrollToBottom]);

  useEffect(() => {
    if (isExpanded !== previousExpanded && previousExpanded !== null) {
      scrollToBottom();
    }
  }, [isExpanded, previousExpanded, dispatch]);

  const handleScroll = (): void => {
    const container = copilotChatFeedRef.current;

    if (!container) {
      return;
    }

    currentScrollPosition.current = container?.scrollTop ?? null;
    isScrolledToBottom.current = container?.scrollHeight - container?.scrollTop - container?.clientHeight < 1;

    if (isScrolledToBottom.current && copilotUndreadMessageCount) {
      dispatch(CopilotViewActions.resetCopilotUnreadMessagesCount());
    }
  };

  const debouncedScroll = debounce(handleScroll, 200);

  return {
    handleScroll: debouncedScroll,
    handleScrollToBottom: scrollToBottom,
  };
};
