import type { FC } from 'react';

import { Heading, Text } from '@livechat/design-system-react-components';
import { addDays, format } from 'date-fns';

import { DateFormat } from 'constants/date';

import * as styles from './styles';

type Props = {
  trialDays: number;
};

export const BilledAfterTrial: FC<Props> = ({ trialDays }) => {
  const trialEndDate = format(addDays(new Date(), trialDays), DateFormat.TrialEndDate);

  return (
    <div className={styles.wrapper}>
      <div className={styles.price}>
        <Heading size="sm">Billed now</Heading>
        <Heading size="sm">$0.00</Heading>
      </div>

      <Text size="sm">Payment for this app will be processed on {trialEndDate}.</Text>
    </div>
  );
};
