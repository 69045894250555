// @ts-strict-ignore
import { isbot } from 'isbot';

import { GENERAL_GROUP_ID } from 'constants/groups';
import { FacebookMessengerProperty } from 'constants/integrations/facebook-messenger';
import { UserType } from 'constants/user-type';
import { toKeyMap } from 'helpers/array';
import { ChatChannel, getClientIdByChatChannel } from 'helpers/chat-channels';
import { type KeyMap } from 'helpers/interface';
import type { CustomerLastVisitResult } from 'interfaces/api/chat';
import { type ChatSummaryResult } from 'services/api/chat/interfaces';
import type { IChatCustomerResult } from 'services/api/interfaces/chat/chat';
import { getPropertyValue } from 'services/serialization/property';
import { getFacebookMessengerIntegration } from 'services/socket-lc3/chat/serialization/helpers/get-facebook-messenger-integration';
import {
  deserializeGeolocationDetails,
  deserializeSessionFields,
} from 'services/socket-lc3/customer/serialization/deserialize';
import { type ICustomerStatistics, type ICustomerVisitDetails } from 'store/entities/common/interfaces';
import { type ICustomer } from 'store/entities/customers/interfaces';
import {
  getBrowserNameForList,
  getDeviceGeneralTypeForList,
  getOperatingSystemForList,
} from 'store/views/traffic/helpers/customers-list';

function getUserAvatarFromChatProperties(chatProperties): string {
  const facebookMessengerClientId = getClientIdByChatChannel(ChatChannel.FacebookMessenger);

  if (!chatProperties || !chatProperties[facebookMessengerClientId]) {
    return null;
  }

  return getPropertyValue<string>(chatProperties, facebookMessengerClientId, FacebookMessengerProperty.ProfilePicture);
}

function deserializeChatSummaryVisitDetails(lastVisitData: CustomerLastVisitResult | null): ICustomerVisitDetails {
  if (!lastVisitData) {
    return null;
  }

  const { user_agent: userAgent } = lastVisitData;

  const browserName = getBrowserNameForList(userAgent);
  const deviceType = getDeviceGeneralTypeForList(userAgent);
  const operatingSystem = getOperatingSystemForList(userAgent);
  const isWebCrawler = isbot(userAgent);

  return {
    browserName,
    deviceType,
    operatingSystem,
    isWebCrawler,
    userAgent,
  };
}

export function deserializeChatSummaryStatistics(stats: IChatCustomerResult['statistics']): ICustomerStatistics {
  if (!stats) {
    return null;
  }

  const {
    threads_count: chatsCount,
    greetings_accepted_count: acceptedGreetingsCount,
    greetings_shown_count: greetingsCount,
    visits_count: visitsCount,
  } = stats;

  return {
    chatsCount,
    acceptedGreetingsCount,
    greetingsCount,
    visitsCount,
  };
}

export function deserializeChatSummaryCustomer(chatSummary: ChatSummaryResult): ICustomer {
  const { access, properties, users, last_thread_summary: lastThreadSummary } = chatSummary;
  const customerUser = (users || []).find((user) => user.type === UserType.Customer) as IChatCustomerResult;

  if (!customerUser) {
    return null;
  }

  const {
    name,
    id,
    email,
    avatar,
    last_visit: lastVisitData,
    session_fields: sessionFieldsData,
    statistics: stats,
  } = customerUser;
  const ip = lastVisitData?.ip ?? '';
  const groupIds = access?.group_ids?.map(String) ?? [GENERAL_GROUP_ID];
  const avatarUrl = getUserAvatarFromChatProperties(properties);
  const cameFromURL = lastThreadSummary?.properties?.routing?.referrer as string;
  const { customVariables, integrationVariables } = deserializeSessionFields(sessionFieldsData);
  const geolocation = deserializeGeolocationDetails(customerUser.last_visit?.geolocation);

  const deserializedData = deserializeChatSummaryVisitDetails(lastVisitData);
  const visitDetails: ICustomerVisitDetails = cameFromURL ? { ...deserializedData, cameFromURL } : deserializedData;
  const statistics = deserializeChatSummaryStatistics(stats);

  const facebookIntegration = getFacebookMessengerIntegration(properties);
  const facebookMessengerClientId = getClientIdByChatChannel(ChatChannel.FacebookMessenger);
  const hasIntegrations = !!facebookIntegration;

  return {
    email: email || '',
    id,
    groupIds,
    name: name || '',
    avatarUrl: avatar || '',
    ip,
    ...(geolocation && { geolocation }),
    ...(visitDetails && { visitDetails }),
    ...(statistics && { statistics }),
    ...(customVariables && { customVariables }),
    ...(avatarUrl && { avatarUrl }),
    ...(integrationVariables && { integrationVariables }),
    ...(hasIntegrations && {
      integrations: {
        ...(facebookIntegration && {
          [facebookMessengerClientId]: facebookIntegration,
        }),
      },
    }),
  };
}

export function deserializeChatsSummaryCustomers(chatsSummary: ChatSummaryResult[]): KeyMap<ICustomer> {
  return toKeyMap(chatsSummary.map((chatSummary) => deserializeChatSummaryCustomer(chatSummary)).filter(Boolean), 'id');
}
