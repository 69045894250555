// @ts-strict-ignore
import { createContext } from 'react';

interface ICardFormContext {
  submit(event: unknown): void;
}

export const CardFormContext = createContext<ICardFormContext>({
  submit: null,
});
