import { useMutation, type UseMutateFunction } from '@tanstack/react-query';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { OutgoingOneClickPaymentEvent } from 'constants/post-message-event';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import type { RequestError } from 'services/api/types';

interface UseDeclineRecurrentCharge {
  declineRecurrentCharge: UseMutateFunction<void, string, string, unknown>;
  isProcessing: boolean;
  error: string | null;
}

async function declineRecurrentCharge(chargeId: string): Promise<void> {
  const { error }: RequestResult<unknown, RequestError> = await ApiManager.billingApi.declineRecurring(chargeId);

  if (error) {
    throw error;
  }
}

export const useDeclineRecurrentCharge = (): UseDeclineRecurrentCharge => {
  const { sendPostMessage } = useOneClickPaymentContext();

  const { mutate, isLoading, error } = useMutation<void, string, string, unknown>(declineRecurrentCharge, {
    onSuccess: () => sendPostMessage(OutgoingOneClickPaymentEvent.PaymentSuccess),
    onError: (error) => sendPostMessage(OutgoingOneClickPaymentEvent.PaymentFailure, { error }),
  });

  return {
    declineRecurrentCharge: mutate,
    isProcessing: isLoading,
    error,
  };
};
