import { combineReducers } from 'redux';

import type { IViewsState } from 'interfaces/store/view-state';

import { archivesReducer } from './archives/reducer';
import { automateViewReducer } from './automate/reducer';
import { chatsReducer } from './chats/reducer';
import { copilotViewReducer } from './copilot/reducer';
import { detailsColumnReducer } from './customer-details/reducer';
import { devToolsReducer } from './dev-tools/reducer';
import { discardChangesModalReducer } from './discard-changes-modal/reducer';
import { greetingsReducer } from './greetings/reducer';
import { homeScreenReducer } from './home-screen/reducer';
import { installCodeReducer } from './install-code/reducer';
import { mobileAppPromoBannerReducer } from './mobile-app-promo-banner/reducer';
import { navigationReducer } from './navigation/reducer';
import { onboardingReducer } from './onboarding/reducer';
import { reportsReducer } from './reports/reducer';
import { settingsReducer } from './settings/reducer';
import { startupErrorReducer } from './startup-error/reducer';
import { subscriptionReducer } from './subscription/reducer';
import { teamReducer } from './team/reducer';
import { ticketsReducer } from './tickets/reducer';
import { trafficReducer } from './traffic/reducer';
import { widgetReducer } from './widget/reducer';

export const viewsReducer = combineReducers<IViewsState>({
  archivesView: archivesReducer,
  chats: chatsReducer,
  detailsColumn: detailsColumnReducer,
  devTools: devToolsReducer,
  discardChanges: discardChangesModalReducer,
  greetings: greetingsReducer,
  homeScreen: homeScreenReducer,
  installCode: installCodeReducer,
  mobileAppPromoBanner: mobileAppPromoBannerReducer,
  navigation: navigationReducer,
  onboarding: onboardingReducer,
  copilot: copilotViewReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  startupError: startupErrorReducer,
  subscription: subscriptionReducer,
  team: teamReducer,
  tickets: ticketsReducer,
  traffic: trafficReducer,
  widget: widgetReducer,
  automate: automateViewReducer,
});
