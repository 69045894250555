import { allButStarter, atLeastEnterprise, atLeastBusiness, atLeastTeam, type PlanType } from './plan-type';

export enum Feature {
  AccessReports,
  AgentsAccessRestriction,
  AgentsAuthentication,
  AgentsInactivityTimeouts,
  AllowEmailFollowUp,
  ArchivesFullHistoryAccess,
  Benchmark,
  ChatButtons,
  ChatFileSharing,
  ChatTakeover,
  ChatWidgetAdvancedManagement,
  EyeCatcherManagement,
  Goals,
  Groups,
  ManualDowngradeDisallowed,
  PrivateMode,
  SalesTracker,
  Tags,
  AutoAccessRules,
  QualityBadge,
  Insights,
  ChatsSentiment,
  KnowledgeHub,
}

export const FEATURE_PLANS: Record<string, PlanType[]> = {
  [Feature.AccessReports]: allButStarter,
  [Feature.AgentsAccessRestriction]: atLeastBusiness,
  [Feature.AgentsAuthentication]: atLeastBusiness,
  [Feature.AgentsInactivityTimeouts]: atLeastTeam,
  [Feature.AllowEmailFollowUp]: atLeastBusiness,
  [Feature.ArchivesFullHistoryAccess]: allButStarter,
  [Feature.Benchmark]: atLeastBusiness,
  [Feature.ChatButtons]: allButStarter,
  [Feature.ChatFileSharing]: allButStarter,
  [Feature.ChatTakeover]: atLeastBusiness,
  [Feature.ChatWidgetAdvancedManagement]: allButStarter,
  [Feature.EyeCatcherManagement]: allButStarter,
  [Feature.Goals]: allButStarter,
  [Feature.Groups]: allButStarter,
  [Feature.ManualDowngradeDisallowed]: atLeastEnterprise,
  [Feature.PrivateMode]: allButStarter,
  [Feature.SalesTracker]: allButStarter,
  [Feature.Tags]: allButStarter,
  [Feature.QualityBadge]: atLeastTeam,
  [Feature.Insights]: atLeastBusiness,
  [Feature.ChatsSentiment]: atLeastEnterprise,
  [Feature.KnowledgeHub]: atLeastTeam,
};
