import { FilterType, TrafficColumn } from './interfaces';

export const LIST_AUTO_REFRESH_THROTTLE_TIME = 5000;
export const LIST_INSTANT_REFRESH_THROTTLE_TIME = 300;
export const CUSTOMERS_COUNT_REFRESH_THROTTLE_THRESHOLD = 100;

export const DEFAULT_COLUMNS_ORDER = [
  TrafficColumn.Name,
  TrafficColumn.Intent,
  TrafficColumn.Email,
  TrafficColumn.Actions,
  TrafficColumn.Activity,
  TrafficColumn.ChattingWith,
  TrafficColumn.VisitingTime,
  TrafficColumn.Priority,
  TrafficColumn.IP,
  TrafficColumn.Country,
  TrafficColumn.State,
  TrafficColumn.City,
  TrafficColumn.Referrer,
  TrafficColumn.Device,
  TrafficColumn.OperatingSystem,
  TrafficColumn.Browser,
  TrafficColumn.LastSeen,
  TrafficColumn.VisitsCount,
  TrafficColumn.LastPage,
  TrafficColumn.ChatsCount,
  TrafficColumn.GreetingsSent,
  TrafficColumn.GreetingsAccepted,
  TrafficColumn.GreetingsIgnored,
  TrafficColumn.Groups,
  TrafficColumn.LastGreeting,
  TrafficColumn.CustomerId,
];

export const DEFAULT_VISIBLE_COLUMNS = [
  TrafficColumn.Name,
  TrafficColumn.Intent,
  TrafficColumn.Email,
  TrafficColumn.Actions,
  TrafficColumn.Activity,
  TrafficColumn.ChattingWith,
  TrafficColumn.VisitingTime,
  TrafficColumn.Priority,
];

export const ALL_COLUMN_NAMES = DEFAULT_COLUMNS_ORDER;

export const CONDITIONAL_COLUMNS = [TrafficColumn.Intent];

export const ALL_TRAFFIC_FILTERS: FilterType[] = [
  FilterType.Activity,
  FilterType.AssignedAgent,
  FilterType.Group,
  FilterType.Country,
  FilterType.City,
  FilterType.State,
  FilterType.Name,
  FilterType.Email,
  FilterType.ReturningCustomer,
  FilterType.NumberOfVisits,
  FilterType.WebCrawlers,
  FilterType.IP,
  FilterType.CameFrom,
  FilterType.LastPageTitle,
  FilterType.Priority,
];
