export const COPILOT_CHAT_ID = 'copilot';

export const PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE = 'Ask me anything!';

export const COPILOT_LOGO = 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/one.png';

export const COPILOT_NO_AI_AGENTS_MESSAGE = {
  header: `I think I'm unplugged. 🔌`,
  text: `My team is plugging me right back in so I should be back soon!`,
};
