import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { OnboardingRoute } from 'constants/onboarding/routes';
import { Section } from 'constants/section';
import { SprigEvents } from 'constants/sprig-events';
import { EventPlace } from 'helpers/analytics';
import { shouldHaveMobileOnboardingSteps } from 'helpers/device';
import { isDefined } from 'helpers/is-defined';
import { navigateToNextStep } from 'helpers/onboarding';
import { AgentProperty } from 'services/api/agent-properties/interfaces';
import { ApiManager } from 'services/api/api-manager';
import { trackEvent } from 'services/event-tracking';
import { getItem, saveItem } from 'services/session-storage';
import { getSprigService } from 'services/sprig';
import { type IAgent } from 'store/entities/agents/interfaces';
import { getLoggedInAgent, getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { CompanyDetailsActions } from 'store/entities/company-details/actions';
import { CodeInstallationActionsNames } from 'store/features/code-installation/actions';
import { getIsOnSection } from 'store/features/routing/selectors';
import { SessionActionNames } from 'store/features/session/actions';
import { type IActionWithPayload } from 'store/helper';

import { OnboardingActions, OnboardingActionsEnum } from './actions';
import type { IOnboardingStep, IOnboardingFinishedPayload, IUpdateCompanySizePayload } from './interfaces';
import { plugins } from './plugins/plugins';
import { getSteps } from './selectors';

function* sendInstructionsEmail(): SagaIterator {
  const alreadySent: boolean = yield call(getItem, 'getInstructionsEmailSent');

  if (!alreadySent) {
    yield call(ApiManager.codeInstructionsApi.sendInstallCodeEmail);
    trackEvent('Automatic email send', EventPlace.Onboarding);

    yield call(saveItem, 'getInstructionsEmailSent', true);
  }
}

function* finishCodeInstallation(): SagaIterator {
  const isOnboarding = yield select(getIsOnSection, Section.Onboarding, false);
  const steps: IOnboardingStep[] = yield select(getSteps);
  const hasMobileSteps = shouldHaveMobileOnboardingSteps();

  if (isOnboarding && hasMobileSteps) {
    navigateToNextStep(OnboardingRoute.InstallCode, steps);
  }
}

function* setInitialValueForOnboardingCompleted(): SagaIterator {
  const agent: IAgent = yield select(getLoggedInAgent);

  if (!agent) {
    return;
  }

  yield put(
    OnboardingActions.onboardingStatusFetched({
      isOnboardingFinished: agent.isOnboardingFinished || false,
    }),
  );
}

function* finishOnboardingSaga(action: IActionWithPayload<string, IOnboardingFinishedPayload>): SagaIterator {
  if (!isDefined(action.payload)) {
    const currentUserLogin: string = yield select(getLoggedInAgentLogin);
    yield call(ApiManager.agentPropertiesApi.set, AgentProperty.VisitedWelcomeTutorial, '1', currentUserLogin);
    void getSprigService().initSprigEvent(SprigEvents.OnboardingFinished);
  }
}

export function* runPreOnboardingConfiguration(): SagaIterator {
  yield all(plugins.map((plugin) => call(plugin)));
}

function* updateCompanySizeSaga(action: IActionWithPayload<string, IUpdateCompanySizePayload>): SagaIterator {
  yield put(CompanyDetailsActions.update(action.payload));
  yield take(CompanyDetailsActions.fetchSuccess);

  const steps = yield select(getSteps);

  navigateToNextStep(OnboardingRoute.CompanySize, steps);
}

function* resetOnboarding(): SagaIterator {
  const currentUserLogin: string = yield select(getLoggedInAgentLogin);
  yield call(ApiManager.agentPropertiesApi.set, AgentProperty.VisitedWelcomeTutorial, '0', currentUserLogin);
  yield put(
    OnboardingActions.onboardingStatusFetched({
      isOnboardingFinished: false,
    }),
  );
}

export function* onboardingSaga(): SagaIterator {
  yield takeEvery(SessionActionNames.SAVE_AUTHENTICATION_CREDENTIALS, setInitialValueForOnboardingCompleted);
  yield takeEvery(OnboardingActionsEnum.SEND_INSTRUCTIONS_EMAIL, sendInstructionsEmail);
  yield takeEvery(OnboardingActionsEnum.RUN_PREONBOARDING_CONFIGURATION, runPreOnboardingConfiguration);
  yield takeEvery(OnboardingActionsEnum.ONBOARDING_FINISHED, finishOnboardingSaga);
  yield takeEvery(CodeInstallationActionsNames.CODE_FOUND, finishCodeInstallation);
  yield takeEvery(OnboardingActionsEnum.UPDATE_COMPANY_SIZE, updateCompanySizeSaga);
  yield takeEvery(OnboardingActionsEnum.RESET_ONBOARDING, resetOnboarding);
}
