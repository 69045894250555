import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { type Suggestion } from 'interfaces/copilot';
import { ApiManager } from 'services/api/api-manager';
import { type IListSuggestionsResponse } from 'services/api/ml-gateway/interfaces/ai-agents';

function deserializeListSuggestions(payload: IListSuggestionsResponse): Suggestion[] {
  return payload.list_suggestions.map((item) => ({
    message: item.message,
    shortcut: item.suggestion_shortcut,
    description: item.description,
  }));
}

async function listSuggestions(): Promise<Suggestion[]> {
  const { result, error } = await ApiManager.mlGatewayApi.listSuggestions();

  if (error) {
    throw error;
  }

  return deserializeListSuggestions(result);
}

type UseListSuggestions = {
  data?: Suggestion[];
  isLoading: boolean;
  isError: boolean;
};

interface IProps {
  refetchOnMount?: boolean;
  enabled?: boolean;
}

export const useListSuggestions = ({ refetchOnMount = false, enabled = true }: IProps = {}): UseListSuggestions => {
  const { data, isLoading, isError } = useQuery([QueryKey.OneSuggestions], listSuggestions, {
    refetchOnMount,
    refetchOnWindowFocus: false,
    enabled,
  });

  return {
    data,
    isLoading,
    isError,
  };
};
