import type { FC } from 'react';

import { ModalHeader, Text } from '@livechat/design-system-react-components';

type Props = {
  isRecurrentCharge: boolean;
};

export const PurchaseHeader: FC<Props> = ({ isRecurrentCharge }) => (
  <ModalHeader title="Confirm purchase">
    <Text size="sm">
      {isRecurrentCharge
        ? 'The application subscription will start after the free trial period.'
        : 'The application subscription will start immediately.'}
    </Text>
  </ModalHeader>
);
