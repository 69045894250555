/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash.isempty';
import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';

import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';
import { autotagsClient } from 'services/connectivity/ml-gateway-api/autotags/client';
import { type AutotagsSetupError } from 'services/connectivity/ml-gateway-api/autotags/types';
import { CannedResponseActions } from 'store/entities/canned-responses/actions';
import type { ICannedResponseAutotags } from 'store/entities/canned-responses/interfaces';
import { getCannedResponses } from 'store/entities/canned-responses/selectors';
import type { ITag } from 'store/entities/tags/interfaces';
import { getTags } from 'store/entities/tags/selectors';
import { getAreAutotagsAvailable } from 'store/features/session/selectors';

import { filterAvailableAutotagsSetup } from '../helpers';

const DEFAULT_AUTOTAGS: Record<string, string[]> = {
  '6': ['positive feedback'],
  '8': ['support'],
  '9': ['support'],
  '13': ['support'],
  '14': ['support'],
  '19': ['support'],
};

const LICENSE_INITIALIZATION_TIMEOUT = 4000;

const SAFE_ERROR_MESSAGE = 'Setup already done';

export default function* setupAutotags(): SagaIterator {
  yield delay(LICENSE_INITIALIZATION_TIMEOUT);

  const isAvailable = yield select(getAreAutotagsAvailable);

  if (!isAvailable) {
    return;
  }

  const defaultCannedResponses: KeyMap<CannedResponse> = yield select(getCannedResponses);
  const defaultTags: ITag[] = yield select(getTags);

  const payload = filterAvailableAutotagsSetup(DEFAULT_AUTOTAGS, defaultCannedResponses, defaultTags);

  if (isEmpty(payload)) {
    return;
  }

  const { result, error: autotagsError }: Awaited<ReturnType<typeof autotagsClient.setupCannedResponseAutotags>> =
    yield call(() => autotagsClient.setupCannedResponseAutotags(payload));

  const error = autotagsError?.http as unknown as AutotagsSetupError;

  if (error && !error?.innerError?.message?.includes(SAFE_ERROR_MESSAGE)) {
    Sentry.captureException(new Error('Failed to setup autotags'));

    return;
  }

  if (result) {
    const cannedResponseAutotags = Object.entries(DEFAULT_AUTOTAGS).reduce<KeyMap<ICannedResponseAutotags>>(
      (acc, [cannedId, data]) => {
        const cannedResponse = defaultCannedResponses[+cannedId];

        if (!cannedResponse) {
          return acc;
        }

        acc[cannedId] = {
          tags: data,
          groupId: cannedResponse.groupId,
        };

        return acc;
      },
      {},
    );

    yield put(CannedResponseActions.setCannedResponseAutotagsData(cannedResponseAutotags));
  }
}
