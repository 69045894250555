import { type FC, useCallback, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CardForm } from 'components/card-form/CardForm';
import { Divider } from 'components/divider/Divider';
import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ThreeDSecure } from 'components/three-d-secure/ThreeDSecure';
import { getCountryCode } from 'helpers/recurly';
import { usePrevious } from 'hooks/use-previous';
import { BillingActions } from 'store/entities/billing/actions';
import { type IAddCreditCardRequestPayload } from 'store/entities/billing/interfaces';
import { getBillingInfo, getCreditCardError, getIsSavingCreditCard } from 'store/entities/billing/selectors';
import { getCompanyDetailsCountry } from 'store/entities/company-details/selectors';
import { getHasRecurlyAccount } from 'store/views/subscription/selectors';

import { ActivationProductAlert } from '../PurchaseModal/Content/ActivationProductAlert';
import { ContentLoader } from '../PurchaseModal/Content/ContentLoader';
import { PaymentSummary } from '../PurchaseModal/Content/PaymentSummary/PaymentSummary';
import { PurchaseItem } from '../PurchaseModal/Content/PurchaseItem/PurchaseItem';
import { PurchaseModalErrors } from '../PurchaseModal/Errors';

import * as styles from './styles';

type Props = {
  isActivationRequired: boolean;
  onCreditCardSaveSuccess: () => void;
};

export const NoCreditCardModalContent: FC<Props> = ({ isActivationRequired, onCreditCardSaveSuccess }) => {
  const { isLoading, charge, paymentIntent } = useOneClickPaymentContext();

  const billingInfo = useSelector(getBillingInfo);
  const companyDetailsCountry = useSelector(getCompanyDetailsCountry);
  const hasRecurlyAccount = useSelector(getHasRecurlyAccount);
  const dispatch = useDispatch();
  const isSavingCreditCard = useSelector(getIsSavingCreditCard);
  const prevIsSavingCreditCard = usePrevious(isSavingCreditCard);
  const creditCardError = useSelector(getCreditCardError);
  const [tokenId, setTokenId] = useState<string | null>(null);
  const [threeDSecureError, setThreeDSecureError] = useState<string | null>(null);

  const shouldHidePicker = paymentIntent?.metadata?.hidePicker || paymentIntent?.metadata?.isExternalTransaction;
  const initialCountry = billingInfo?.country || getCountryCode(companyDetailsCountry);

  useEffect(() => {
    if (prevIsSavingCreditCard && !isSavingCreditCard && !creditCardError) {
      onCreditCardSaveSuccess();
    }
  }, [isSavingCreditCard, prevIsSavingCreditCard, creditCardError, onCreditCardSaveSuccess]);

  const handleCreditCardSave = useCallback(
    (creditCardData: IAddCreditCardRequestPayload): void => {
      dispatch(BillingActions.addCreditCard(creditCardData));
    },
    [dispatch],
  );

  const handleSubscriptionCardFromSubmitSuccess = useCallback(
    (tokenId: string): void => {
      setTokenId(tokenId);
      handleCreditCardSave({
        token: tokenId,
        isAlreadyUsingRecurly: hasRecurlyAccount,
        shouldSaveSubscription: false,
      });
    },
    [handleCreditCardSave, hasRecurlyAccount],
  );

  const handleThreeDSecureSuccess = useCallback(
    (threeDSecureTokenId: string) => {
      setThreeDSecureError(null);
      handleCreditCardSave({
        token: tokenId ?? '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        three_d_secure_action_result_token: threeDSecureTokenId,
        isAlreadyUsingRecurly: hasRecurlyAccount,
        shouldSaveSubscription: false,
      });
    },
    [tokenId, hasRecurlyAccount, handleCreditCardSave],
  );

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isActivationRequired) {
    return (
      <>
        <ActivationProductAlert />
        <PurchaseItem hidePicker />
      </>
    );
  }

  return (
    <>
      <PurchaseModalErrors />
      <PurchaseItem hidePicker={shouldHidePicker} />
      <Divider className={styles.divider} />
      <CardForm
        billingInfo={billingInfo}
        initialCountry={initialCountry}
        onSubmitSuccess={handleSubscriptionCardFromSubmitSuccess}
        creditCardError={creditCardError}
        threeDSecureError={threeDSecureError}
        isSaving={isSavingCreditCard}
        expandable
      />
      <ThreeDSecure
        serverError={creditCardError}
        onSuccess={handleThreeDSecureSuccess}
        onError={setThreeDSecureError}
      />
      {charge && <Divider className={styles.divider} />}
      <PaymentSummary />
    </>
  );
};
