import type { FC } from 'react';

import { Alert, Button } from '@livechat/design-system-react-components';
import isArray from 'lodash.isarray';
import isString from 'lodash.isstring';

import { openChatWidget } from 'services/chat-widget';

import * as styles from './styles';

interface Props {
  error: string | unknown[];
}

export const ErrorBanner: FC<Props> = ({ error }) => (
  <Alert kind="error" className={styles.wrapper}>
    {isArray(error) && (
      <div>
        <span>The transaction was declined. Please use a different card or&nbsp;</span>
        <Button kind="link" onClick={openChatWidget}>
          contact us
        </Button>{' '}
        for more information.
      </div>
    )}

    {isString(error) && error}
  </Alert>
);
