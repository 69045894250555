export enum AutomateSection {
  Overview = 'Overview',
  Chatbots = 'Chatbots',
  ReplySuggestions = 'Reply suggestions',
  KnowledgeSources = 'Knowledge sources',
  RoutingRules = 'Routing rules',
  SuggestedResponses = 'Suggested responses',
  CannedResponses = 'Canned responses',
  KnowledgeHub = 'Knowledge hub',
  KnowledgeHubAllSources = 'Knowledge hub all sources',
  KnowledgeHubWebsites = 'Knowledge hub websites',
  KnowledgeHubPDF = 'Knowledge hub PDF',
}
