import { TopBarNotificationType } from 'constants/notifications';
import type { KeyMap } from 'helpers/interface';
import { type ITopBarNotification } from 'interfaces/notification-bar';

import { isDesktopView } from './device';

interface ITopBarNotificationOptions {
  priority: number;
  visibleOnMobile: boolean;
}

export const getTopBarNotificationOptions = (type: TopBarNotificationType): ITopBarNotificationOptions => {
  // Higher priority should be displayed first
  const TopBarNotificationsOptions: KeyMap<ITopBarNotificationOptions> = {
    [TopBarNotificationType.TrialWarning]: {
      priority: 1,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.StatusAway]: {
      priority: 2,
      visibleOnMobile: true,
    },
    [TopBarNotificationType.TrialWarningImportant]: {
      priority: 3,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.SendPendingInvites]: {
      priority: 4,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.BrowserNotifications]: {
      priority: 5,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.InstallCode]: {
      priority: 6,
      visibleOnMobile: false,
    },
    [TopBarNotificationType.ConnectionRestored]: {
      priority: 7,
      visibleOnMobile: true,
    },
    [TopBarNotificationType.Reconnecting]: {
      priority: 8,
      visibleOnMobile: true,
    },
    [TopBarNotificationType.Offline]: {
      priority: 9,
      visibleOnMobile: true,
    },
  };

  return TopBarNotificationsOptions[type];
};

export const getVisibleNotifications = (notifications: ITopBarNotification[]): ITopBarNotification[] =>
  notifications.filter(
    (notification) => isDesktopView() || getTopBarNotificationOptions(notification.name).visibleOnMobile,
  );
