import type { FC, MouseEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { anyToBoolean, type BooleanNumericFlag } from 'helpers/boolean';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { useChangeCurrentAgentStatus } from 'hooks/api/agents/use-change-current-agent-status';
import { useLicenseProperty } from 'hooks/connectivity/configuration-api/properties/use-license-property';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { trackEvent } from 'services/event-tracking';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getNotifications } from 'store/features/notifications-bar/selectors';

import { BrowserNotificationsBar } from './browser-notifications-bar/BrowserNotificationsBar';
import { CodeNotInstalledBar } from './CodeNotInstalledBar';
import { ConnectionRestoredBar } from './ConnectionRestoredBar';
import { NOTIFICATIONS_TOP_BAR_TEST_ID } from './constants';
import { handleInstallCodeLinkClicked, handleNavigateToSubscription, handleSendCodeBtnClicked } from './helpers';
import { useConnectionBarsVisibility } from './hooks/use-connection-bars-visibility';
import { NotAcceptingChatsBar } from './NotAcceptingChatsBar';
import { OfflineBar } from './OfflineBar';
import { ReconnectingBar } from './reconnecting-bar/ReconnectingBar';
import { SendPendingInvitesBar } from './SendPendingInvitesBar';
import { TrialExpiringBar } from './TrialExpiringBar';

import * as styles from './styles';

export const NotificationsBar: FC = () => {
  const dispatch = useDispatch();
  useConnectionBarsVisibility();
  const { handleStatusChange } = useChangeCurrentAgentStatus();
  const { data: isNonProfitLicense } = useLicenseProperty<BooleanNumericFlag>(
    PlatformNamespace.CORE,
    CoreProperty.NonProfit,
  );
  const isNonProfit = anyToBoolean(isNonProfitLicense);
  const activeNotifications = useSelector(getNotifications);
  const { data: isContinuousChatEnabled } = useLicenseProperty<BooleanNumericFlag>(
    PlatformNamespace.CORE,
    CoreProperty.ContinuousChatWidgetEnabled,
  );
  const isMessagingModeEnabled = anyToBoolean(isContinuousChatEnabled);

  const handleChangeStatusToOnline = (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    handleStatusChange({ status: LoginStatus.Online, eventPlace: EventPlace.NotificationBar });
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.StatusAway));
    trackEvent('Status changed to online', EventPlace.TopBar, { type: 'From bar' });
  };

  const handleSkipClick = (name: TopBarNotificationType): void => {
    dispatch(NotificationsBarActions.hideNotificationsBar(name));
    trackEvent('Skipped', EventPlace.TopBar, { topBar: name });
  };

  return (
    <div className={styles.notificationBarContainer} id="notifications-bar" data-testid={NOTIFICATIONS_TOP_BAR_TEST_ID}>
      <TransitionGroup component={null}>
        {getVisibleNotifications(activeNotifications).map((item) => {
          switch (item.name) {
            case TopBarNotificationType.TrialWarning:
            case TopBarNotificationType.TrialWarningImportant: {
              if (isNonProfit) {
                return null;
              }

              return (
                <CSSTransition key={TopBarNotificationType.TrialWarning} classNames="animate" timeout={800}>
                  <TrialExpiringBar
                    onNavigateToSubscription={handleNavigateToSubscription}
                    {...item.props} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.StatusAway: {
              return (
                <CSSTransition
                  key={TopBarNotificationType.StatusAway}
                  classNames="animate"
                  timeout={800}
                  appear={true}
                  enter={true}
                >
                  <NotAcceptingChatsBar
                    onChangeStatusToOnlineClicked={handleChangeStatusToOnline}
                    isMessagingModeEnabled={isMessagingModeEnabled}
                    {...item.props} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.InstallCode: {
              return (
                <CSSTransition key={TopBarNotificationType.InstallCode} classNames="animate" timeout={800}>
                  <CodeNotInstalledBar
                    onFollowLinkClicked={handleInstallCodeLinkClicked}
                    onSendCodeBtnClicked={handleSendCodeBtnClicked}
                    onSkip={handleSkipClick}
                    {...item.props} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.Reconnecting: {
              return (
                <CSSTransition key={TopBarNotificationType.Reconnecting} classNames="animate" timeout={800}>
                  <ReconnectingBar key={TopBarNotificationType.Reconnecting} />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.Offline: {
              return (
                <CSSTransition key={TopBarNotificationType.Offline} classNames="animate" timeout={800}>
                  <OfflineBar key={TopBarNotificationType.Offline} />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.ConnectionRestored: {
              return (
                <CSSTransition key={TopBarNotificationType.ConnectionRestored} classNames="animate" timeout={800}>
                  <ConnectionRestoredBar key={TopBarNotificationType.ConnectionRestored} />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.SendPendingInvites: {
              return (
                <CSSTransition key={TopBarNotificationType.SendPendingInvites} classNames="animate" timeout={800}>
                  <SendPendingInvitesBar />
                </CSSTransition>
              );
            }
            case TopBarNotificationType.BrowserNotifications: {
              return (
                <CSSTransition key={TopBarNotificationType.BrowserNotifications} classNames="animate" timeout={800}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <BrowserNotificationsBar onSkip={handleSkipClick} {...item.props} />
                </CSSTransition>
              );
            }

            default: {
              return null;
            }
          }
        })}
      </TransitionGroup>
    </div>
  );
};
