import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

export const label = (isActive?: boolean, isMainEntry?: boolean): string => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${isActive || isMainEntry ? '1' : '0.6'};
  gap: var(${SpacingToken.Spacing2});
`;

export const wrapper = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  flex-grow: 1;
  min-width: 0;
`;
