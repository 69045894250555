import type { FC } from 'react';

import { ModalHeader, Text } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ChargeType } from 'constants/one-click-payment';

import { ActivateProductHeader } from '../PurchaseModal/Header/ActivateProductHeader';
import { HeaderLoader } from '../PurchaseModal/Header/HeaderLoader';

type Props = {
  isActivationRequired: boolean;
};

export const NoCreditCardModalHeader: FC<Props> = ({ isActivationRequired }) => {
  const { isLoading, paymentIntent } = useOneClickPaymentContext();
  const isRecurrentCharge = paymentIntent?.metadata.type === ChargeType.RecurrentCharge;

  if (isLoading) {
    return <HeaderLoader />;
  }

  if (isActivationRequired) {
    return <ActivateProductHeader />;
  }

  return (
    <ModalHeader title="Billing details needed">
      <Text size="sm">Please provide billing information to proceed with your purchase.</Text>
      <Text size="sm">
        {isRecurrentCharge
          ? 'The application subscription will start after the free trial period.'
          : 'The application subscription will start immediately.'}
      </Text>
    </ModalHeader>
  );
};
