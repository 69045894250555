import { useState, useCallback, type FC } from 'react';

import { type IPickerListItem, Picker, FormField } from '@livechat/design-system-react-components';

import { selectCountriesToPickerItems } from 'constants/recurly-countries';
import { type RecurlyFieldType } from 'interfaces/recurly';

import * as styles from './styles';

interface IProps {
  type: RecurlyFieldType;
  initialValue?: string;
  error?: string;
  required?: boolean;
  onChange?(value: string): void;
}

export const CountrySelectField: FC<IProps> = ({ onChange, type, error, required, initialValue = 'US' }) => {
  const initialValueItem = selectCountriesToPickerItems.filter((item) => item.key === initialValue);
  const [selectedCountry, setCountry] = useState<IPickerListItem[]>(initialValueItem);
  const handleItemSelect = useCallback(
    (items: IPickerListItem[] | null) => {
      if (!items) {
        return;
      }
      setCountry(items);
      if (onChange) {
        onChange(items[0].key);
      }
    },
    [onChange]
  );

  return (
    <>
      <input type="hidden" data-recurly={type} data-testid="select-field-country" value={selectedCountry[0]?.key} />
      <FormField labelText="Country" error={error} className={styles.selectField}>
        <Picker
          data-testid="country-picker"
          id={type}
          options={selectCountriesToPickerItems}
          onSelect={handleItemSelect}
          placement="bottom"
          selected={selectedCountry}
          isRequired={required}
          hideClearButton
          placeholder="Search..."
        />
      </FormField>
    </>
  );
};
