import { type FC, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SideNavigationContainer } from 'components/side-navigation-v2/SideNavigationContainer';
import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { SubscriptionRoutes } from 'constants/subscription/routes';
import { getCanAccessInvoices } from 'store/features/session/selectors';

import * as styles from './styles';

interface Props {
  hidden: boolean;
}

const getNavigationItems = (canAccessInvoices: boolean) => {
  const items = [
    {
      name: 'Subscription',
      path: SubscriptionRoutes.Subscription,
    },
    {
      name: 'Account details',
      path: SubscriptionRoutes.AccountDetails,
    },
  ];

  if (canAccessInvoices) {
    items.splice(1, 0, {
      name: 'Invoices',
      path: SubscriptionRoutes.Invoices,
    });
  }

  return items;
};

export const SideNavigation: FC<Props> = ({ hidden }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const canAccessInvoices = useSelector(getCanAccessInvoices);
  const navigationItems = useMemo(() => getNavigationItems(canAccessInvoices), [canAccessInvoices]);

  const handleClick = (link: string): void => {
    navigate(link);
  };

  const getIsActive = (path: string): boolean => {
    if (
      path === (SubscriptionRoutes.Subscription as string) &&
      pathname.startsWith(SubscriptionRoutes.Subscription) &&
      pathname !== (SubscriptionRoutes.Invoices as string) &&
      pathname !== (SubscriptionRoutes.AccountDetails as string) &&
      pathname !== (SubscriptionRoutes.CancelAccount as string)
    ) {
      return true;
    } else if (
      path === (SubscriptionRoutes.AccountDetails as string) &&
      [SubscriptionRoutes.AccountDetails, SubscriptionRoutes.CancelAccount].includes(pathname as SubscriptionRoutes)
    ) {
      return true;
    }

    return pathname === path;
  };

  return (
    <div className={styles.wrapper(hidden)}>
      <SideNavigationContainer noGaps title="Billing">
        {navigationItems.map((item) => (
          <SideNavigationItem
            key={item.name}
            label={item.name}
            onClick={() => handleClick(item.path)}
            isActive={getIsActive(item.path)}
            isIconHidden={true}
          />
        ))}
      </SideNavigationContainer>
    </div>
  );
};
