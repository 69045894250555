import type { SourceResponse } from 'services/api/ml-gateway/interfaces/ai-agents';
import type { Source } from 'store/entities/copilot/interfaces';

import { capitalizeFirstLetter } from '../string';
import { isValidUrlWithoutPrefix } from '../validate';

import { mapResponseToViewFilters, REPORT_URL_BY_NAME_CONFIG } from './reports';

export function extractDomainFromURL(url: string): string {
  if (isValidUrlWithoutPrefix(url)) {
    const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im;
    const matches = url.match(domainRegex);

    return matches?.[1] || '';
  }

  return '';
}

export function tryExtractTitle(url: string): string {
  try {
    const { pathname } = new URL(url);
    const parts = pathname?.split('/').filter((part) => part.length > 0);

    if (parts.length > 0) {
      const inputString = parts[parts.length - 1].replace(/-/g, ' ');

      return capitalizeFirstLetter(inputString);
    }

    const domain = extractDomainFromURL(url);

    return capitalizeFirstLetter(domain.split('.')[0]);
  } catch {
    return '';
  }
}

export function mapSources(sources: SourceResponse[]): Source[] {
  return sources.map((source) => {
    if ('url' in source) {
      return {
        name: source.name || tryExtractTitle(source.url),
        url: source.url,
        type: 'url',
        skillId: source.skill_id,
      };
    }

    if (source.type === 'file') {
      return {
        type: 'file',
        name: source.name,
        fileType: 'pdf',
        fileName: source.file_name,
        skillId: source.skill_id,
      };
    }

    const filters = source?.params?.filters || {};
    const url = REPORT_URL_BY_NAME_CONFIG[source.name];

    return {
      name: capitalizeFirstLetter(source.name.replace('_', ' ')),
      url,
      type: 'api_call',
      params: mapResponseToViewFilters(filters),
    };
  });
}
