import { CopilotEntitiesActionNames, type CopilotEntitiesAction } from 'store/entities/copilot/actions';

import { CopilotViewActionNames, type CopilotViewAction } from './actions';
import {
  getStateForCancelCopilotRequest,
  getStateForCloseCopilotDetails,
  getStateForCloseCommandSuggestions,
  getStateForHideCopilotModal,
  getStateForHideCopilotNewMessageTooltip,
  getStateForIncreaseCopilotUnreadMessagesCount,
  getStateForCopilotIsLoading,
  getStateForOpenCopilotDetails,
  getStateForOpenCommandSuggestions,
  getStateForRemoveCopilotDraftMessage,
  getStateForResetCopilotUnreadMessagesCount,
  getStateForSaveCopilotDraftMessage,
  getStateForSetCopilotListStateSnapshot,
  getStateForSetScrollPosition,
  getStateForShowCopilotModal,
  getStateForShowCopilotNewMessageTooltip,
  getStateForToggleCopilotModal,
  getStateForToggleCopilotModalExpand,
  getStateForWidgetWebsite,
} from './helpers';
import { type CopilotViewState } from './interfaces';

const initialState: CopilotViewState = {
  draftMessage: '',
  unreadMessagesCount: 0,
  isLoading: false,
  listStateSnapshot: null,
  modal: {
    isOpened: false,
    isExpanded: false,
    scrollPosition: null,
    isDetailsOpened: false,
  },
  isNewMessageToastVisible: false,
  isCommandSuggestionsOpened: false,
  widgetWebsite: null,
};

export const copilotViewReducer = (
  state: CopilotViewState = initialState,
  action: CopilotViewAction | CopilotEntitiesAction,
): CopilotViewState => {
  switch (action.type) {
    case CopilotViewActionNames.RESET_COPILOT_UNREAD_MESSAGES_COUNT: {
      return getStateForResetCopilotUnreadMessagesCount(state);
    }
    case CopilotViewActionNames.SET_COPILOT_IS_LOADING: {
      return getStateForCopilotIsLoading(state, action.payload);
    }
    case CopilotViewActionNames.INCREASE_COPILOT_UNREAD_MESSAGES_COUNT: {
      return getStateForIncreaseCopilotUnreadMessagesCount(state, action.payload);
    }
    case CopilotViewActionNames.SET_COPILOT_LIST_STATE_SNAPSHOT: {
      return getStateForSetCopilotListStateSnapshot(state, action.payload);
    }
    case CopilotViewActionNames.SAVE_COPILOT_DRAFT_MESSAGE: {
      return getStateForSaveCopilotDraftMessage(state, action.payload);
    }
    case CopilotViewActionNames.REMOVE_COPILOT_DRAFT_MESSAGE: {
      return getStateForRemoveCopilotDraftMessage(state);
    }
    case CopilotEntitiesActionNames.CANCEL_COPILOT_REQUEST: {
      return getStateForCancelCopilotRequest(state);
    }
    case CopilotViewActionNames.SHOW_COPILOT_MODAL: {
      return getStateForShowCopilotModal(state, action.payload);
    }
    case CopilotViewActionNames.HIDE_COPILOT_MODAL: {
      return getStateForHideCopilotModal(state);
    }
    case CopilotViewActionNames.TOGGLE_COPILOT_MODAL_EXPAND: {
      return getStateForToggleCopilotModalExpand(state);
    }
    case CopilotViewActionNames.TOGGLE_COPILOT_MODAL: {
      return getStateForToggleCopilotModal(state);
    }
    case CopilotViewActionNames.SET_SCROLL_POSITION: {
      return getStateForSetScrollPosition(state, action.payload);
    }
    case CopilotViewActionNames.OPEN_COPILOT_DETAILS: {
      return getStateForOpenCopilotDetails(state);
    }
    case CopilotViewActionNames.CLOSE_COPILOT_DETAILS: {
      return getStateForCloseCopilotDetails(state);
    }
    case CopilotViewActionNames.SHOW_COPILOT_NEW_MESSAGE_TOAST: {
      return getStateForShowCopilotNewMessageTooltip(state);
    }
    case CopilotViewActionNames.HIDE_COPILOT_NEW_MESSAGE_TOAST: {
      return getStateForHideCopilotNewMessageTooltip(state);
    }
    case CopilotViewActionNames.OPEN_COMMAND_SUGGESTIONS: {
      return getStateForOpenCommandSuggestions(state);
    }
    case CopilotViewActionNames.CLOSE_COMMAND_SUGGESTIONS: {
      return getStateForCloseCommandSuggestions(state);
    }
    case CopilotViewActionNames.COPILOT_ADD_WEBSITE: {
      return getStateForWidgetWebsite(state, action.payload);
    }
    default:
      return state;
  }
};
