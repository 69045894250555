import { isError } from '../http/type-guards';

import {
  type ConfigurationApiErrorResponse,
  type ConfigurationApiNormalizedError,
  type ConfigurationApiResponse,
} from './types';

export function isConfigurationApiError(item: unknown): item is ConfigurationApiNormalizedError {
  const error = item as ConfigurationApiNormalizedError;

  return typeof error === 'object' && error !== null && 'status' in error && 'type' in error && 'message' in error;
}

export function isApiErrorResponse(item: unknown): item is ConfigurationApiErrorResponse {
  const error = item as ConfigurationApiErrorResponse;

  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    error.error !== null &&
    'type' in error.error &&
    'message' in error.error
  );
}

export function isWithApiHttpError(item: unknown): item is ConfigurationApiResponse<unknown> {
  const error = item as ConfigurationApiResponse<unknown>;

  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    error.error !== null &&
    'http' in error.error &&
    (isApiErrorResponse(error.error.http) || isError(error.error.http))
  );
}
