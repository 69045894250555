import { ML_GATEWAY_API_VERSION } from 'constants/ml-gateway';
import { type PickRequired } from 'helpers/interface';
import type { IChatTopics } from 'interfaces/reports';

import { BaseApi, PassVersionMode } from '../base-api';

import type {
  AIAgentsListDTO,
  AssignToAgents,
  GetDocumentResponse,
  GetDocumentStructureResponse,
  IAddKnowledgeSource,
  IAnalyzeSourcePayload,
  IAnalyzeSourceResponse,
  IAnnotateReplyRequest,
  ICancelLearning,
  IGenerateReplyRequest,
  IGenerateReplyResponse,
  IKnowledgeSourceFileResponse,
  IKnowledgeSourceResponse,
  ILearnSkill,
  IListSuggestionsResponse,
  IRemoveSkill,
  ISendFeedbackRequest,
  ISendMessageRequest,
  IUpdateSkill,
  ListSkillsResponse,
  IAddNewAgentPayload,
  IAddNewAgentResponse,
  AcknowledgeNotificationsRequest,
  NotificationListDTO,
} from './interfaces/ai-agents';
import type { GetTopicsWithSentimentReportRequest } from './interfaces/chat-topics';
import type { MLGatewaySendEvent } from './interfaces/event-collector';

export class MLGatewayApi extends BaseApi {
  protected readonly version = ML_GATEWAY_API_VERSION;
  protected readonly passVersionMode = PassVersionMode.Prefix;

  getTopicsWithSentimentReport = (payload: GetTopicsWithSentimentReportRequest) =>
    this.post<IChatTopics>('reports/get-topic-sentiment', payload);

  generateReply = (payload: IGenerateReplyRequest) =>
    this.post<IGenerateReplyResponse>('ai-agents/responses/generate', payload);

  sendMessage = (payload: ISendMessageRequest) =>
    this.post<IGenerateReplyResponse>('ai-agents/chat/send-message', payload);

  sendAiAgentFeedback = (payload: ISendFeedbackRequest) => this.post('ai-agents/responses/send-feedback', payload);

  analyzeSource = (payload: IAnalyzeSourcePayload) =>
    this.post<IAnalyzeSourceResponse>('ai-agents/skills/analyze-source', payload);

  addUrlSkill = (payload: IAddKnowledgeSource) =>
    this.post<IKnowledgeSourceResponse>('ai-agents/skills/add-url', payload);

  listSkills = () => this.post<ListSkillsResponse>('ai-agents/skills/list', {});

  learnSkill = (payload: ILearnSkill) => this.post<ILearnSkill>('ai-agents/skills/learn', payload);

  removeSkill = (payload: IRemoveSkill) => this.post<IRemoveSkill>('ai-agents/skills/remove', payload);

  cancelLearningSkill = (payload: ICancelLearning) =>
    this.post<ICancelLearning>('ai-agents/skills/cancel-learning', payload);

  sendEvent = (payload: MLGatewaySendEvent) => this.post('events/send-event', payload);

  updateSkill = (payload: PickRequired<IUpdateSkill, 'id'>) =>
    this.post<IUpdateSkill>('ai-agents/skills/update', payload);

  batchCancelLearning = (payload: string[]) =>
    this.post<ICancelLearning>('ai-agents/skills/batch-cancel-learning', { ids: payload });

  batchLearnSkill = (payload: string[]) => this.post<ILearnSkill>('ai-agents/skills/batch-learn', { ids: payload });

  listSuggestions = () => this.post<IListSuggestionsResponse>('ai-agents/chat/list-suggestions', {});

  sendAnnotation = (payload: IAnnotateReplyRequest) => this.post('ai-agents/responses/annotate', payload);

  listAIAgents = () => this.post<AIAgentsListDTO[]>('ai-agents/agents/list', {});

  assignToAIAgents = (payload: AssignToAgents) => this.post('ai-agents/skills/assign-to-agents', payload);

  unassignFromAIAgents = (payload: AssignToAgents) => this.post('ai-agents/skills/unassign-from-agents', payload);

  getStructure = (source_id: string) =>
    this.post<GetDocumentStructureResponse>('ai-agents/sources/get-structure', { source_id });

  getDocument = (source_id: string, document_id: string) =>
    this.post<GetDocumentResponse>('ai-agents/sources/get-document', { document_id, source_id });

  removeDocuments = (source_id: string, document_ids: string[]) =>
    this.post<GetDocumentResponse>('ai-agents/sources/remove-documents', { document_ids, source_id });

  addFileSkill = (formData: FormData) => this.post<IKnowledgeSourceFileResponse>('ai-agents/skills/add-file', formData);

  addNewAgent = (payload: IAddNewAgentPayload) => this.post<IAddNewAgentResponse>('ai-agents/agents/add', payload);

  listNotifications = () => this.post<NotificationListDTO>('ai-agents/notifications/list', {});

  acknowledgeNotifications = (payload: AcknowledgeNotificationsRequest) =>
    this.post('ai-agents/notifications/acknowledge', payload);
}
