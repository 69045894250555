/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';

import { supportedCards } from '../constants';

import * as styles from './styles';

export const SupportedCards: FC = () => (
  <div>
    {supportedCards.map((card, index) => (
      <img
        className={styles.iconWithSpace}
        src={card['1x']}
        srcSet={`${card['2x']} 2x`}
        width="42px"
        height="28px"
        alt="card-icon"
        key={index}
      />
    ))}
  </div>
);
