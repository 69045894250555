import type { FC } from 'react';

import { type CopilotActionButtonTypes, type CopilotOnboardingActionButtons } from 'store/entities/copilot/interfaces';

import { type IRequiredActionButtonProps } from './interfaces';
import { OnboardingActionButton } from './OnboardingActionButton';

type PossibleProps = IRequiredActionButtonProps & Omit<CopilotOnboardingActionButtons, 'type'>;

export const ActionButtonsMap: Record<CopilotActionButtonTypes, FC<PossibleProps>> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'onboarding-button': OnboardingActionButton,
};
