import { useState, type FC } from 'react';

import { useSelector } from 'react-redux';

import { getBillingInfo } from 'store/entities/billing/selectors';
import { getCanManageApplicationPayments } from 'store/features/session/selectors';

import { NoCreditCardModal } from './NoCreditCardModal/NoCreditCardModal';
import { PurchaseModal } from './PurchaseModal/PurchaseModal';
import { RequestBuyModal } from './RequestBuyModal/RequestBuyModal';

export const PaymentModal: FC = () => {
  const canUserBuy = useSelector(getCanManageApplicationPayments);
  const hasBillingDetails = !!useSelector(getBillingInfo)?.lastFour;
  const [initialHasBillingDetails] = useState<boolean>(hasBillingDetails);

  if (!canUserBuy) {
    return <RequestBuyModal />;
  }

  if (!initialHasBillingDetails) {
    return <NoCreditCardModal />;
  }

  return <PurchaseModal />;
};
