import type { FC } from 'react';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ChargeType } from 'constants/one-click-payment';

import { AcceptDirectChargeFooter } from './AcceptDirectCharge';
import { AcceptRecurrentChargeFooter } from './AcceptRecurrentCharge';
import { ActivateProductFooter } from './ActivateProduct';
import { ErrorFooter } from './ErrorFooter';
import { FooterLoader } from './FooterLoader';

type Props = {
  isActivationRequired: boolean;
};

export const PurchaseModalFooter: FC<Props> = ({ isActivationRequired }) => {
  const { isLoading, paymentIntent, charge } = useOneClickPaymentContext();

  if (isLoading) {
    return <FooterLoader />;
  }

  if (isActivationRequired) {
    return <ActivateProductFooter />;
  }

  if (charge && paymentIntent?.metadata.type === ChargeType.DirectCharge) {
    return <AcceptDirectChargeFooter />;
  }

  if (charge && paymentIntent?.metadata.type === ChargeType.RecurrentCharge) {
    return <AcceptRecurrentChargeFooter />;
  }

  return <ErrorFooter />;
};
