import { css } from '@emotion/css';

import { SubscriptionCardInputFieldSize } from './constants';

const small = css`
  flex: 0.5;
`;

const medium = css`
  max-width: 50%;
  box-sizing: border-box;
`;

export const inputField = (isHidden: boolean, size: SubscriptionCardInputFieldSize): string => css`
  flex: 1;
  padding: 0;

  > div {
    width: 100%;
    -webkit-appearance: none;
  }

  input {
    font-size: 15px;
  }

  ${isHidden && 'display: none;'}
  ${size === SubscriptionCardInputFieldSize.Small && small}
  ${size === SubscriptionCardInputFieldSize.Medium && medium}
`;

export const labelContainer = css`
  display: flex;
`;

export const tooltipIcon = css`
  vertical-align: middle;
  padding: 2px;
  margin-left: 2px;
`;

export const tooltipMessage = css`
  width: 224px;
`;
