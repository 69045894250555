import { type FC, type MouseEvent, useCallback, useContext } from 'react';

import { Button, Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { CardFormContext } from 'components/card-form/CardFormContext';
import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { getConfig } from 'helpers/config';
import { getIsSavingCreditCard } from 'store/entities/billing/selectors';

import { ActivateProductFooter } from '../PurchaseModal/Footer/ActivateProduct';
import { ErrorFooter } from '../PurchaseModal/Footer/ErrorFooter';
import { FooterLoader } from '../PurchaseModal/Footer/FooterLoader';
import * as styles from '../PurchaseModal/Footer/styles';

const { livechatPageUrl } = getConfig();

interface Props {
  isProcessing: boolean;
  isActivationRequired: boolean;
}

export const NoCreditCardModalFooter: FC<Props> = ({ isProcessing, isActivationRequired }) => {
  const { isLoading, isUpdating, paymentIntent, charge, closeModal, isProcessable } = useOneClickPaymentContext();
  const isSavingCreditCard = useSelector(getIsSavingCreditCard);
  const cardFormContext = useContext(CardFormContext);

  const isSubmitting = isProcessing || isSavingCreditCard;

  const handleSubmitCardSave = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      cardFormContext.submit(event);
    },
    [cardFormContext],
  );

  if (isLoading) {
    return <FooterLoader />;
  }

  if (isActivationRequired) {
    return <ActivateProductFooter />;
  }

  if (charge && paymentIntent) {
    return (
      <div className={styles.purchaseWrapper}>
        <Text size="xs" as="div">
          By continuing you agree to <strong>Text</strong>{' '}
          <Button
            kind="link-light"
            size="xcompact"
            href={`${livechatPageUrl}/legal/terms/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms
          </Button>
        </Text>

        <div className={styles.buttonsWrapper}>
          <Button kind="secondary" disabled={isSubmitting} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            kind="primary"
            disabled={!isProcessable || isUpdating}
            loading={isSubmitting}
            loaderLabel="Processing"
            onClick={handleSubmitCardSave}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  return <ErrorFooter />;
};
