import { type FC } from 'react';

import { Divider } from 'components/divider/Divider';
import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import { PurchaseModalErrors } from '../Errors';

import { ActivationProductAlert } from './ActivationProductAlert';
import { ContentLoader } from './ContentLoader';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { PurchaseItem } from './PurchaseItem/PurchaseItem';

import * as styles from './styles';

type Props = {
  isActivationRequired: boolean;
};

export const PurchaseModalContent: FC<Props> = ({ isActivationRequired }) => {
  const { isLoading, charge, paymentIntent } = useOneClickPaymentContext();

  const shouldHidePicker = paymentIntent?.metadata?.hidePicker || paymentIntent?.metadata?.isExternalTransaction;

  if (isLoading) {
    return <ContentLoader />;
  }

  if (isActivationRequired) {
    return (
      <>
        <ActivationProductAlert />
        <PurchaseItem hidePicker />
      </>
    );
  }

  return (
    <>
      <PurchaseModalErrors />
      <PurchaseItem hidePicker={shouldHidePicker} />
      {charge && <Divider className={styles.divider} />}
      <PaymentSummary />
    </>
  );
};
