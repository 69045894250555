/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash.isempty';
import { type SagaIterator } from 'redux-saga';
import { call, put, takeEvery, all } from 'redux-saga/effects';

import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import {
  type IAddNewAgentPayload,
  type AIAgentsListDTO,
  type IAddNewAgentResponse,
} from 'services/api/ml-gateway/interfaces/ai-agents';
import type { RequestError } from 'services/api/types';

import { AIAgentsEntitiesActions } from './actions';
import { ONE_AGENT_TEMPLATE, REPLY_SUGGESTIONS_AGENT_TEMPLATE } from './constants';
import { deserializeAIAgents, deserializeNewAiAgents } from './helpers';

export function* createOneAndReplySuggestionsAgents(): SagaIterator {
  const { mlGatewayApi } = ApiManager;

  const agentPayloads: IAddNewAgentPayload[] = [
    {
      name: 'Reply Suggestions',
      agent_template_id: REPLY_SUGGESTIONS_AGENT_TEMPLATE.id,
      skill_ids: [],
    },
    {
      name: 'One',
      agent_template_id: ONE_AGENT_TEMPLATE.id,
      skill_ids: [],
    },
  ];

  try {
    const results: RequestResult<IAddNewAgentResponse, RequestError>[] = yield all(
      agentPayloads.map((payload) => call(mlGatewayApi.addNewAgent, payload)),
    );

    const deserializedAIAgents = deserializeNewAiAgents(results.map((result) => result.result));

    yield put(AIAgentsEntitiesActions.saveAIAgents(deserializedAIAgents));
  } catch (error) {
    Sentry.captureException(new Error('Failed to create new AI Agents'));
  }
}

export function* fetchAIAgents(): SagaIterator {
  const { mlGatewayApi } = ApiManager;

  const { result }: RequestResult<AIAgentsListDTO[], RequestError> = yield call(mlGatewayApi.listAIAgents);

  if (result) {
    if (!isEmpty(result)) {
      const deserializedAIAgents = deserializeAIAgents(result);

      yield put(AIAgentsEntitiesActions.saveAIAgents(deserializedAIAgents));

      return;
    }

    yield call(createOneAndReplySuggestionsAgents);
  }
}

export default function* aiAgentsSagas(): SagaIterator {
  yield takeEvery('APP_READY', fetchAIAgents);
}
