import { type FC, useState, useCallback } from 'react';

import { Button } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent } from 'constants/toasts';
import { handleDirectInstallationUrlFlow } from 'helpers/apps';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { showToast } from 'helpers/toast';
import { ToastVariant } from 'store/features/toasts/interfaces';

import * as styles from './styles';

export const ActivateProductFooter: FC = () => {
  const [isActivating, setIsActivating] = useState(false);
  const { closeModal, paymentIntent } = useOneClickPaymentContext();

  const activateProduct = useCallback(() => {
    const { directInstallUrl, clientId } = paymentIntent?.metadata || {};

    if (!paymentIntent || !directInstallUrl || !clientId) {
      return;
    }

    setIsActivating(true);

    handleDirectInstallationUrlFlow({
      clientId,
      directInstallUrl,
      name: paymentIntent.name,
      onClose: () => {
        sendPostMessageToMarketplace(PostMessageEvent.CheckOnboarding);
        showToast(ToastVariant.Success, ToastContent.ONE_CLICK_PAYMENT_ACTIVATED);
        closeModal();
      },
    });
  }, [paymentIntent, closeModal]);

  return (
    <div className={styles.footerWrapper}>
      <Button kind="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button kind="primary" loading={isActivating} loaderLabel="Activating..." onClick={activateProduct}>
        Activate app
      </Button>
    </div>
  );
};
