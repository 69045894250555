import type { ComponentType } from 'react';

import { type ITooltipProps } from '@livechat/design-system-react-components';

import { NavigationTooltipType } from 'constants/navigation';
import { useParamSelector } from 'hooks/use-param-selector';
import { getNavigationItemTooltip } from 'store/views/navigation/selectors';

import { AutomateTooltip } from '../tooltips/AutomateTooltip';
import { HelpDeskAppTooltip } from '../tooltips/HelpDeskAppTooltip';
import { HelpDeskPromoTooltip } from '../tooltips/HelpDeskPromoTooltip';
import { NewAvailabilityTooltip } from '../tooltips/NewAvailabilityTooltip';
import { NewVisitorTooltip } from '../tooltips/NewVisitorTooltip';
import { PrivateSuggestedCannedsTooltip } from '../tooltips/PrivateSuggestedCannedsTooltip';
import { TargetedMessagesTooltip } from '../tooltips/TargetedMessagesTooltip';

export interface INavigationTooltip {
  content: ComponentType | null;
  props: Omit<ITooltipProps, 'triggerRenderer'>;
}

const tooltipsMap = new Map([
  [NavigationTooltipType.NewVisitor, NewVisitorTooltip],
  [NavigationTooltipType.HelpDeskApp, HelpDeskAppTooltip],
  [NavigationTooltipType.HelpDeskPromo, HelpDeskPromoTooltip],
  [NavigationTooltipType.TargetedMessages, TargetedMessagesTooltip],
  [NavigationTooltipType.NewAvailability, NewAvailabilityTooltip],
  [NavigationTooltipType.PrivateSuggestedCanneds, PrivateSuggestedCannedsTooltip],
  [NavigationTooltipType.Automate, AutomateTooltip],
]);

export const useNavigationTooltip = (itemId: string): INavigationTooltip | null => {
  const tooltip = useParamSelector(getNavigationItemTooltip, itemId);
  const content = tooltipsMap.get(tooltip?.type) || null;

  if (!tooltip || !content) {
    return null;
  }

  const { type, kind, show } = tooltip;

  return {
    content: tooltipsMap.get(type) || null,
    props: {
      kind,
      isVisible: show,
      placement: 'right-start' as ITooltipProps['placement'],
      offsetMainAxis: 12,
    },
  };
};
