import { type StartupErrorAction, StartupErrorActionNames } from './actions';
import { type StartupErrorViewState } from './interfaces';

const initialState: StartupErrorViewState = {
  isUnexpectedErrorVisible: false,
};

export function startupErrorReducer(
  state: StartupErrorViewState = initialState,
  action: StartupErrorAction,
): StartupErrorViewState {
  switch (action.type) {
    case StartupErrorActionNames.SET_UNEXPECTED_ERROR: {
      const {
        payload: { isVisible },
      } = action;

      return {
        ...state,
        isUnexpectedErrorVisible: isVisible,
      };
    }

    case StartupErrorActionNames.SET_SIGN_IN_ERROR: {
      const {
        payload: { signInError },
      } = action;

      return {
        ...state,
        signInError,
      };
    }

    default:
      return state;
  }
}
