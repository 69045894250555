import { isBefore, startOfDay } from 'date-fns';
import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select, take } from 'redux-saga/effects';

import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { Feature } from 'constants/feature';
import { KnowledgeHubEvent } from 'constants/knowledge-hub';
import { QueryKey } from 'constants/query-key';
import { SprigEvents } from 'constants/sprig-events';
import { EventPlace } from 'helpers/analytics';
import { isMobileDevice } from 'helpers/device';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import { type KnowledgeSourceItem } from 'interfaces/knowledge-sources';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { getSprigService } from 'services/sprig';
import { AccountsActionNames } from 'store/entities/accounts/actions';
import { getAccountCreateDate } from 'store/entities/accounts/selectors';
import { getLoggedInAgentAccountId, getLoggedInAgentName } from 'store/entities/agents/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperty,
  getCopilotMessageSentCount,
  getCopilotOpenedCount,
  getCopilotSprigSurveySeen,
} from 'store/features/agent-custom-properties/selectors';
import { getCanUseFeature } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCopilotIsLoading } from 'store/views/copilot/selectors';

import { CopilotEntitiesActions } from '../actions';
import {
  KNOWLEDGE_HUB_EXPLORE_KNOWLEDGE_HUB_MESSAGE,
  KNOWLEDGE_HUB_RELEASE_DATE,
  KNOWLEDGE_HUB_TELL_ME_MORE_MESSAGE,
} from '../constants';
import { isAccountCreatedBeforeCopilotPopoverRelease, setUnreadMessagesCount } from '../helper-sagas';
import { type CopilotOnboardingActionPayload } from '../interfaces';

import {
  COPILOT_ONBOARDING_PRIORITY,
  MAX_COPILOT_ONBOARDING_MESSAGES_COUNT,
  MAX_COPILOT_ONBOARDING_OPENED_COUNT,
} from './constants';
import { type OnboardingConfig } from './copilot-onboarding-config';
import { KNOWLEDGE_HUB_ONBOARDING_CONFIG } from './knowledge-hub-onboarding-config';

function* getIsAccountCreatedBeforeKnowledgeHubRelease(): SagaIterator {
  const currentAgentId: string = yield select(getLoggedInAgentAccountId);
  let agentCreationDate: string = yield select(getAccountCreateDate, currentAgentId);

  if (!agentCreationDate) {
    yield take(AccountsActionNames.SET_ACCOUNTS);

    agentCreationDate = yield select(getAccountCreateDate, currentAgentId);
  }

  if (!agentCreationDate) {
    return false;
  }

  const knowledgeHubReleaseDate = startOfDay(new Date(KNOWLEDGE_HUB_RELEASE_DATE));

  return isBefore(agentCreationDate, knowledgeHubReleaseDate);
}

export function* getOnboardingToStart(): SagaIterator {
  const isMobile: boolean = isMobileDevice();
  const isCopilotTyping = yield select(getCopilotIsLoading);
  const canUseKnowledgeHub: boolean = yield select(getCanUseFeature, Feature.KnowledgeHub);
  const isCreatedBeforeKnowledgeHubRelease = yield call(getIsAccountCreatedBeforeKnowledgeHubRelease);

  if (isCopilotTyping) {
    return null;
  }

  const hasSeenCopilotOnboarding: boolean = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen,
  );

  const hasSeenKnowledgeHubOnboarding: boolean = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.KnowledgeHubOneOnboardingSeen,
  );

  return COPILOT_ONBOARDING_PRIORITY.find((onboardingName) => {
    switch (onboardingName) {
      case 'copilot-onboarding':
        return !hasSeenCopilotOnboarding;
      case 'knowledge-hub-onboarding':
        return !hasSeenKnowledgeHubOnboarding && canUseKnowledgeHub && isCreatedBeforeKnowledgeHubRelease && !isMobile;
      default:
        return null;
    }
  });
}

export function* handleCopilotOnboardingAction(
  action: IActionWithPayload<string, CopilotOnboardingActionPayload>,
): SagaIterator {
  const { content, props, eventId } = action.payload;

  yield put(
    CopilotEntitiesActions.updateCopilotEvent({
      eventId,
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'agent',
      authorType: 'agent',
      eventId: uniqueId(),
      text: content,
      timestampInMs: Date.now(),
      type: 'text-message',
    }),
  );

  if (props.navigateUrl) {
    navigate(props.navigateUrl);
    trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Adding source clicked' });
  }

  if (props.delay) {
    yield put(CopilotViewActions.setCopilotIsLoading(true));
    yield delay(props.delay);
    yield put(CopilotViewActions.setCopilotIsLoading(false));
  }

  switch (props.responseType) {
    case 'final-message':
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: `I’m here if you need any help! 👍`,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Explore' });
      break;
    case 'knowledge-hub-info':
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: KNOWLEDGE_HUB_TELL_ME_MORE_MESSAGE,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Tell me more' });
      break;
    case 'explore-knowledge-hub':
      navigate(AbsoluteAutomateRoutes.KnowledgeHubAllSources);
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: KNOWLEDGE_HUB_EXPLORE_KNOWLEDGE_HUB_MESSAGE,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: `I’m here if you need any help! 👍`,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Explore knowledge hub' });
      break;
  }

  yield call(setUnreadMessagesCount);
}

export function* startCopilotOnboarding(config: OnboardingConfig): SagaIterator {
  trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding started' });

  const loggedAgentName: string = yield select(getLoggedInAgentName);

  const queryClient = getQueryClient();
  const knowledgeSources = queryClient.getQueryData<KnowledgeSourceItem[]>([QueryKey.KnowledgeSourcesList]);
  const hasAnyKnowledgeSources = !!knowledgeSources?.length;

  for (const getMessage of config) {
    const message = getMessage({ name: loggedAgentName, hasAnyKnowledgeSources });

    if (!message) {
      continue;
    }

    yield put(
      CopilotEntitiesActions.addCopilotMessage({
        authorId: 'live-assistant',
        authorType: 'live-assistant',
        eventId: uniqueId(),
        text: message.text,
        timestampInMs: Date.now(),
        type: 'text-message',
        properties: {
          actionButtons: message.actionButtons ?? undefined,
          withoutActions: true,
        },
      }),
    );

    if (message.delay) {
      yield put(CopilotViewActions.setCopilotIsLoading(true));
      yield delay(message.delay);
      yield put(CopilotViewActions.setCopilotIsLoading(false));
    }

    yield call(setUnreadMessagesCount);
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.OnePopoverOnboardingSeen]: true,
    }),
  );
  trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding finished' });
}

export function* startKnowledgeHubOnboarding(): SagaIterator {
  const loggedAgentName: string = yield select(getLoggedInAgentName);

  for (const getMessage of KNOWLEDGE_HUB_ONBOARDING_CONFIG) {
    const message = getMessage({ name: loggedAgentName });

    if (message) {
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: message.text,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            actionButtons: message.actionButtons ?? undefined,
            withoutActions: true,
          },
        }),
      );

      if (message.delay) {
        yield put(CopilotViewActions.setCopilotIsLoading(true));
        yield delay(message.delay);
        yield put(CopilotViewActions.setCopilotIsLoading(false));
      }
    }
  }

  yield call(setUnreadMessagesCount);

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.KnowledgeHubOneOnboardingSeen]: true,
    }),
  );

  trackEvent(KnowledgeHubEvent.OnboardingSeen, EventPlace.One);
}

export function* handleCopilotPromo(): SagaIterator {
  const copilotOpenedCount = yield select(getCopilotOpenedCount);
  const copilotMessageSentCount = yield select(getCopilotMessageSentCount);
  const copilotSprigSurveySeen = yield select(getCopilotSprigSurveySeen);

  if (copilotSprigSurveySeen) {
    return;
  }

  const sprig = getSprigService();
  const isOldUser = yield call(isAccountCreatedBeforeCopilotPopoverRelease);

  if (isOldUser && copilotMessageSentCount === 1) {
    yield call([sprig, sprig.initSprigEvent], SprigEvents.FirstMessageInOne);
  }

  if (
    copilotMessageSentCount === MAX_COPILOT_ONBOARDING_MESSAGES_COUNT &&
    copilotOpenedCount === MAX_COPILOT_ONBOARDING_OPENED_COUNT
  ) {
    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.OneSprigSurveySeen]: 1,
      }),
    );
    yield call([sprig, sprig.initSprigEvent], SprigEvents.OneConversation);
  }
}
