import * as Sentry from '@sentry/browser';
import debug from 'debug';
import memoize from 'lodash.memoize';
import throttle from 'lodash.throttle';

import { SESSION_STORAGE_SESSION_SOURCE_PARAMS_KEY } from 'constants/analytics';
import { type AppsSection } from 'constants/apps/events';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { EnvironmentType } from 'constants/environment';
import { LoginErrorEvent } from 'constants/login-error-event';
import { type Section } from 'constants/section';
import { EventPlace, type EventSubsection } from 'helpers/analytics';
import { type AutomateSection } from 'helpers/analytics-automate';
import { type SettingsSection } from 'helpers/analytics-settings';
import { browserMajorVersion, browserName } from 'helpers/browser';
import { getConfig } from 'helpers/config';
import { getEnvironment } from 'helpers/feature-toggle';
import { isGhostLogin } from 'helpers/ghost';
import { trimObject } from 'helpers/object';
import { getRoute } from 'helpers/routes';
import { type AmplitudeEventProperties, logAmplitudeEvent } from 'services/amplitude';
import { AppStateProvider } from 'services/app-state-provider';
import { getItem } from 'services/local-storage';
import { getItem as getSessionItem } from 'services/session-storage';
import { getOrganizationId } from 'store/entities/accounts/selectors';
import { type IAgent } from 'store/entities/agents/interfaces';
import { getLoggedInAgent, getLoggedInAgentRole } from 'store/entities/agents/selectors';
import { getExperimentsGroups } from 'store/entities/experiments/selectors';
import { getBusinessModel, getLicenseId, getTrialDay } from 'store/features/session/selectors';

export const KEYBOARD_SHORTCUT_EVENT_THROTTLE_TIME = 60000; // 1 minute in milliseconds
export const CANNED_RESPONSE_USED_EVENT_THROTTLE_TIME = 43200000; // 12hours in milliseconds

const log = debug(DebugLogsNamespace.AppBusinessEvents);

const noLicenseEvents: string[] = [LoginErrorEvent.LoginError];

const noLicenseEventPlaces = [EventPlace.TooManyAgents, EventPlace.StartupError, EventPlace.LogoutScreen];

export function enableBusinessEventsLogging(): void {
  if (getConfig().enableBusinessEventsLogging) {
    // you can disable logging by setting localStorage.debug = '-app:businessEvents';
    if (!debug(DebugLogsNamespace.AppBusinessEvents).enabled) {
      debug.enable([DebugLogsNamespace.AppBusinessEvents, getItem('debug')].filter((s) => !!s).join(','));
    }
  }
}

function eventPlaceToPrefix(eventPlace: EventPlace): string {
  switch (eventPlace) {
    case EventPlace.Onboarding:
    case EventPlace.InstallCode:
      return 'License onboarding';
    default:
      return eventPlace.toString();
  }
}

export function getDefaultEventProperties(eventPlace: EventPlace | null = null): AmplitudeEventProperties {
  const state = AppStateProvider.getState();
  const businessModel = getBusinessModel(state);
  const permission = getLoggedInAgentRole(state);
  const days = getTrialDay(state);
  const place = eventPlace;
  const test = getExperimentsGroups(state);
  const path = getRoute();
  const license = getLicenseId(state);
  const organization = getOrganizationId(state);
  const sourceParams = getSessionItem(SESSION_STORAGE_SESSION_SOURCE_PARAMS_KEY);

  return {
    days,
    license,
    organization,
    path,
    permission,
    businessModel,
    place,
    test,
    browserName,
    browserVersion: browserMajorVersion,
    legacyApp: false,
    sourceParams,
  };
}

function createEventName(eventName: string, eventPlace: EventPlace): string {
  return `${eventPlaceToPrefix(eventPlace)}: ${eventName}`;
}

function trackRawEvent(eventName: string, eventProperties: AmplitudeEventProperties = {}): void {
  const state = AppStateProvider.getState();
  const agent: IAgent = getLoggedInAgent(state);

  const isGhost = isGhostLogin();
  const agentLogin = agent && agent.login;
  const isProduction = getEnvironment(true) === EnvironmentType.Production;
  const isTextAccount = /(livechatinc|livechat|text)\.com$/.test(agentLogin);

  const skipTracking = isGhost || (isProduction && isTextAccount);

  if (skipTracking) {
    return;
  }

  const properties = trimObject(eventProperties);

  const hasLicenseId = !!properties.license;
  const isEventAllowedWithoutLicenseId =
    noLicenseEvents.includes(eventName) || noLicenseEventPlaces.some((place) => eventName.startsWith(place));

  if (!hasLicenseId && !isEventAllowedWithoutLicenseId) {
    // Api endpoint will ignore events that have no `license` property (except whitelisted events)
    log(
      `%cERROR - No license id provided. This event WAS NOT SENT. %c${eventName}`,
      'color: #ea3323; background: #fcf0f0',
      'color: #d226e6'
    );
    Sentry.captureException(new Error(`No license id found when sending Amplitude event: ${eventName}`));

    return;
  }

  logAmplitudeEvent(eventName, properties);

  log(`%c${eventName} %c${JSON.stringify(properties)}`, 'color: #d226e6', 'color: #928543');
}

export function trackEvent(
  eventName: string,
  eventPlace: EventPlace | null = null,
  eventProperties: AmplitudeEventProperties = {}
): void {
  const defaultProperties = getDefaultEventProperties(eventPlace);
  const properties: AmplitudeEventProperties = {
    ...defaultProperties,
    ...eventProperties,
  };

  if (eventPlace) {
    trackRawEvent(createEventName(eventName, eventPlace), properties);
  } else {
    trackRawEvent(eventName, properties);
  }
}

const cachedThrottledTrackEvent = memoize((fullEventName: string, wait: number) =>
  throttle(trackEvent, wait, { trailing: false })
);

export function trackThrottledEvent(
  eventName: string,
  eventPlace: EventPlace,
  wait: number,
  eventProperties: AmplitudeEventProperties = {}
): void {
  const cachedTrack = cachedThrottledTrackEvent(createEventName(eventName, eventPlace), wait);
  cachedTrack(eventName, eventPlace, eventProperties);
}

export function composeSubsectionEventPlace(section: EventPlace, subsection: EventSubsection): EventPlace {
  return `${section} - ${subsection}` as unknown as EventPlace;
}

export function trackSettingsEvent(
  eventName: string,
  settingsSection: SettingsSection,
  eventProperties?: AmplitudeEventProperties
): void {
  trackEvent(eventName, composeSubsectionEventPlace(EventPlace.Settings, settingsSection), {
    settingsSection,
    ...eventProperties,
  });
}

export function trackAutomateEvent(
  eventName: string,
  automateSection: AutomateSection,
  eventProperties?: AmplitudeEventProperties
): void {
  trackEvent(eventName, composeSubsectionEventPlace(EventPlace.Automate, automateSection), {
    automateSection,
    ...eventProperties,
  });
}

export function trackDetailsColumnEvent(
  eventName: string,
  section: Section,
  eventProperties?: AmplitudeEventProperties
): void {
  trackEvent(eventName, EventPlace.CustomerDetails, {
    section,
    ...eventProperties,
  });
}

export function trackAppsSectionEvent(
  eventName: string,
  appsSection: AppsSection,
  eventProperties?: AmplitudeEventProperties
): void {
  trackEvent(eventName, composeSubsectionEventPlace(EventPlace.Apps, appsSection), {
    appsSection,
    ...eventProperties,
  });
}
