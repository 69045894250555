import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { getShouldShowAutomateTooltip } from 'store/features/agent-custom-properties/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const AutomateBadge: FC = () => {
  const shouldShowAutomateTooltip = useSelector(getShouldShowAutomateTooltip);

  if (!shouldShowAutomateTooltip) {
    return null;
  }

  return <NavigationBadge kind="primary" type="dot" />;
};
