import type { FC } from 'react';

import { Alert, Heading, Text } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import * as styles from './styles';

export const PurchaseModalErrors: FC = () => {
  const { error, isProcessable } = useOneClickPaymentContext();

  if (!error && !isProcessable) {
    return (
      <Alert kind="warning" className={styles.alert}>
        <Heading size="xs">Transaction already processed</Heading>
        You can no longer make operations. This transaction has already been processed.
      </Alert>
    );
  }

  if (error) {
    return (
      <Alert kind="warning" className={styles.alert}>
        <Heading size="xs">Payment has been declined</Heading>
        <Text>
          Payment has been declined. We&apos;re sorry, but there&apos;s been an error and the transaction failed. Try
          again or contact our support.
        </Text>
      </Alert>
    );
  }

  return null;
};
