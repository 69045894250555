/* eslint-disable react/jsx-props-no-spreading */
import { type FC } from 'react';

import { cx } from '@emotion/css';
import {
  ContentCopy,
  CannedResponse,
  ThumbDown,
  ThumbDownFilled,
  ChevronDown,
  ChevronUp,
} from '@livechat/design-system-icons';
import { Badge, Button, Icon, Tooltip } from '@livechat/design-system-react-components';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { plural } from 'helpers/string';
import useToggle from 'hooks/use-toggle';
import { trackEvent } from 'services/event-tracking';
import { type CopilotMessageEvent } from 'store/entities/copilot/interfaces';

import { COPILOT_CHAT_FEED_ID, COPILOT_SCROL_TIMEOUT } from '../constants';
import { getSources } from '../helpers';

import { ApiSourceItem } from './ApiCallSourceItem';
import { PdfSourceItem } from './PdfSourceItem';
import { UrlSourceItem } from './UrlSourceItem';
import { useMessageBottomBar } from './use-message-bottom-bar';

import * as styles from './styles';

interface IProps {
  event: CopilotMessageEvent;
}

export const CopilotAssistantMessageBottomBar: FC<IProps> = ({ event }) => {
  const [isSourcesVisible, toggleIsSourcesVisible] = useToggle(false);

  const eventSources = event?.properties?.sources;
  const isWithSources = !!eventSources && eventSources.length > 0;
  const isMessageRated = !!event?.properties?.rated;
  const numberOfSources = eventSources?.length || 0;

  const { topSources } = getSources(eventSources);
  const { handleCopyClick, handleAddCannedResponseClick, handleRateMessageClick } = useMessageBottomBar(event);

  const sourcesText = `${plural(numberOfSources, 'Source', 'Sources')}`;

  const handleSourceExpand = (): void => {
    const copilotChatFeed = document.getElementById(COPILOT_CHAT_FEED_ID);

    const isScrolledToBottom = copilotChatFeed
      ? copilotChatFeed.scrollHeight - copilotChatFeed.scrollTop - copilotChatFeed.clientHeight < 1
      : false;

    toggleIsSourcesVisible();

    if (isScrolledToBottom && copilotChatFeed) {
      setTimeout(() => {
        copilotChatFeed.scrollTo({ top: copilotChatFeed.scrollHeight });
      }, COPILOT_SCROL_TIMEOUT);
    }
    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'source expanded' });
  };

  return (
    <div className={styles.bottomBarContainer}>
      <div className={styles.bottomBar}>
        <div>
          {isWithSources && (
            <div className={styles.sourcesContainer}>
              <Badge
                className={cx({ [styles.sourcesBadgeActive]: isSourcesVisible })}
                size="compact"
                count={numberOfSources}
                kind="tertiary"
              />
              <Button
                kind="plain"
                size="xcompact"
                onClick={handleSourceExpand}
                className={cx(styles.sourcesButton, { [styles.sourcesButtonActive]: isSourcesVisible })}
                iconPosition="right"
                icon={<Icon source={isSourcesVisible ? ChevronUp : ChevronDown} />}
              >
                {sourcesText}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.bottomBarIcons}>
          <Tooltip
            placement="top"
            kind="invert"
            className="tooltip"
            triggerRenderer={
              <Button
                kind="plain"
                size="xcompact"
                className={styles.button}
                icon={<Icon source={ContentCopy} />}
                onClick={() => {
                  void handleCopyClick();
                }}
              />
            }
          >
            <div className={styles.tooltip}>Copy</div>
          </Tooltip>

          <Tooltip
            placement="top"
            kind="invert"
            className="tooltip"
            triggerRenderer={
              <Button
                kind="plain"
                size="xcompact"
                className={styles.button}
                icon={<Icon source={CannedResponse} />}
                onClick={() => {
                  void handleAddCannedResponseClick();
                }}
              />
            }
          >
            <div className={styles.tooltip}>Save canned response</div>
          </Tooltip>

          {event?.sessionId && (
            <Tooltip
              placement="top"
              kind="invert"
              className="tooltip"
              useClickHookProps={{
                ignoreMouse: true,
              }}
              triggerRenderer={
                <Button
                  disabled={isMessageRated}
                  kind="plain"
                  size="xcompact"
                  className={cx(styles.button, {
                    [styles.defaultCursor]: isMessageRated,
                  })}
                  icon={<Icon source={isMessageRated ? ThumbDownFilled : ThumbDown} />}
                  onClick={handleRateMessageClick}
                />
              }
            >
              <div className={styles.tooltip}>{isMessageRated ? 'Rated badly' : 'Rate as bad response'}</div>
            </Tooltip>
          )}
        </div>
      </div>
      {isSourcesVisible && (
        <ul className={styles.sourcesList}>
          {topSources.map((source) => {
            switch (source.type) {
              case 'api_call':
                return <ApiSourceItem key={source.name} {...source} />;

              case 'url':
                return <UrlSourceItem key={source.name} {...source} />;

              // in the future we would need to diffrentiate between many file sub-types
              case 'file':
                return <PdfSourceItem key={source.name} {...source} />;

              default:
                return null;
            }
          })}
        </ul>
      )}
    </div>
  );
};
