/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';
import { select, put, call, takeLatest } from 'redux-saga/effects';

import { EventNames } from 'constants/event-bus-events';
import { type KeyMap } from 'helpers/interface';
import { prepareTextEnhancementPayload } from 'helpers/text-enhancements';
import { type ITextEnhancementError } from 'hooks/text-enhancements/interfaces';
import { type ITextEnhancementResult } from 'hooks/text-enhancements/use-text-enhancement';
import { type MLGatewayError } from 'services/api/ml-gateway/interfaces/common';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { textEnhancementsClient } from 'services/connectivity/ml-gateway-api/text-enhancements/client';
import { EventBus } from 'services/event-bus';
import { type IActionWithPayload } from 'store/helper';

import { ChatsViewActions, ChatsViewActionsNames } from '../actions';
import { type RequestTextEnhancementsPayload } from '../interfaces';
import { getCurrentMessageBoxValue, getSelectedThreadId, getTextEnhancementsCurrentThreadSessions } from '../selectors';

function* generateTextEnhancements(payload: IActionWithPayload<string, RequestTextEnhancementsPayload>): SagaIterator {
  const {
    payload: { action, sessionId },
  } = payload;

  const currentMessageBoxValue: string = yield select(getCurrentMessageBoxValue);
  const apiPayload = prepareTextEnhancementPayload(action, currentMessageBoxValue);

  if (!apiPayload) {
    return null;
  }

  const { method, ...rest } = apiPayload;
  const textEnhClient = textEnhancementsClient[method];
  yield put(ChatsViewActions.openTextEnhancementsToolbar());

  const currentThreadId = yield select(getSelectedThreadId);

  yield put(
    ChatsViewActions.saveTextEnhancementsToolbarState({
      threadId: currentThreadId,
      response: '',
      prevValue: currentMessageBoxValue,
      error: null,
      isLoading: true,
    }),
  );

  const {
    result,
    error,
  }: ApiClientResponse<ITextEnhancementResult, MLGatewayError<ITextEnhancementError>> = yield call(textEnhClient, {
    ...rest,
    session_id: sessionId,
  });

  const currentThreadSessionsMap: KeyMap<string> = yield select(getTextEnhancementsCurrentThreadSessions);
  const currentThreadSessions = Object.values(currentThreadSessionsMap);

  if (error) {
    const httpError = error.http as unknown as MLGatewayError<ITextEnhancementError>;
    const shouldContinue = currentThreadSessions.find((item) => item === httpError?.innerError?.session_id);

    if (!shouldContinue) {
      return;
    }

    yield put(
      ChatsViewActions.saveTextEnhancementsToolbarState({
        threadId: currentThreadId,
        response: '',
        prevValue: currentMessageBoxValue,
        error: httpError?.innerError,
        isLoading: false,
      }),
    );

    return;
  }

  const response = result.result[0];

  const shouldContinue = currentThreadSessions.find((item) => item === result.session_id);

  if (!shouldContinue) {
    return;
  }

  yield put(ChatsViewActions.saveDraftMessage({ message: response, threadId: currentThreadId }));

  yield put(
    ChatsViewActions.saveTextEnhancementsToolbarState({
      threadId: currentThreadId,
      response,
      prevValue: currentMessageBoxValue,
      error: null,
      isLoading: false,
    }),
  );

  EventBus.emit(EventNames.SetMessageBoxValue, response);
}

export function* textEnhancementsSaga(): SagaIterator {
  yield takeLatest(ChatsViewActionsNames.REQUEST_TEXT_ENHANCEMENTS, generateTextEnhancements);
}
