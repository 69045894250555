// @ts-strict-ignore
import type { IPickerListItem } from '@livechat/design-system-react-components';
import memoizeOne from 'memoize-one';

import { type Filter } from 'constants/filters/filter';
import { getImageWithCDN } from 'helpers/url';
import type { EqualityFilter, NumberRangeFilter, StringFilter } from 'interfaces/filter';

import type { IGoalFilterItem } from './goal-filter/goal-filter';
import type { IGreetingFilterItem } from './greeting-filter/interfaces';
import type { AvailableFilter, FilterConfig } from './interfaces';

/**
 * Creates an array with values to update filters.
 * @param selectedValue Selected filter value
 * @param currentValues All filter values which are currently set
 * @param singleOnlyValue Filter value which is supposed to deselect all other values and which is deselected if any other filter is selected.
 */
export const getMultiselectFilterValues = (
  selectedValue: string,
  currentValues: string[],
  singleOnlyValue?: string,
): string[] => {
  if (!currentValues) {
    return [selectedValue];
  }
  if (currentValues.some((selected) => selectedValue === selected)) {
    const withoutDeselected = currentValues.filter((selected) => selectedValue !== selected);

    return withoutDeselected.length === 0 ? null : withoutDeselected;
  }
  if (singleOnlyValue && selectedValue === singleOnlyValue) {
    return [selectedValue];
  }
  const withoutPredefined = currentValues.filter((selected) => selected !== singleOnlyValue);

  return [...withoutPredefined, selectedValue];
};

export function getFilteringType(
  filterValues: StringFilter | NumberRangeFilter | EqualityFilter,
  filteringTypes: string[],
): string {
  if (!filterValues) {
    return filteringTypes[0];
  }

  return filterValues.type;
}

export function isSameChoice(currentValues: string[], newValue: string): boolean {
  if (!currentValues?.length) {
    return false;
  }

  const [currentValue] = currentValues;

  return currentValue === newValue;
}

export const getSelectedItems = memoizeOne(
  (items: IGoalFilterItem[] | IGreetingFilterItem[] | any[], selectedIds: string[]): IPickerListItem[] | null => {
    if (!selectedIds) {
      return null;
    }

    const selectedItems = selectedIds.reduce((acc: IPickerListItem[], id: string) => {
      const item = items.find((item) => item?.id === id || item?.key === id || item?.name === id);

      if (item) {
        const key = item?.id || item?.key || item?.name;

        acc.push({
          key: key.toString(),
          name: item.name,
          // only for Agents filter currently
          avatarSrc: item?.avatarUrl ? getImageWithCDN(item.avatarUrl) : null,
          secondaryText: item?.email ? item.email : null,
        });
      }

      return acc;
    }, []);

    return selectedItems.length > 0 ? selectedItems : null;
  },
);

export const getFilterValue = (selectedItems: IPickerListItem[]): string[] => {
  const selectedItemsKeys = selectedItems.map((item) => item.key);

  return selectedItemsKeys.length > 0 ? selectedItemsKeys : null;
};

function calculateOrderedFilters(availableFilters: AvailableFilter[]): Filter[] {
  return availableFilters.filter((filter) => !filter.readOnly).map((filter: AvailableFilter) => filter.filter);
}

export const getOrderedFilters = memoizeOne((availableFilters: AvailableFilter[]): Filter[] =>
  calculateOrderedFilters(availableFilters),
);

function calculateFilterConfigs(availableFilters: AvailableFilter[]): { [key in Filter]?: FilterConfig } {
  return availableFilters.reduce(
    (acc, filter: AvailableFilter) => ({
      ...acc,
      [filter.filter]: filter.config,
    }),
    {},
  );
}

export const getFilterConfigs = memoizeOne((availableFilters: AvailableFilter[]): { [key in Filter]?: FilterConfig } =>
  calculateFilterConfigs(availableFilters),
);

export const calculateVisibleFilters = memoizeOne(
  (availableFilters: AvailableFilter[], filtersOrder: string[], currentFilter: Filter): string[] => {
    let filtersToShow: string[];
    if (currentFilter && !filtersOrder.some((filter: Filter) => filter === currentFilter)) {
      filtersToShow = [currentFilter, ...filtersOrder];
    } else {
      filtersToShow = filtersOrder;
    }

    return filtersToShow.filter((filterToShow: Filter) =>
      availableFilters.some((availableFilter) => availableFilter.filter === filterToShow),
    );
  },
);
