import {
  Automation,
  AutomationFilled,
  Messages,
  MessagesFilled,
  People,
  PeopleFilled,
} from '@livechat/design-system-icons';

import { ChatsBadge } from 'components/navigation/badges/chats-badge/ChatsBadge';
import { EngageBadge } from 'components/navigation/badges/EngageBadge';
import { TeamBadge } from 'components/new-navigation/badges/team-badge/TeamBadge';
import { NavigationPath } from 'constants/navigation';
import { Right } from 'constants/right';
import { BaseRouteName } from 'constants/routes';
import { Section } from 'constants/section';
import type { NavigationItemBadgeComponent } from 'interfaces/components/navigation/navigation-item-badge';
import type { NavigationItem } from 'interfaces/navigation-item';
import type { IWidget } from 'store/entities/applications/interfaces';

const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'chats',
    name: 'Chats',
    path: NavigationPath.Chats,
    iconName: Messages,
    activeIconName: MessagesFilled,
    trackEngagementTitle: 'Chats section opened',
    badgeComponent: ChatsBadge as NavigationItemBadgeComponent,
    independentBadge: true,
  },
  {
    id: 'engage',
    name: 'Engage',
    path: NavigationPath.Engage,
    iconName: Automation,
    activeIconName: AutomationFilled,
    trackEngagementTitle: 'Traffic section opened',
    badgeComponent: EngageBadge,
    independentBadge: true,
  },
  {
    id: 'one',
    name: 'One',
    path: '',
  },
  {
    id: 'team',
    testId: 'team-navigation-item',
    name: 'Team',
    path: NavigationPath.Team,
    iconName: People,
    activeIconName: PeopleFilled,
    trackEngagementTitle: 'Team: Section visited',
    right: Right.AgentsSectionAccess,
    badgeComponent: TeamBadge,
    independentBadge: true,
  },
  {
    id: 'mobile-profile',
    name: 'Profile',
    path: NavigationPath.MobileProfile,
    iconName: People,
    activeIconName: PeopleFilled,
    trackEngagementTitle: '',
  },
];

export function buildMobileNavigationItems(): NavigationItem[] {
  return NAVIGATION_ITEMS;
}

export type IWidgetItem = NavigationItem & {
  appSlug?: string;
  index: number;
  placement: ('desktop' | 'mobile')[];
};

const findDuplicateWidgets =
  ({ name }: IWidget) =>
  (acc: number[], item: IWidget, index: number) => {
    if (item.name === name) {
      acc.push(index);
    }

    return acc;
  };

const getPath = (app: IWidgetItem, duplicates: number[]): string => {
  const withoutDuplicates = duplicates.length === 1;
  const appSlug = withoutDuplicates && app.appSlug;

  return `/${BaseRouteName.App}/${appSlug || app.id}`;
};

const activityCallback =
  (app: IWidgetItem, duplicates: number[], route: string) =>
  (section: Section): boolean => {
    if (section !== Section.App) {
      return false;
    }

    const { index, appSlug, id } = app;
    const isFirstDuplicates = index === duplicates[0];

    return (
      (!!appSlug && isFirstDuplicates && route.startsWith(`/app/${appSlug}`)) ||
      (!!id && route.startsWith(`/app/${id}`))
    );
  };

export function buildFullscreenWidgetsItems(fullscreenWidgets: IWidget[], route: string): IWidgetItem[] {
  return fullscreenWidgets.map((widget, index) => {
    const duplicates = fullscreenWidgets.reduce(findDuplicateWidgets(widget), []);

    const { id, name, appSlug, icon, interfaceDarkIcon } = widget;

    const widgetData: IWidgetItem = {
      appSlug,
      customIconUrl: interfaceDarkIcon || icon,
      id,
      index,
      name,
      path: '',
      placement: ['desktop'],
    };

    widgetData.path = getPath(widgetData, duplicates);
    widgetData.activityCallback = activityCallback(widgetData, duplicates, route);

    return widgetData;
  });
}
