import type { FC } from 'react';

import { Button } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import { ActionButton } from './ActionButton';

import * as styles from './styles';

export const RequestBuyModalFooter: FC = () => {
  const { paymentIntent, closeModal } = useOneClickPaymentContext();

  if (!paymentIntent) {
    return null;
  }

  return (
    <div className={styles.footer}>
      <Button kind="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <ActionButton />
    </div>
  );
};
