import { useEffect, type FC, useState } from 'react';

import { Modal } from '@livechat/design-system-react-components';
import { ThreeDSecureAction } from '@recurly/react-recurly';

import { JSONParse } from 'helpers/json';

import * as styles from './styles';

interface IProps {
  serverError?: string;
  threeDSecureToken?: string;
  onSuccess(tokenId: string): void;
  onError(errorMessage: string): void;
}

export const ThreeDSecure: FC<IProps> = ({ threeDSecureToken, serverError, onSuccess, onError }) => {
  const [secureToken, setSecureToken] = useState<string | null>(null);

  useEffect(() => {
    if (threeDSecureToken) {
      setSecureToken(threeDSecureToken);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const error = JSONParse<{ three_d_secure_action_token_id: string }>(serverError);

    if (!error?.three_d_secure_action_token_id) {
      return;
    }

    setSecureToken(error.three_d_secure_action_token_id);
  }, [serverError, threeDSecureToken]);

  const handleError = (err: { message: string }): void => {
    setSecureToken(null);
    onError(err.message);
  };

  const handleSuccess = (token: { id: string }): void => {
    setSecureToken(null);
    onSuccess(token.id);
  };

  if (secureToken) {
    return (
      <Modal onClose={() => setSecureToken(null)}>
        <ThreeDSecureAction
          data-testid="three-d-secure"
          className={styles.modalBody}
          actionTokenId={secureToken}
          onToken={handleSuccess}
          onError={handleError}
        />
      </Modal>
    );
  }

  return null;
};
