// @ts-strict-ignore
import { useCallback, type ReactChild } from 'react';

import { useSelector } from 'react-redux';

import { BadgeType } from 'components/badge/Badge';
import { buildMobileNavigationItems } from 'components/navigation/configuration';
import { navigate } from 'helpers/routing';
import { shortenedCountNumber } from 'helpers/string';
import { getMyChatsIds, getQueuedIds, getUnassignedIds } from 'store/entities/chats/selectors';
import { getCurrentSection } from 'store/features/routing/selectors';

import { MobileNavigationItem } from './MobileNavigationItem';
import MobileNavigationMyProfileItem from './MobileNavigationMyProfileItem';
import { MobileNavigationOne } from './MobileNavigationOne';

import * as styles from './styles';

function getChatsCount(assignedChats = 0, queuedChats = 0, unassignedChats = 0): JSX.Element | string | null {
  if (assignedChats > 0) {
    return shortenedCountNumber(assignedChats);
  }

  if (queuedChats + unassignedChats > 0) {
    return <span className={styles.whiteDot} />;
  }

  return null;
}

function getBadgeContent(
  id: string,
  chatsCount: number,
  queuedChatsCount: number,
  unassignedChatsCount: number,
): ReactChild {
  const builders = {
    chats: () => getChatsCount(chatsCount, queuedChatsCount, unassignedChatsCount),
    visitors: () => (queuedChatsCount > 0 ? shortenedCountNumber(queuedChatsCount) : null),
    customers: () => (queuedChatsCount > 0 ? shortenedCountNumber(queuedChatsCount) : null),
  };

  const builder = builders[id];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return builder ? builder() : null;
}

export const MobileNavigation = (): JSX.Element => {
  const chatsCount = useSelector(getMyChatsIds).length;
  const queuedChatsCount = useSelector(getQueuedIds).length;
  const unassignedChatsCount = useSelector(getUnassignedIds).length;
  const navigationItems = buildMobileNavigationItems();
  const currentSection = useSelector(getCurrentSection);

  const onItemClick = useCallback((path: string) => {
    navigate(path, {
      replace: false,
    });
  }, []);

  return (
    <div className={styles.mobileNavigation}>
      <ul className={styles.list}>
        {navigationItems.map((item) => {
          switch (item.id) {
            case 'mobile-profile': {
              return (
                <li key={item.id}>
                  <MobileNavigationMyProfileItem
                    path={item.path}
                    active={item.id.startsWith(currentSection)}
                    badgeContent={getBadgeContent(item.id, chatsCount, queuedChatsCount, unassignedChatsCount)}
                    badgeType={BadgeType.Alert}
                    onItemClick={onItemClick}
                  />
                </li>
              );
            }

            case 'one': {
              return (
                <li key={item.id}>
                  <MobileNavigationOne />
                </li>
              );
            }

            default: {
              return (
                <li key={item.id}>
                  <MobileNavigationItem
                    icon={item.iconName}
                    activeIcon={item.activeIconName}
                    title={item.name}
                    path={item.path}
                    active={item.id.startsWith(currentSection)}
                    badgeContent={getBadgeContent(item.id, chatsCount, queuedChatsCount, unassignedChatsCount)}
                    badgeType={BadgeType.Alert}
                    onItemClick={onItemClick}
                  />
                </li>
              );
            }
          }
        })}
      </ul>
    </div>
  );
};
