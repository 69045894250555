import { useEffect, useState, type FC } from 'react';

import { Modal, ModalPortal } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { useParamSelector } from 'hooks/use-param-selector';
import { isApplicationInstalled } from 'store/entities/applications/selectors';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { PurchaseModalContent } from './Content/PurchaseModalContent';
import { PurchaseModalFooter } from './Footer/PurchaseModalFooter';
import { PurchaseModalHeader } from './Header/PurchaseModalHeader';

import * as styles from './styles';

export const PurchaseModal: FC = () => {
  const { closeModal, onPaymentModalClose, paymentIntent } = useOneClickPaymentContext();
  const [isActivationRequired, setIsActivationRequired] = useState(false);

  const isInstalled = useParamSelector(isApplicationInstalled, paymentIntent?.metadata?.itemId);

  useEffect(() => {
    if (isInstalled && paymentIntent?.metadata?.directInstallUrl) {
      setIsActivationRequired(true);
    } else if (isInstalled) {
      onPaymentModalClose();
    }
  }, [isInstalled, paymentIntent?.metadata?.directInstallUrl, onPaymentModalClose]);

  return (
    <ModalPortal zIndex={StackingContextLevel.FullOverlay}>
      <Modal
        className={styles.modal}
        fullSpaceContent
        onClose={closeModal}
        heading={<PurchaseModalHeader isActivationRequired={isActivationRequired} />}
        footer={<PurchaseModalFooter isActivationRequired={isActivationRequired} />}
      >
        <PurchaseModalContent isActivationRequired={isActivationRequired} />
      </Modal>
    </ModalPortal>
  );
};
