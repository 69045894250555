import { useEffect, useState } from 'react';

import { type LoadScriptOptions, loadScript } from 'helpers/load-script';

export enum ScriptURL {
  Recurly = 'https://js.recurly.com/v4/recurly.js',
}

const scriptLoadingStatuses: Record<string, 'loading' | 'loaded' | 'error'> = {};

export const useScript = (resourceUrl: string, options?: Partial<LoadScriptOptions>): boolean => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadScriptIfNeeded = async (): Promise<void> => {
      try {
        if (scriptLoadingStatuses[resourceUrl] === 'loaded') {
          setScriptLoaded(true);

          return;
        }

        if (scriptLoadingStatuses[resourceUrl] === 'loading') {
          const checkLoaded = setInterval(() => {
            if (scriptLoadingStatuses[resourceUrl] === 'loaded') {
              setScriptLoaded(true);
              clearInterval(checkLoaded);
            } else if (scriptLoadingStatuses[resourceUrl] === 'error') {
              setScriptLoaded(false);
              clearInterval(checkLoaded);
            }
          }, 100);

          return;
        }

        scriptLoadingStatuses[resourceUrl] = 'loading';

        await loadScript(resourceUrl, options);
        scriptLoadingStatuses[resourceUrl] = 'loaded';
        setScriptLoaded(true);
      } catch (error) {
        scriptLoadingStatuses[resourceUrl] = 'error';
        setScriptLoaded(false);
      }
    };

    void loadScriptIfNeeded();

    return () => {
      // Clean up any ongoing checks if the component unmounts
      Object.keys(scriptLoadingStatuses).forEach((url) => {
        if (scriptLoadingStatuses[url] === 'loading') {
          scriptLoadingStatuses[url] = 'error';
        }
      });
    };
  }, [resourceUrl, options]);

  return isScriptLoaded;
};
