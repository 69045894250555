import { type KeyMap } from 'helpers/interface';
import type { CannedResponse } from 'interfaces/canned-responses';
import type { IStoreState } from 'interfaces/store/store-state';
import {
  createHasFetchedSelector,
  createRequestFetchingSelector,
  type IWithRequestsState,
} from 'store/requests/selectors';
import { getSelectedGroupId } from 'store/views/settings/selectors';

import { CannedResponseActionNames } from './actions';
import { type ICannedResponseAutotags, type ICannedResponsesState } from './interfaces';

const EMPTY_ARRAY = [];

export interface IWithCannedResponsesState {
  entities: {
    cannedResponses: ICannedResponsesState;
  };
}

export const getIsFetchingCannedResponses = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([CannedResponseActionNames.FETCH_CANNED_RESPONSES])(state);

export function getCannedResponses(state: IWithCannedResponsesState): KeyMap<CannedResponse> {
  return state.entities.cannedResponses.byIds;
}

export const getCannedResponseById = (state: IWithCannedResponsesState, id: number): CannedResponse | null => {
  return getCannedResponses(state)[id];
};

export function getCannedResponse(state: IWithCannedResponsesState, id: number): CannedResponse | null {
  const canned = state.entities.cannedResponses.byIds?.[id];
  if (!canned) {
    return null;
  }

  return {
    ...canned,
    tags: canned.tags,
  };
}

export const getCannedResponsesForGroup = (state: IWithCannedResponsesState, groupId: string): CannedResponse[] => {
  const cannedResponses = getCannedResponses(state);
  const res = Object.values(cannedResponses).filter((cannedResponse) => groupId === cannedResponse.groupId);

  if (!res.length) {
    return EMPTY_ARRAY as CannedResponse[];
  }

  return res;
};

export const getCannedResponsesForSelectedGroup = (
  state: IWithCannedResponsesState & IStoreState,
): CannedResponse[] => {
  const groupId = getSelectedGroupId(state);

  return getCannedResponsesForGroup(state, groupId);
};

export function getCannedResponsesIdsByTags(state: IWithCannedResponsesState): KeyMap<string[]> {
  return state.entities.cannedResponses.idsByTags;
}

export const getHasCannedResponseSaved = (state: IStoreState): boolean =>
  createHasFetchedSelector([CannedResponseActionNames.SAVE_CANNED_RESPONSE])(state);

export const getCannedResponseAutotags = (state: IStoreState): KeyMap<ICannedResponseAutotags> =>
  state.entities.cannedResponses.autotags;

export const getIsCannedResponseConnectedWithAutotags = (state: IStoreState, cannedId: number): boolean => {
  return !!getCannedResponseAutotags(state)[cannedId];
};

export const getCannedResponseAutotagsById = (state: IStoreState, cannedId: number): string[] => {
  const cannedAutotags = state.entities.cannedResponses.autotags[cannedId];

  if (!cannedAutotags) {
    return EMPTY_ARRAY as string[];
  }

  return cannedAutotags.tags;
};

export const getIsFetchingCannedResponsesAutotags = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector([CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_BASE])(state);
