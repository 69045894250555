/* eslint-disable @typescript-eslint/naming-convention */
import { type UseMutateFunction, useMutation } from '@tanstack/react-query';

import type { IAIAssistantReplyFeedbackEvent } from 'services/api/ml-gateway/interfaces/event-collector';
import { eventCollectorClient } from 'services/connectivity/ml-gateway-api/event-collector/client';

type SendFeedbackEvent = {
  requestId: string;
};

type IUseSendFeedbackProps = {
  onSuccess: (payload: IAIAssistantReplyFeedbackEvent) => void;
  onError: () => void;
};

type UseSendFeedbackEvent = {
  sendFeedbackEvent: UseMutateFunction<IAIAssistantReplyFeedbackEvent, unknown, SendFeedbackEvent, unknown>;
};

export const useSendFeedbackEvent = ({ onSuccess, onError }: IUseSendFeedbackProps): UseSendFeedbackEvent => {
  const { mutate } = useMutation<IAIAssistantReplyFeedbackEvent, unknown, SendFeedbackEvent>({
    mutationFn: async ({ requestId }: SendFeedbackEvent) => {
      const eventToSend: IAIAssistantReplyFeedbackEvent = {
        project: 'live-assistant',
        type: 'feedback',
        request_id: requestId,
        template_version: '1.0.0',
        data: {
          version: '1.0.0',
          thumbsdown: true,
          negative_aspects: [],
        },
      };

      const { error } = await eventCollectorClient.sendEvent(eventToSend);

      if (error) {
        throw error;
      }

      return eventToSend;
    },
    onSuccess,
    onError,
  });

  return {
    sendFeedbackEvent: mutate,
  };
};
