import { type FC, type MouseEvent, useEffect, useState } from 'react';

import { Close as CloseIcon, ArrowDownward as ArrowDownwardIcon } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { plural } from 'helpers/string';
import { CopilotEmitterEvent, copilotEventEmitter } from 'services/copilot-event-emitter';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalExpanded, getCopilotUndreadMessageCount } from 'store/views/copilot/selectors';

import * as styles from './styles';

interface IProps {
  onScrollToBottom: () => void;
}

export const UnseenNotificationBadge: FC<IProps> = ({ onScrollToBottom }) => {
  const unreadMessageCount = useSelector(getCopilotUndreadMessageCount);
  const dispatch = useDispatch();
  const [isUnreadMessageBadgeVisible, setIsUnreadMessageBadgeVisible] = useState(true);
  const isExpanded = useSelector(getIsCopilotModalExpanded);

  const unreadMessageText = plural(unreadMessageCount, 'new message', 'new messages');

  useEffect(() => {
    if (!isUnreadMessageBadgeVisible) {
      setIsUnreadMessageBadgeVisible(true);
    }
  }, [unreadMessageCount]);

  if (!unreadMessageCount || !isUnreadMessageBadgeVisible) {
    return null;
  }

  function handleUnreadMessagesBadgeClose(event: MouseEvent<HTMLElement>): void {
    event.stopPropagation();

    setIsUnreadMessageBadgeVisible(false);
  }

  function handleUnreadMessagesBadgeClick(): void {
    dispatch(CopilotViewActions.resetCopilotUnreadMessagesCount());
    onScrollToBottom();

    copilotEventEmitter.emit(CopilotEmitterEvent.FocusCopilotMessageBox);

    setIsUnreadMessageBadgeVisible(false);
  }

  return (
    <div className={styles.unreadMessagesBadge(isExpanded)} onClick={handleUnreadMessagesBadgeClick}>
      <div>{unreadMessageCount}</div>
      <span>{unreadMessageText}</span>
      <Button
        kind="text"
        size="compact"
        className={styles.closeButton}
        icon={<Icon size="small" source={ArrowDownwardIcon} aria-label="Down" />}
        onClick={handleUnreadMessagesBadgeClick}
      />
      <Button
        kind="text"
        size="compact"
        className={styles.closeButton}
        icon={<Icon size="small" source={CloseIcon} aria-label="Close" />}
        onClick={handleUnreadMessagesBadgeClose}
      />
    </div>
  );
};
