export enum ProductOnboardingStep {
  AccountActivated = 'accountActivated',
  AvatarUploaded = 'avatarUploaded',
  CodeInstalled = 'codeInstalled',
  DesktopAppDownloaded = 'desktopAppDownloaded',
  SampleChat = 'sampleChat',
  InviteTeammates = 'inviteTeammates',
  /**
   * This step is not present in REST API so it must be excluded from IProductOnboardingProgress type
   */
  SetupChatbot = 'setupChatbot',
  SetupMessenger = 'setupMessenger',
  InstallMessenger = 'installMessenger',
  SetupWhatsApp = 'setupWhatsApp',
  InstallWhatsApp = 'installWhatsApp',
  SetupTwilio = 'setupTwilio',
  InstallTwilio = 'installTwilio',
  SetupTelegram = 'setupTelegram',
  InstallTelegram = 'installTelegram',
  KnowledgeHub = 'knowledgeHub',
}

export interface IProductOnboardingProgress
  extends Record<
    Exclude<
      ProductOnboardingStep,
      | ProductOnboardingStep.SetupChatbot
      | ProductOnboardingStep.SetupMessenger
      | ProductOnboardingStep.InstallMessenger
      | ProductOnboardingStep.InstallWhatsApp
      | ProductOnboardingStep.SetupWhatsApp
      | ProductOnboardingStep.SetupTwilio
      | ProductOnboardingStep.InstallTwilio
      | ProductOnboardingStep.SetupTelegram
      | ProductOnboardingStep.InstallTelegram
      | ProductOnboardingStep.KnowledgeHub
    >,
    boolean
  > {}
