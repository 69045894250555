import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type Nullable } from 'helpers/interface';
import { redirectToStartupError } from 'helpers/routing';
import type { RequestResult } from 'interfaces/api/client';
import {
  deserializeAccountsPayload,
  deserializeRolesPayload,
  deserializeSingleRolesPayload,
  type IRolesPayload,
  type ISingleRolePayload,
} from 'services/api/accounts/deserializer';
import { ApiManager } from 'services/api/api-manager';
import { AppStateProvider } from 'services/app-state-provider';
import { accountsClient } from 'services/connectivity/global-accounts-api/accounts/client';
import { type ListAccountsResponse } from 'services/connectivity/global-accounts-api/accounts/types';
import { normalizeError } from 'services/connectivity/global-accounts-api/helpers';
import {
  type GlobalAccountsApiClientError,
  type ConfigurationApiResponse as GlobalAccountsApiResponse,
} from 'services/connectivity/global-accounts-api/types';
import { waitBeforeRetry } from 'services/connectivity/retry-policy/wait-before-retry';
import { AccountsActions } from 'store/entities/accounts/actions';
import { type IAccount, type IAgentRole } from 'store/entities/accounts/interfaces';

const log = debug(DebugLogsNamespace.AppServerConnection);

async function fetchWithRetry<R, T extends { result: Nullable<R>; error?: Nullable<GlobalAccountsApiClientError> }>(
  fetchFunction: () => Promise<T>,
  retryAttempt: number = 1,
  maxRetries: number = 3,
): Promise<T> {
  const response = await fetchFunction();

  if (response.result) {
    return response;
  }

  const { error } = response;
  log(`Error while fetching accounts and roles`);
  log(error);

  if (retryAttempt >= maxRetries) {
    return response;
  }

  await waitBeforeRetry(retryAttempt);

  return fetchWithRetry(fetchFunction, retryAttempt + 1, maxRetries);
}

async function fetchRoles(): Promise<RequestResult<IRolesPayload[], GlobalAccountsApiClientError>> {
  return fetchWithRetry(
    () =>
      ApiManager.accountsApi.getOrganizationRoles() as Promise<
        RequestResult<IRolesPayload[], GlobalAccountsApiClientError>
      >,
  );
}

async function fetchAccounts(): Promise<GlobalAccountsApiResponse<ListAccountsResponse>> {
  return fetchWithRetry(() => accountsClient.list());
}

function logErrorAndRedirectOut(error: GlobalAccountsApiClientError): void {
  Sentry.captureException(error);
  redirectToStartupError(normalizeError(error).error_description);
}

async function fetchAccountsAndRoles(): Promise<{ accounts: IAccount[]; roles: IAgentRole[] }> {
  const [accountsResponse, rolesResponse] = await Promise.all([fetchAccounts(), fetchRoles()]);

  const error = accountsResponse.error || rolesResponse.error;
  if (error) {
    throw error;
  }

  const accounts = accountsResponse.result;
  const roles: RequestResult<ISingleRolePayload, GlobalAccountsApiClientError>[] = await Promise.all(
    deserializeRolesPayload(rolesResponse.result).map((item) => ApiManager.accountsApi.getOrganizationRole(item)),
  );

  const roleError = roles.find(({ error }) => error);

  if (roleError) {
    throw roleError.error;
  }

  return {
    accounts: deserializeAccountsPayload(accounts),
    roles: deserializeSingleRolesPayload(roles),
  };
}

export async function fetchAndStoreAccountsAndRoles(): Promise<void> {
  try {
    const { accounts, roles } = await fetchAccountsAndRoles();

    AppStateProvider.dispatch(AccountsActions.setRoles(roles));
    AppStateProvider.dispatch(AccountsActions.setAccounts(accounts));
  } catch (error) {
    logErrorAndRedirectOut(error as GlobalAccountsApiClientError);
  }
}
