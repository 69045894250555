import { type FC } from 'react';

import { useDispatch } from 'react-redux';

import { uniqueId } from 'helpers/string';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';

import { STATIC_SUGGESTION_DELAY } from './constants';
import { Suggestion } from './Suggestion';

const STATIC_QUESTION = 'How Copilot can assist my team with queries?';
const DESCRIPTION = 'Fuel Copilot with knowledge about your business';
const STATIC_MESSAGE =
  'I can learn about your products and services and help your team with queries regarding your business. Feed me with knowledge about your business in [Reply suggestions](https://www.livechat.com/help/reply-suggestions/) and I’ll:\n\n - quickly find relevant information from your knowledge base, \n- support agents by providing them reply suggestions during chats with customers.';

export const StaticSuggestion: FC = () => {
  const dispatch = useDispatch();

  const handleOnClick = (): void => {
    dispatch(
      CopilotEntitiesActions.addCopilotConversation([
        {
          event: {
            authorId: 'agent',
            authorType: 'agent',
            eventId: uniqueId(),
            text: STATIC_QUESTION,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
          delay: STATIC_SUGGESTION_DELAY,
        },
        {
          event: {
            authorId: 'live-assistant',
            authorType: 'live-assistant',
            eventId: uniqueId(),
            text: STATIC_MESSAGE,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
        },
      ]),
    );
  };

  return <Suggestion id={4} message={STATIC_QUESTION} description={DESCRIPTION} onClick={handleOnClick} />;
};
