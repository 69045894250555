import type { FC } from 'react';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ChargeType } from 'constants/one-click-payment';

import { ActivateProductHeader } from './ActivateProductHeader';
import { HeaderLoader } from './HeaderLoader';
import { PurchaseHeader } from './PurchaseHeader';

type Props = {
  isActivationRequired: boolean;
};

export const PurchaseModalHeader: FC<Props> = ({ isActivationRequired }) => {
  const { isLoading, paymentIntent } = useOneClickPaymentContext();
  const isRecurrentCharge = paymentIntent?.metadata.type === ChargeType.RecurrentCharge;

  if (isLoading) {
    return <HeaderLoader />;
  }

  if (isActivationRequired) {
    return <ActivateProductHeader />;
  }

  return <PurchaseHeader isRecurrentCharge={isRecurrentCharge} />;
};
