import { type FC } from 'react';

import { Button } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { navigateToMarketplace } from 'helpers/routing';
import { useParamSelector } from 'hooks/use-param-selector';
import { ProductCartActions } from 'store/entities/product-cart/actions';
import { getIsAddingProductToCart, getIsProductInCart } from 'store/entities/product-cart/selectors';

export const ActionButton: FC = () => {
  const { paymentIntent, closeModal } = useOneClickPaymentContext();
  const { itemId = '', isExternalTransaction } = paymentIntent?.metadata || {};

  const dispatch = useDispatch();
  const isInCart = useParamSelector(getIsProductInCart, itemId);
  const isAddingToCart = useParamSelector(getIsAddingProductToCart, itemId);

  if (isExternalTransaction) {
    return (
      <Button kind="primary" disabled>
        Confirm
      </Button>
    );
  }

  if (isInCart) {
    const handleViewCart = (): void => {
      navigateToMarketplace('/cart');
      closeModal();
    };

    return (
      <Button kind="primary" onClick={handleViewCart}>
        View Cart
      </Button>
    );
  }

  const handleAddToCart = (): void => {
    dispatch(
      ProductCartActions.addApplication({
        id: itemId,
      }),
    );
  };

  return (
    <Button kind="primary" onClick={handleAddToCart} loading={isAddingToCart}>
      Add to Cart
    </Button>
  );
};
