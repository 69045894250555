import { AbsoluteAutomateRoutes } from 'constants/automate/constants';

import { type CopilotOnboardingActionButtons } from '../interfaces';

export type Message = {
  text: string;
  actionButtons?: CopilotOnboardingActionButtons[];
  delay?: number;
} | null;

export type OnboardingConfig = ((contextVariables: { name: string; hasAnyKnowledgeSources: boolean }) => Message)[];

export const COPILOT_ONBOARDING_CONFIG: ((contextVariables: {
  name: string;
  hasAnyKnowledgeSources: boolean;
}) => Message)[] = [
  ({ name }) => ({
    text: `Hi, ${name}!`,
    delay: 2000,
  }),
  () => ({
    text: `I'm Copilot, your AI assistant and I’m here to make your job easier!`,
    delay: 2000,
  }),
  ({ hasAnyKnowledgeSources }) => ({
    text: hasAnyKnowledgeSources
      ? `I will answer your questions about LiveChat. I also learn your business and ensure chat assistance based on the provided sources.`
      : 'I will answer your questions about LiveChat. I also learn your business and ensure chat assistance based on the provided sources. Expand the window to enjoy a complete chat experience with all AI features.',
    delay: hasAnyKnowledgeSources ? 2000 : undefined,
    actionButtons: !hasAnyKnowledgeSources
      ? [
          {
            content: 'Train me with sources',
            type: 'onboarding-button',
            props: {
              responseType: 'final-message',
              navigateUrl: AbsoluteAutomateRoutes.KnowledgeHubAllSources,
              kind: 'secondary',
              delay: 2000,
            },
          },
          {
            content: 'Not now',
            type: 'onboarding-button',
            props: {
              responseType: 'final-message',
              kind: 'text',
              delay: 2000,
            },
          },
        ]
      : undefined,
  }),
];

export const COPILOT_ONBOARDING_CONFIG_OLD_USERS: ((contextVariables: {
  name: string;
  hasAnyKnowledgeSources: boolean;
}) => Message)[] = [
  ({ name }) => ({
    text: `Hey, ${name}!\n\n Now you can ask me anything from any place in the app. I’m always available in the top bar. Expand the window to enjoy a complete chat experience with all AI features.`,
  }),
];
