import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { formatDecimalPrice } from 'components/one-click-payment/helpers';
import { BillingCycle } from 'constants/one-click-payment';

import * as styles from './styles';

interface Props {
  value: number;
  months?: BillingCycle;
}

export const PriceFormatter: FC<Props> = ({ value, months }) => {
  if (!value) {
    return null;
  }

  const formattedValue = formatDecimalPrice(value);

  return (
    <>
      ${formattedValue}
      {months && (
        <Text className={styles.frequency} as="span" size="sm">
          {months === BillingCycle.Monthly && '/mo'}
          {months === BillingCycle.Yearly && '/yr'}
        </Text>
      )}
    </>
  );
};
