// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { isDesktopView, isMobileView } from 'helpers/device';
import {
  collapseNotificationBar,
  collapseNotificationBarWithoutAnimation,
  expandNotificationBar,
} from 'services/notifications-bar';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentStatus } from 'store/entities/agents/selectors';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import {
  getHasPendingInvites,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { getHadChatsRecently, getIsCodeInstalled } from 'store/features/code-installation/selectors';

import { NotificationsBarActions, NotificationsBarActionsNames } from './actions';
import { shouldShowBrowserNotificationsBar } from './helpers';
import { hasMobileNotifications, hasNotifications, isNotificationBarVisible } from './selectors';

function* expand(): SagaIterator {
  const isVisible = yield select(isNotificationBarVisible);

  if (!isVisible) {
    return;
  }

  const shouldExpandOnDesktop = yield select(hasNotifications);
  const shouldExpandOnMobile = yield select(hasMobileNotifications);
  const shouldExpand = (isMobileView() && shouldExpandOnMobile) || (isDesktopView() && shouldExpandOnDesktop);

  if (!shouldExpand) {
    return;
  }

  expandNotificationBar();
}

function* handleBrowserNotificationBar(): SagaIterator {
  const showBrowserNotificationsBar = shouldShowBrowserNotificationsBar();
  const isCodeInstalled = yield select(getIsCodeInstalled);

  if (showBrowserNotificationsBar && isCodeInstalled) {
    const barType = showBrowserNotificationsBar as TopBarNotificationType;
    yield put(
      NotificationsBarActions.showNotificationsBar({
        name: TopBarNotificationType.BrowserNotifications,
        props: { type: barType },
      }),
    );
  }
}

function* handleInstallCodeNotificationBar(): SagaIterator {
  const isCodeInstalled = yield select(getIsCodeInstalled);
  const hadChatsRecently = yield select(getHadChatsRecently);

  if (hadChatsRecently) {
    return;
  }

  if (!isCodeInstalled) {
    yield put(
      NotificationsBarActions.showNotificationsBar({
        name: TopBarNotificationType.InstallCode,
      }),
    );
  }
}

function* handleAwayStatusNotificationBar(): SagaIterator {
  const status: LoginStatus = yield select(getLoggedInAgentStatus);

  if (status === LoginStatus.Away) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }));
  }
}

function* handlePendingInvitesNotificationBar(): SagaIterator {
  const areAgentCustomPropertiesFetched: boolean = yield select(hasFetchedAgentCustomProperties);

  if (!areAgentCustomPropertiesFetched) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }
  const hasPendingInvites = yield select(getHasPendingInvites);

  if (hasPendingInvites) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.SendPendingInvites }));
  }
}

function* initializeSaga(): SagaIterator {
  yield all([
    call(handleBrowserNotificationBar),
    call(handleInstallCodeNotificationBar),
    call(handleAwayStatusNotificationBar),
    call(handlePendingInvitesNotificationBar),
  ]);

  yield put(NotificationsBarActions.toggleDisplayNotificationsBar());
  yield call(expand);
}

function collapseWithoutAnimation(): void {
  collapseNotificationBarWithoutAnimation();
}

function* hideNotification(): SagaIterator {
  const hasNotificationsOnDesktop = yield select(hasNotifications);
  const hasNotificationsOnMobile = yield select(hasMobileNotifications);
  const shouldCollapse =
    (isMobileView() && !hasNotificationsOnMobile) || (isDesktopView() && !hasNotificationsOnDesktop);

  if (!shouldCollapse) {
    return;
  }

  collapseNotificationBar();
  yield call(expand);
}

export function* notificationsBarSaga(): SagaIterator {
  yield takeLatest('APP_READY', initializeSaga);
  yield takeEvery(NotificationsBarActionsNames.HIDE_NOTIFICATIONS_BAR, hideNotification);
  yield takeEvery(NotificationsBarActionsNames.HIDE_ALL_NOTIFICATIONS_BAR, collapseWithoutAnimation);
  yield takeEvery(
    [NotificationsBarActionsNames.SHOW_NOTIFICATIONS_BAR, NotificationsBarActionsNames.SHOW_ALL_NOTIFICATIONS_BAR],
    expand,
  );
}
