import isEmpty from 'lodash.isempty';

import { ChatSummaryErrorMessages } from 'components/customer-details-column/sections/chat-summary/constants';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { type FileUploadError } from 'helpers/file';
import { plural } from 'helpers/string';
import { AppStateProvider } from 'services/app-state-provider';
import {
  type IUpdateTicketAssigneeSuccessPayload,
  type IUpdateTicketRequesterSuccessPayload,
} from 'store/entities/tickets/interfaces';
import { ToastsActions } from 'store/features/toasts/actions';
import { type ToastVariant } from 'store/features/toasts/interfaces';
import {
  type ITicketsViewSendMessagePayload,
  type ITicketsViewBatchUpdatePayload,
} from 'store/views/tickets/interfaces';

import { getAssignedAgentText } from './get-last-event-message-text';
import { getTicketStatusLabel, getToastContent as getTicketsToastContent } from './tickets';

type ToastWithCustomVariables = Extract<
  ToastContent,
  | ToastContent.CHANGE_ROLE_SUCCESS
  | ToastContent.CHANGE_CHATS_LIMIT_SUCCESS
  | ToastContent.CREATE_TICKET_ERROR
  | ToastContent.MISSING_GROUP_ACCESS
  | ToastContent.TICKET_BATCH_UPDATE_SUCCESS
  | ToastContent.TICKET_REQUESTER_UPDATE_SUCCESS
  | ToastContent.TICKET_ASSIGNEE_UPDATE_SUCCESS
  | ToastContent.TICKET_SEND_MESSAGE_SUCCESS
  | ToastContent.CHAT_ALREADY_PICKED
  | ToastContent.AGENT_APPROVE_SUCCESS
  | ToastContent.GREETING_DELETE_SUCCESS
  | ToastContent.GREETING_DELETE_ERROR
  | ToastContent.GREETING_UPDATE_SUCCESS
  | ToastContent.GREETING_UPDATE_FAILURE
  | ToastContent.GREETING_CREATE_SUCCESS
  | ToastContent.GREETING_CREATE_FAILURE
  | ToastContent.GREETING_ACTIVATION_SUCCESS
  | ToastContent.GREETING_ACTIVATION_FAILURE
  | ToastContent.GREETING_DUPLICATE_SUCCESS
  | ToastContent.GREETING_DUPLICATE_FAILURE
  | ToastContent.INVITATIONS_SENT
  | ToastContent.START_CHAT_ERROR
  | ToastContent.CANNED_SAVE_SUCCESS
  | ToastContent.CHAT_SENTIMENT_ENABLED
  | ToastContent.TARGET_CHANGED_SUCCESS
  | ToastContent.GOAL_UPDATE_SUCCESS
  | ToastContent.CHAT_BUTTON_IMAGE_UPLOAD_ERROR
  | ToastContent.KNOWLEDGE_HUB_MAX_LIMIT_REACHED
>;

type ToastVariable = {
  [ToastContent.CHANGE_ROLE_SUCCESS]: { agentName: string };
  [ToastContent.CHANGE_CHATS_LIMIT_SUCCESS]: { agentName: string };
  [ToastContent.MISSING_GROUP_ACCESS]: { groupName: string };
  [ToastContent.CREATE_TICKET_ERROR]: { error?: string };
  [ToastContent.TICKET_BATCH_UPDATE_SUCCESS]: { payload: ITicketsViewBatchUpdatePayload };
  [ToastContent.TICKET_REQUESTER_UPDATE_SUCCESS]: { payload: IUpdateTicketRequesterSuccessPayload };
  [ToastContent.TICKET_ASSIGNEE_UPDATE_SUCCESS]: { payload: IUpdateTicketAssigneeSuccessPayload };
  [ToastContent.TICKET_SEND_MESSAGE_SUCCESS]: { payload: ITicketsViewSendMessagePayload };
  [ToastContent.CHAT_ALREADY_PICKED]: { agentName?: string };
  [ToastContent.AGENT_APPROVE_SUCCESS]: { numberOfInvitations: number };
  [ToastContent.GREETING_DELETE_SUCCESS]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_DELETE_ERROR]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_UPDATE_SUCCESS]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_UPDATE_FAILURE]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_CREATE_SUCCESS]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_CREATE_FAILURE]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_ACTIVATION_SUCCESS]: { targetedMessageSubtype: string; isActive: boolean };
  [ToastContent.GREETING_ACTIVATION_FAILURE]: { targetedMessageSubtype: string; isActive: boolean };
  [ToastContent.GREETING_DUPLICATE_SUCCESS]: { targetedMessageSubtype: string };
  [ToastContent.GREETING_DUPLICATE_FAILURE]: { targetedMessageSubtype: string };
  [ToastContent.INVITATIONS_SENT]: { numberOfInvitations: number };
  [ToastContent.START_CHAT_ERROR]: { message: string };
  [ToastContent.CANNED_SAVE_SUCCESS]: { groupName?: string | null };
  [ToastContent.CHAT_SENTIMENT_ENABLED]: { disabled: boolean };
  [ToastContent.TARGET_CHANGED_SUCCESS]: { targetName: string };
  [ToastContent.GOAL_UPDATE_SUCCESS]: { goalName: string };
  [ToastContent.CHAT_BUTTON_IMAGE_UPLOAD_ERROR]: { error?: FileUploadError };
  [ToastContent.KNOWLEDGE_HUB_MAX_LIMIT_REACHED]: { type: string };
};

type ToastMap = {
  [T in ToastContent]?: () => string;
};

type ToastMapWithVariables = {
  [T in ToastWithCustomVariables]?: (param: ToastVariable[T]) => string;
};

const simpleToastMap: ToastMap = {
  [ToastContent.ASSIGN_CHAT_ERROR]: () => 'There was a problem with assigning chat.',
  [ToastContent.BAN_VISITOR_SUCCESS]: () => 'Customer has been banned.',
  [ToastContent.CHANGE_OWNER_SUCCESS]: () => 'Owner has been changed.',
  [ToastContent.COPY_CODE_SUCCESS]: () => 'Link copied. Now you can share it with your developer.',
  [ToastContent.CREATE_TICKET_SUCCESS]: () => 'Ticket has been created.',
  [ToastContent.CUSTOMER_UPDATE_SUCCESS]: () => 'Customer was successfully updated',
  [ToastContent.CUSTOMER_UPDATE_ERROR]: () => 'There was a problem with updating customer',
  [ToastContent.NO_CHAT_FOUND_ERROR]: () => 'No chat was found with the given id.',
  [ToastContent.DOWNLOAD_TRANSCRIPT_ERROR]: () => 'Chat transcript cannot be downloaded.',
  [ToastContent.SEND_TRANSCRIPT_ERROR]: () => 'Chat transcript has not been sent.',
  [ToastContent.SEND_TRANSCRIPT_SUCCESS]: () => 'Chat transcript sent to email.',
  [ToastContent.SUBSCRIPTION_CREATED]: () => 'Your subscription has been created',
  [ToastContent.SUBSCRIPTION_UPDATED]: () => 'Your subscription has been updated',
  [ToastContent.UNBAN_VISITOR_SUCCESS]: () => 'Customer unbanned.',
  [ToastContent.TICKET_BATCH_UPDATE_ERROR]: () => 'Something went wrong. Tickets have not been updated.',
  [ToastContent.TICKET_SUBJECT_UPDATE_SUCCESS]: () => 'Ticket subject has been updated.',
  [ToastContent.TICKET_REQUESTER_UPDATE_ERROR]: () => 'Ticket requester has not been updated.',
  [ToastContent.TICKET_CCS_UPDATE_SUCCESS]: () => 'Ticket CCs have been updated.',
  [ToastContent.TICKET_CCS_UPDATE_ERROR]: () => 'Ticket CCs have not been updated.',
  [ToastContent.TICKET_ASSIGNEE_UPDATE_ERROR]: () => 'Assignee has not been updated.',
  [ToastContent.TICKET_GROUPS_UPDATE_SUCCESS]: () => 'Groups have been updated.',
  [ToastContent.TICKET_GROUPS_UPDATE_ERROR]: () => 'Groups have not been updated.',
  [ToastContent.CANNED_SAVE_ERROR]: () => 'Canned response has not been saved.',
  [ToastContent.CANNED_UPDATE_SUCCESS]: () => 'Canned response has been updated.',
  [ToastContent.CANNED_UPDATE_ERROR]: () => 'Canned response has not been updated.',
  [ToastContent.CANNED_REMOVE_SUCCESS]: () => 'Canned response has been deleted.',
  [ToastContent.CANNED_REMOVE_ERROR]: () => 'Canned response has not been deleted.',
  [ToastContent.CANT_START_CHAT_INACCESSIBLE]: () =>
    'Cannot open this chat - it’s already assigned to a group you’re not a member of.',
  [ToastContent.CANT_START_CHAT_THREAD_REMOVED]: () => 'Cannot open this chat now - please try again in a moment.',
  [ToastContent.CANT_ASSIGN_CHAT_THREAD]: () => 'Cannot assign this chat now - please try again in a moment.',
  [ToastContent.START_SUPERVISE_FAILURE]: () => 'Cannot open this chat.',
  [ToastContent.DEFAULT_ERROR]: () => 'Something went wrong. Please try again in few minutes.',
  [ToastContent.TRANSFER_SUCCESS]: () => 'Chat transferred.',
  [ToastContent.TRANSFER_ERROR]: () => 'Cannot transfer the chat.',
  [ToastContent.CHAT_MENU_INTEGRATION_ERROR]: () => 'Something went wrong.',
  [ToastContent.CHAT_MENU_INTEGRATION_SUCCESS]: () => 'Done',
  [ToastContent.FETCH_SINGLE_ARCHIVE_ERROR]: () => 'Chat has not been found.',
  [ToastContent.REPORT_CREATE_ERROR]: () => 'There was a problem with creating this report.',
  [ToastContent.REPORT_CREATE_SUCCESS]: () => 'Your report is all scheduled up!',
  [ToastContent.REPORT_DELETE_ERROR]: () => 'There was a problem with deleting this report.',
  [ToastContent.REPORT_DELETE_SUCCESS]: () => 'Report deleted.',
  [ToastContent.REPORT_EXISTS_ERROR]: () => 'Report for this data already exists.',
  [ToastContent.REPORT_SAVE_ERROR]: () => 'There was a problem with editing this report.',
  [ToastContent.REPORT_SAVE_SUCCESS]: () => 'Report saved.',
  [ToastContent.TAG_ADD_ERROR]: () => 'Tag has not been added.',
  [ToastContent.TAG_UPDATE_ERROR]: () => 'Tag has not been updated.',
  [ToastContent.TAG_UPDATE_LIMIT_ERROR]: () =>
    'Tag has not been updated. You reached the trial tagging limit. Upgrade to paid to tag without limits.',
  [ToastContent.TAG_ADD_SUCCESS]: () => 'Tag added.',
  [ToastContent.TAG_DELETE_SUCCESS]: () => 'Tag has been deleted.',
  [ToastContent.TAG_EXISTS]: () => 'Tag already exists. Use another name.',
  [ToastContent.TAGS_LIMIT_REACHED]: () => 'You reached the tags limit. Remove tags to add a new one.',
  [ToastContent.TAG_EXIST_SETTINGS]: () => 'Tag already exists.',
  [ToastContent.TICKET_COPY_URL_SUCCESS]: () => 'Ticket URL has been copied.',
  [ToastContent.TICKET_SEND_MESSAGE_ERROR]: () => 'Message has not been sent.',
  [ToastContent.TICKET_UNSPAM_ERROR]: () => 'Ticket status has not been changed.',
  [ToastContent.TICKET_UNSPAM_SUCCESS]: () => 'Ticket is no longer marked as spam.',
  [ToastContent.TODO_CHECKLIST_FETCH_ERROR]: () => 'Could not create the home screen checklist.',
  [ToastContent.TODO_CHECKLIST_SAVE_ERROR]: () => 'Could not save the home screen checklist progress.',
  [ToastContent.GA_INSTALL_ERROR]: () => 'Could not enable Google Analytics extension. Please inform us.',
  [ToastContent.GA_UNINSTALL_ERROR]: () => 'Could not disable Google Analytics extension. Please inform us.',
  [ToastContent.ADD_APPLICATION_TO_THE_CART_ERROR]: () => 'Could not add application to the cart.',
  [ToastContent.REMOVE_APPLICATION_FROM_THE_CART_ERROR]: () => 'Could not remove application from the cart.',
  [ToastContent.APPLICATION_INSTALL_ERROR]: () => 'Could not enable integration. Please inform us.',
  [ToastContent.APPLICATION_INSTALL_SUCCESS]: () => 'The application has been installed successfully.',
  [ToastContent.APPLICATION_UNINSTALL_SUCCESS]: () => 'The application has been successfully deleted.',
  [ToastContent.APPLICATION_UNINSTALL_ERROR]: () => 'Could not disable integration. Please inform us.',
  [ToastContent.REPORT_FETCH_ERROR]: () => 'Could not load the report.',
  [ToastContent.TOAST_ERROR_MESSAGE]: () => "Can't upload files larger than 10MB.",
  [ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_SUCCESS]: () => 'Message sent',
  [ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_ERROR]: () => 'Message could not be sent',
  [ToastContent.RESEND_INVITATION_SUCCESS]: () => 'Invitation has been sent.',
  [ToastContent.AGENT_SUSPEND_SUCCESS]: () => 'Agent has been suspended.',
  [ToastContent.AGENT_SUSPEND_ERROR]: () => 'Could not suspend agent.',
  [ToastContent.AGENTS_SUSPEND_SUCCESS]: () => 'Agents has been suspended.',
  [ToastContent.AGENTS_SUSPEND_ERROR]: () => 'Could not suspend agents.',
  [ToastContent.AGENT_ACTIVATE_SUCCESS]: () => 'Agent has been activated.',
  [ToastContent.AGENT_DELETE_SUCCESS]: () => 'Agent has been removed.',
  [ToastContent.AGENT_DELETE_ERROR]: () => 'Could not remove agent.',
  [ToastContent.GROUP_DELETE_SUCCESS]: () => 'Group has been removed.',
  [ToastContent.GROUP_DELETE_ERROR]: () => 'Could not remove group.',
  [ToastContent.GROUP_CREATE_ERROR]: () => 'We could not create the group. Try again.',
  [ToastContent.GROUP_UPDATE_SUCCESS]: () => 'Group updated successfully',
  [ToastContent.GROUP_UPDATE_ERROR]: () => 'We could not update the group. Try again.',
  [ToastContent.AGENT_APPROVE_ERROR]: () => 'Could not approve invitation.',
  [ToastContent.AGENT_UPDATE_SUCCESS]: () => 'Agent has been updated.',
  [ToastContent.CHATBOT_UPDATE_SUCCESS]: () => 'Chatbot has been updated.',
  [ToastContent.CHATBOT_UPDATE_FAILURE]: () => 'Chatbot has not been updated.',
  [ToastContent.AGENT_UPDATE_ERROR]: () => 'Agent could not be updated.',
  [ToastContent.REACTIVATION_EMAIL_SENT]: () => 'Reactivation email has been sent.',
  [ToastContent.REQUEST_ACTIVATE_SUCCESS]: () => 'Activation request has been sent.',
  [ToastContent.REQUEST_ACTIVATE_FAILURE]: () => 'Activation request could not be sent.',
  [ToastContent.INCOMPLETE_THREAD_FETCH_ERROR]: () => "Chat hasn't been loaded properly.",
  [ToastContent.EMAIL_MAPPING_NAME_SAVE_SUCCESS]: () => 'Team name saved',
  [ToastContent.EMAIL_MAPPING_NAME_CLEAR_SUCCESS]: () => 'Team name cleared',
  [ToastContent.EMAIL_MAPPING_FETCH_ERROR]: () =>
    'Could not load email mapping settings. Please refresh the page and try again.',
  [ToastContent.EMAIL_MAPPING_SAVE_ERROR]: () => 'Could not save your team name. Please try again.',
  [ToastContent.CHATBOT_ADD_FAILURE]: () => 'ChatBot could not be created.',
  [ToastContent.CHATBOT_DELETE_SUCCESS]: () => 'ChatBot has been deleted.',
  [ToastContent.EMAIL_SENT_SUCCESS]: () => 'Email has been send.',
  [ToastContent.EMAIL_SENT_FAILURE]: () => 'Email could not be sent. Please try again.',
  [ToastContent.CUSTOM_SEGMENT_LABEL_UPDATE_SUCCESS]: () => 'Segment label updated.',
  [ToastContent.CUSTOM_SEGMENT_LABEL_UPDATE_FAILURE]: () => 'Segment label could not be updated. Please try again.',
  [ToastContent.CUSTOM_SEGMENT_SAVE_SUCCESS]: () => 'Segment saved.',
  [ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE]: () => 'Segment could not be saved. Please try again.',
  [ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS]: () =>
    'The segment could not be saved. Reduce the number of filters and try again.',
  [ToastContent.CUSTOM_SEGMENTS_ORDER_UPDATE_SUCCESS]: () => 'Segments order updated.',
  [ToastContent.CUSTOM_SEGMENTS_ORDER_UPDATE_FAILURE]: () => 'Segments order could not be updated. Please try again.',
  [ToastContent.CUSTOM_SEGMENT_DELETE_SUCCESS]: () => 'Segment deleted.',
  [ToastContent.CUSTOM_SEGMENT_DELETE_FAILURE]: () => 'Segment could not be deleted. Please try again.',
  [ToastContent.CUSTOMER_SEGMENT_SAVE_SUCCESS]: () => 'Segment saved.',
  [ToastContent.CUSTOMER_SEGMENT_SAVE_FAILURE]: () => 'Segment could not be saved. Please try again.',
  [ToastContent.CUSTOMER_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS]: () =>
    'Number of filters exceeded in this segment. Please remove the longest filters and try again.',
  [ToastContent.CUSTOMER_SEGMENT_DELETE_SUCCESS]: () => 'Segment deleted.',
  [ToastContent.CUSTOMER_SEGMENT_DELETE_FAILURE]: () => 'Segment could not be deleted. Please try again.',
  [ToastContent.CUSTOMER_SEGMENT_LABEL_UPDATE_SUCCESS]: () => 'Segment label updated.',
  [ToastContent.CUSTOMER_SEGMENT_LABEL_UPDATE_FAILURE]: () => 'Segment label could not be updated. Please try again.',
  [ToastContent.CUSTOMER_SEGMENTS_ORDER_CHANGE_FAILURE]: () => 'Segments order could not be updated. Please try again.',
  [ToastContent.SUBSCRIPTION_FETCH_ERROR]: () =>
    'Your subscription could not be loaded. Please try again or let us know.',
  [ToastContent.GROUPS_PER_AGENT_LIMIT_EXCEEDED]: () =>
    'Agents can’t be assigned to more than one group on starter plan. Edit agents to downgrade',
  [ToastContent.IN_APP_UPGRADE_PURCHASE_TRANSACTION_SUCCESS]: () => 'You have successfully purchased the upgrade.',
  [ToastContent.IN_APP_UPGRADE_PURCHASE_TRANSACTION_FAILURE]: () =>
    'Something went wrong. Your transaction couldn’t be completed.',
  [ToastContent.IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_SUCCESS]: () =>
    'You have successfully canceled the upgrade subscription.',
  [ToastContent.IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_FAILURE]: () => 'Cancelling the upgrade failed.',
  [ToastContent.IN_APP_UPGRADE_REGISTRATION_FAILURE]: () => 'Unable to register transaction.',
  [ToastContent.SOMETHING_WENT_WRONG]: () => 'Something went wrong. Please refresh the page.',
  [ToastContent.SETTINGS_SAVE_ERROR]: () => 'There was an error while saving the settings.',
  [ToastContent.AGENT_STATUS_CHANGE_FAILED]: () => 'Agent status change failed',
  [ToastContent.INACTIVITY_TIMEOUT_CHANGE_SUCCESS]: () => 'Timeout changed successfully',
  [ToastContent.INACTIVITY_TIMEOUT_CHANGE_FAILURE]: () => 'Timeout change error. Please try again later',
  [ToastContent.AGENT_CUSTOM_PROPERTY_UPDATE_FAILURE]: () => 'Agent custom property update failed',
  [ToastContent.AGENT_CUSTOM_PROPERTY_UPDATE_SUCCESS]: () => 'Agent custom property updated',
  [ToastContent.CUSTOMER_DETAILS_WIDGETS_CONFIGURATOR_UPDATE_FAILURE]: () =>
    'Something went wrong. Please try again in few minutes.',
  [ToastContent.BATCH_AGENTS_UPDATE_FAILURE]: () => 'Agents have not be updated',
  [ToastContent.BATCH_AGENTS_UPDATE_SUCCESS]: () => 'Agents have been updated',
  [ToastContent.AGENT_INVITE_FAILURE]: () => 'Inviting agents failed. Please try again.',
  [ToastContent.BATCH_BOTS_UPDATE_FAILURE]: () => 'Bots have not be updated',
  [ToastContent.BATCH_BOTS_UPDATE_SUCCESS]: () => 'Bots have been updated',
  [ToastContent.CHAT_SUMMARY_CREATED]: () => 'Chat summary created.',
  [ToastContent.CHAT_COULDNT_BE_SUMMARIZED]: () => ChatSummaryErrorMessages.ServerResponse,
  [ToastContent.CHAT_SUMMARY_COULDNT_BE_SAVED]: () => ChatSummaryErrorMessages.AddFail,
  [ToastContent.CHAT_SUMMARY_REMOVED]: () => ChatSummaryErrorMessages.Removed,
  [ToastContent.CHAT_SUMMARY_COULDNT_BE_REMOVED]: () => ChatSummaryErrorMessages.RemoveFail,
  [ToastContent.CHAT_SUMMARY_COULDNT_BE_RETRIEVED]: () => ChatSummaryErrorMessages.RetrievalFail,
  [ToastContent.CHAT_SUMMARY_COPIED]: () => 'Chat summary copied.',
  [ToastContent.REPORT_VIEWS_SAVE_SUCCESS]: () => 'Report view saved.',
  [ToastContent.REPORT_VIEWS_SAVE_FAILURE]: () => 'Report view could not be saved. Please try again later.',
  [ToastContent.REPORT_VIEWS_SAVE_FAILURE_TOO_COMPLEX_FILTERS]: () =>
    'Report view could not be saved. Reduce the number of filters and try again.',
  [ToastContent.REPORT_VIEWS_DELETE_SUCCESS]: () => 'Report view deleted.',
  [ToastContent.REPORT_VIEWS_DELETE_FAILURE]: () => 'Could not delete report view. Please try again.',
  [ToastContent.AUTOTAGS_CREATE_FAILURE]: () => 'Failed to add autotags for given canned response.',
  [ToastContent.AUTOTAGS_UPDATE_FAILURE]: () => 'Failed to update autotags for given canned response.',
  [ToastContent.KNOWLEDGE_SOURCE_DELETE_SUCCESS]: () => 'Knowledge source was deleted',
  [ToastContent.KNOWLEDGE_SOURCE_DELETE_FAILURE]: () => 'Could not delete knowledge source',
  [ToastContent.KNOWLEDGE_SOURCE_ADD_FAILURE]: () => 'Could not upload knowledge source',
  [ToastContent.KNOWLEDGE_SOURCE_REMOVE_FAILURE]: () => 'Could not remove status notification',
  [ToastContent.KNOWLEDGE_SOURCE_CANCEL_FAILURE]: () => 'Could not cancel uploading knowledge source',
  [ToastContent.KNOWLEDGE_SOURCE_RENAME_FAILURE]: () => 'Failed to rename source',
  [ToastContent.KNOWLEDGE_SOURCE_RENAME_SUCCESS]: () => 'Source renamed successfully',
  [ToastContent.KNOWLEDGE_SOURCE_UPDATE_FAILURE]: () => 'Could not update knowledge source',
  [ToastContent.KNOWLEDGE_SOURCE_ALREADY_LEARNED]: () => 'Knowledge source was already uploaded',
  [ToastContent.RICH_MESSAGE_SEND_CARDS_ERROR]: () => 'Error sending message',
  [ToastContent.COLLECT_INVOICE_SUCCESS]: () => 'Invoice paid succesfully',
  [ToastContent.KNOWLEDGE_SOURCES_UPDATE_FAILURE]: () => 'Could not update knowledge sources',
  [ToastContent.MESSAGE_COPIED_SUCCESS]: () => 'Message copied successfully',
  [ToastContent.MESSAGE_COPIED_ERROR]: () => 'Could not copy message',
  [ToastContent.GOAL_DELETE_ERROR]: () => 'Goal could not be deleted. Please try again later.',
  [ToastContent.GOAL_DELETE_SUCCESS]: () => 'Goal has been deleted.',
  [ToastContent.GOAL_UPDATE_ERROR]: () => 'Goal could not be updated. Please try again later.',
  [ToastContent.REVIEW_COPIED_SUCCESS]: () => 'Review copied. Share it on your social media!',
  [ToastContent.REVIEW_CREATE_ERROR]: () => "Couldn't create review page.",
  [ToastContent.GOAL_CREATE_SUCCESS]: () => 'The Goal is up and running!',
  [ToastContent.GOAL_CREATE_ERROR]: () => 'Goal could not be created. Please try again later.',
  [ToastContent.SEND_CODE_INSTRUCTIONS_ERROR]: () => `Could not send instructions. Please try again later.`,
  [ToastContent.SEND_AI_ASSISTANT_MESSAGE_FEEDBACK_ERROR]: () => 'Rating message failed. Please try again later.',
  [ToastContent.LEGACY_APPLICATION_UPDATE_SUCCESS]: () => 'Application updated.',
  [ToastContent.LEGACY_APPLICATION_UPDATE_FAILURE]: () => 'Application update failed.',
  [ToastContent.CHANGES_SAVED]: () => 'Changes saved',
  [ToastContent.SAVING_FAILED]: () => 'Saving failed',
  [ToastContent.TRUSTED_DOMAIN_ADD_ERROR]: () => 'Couldn’t add domain',
  [ToastContent.TRUSTED_DOMAIN_REMOVE_SUCCESS]: () => 'Domain removed',
  [ToastContent.TRUSTED_DOMAIN_REMOVE_ERROR]: () => 'Couldn’t remove domain',
  [ToastContent.TRUSTED_DOMAIN_ADD_SUCCESS]: () => 'Domain added',
  [ToastContent.CHAT_BUTTON_DELETE_SUCCESS]: () => 'Chat button deleted',
  [ToastContent.CHAT_BUTTON_DELETE_ERROR]: () => 'Couldn`t delete button',
  [ToastContent.CHAT_BUTTON_SAVE_SUCCESS]: () => 'Chat button saved',
  [ToastContent.CHAT_BUTTON_CREATE_SUCCESS]: () => 'Chat button created',
  [ToastContent.CHAT_BUTTON_CODE_COPIED]: () => 'Code copied',
  [ToastContent.SALES_TRACKER_CREATE_SUCCESS]: () => 'Tracker saved',
  [ToastContent.SALES_TRACKER_DELETE_SUCCESS]: () => 'Tracker deleted',
  [ToastContent.SALES_TRACKER_CREATE_ERROR]: () => 'Couldn’t generate tracker',
  [ToastContent.SALES_TRACKER_DELETE_ERROR]: () => 'Couldn’t delete tracker.',
  [ToastContent.SALES_TRACKER_UPDATE_SUCCESS]: () => 'Tracker has been updated',
  [ToastContent.SALES_TRACKER_UPDATE_ERROR]: () => 'Couldn’t update tracker',
  [ToastContent.SALES_TRACKER_CODE_COPIED]: () => 'Code copied',
  [ToastContent.ADD_WEBHOOK_SUCCESS]: () => 'Webhook added',
  [ToastContent.ADD_WEBHOOK_FAILURE]: () => 'Adding webhook failed',
  [ToastContent.ADD_WEBHOOK_FAILURE_DUPLICATE]: () => 'Webhook for this URL already exists',
  [ToastContent.DELETE_WEBHOOK_SUCCESS]: () => 'Webhook deleted',
  [ToastContent.DELETE_WEBHOOK_FAILURE]: () => 'Webhook deletion failed',
  [ToastContent.EYE_CATCHER_UPDATE_SUCCESS]: () => 'Changes saved',
  [ToastContent.EYE_CATCHER_UPDATE_FAILURE]: () => `Couldn't save changes`,
  [ToastContent.ERROR_NO_TAGS_FOUND]: () => 'No tags found. Please try again later.',
  [ToastContent.ERROR_TAG_ALREADY_EXIST]: () => 'Tag already exists. Use another name.',
  [ToastContent.ERROR_TAGS_LIMIT_REACHED]: () => 'Tags limit reached.',
  [ToastContent.ERROR_TAG_NO_GROUP]: () => 'This tag does not belong to the current group.',
  [ToastContent.KNOWLEDGE_BASE_ACCOUNT_CREATION_FAILURE]: () => 'Failed to create KnowledgeBase account',
  [ToastContent.YOU_HAVE_BEEN_RE_LOGGED]: () => "You've been re-logged due to a recent change in Invitation settings",
  [ToastContent.ADD_INACTIVITY_ERROR]: () => 'Could not save inactivity reason',
  [ToastContent.INVOICE_DETAILS_UPDATE_FAILURE]: () => 'Could not update invoice details',
  [ToastContent.INVOICE_DETAILS_UPDATE_SUCCESS]: () => 'Invoice details updated',
  [ToastContent.COMPANY_DETAILS_UPDATE_FAILURE]: () => 'Could not update company details',
  [ToastContent.COMPANY_DETAILS_UPDATE_SUCCESS]: () => 'Company details updated',
  [ToastContent.CODE_INSTALLATION_SUCCESS]: () => 'All set! LiveChat is now live on your website.',
  [ToastContent.CODE_INSTALLATION_FAILURE]: () =>
    "Couldn't find LiveChat on your website. Set it up to engage with your visitors.",
  [ToastContent.ONE_CLICK_PAYMENT_SUCCESS]: () => 'Your purchase has been successful.',
  [ToastContent.ONE_CLICK_PAYMENT_ACTIVATED]: () => 'The configuration process has been completed.',
  [ToastContent.KEEP_TRIAL_PLAN_SUCCESS]: () => 'All set! Your subscription will be updated when the trial ends.',
  [ToastContent.NO_AI_AGENTS]: () => "Couldn't generate a reply. Try again in a bit",
  [ToastContent.ADDING_URL_SOURCE_SUCCESS]: () => 'Adding source. This usually takes around 60 minutes',
  [ToastContent.ADDING_URL_SOURCE_FAILURE]: () => 'There was an isssue and we couldn’t add the source',
  [ToastContent.ADDING_FILE_SOURCE_SUCCESS]: () => 'Adding source. This usually takes around 60 minutes',
  [ToastContent.ADDING_FILE_SOURCE_FAILURE]: () => 'There was an isssue and we couldn’t add the file source',
  [ToastContent.UPLOADED_FILE_TOO_LARGE]: () => 'File too large. Maximum file size is 46 MB.',
  [ToastContent.KNOWLEDGE_SOURCE_PAGES_DELETE_SUCCESS]: () => 'Pages were deleted', // TODO: name may change
  [ToastContent.KNOWLEDGE_SOURCE_PAGES_DELETE_FAILURE]: () => 'Could not delete pages', // TODO: name may change
  [ToastContent.UPDATING_URL_SOURCE_TYPE_PUBLIC_SUCCESS]: () => 'Your knowledge source is now public',
  [ToastContent.UPDATING_URL_SOURCE_TYPE_INTERNAL_SUCCESS]: () => 'Your knowledge source is now internal',
  [ToastContent.UPDATING_URL_SOURCE_TYPE_FAILURE]: () => 'Changing source type failed',
  [ToastContent.KNOWLEDGE_SOURCE_AUTO_SYNC_UPDATE_SUCCESS]: () => 'Auto-sync saved',
  [ToastContent.KNOWLEDGE_SOURCE_AUTO_SYNC_UPDATE_FAILURE]: () => 'Couldn’t save auto-sync',
  [ToastContent.KNOWLEDGE_HUB_SOURCE_ALREADY_ADDED]: () => 'This website is already added as a knowledge source.',
};

const toastMapWithVariables: ToastMapWithVariables = {
  [ToastContent.CHANGE_ROLE_SUCCESS]: ({ agentName }) => `You changed the role for ${agentName}.`,
  [ToastContent.CHANGE_CHATS_LIMIT_SUCCESS]: ({ agentName }) => `You changed chats limit for ${agentName}.`,
  [ToastContent.CREATE_TICKET_ERROR]: ({ error }) => `Ticket has not been created.${error ? ` ${error}` : ''}`,
  [ToastContent.MISSING_GROUP_ACCESS]: ({ groupName }) => `Missing access to group ${groupName}.`,
  [ToastContent.TICKET_BATCH_UPDATE_SUCCESS]: ({ payload }) =>
    getTicketsToastContent(payload.ticketIds, payload.batchAction, payload.data),
  [ToastContent.TICKET_REQUESTER_UPDATE_SUCCESS]: ({ payload }) =>
    `Ticket requester changed to ${payload.requester.email}`,
  [ToastContent.TICKET_ASSIGNEE_UPDATE_SUCCESS]: ({ payload }) =>
    payload.assignee ? `Assignee changed to ${payload.assignee.name}.` : 'Assignee has been removed.',
  [ToastContent.TICKET_SEND_MESSAGE_SUCCESS]: ({ payload }) => {
    const { attachments, message, status } = payload;
    const onlyStatusChanged = attachments.length === 0 && message.length === 0;

    return onlyStatusChanged && status
      ? `Ticket status has been changed to ${getTicketStatusLabel(status)}.`
      : 'Message has been sent.';
  },
  [ToastContent.CHAT_ALREADY_PICKED]: ({ agentName }) => {
    return getAssignedAgentText(agentName);
  },
  [ToastContent.AGENT_APPROVE_SUCCESS]: ({ numberOfInvitations }) =>
    `${plural(numberOfInvitations, 'Invitation', 'Invitations')} has been approved.`,
  [ToastContent.GREETING_DELETE_SUCCESS]: () => `Campaign has been deleted`,
  [ToastContent.GREETING_DELETE_ERROR]: () => `Campaign could not be deleted. Please try again.`,
  [ToastContent.GREETING_UPDATE_SUCCESS]: () => `Campaign has been updated`,
  [ToastContent.GREETING_UPDATE_FAILURE]: () => `Campaign could not be updated. Please try again.`,
  [ToastContent.GREETING_CREATE_SUCCESS]: () => `Campaign has been created`,
  [ToastContent.GREETING_CREATE_FAILURE]: () => `Campaign could not be created. Please try again.`,
  [ToastContent.GREETING_ACTIVATION_SUCCESS]: ({ isActive }) => `Campaign ${isActive ? 'set live' : 'paused'}`,
  [ToastContent.GREETING_ACTIVATION_FAILURE]: ({ isActive }) =>
    `Campaign could not be ${isActive ? 'set live' : 'paused'}. Please try again.`,
  [ToastContent.GREETING_DUPLICATE_SUCCESS]: () => `Campaign has been duplicated`,
  [ToastContent.GREETING_DUPLICATE_FAILURE]: () => `Campaign could not be duplicated. Please try again.`,
  [ToastContent.GOAL_UPDATE_SUCCESS]: ({ goalName }) => `${goalName} goal has been updated.`,
  [ToastContent.INVITATIONS_SENT]: ({ numberOfInvitations }) =>
    `${numberOfInvitations} ${plural(numberOfInvitations, 'agent has', 'agents have')} been invited`,
  [ToastContent.START_CHAT_ERROR]: ({ message }) => `There was a problem with starting the chat. ${message}`,
  [ToastContent.CANNED_SAVE_SUCCESS]: ({ groupName }) =>
    groupName ? `Canned response was successfully created  in group ${groupName}` : 'Canned response has been saved.',
  [ToastContent.CHAT_SENTIMENT_ENABLED]: ({ disabled }) => `Chat sentiment turned ${!disabled ? 'off' : 'on'}`,
  [ToastContent.TARGET_CHANGED_SUCCESS]: ({ targetName }) => `${targetName} target changed successfully.`,
  [ToastContent.CHAT_BUTTON_IMAGE_UPLOAD_ERROR]: ({ error }) =>
    `${error ? error.message : 'Image could not be uploaded'}`,
  [ToastContent.KNOWLEDGE_HUB_MAX_LIMIT_REACHED]: ({ type }) =>
    `You reached the limit of ${type} sources for your plan`,
};

export const getToastContent = <T extends ToastContent>(
  contentType: T,
  templateVars?: T extends ToastWithCustomVariables ? ToastVariable[T] : never,
): string => {
  if (isEmpty(templateVars)) {
    const toast = simpleToastMap[contentType];

    return toast ? toast() : '';
  }

  const toast = toastMapWithVariables[contentType as ToastWithCustomVariables] as (
    param: ToastVariable[keyof ToastVariable],
  ) => string;

  return toast ? toast(templateVars) : '';
};

const isToastContent = (content: unknown): content is ToastContent => typeof content === 'number';

export const showToast = (
  kind: ToastVariant,
  message: ToastContent | string,
  options?: { autoHideDelayTime?: ToastAutoHideDelay } & ToastVariable[keyof ToastVariable],
): void => {
  const { autoHideDelayTime, ...messageVars } = { autoHideDelayTime: ToastAutoHideDelay.Short, ...options };
  const content = isToastContent(message) ? getToastContent(message, messageVars) : message;

  return void AppStateProvider.dispatch(ToastsActions.createToast({ autoHideDelayTime, content, kind }));
};
