import type { FC } from 'react';

import { Button } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import * as styles from './styles';

export const ErrorFooter: FC = () => {
  const { closeModal } = useOneClickPaymentContext();

  return (
    <div className={styles.footerWrapper}>
      <Button kind="secondary" onClick={closeModal}>
        Close
      </Button>
      <Button kind="primary" disabled>
        Confirm
      </Button>
    </div>
  );
};
