import { BaseApi } from '../base-api';

export interface IEmailiniumTranscriptBody {
  recipient: string;
  chat_id: string;
  thread_id: string;
  include_system_messages?: boolean;
  include_supervisors_and_private_notes?: boolean;
  include_session_fields?: boolean;
  include_geolocation?: boolean;
  include_referrer?: boolean;
  use_visitor_timezone?: boolean;
}

export interface IEmailiniumContactAccountManagerBody {
  message: string;
}

export interface IEmailiniumResponse {
  code: string;
  message: string;
}

export interface IEmailiniumValidateEmailsBody {
  email: string;
}

export type IEmailiniumValidateEmailsResponse = {
  result: {
    email: string
    verdict: "Valid" | "Risky" | "Invalid"
    score: number
    local: string
    host: string
    suggestion?: string | null
    checks: {
      domain: {
        has_valid_address_syntax: boolean
        has_mx_or_a_record: boolean
        is_suspected_disposable_address: boolean
      },
      local_part: {
        is_suspected_role_address: boolean
      }
    }
  }
}

export class EmailiniumApi extends BaseApi {
  sendTranscript = (body: IEmailiniumTranscriptBody) => this.post('transcript', body);
  contactAccountManager = (body: IEmailiniumContactAccountManagerBody) =>
    this.post<IEmailiniumResponse, IEmailiniumResponse>('subscription/contact_account_manager', body);

  validateEmail = (body: IEmailiniumValidateEmailsBody) => this.post<IEmailiniumValidateEmailsResponse>('email/validation', body);
}
