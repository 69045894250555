import { createContext, useContext } from 'react';

import type { OutgoingOneClickPaymentEvent } from 'constants/post-message-event';
import { type Charge, type PaymentIntent } from 'interfaces/one-click-payment';
import type { OneClickPaymentOutgoingPostMessage } from 'interfaces/post-message';

type Context = {
  isLoading: boolean;
  isUpdating: boolean;
  isProcessable: boolean;
  paymentIntent: PaymentIntent | null;
  charge: Charge | null;
  error: unknown;
  closeModal: () => void;
  onPaymentModalClose: () => void;
  updateBillingCycle: (billingCycle: number) => void;
  sendPostMessage: (
    eventName: OutgoingOneClickPaymentEvent,
    data?: OneClickPaymentOutgoingPostMessage['event_data'],
  ) => void;
};

export const OneClickPaymentContext = createContext<Context>({
  isLoading: false,
  isUpdating: false,
  isProcessable: false,
  paymentIntent: null,
  charge: null,
  error: null,
  closeModal: () => {},
  onPaymentModalClose: () => {},
  updateBillingCycle: () => {},
  sendPostMessage: () => {},
});

export const useOneClickPaymentContext = (): Context => {
  const context = useContext(OneClickPaymentContext);

  if (context === undefined) {
    throw new Error('useOneClickPaymentContext must be used within a OneClickPaymentContextProvider');
  }

  return context;
};
