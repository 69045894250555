import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

export const menuWrapper = css`
  border-radius: 4px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  width: 288px;
  max-height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;

  &&::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const menuWrapperScrollableArea = css`
  overflow-y: auto;
  max-height: 47vh;
`;

export const menuDivider = css`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
`;

export const menuProfile = css`
  display: flex;
  padding: 10px 16px;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    background-color: #e1e9ec;
    text-decoration: none;
  }
`;

export const menuListAccountsPanel = css`
  a {
    padding: var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing2});
  }
`;

export const menuListProduct = css`
  a {
    padding: 9px var(${SpacingToken.Spacing2});
  }
`;

export const menuListHeader = css`
  display: flex;
  height: 40px;
  padding: 9px var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing0});
  align-items: center;

  img {
    margin: 0 var(${SpacingToken.Spacing1});
    height: 18px;
    width: 32px;
  }
`;

export const menuProductMeta = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  margin-left: 12px;
  max-width: 100%;

  h3,
  span {
    margin: 0;
    color: #424d57;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  }

  span {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const menuProductBadge = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
`;

export const menuProductIconWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

export const menuProfileMeta = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  margin-left: 10px;
  max-width: 100%;

  h3,
  span {
    margin: 0;
    color: #424d57;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  }

  span {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const menuList = css`
  margin: 0;
  padding: 6px 0;
`;

export const menuListProducts = css`
  border-radius: 4px;
  background-color: var(${DesignToken.PickerListBackground});
`;

const linkStyles = css`
  position: relative;
  padding: 9px var(${SpacingToken.Spacing2});
  display: flex;
  font-size: 13px;
  color: #424d57;
  cursor: pointer;
  text-decoration: none;
  border-radius: var(${RadiusToken.Radius3});

  &:hover {
    text-decoration: none;
  }
`;

const spanStyles = css`
  &.addition {
    display: flex;
    position: absolute;
    top: 50%;
    right: 16px;
    justify-content: center;
    min-width: 18px;
    transform: translateY(-50%);
  }
`;

export const menuListItem = css`
  list-style: none;
  margin: 0 var(${SpacingToken.Spacing2});

  a {
    ${linkStyles}

    &:hover {
      background-color: #e1e9ec;
    }
  }

  span {
    ${spanStyles}
  }
`;

export const appsWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #424d57;
  font-size: 13px;
  padding: 9px var(${SpacingToken.Spacing2});
`;

export const appsIconsWrapper = css`
  display: flex;

  > div {
    position: relative;

    &:hover .lc-css-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  span {
    padding: 5px;
    display: inline-flex;

    &:hover {
      cursor: pointer;
      border-radius: 3px;
      background-color: #e0e8eb;
    }
  }
`;

export const avatar = css`
  min-width: 40px;
`;

export const profile = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: var(${DesignToken.ContentBasicSecondary});
  background-color: var(${DesignToken.PickerListGroupBackground});
  padding: var(${SpacingToken.Spacing1}) var(${SpacingToken.Spacing4});
`;

export const newBadge = css`
  text-transform: uppercase;
  font-weight: bold;
  color: var(${DesignToken.ActionNegativeDefault});
  margin-right: 6px;
`;

export const menuProfileMetaName = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing1});
`;

export const customStatusButton = (backgroundColor: string) => css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});

  &::before {
    content: ' ';
    display: inline-block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: var(${backgroundColor});
    border: 1px solid var(${DesignToken.SurfaceBasicDefault});
    box-sizing: border-box;
    background-clip: padding-box;
  }
`;
