import type { ComponentProps } from 'react';

import {
  Apps,
  AppsFilled,
  Archives,
  ArchivesFilled,
  Automation,
  AutomationFilled,
  CreditCardOutline,
  CreditCardOutlineFilled,
  Engage,
  EngageFilled,
  HelpDeskMono,
  Home,
  HomeFilled,
  Messages,
  MessagesFilled,
  Notifications,
  NotificationsFilled,
  People,
  PeopleFilled,
  Report,
  ReportFilled,
  Settings,
  SettingsFilled,
  Tickets,
  TicketsFilled,
} from '@livechat/design-system-icons';

import { NavigationId, NavigationLabel, NavigationPath, NavigationTooltipType } from 'constants/navigation';
import { Section } from 'constants/section';
import { getInsightsWebComponentsSrc } from 'helpers/insights';
import { loadScript } from 'helpers/load-script';
import { noop } from 'helpers/noop';
import { plural } from 'helpers/string';
import { AppStateProvider } from 'services/app-state-provider';
import { getIsInsightsInstalled } from 'store/entities/applications/selectors';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';
import { getTrialDaysLeft } from 'store/features/session/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';
import { isNavigationItemTooltipShown } from 'store/views/navigation/selectors';

import { AppsBadge } from './badges/AppsBadge';
import { AutomateBadge } from './badges/AutomateBadge';
import { ChatsBadge } from './badges/chats-badge/ChatsBadge';
import { EngageBadge } from './badges/EngageBadge';
import { ReportsBadge } from './badges/ReportsBadge';
import { SettingsBadge } from './badges/settings-badge/SettingsBadge';
import { SubscriptionBadge } from './badges/SubscriptionBadge';
import { TeamBadge } from './badges/team-badge/TeamBadge';
import { TicketsBadge } from './badges/TicketsBadge';
import { type NavigationBadgeComponent } from './interfaces';
import type {
  IActivityComparatorParams,
  IOnItemClickParams as IDefaultOnItemClickParams,
  onItemClick,
} from './items/navigation-item/helpers';
import type { NavigationIcon } from './navigation-icon/NavigationIcon';

interface ITopNavigationConfigurationParams {
  shouldShowTickets: boolean;
  shouldShowTeam: boolean;
}

interface IOnItemClickParams extends IDefaultOnItemClickParams {
  defaultOnItemClick: typeof onItemClick;
}

type OnItemHoverParams = Omit<IDefaultOnItemClickParams, 'trackingTitle'>;

type NavigationIconConfiguration = Omit<ComponentProps<typeof NavigationIcon>, 'isActive' | 'children'>;

interface INavigationItemConfiguration {
  icon: NavigationIconConfiguration;
  path?: NavigationPath;
  trackingTitle?: string;
  badge?: NavigationBadgeComponent;
  label?: string;
  title?: string;
  testId?: string;
  activityComparator?: (params: IActivityComparatorParams) => boolean;
  onItemClick?: (params: IOnItemClickParams) => void;
  onItemHover?: (params: OnItemHoverParams) => void;
}

const getSettingsPath = (path: NavigationPath): NavigationPath => {
  const isCodeInstalled = AppStateProvider.selectFromStore(getIsCodeInstalled);
  const isTargetedMessagesTooltipVisible = AppStateProvider.selectFromStore((state) =>
    isNavigationItemTooltipShown(state, NavigationId.Settings, NavigationTooltipType.TargetedMessages),
  );

  if (isTargetedMessagesTooltipVisible) {
    return `${path}/targeted-messages` as NavigationPath;
  }
  if (!isCodeInstalled) {
    return `${path}/overview` as NavigationPath;
  }

  return path;
};
const getSubscriptionLabel = (): string => {
  const isTrial = AppStateProvider.selectFromStore(getIsSubscriptionInTrialAndNotSubscribed);
  if (!isTrial) {
    return 'Billing';
  }
  const trialDaysLeft = AppStateProvider.selectFromStore(getTrialDaysLeft);
  if (trialDaysLeft < 31) {
    return `${trialDaysLeft} ${plural(trialDaysLeft, 'day', 'days')}`;
  }

  return 'Subscribe';
};

export const navigationItems = new Map<NavigationId, INavigationItemConfiguration>([
  [
    NavigationId.Home,
    {
      path: NavigationPath.Home,
      icon: { inactiveIcon: Home, activeIcon: HomeFilled },
      trackingTitle: 'Home section opened',
      label: NavigationLabel.Home,
    },
  ],
  [
    NavigationId.Chats,
    {
      path: NavigationPath.Chats,
      icon: { inactiveIcon: Messages, activeIcon: MessagesFilled },
      trackingTitle: 'Chats section opened',
      badge: ChatsBadge,
      label: NavigationLabel.Chats,
    },
  ],
  [
    NavigationId.Engage,
    {
      path: NavigationPath.Engage,
      icon: { inactiveIcon: Engage, activeIcon: EngageFilled },
      trackingTitle: 'Traffic section opened',
      badge: EngageBadge,
      label: NavigationLabel.Engage,
      activityComparator: ({ section, id }) => !!section?.startsWith(id),
      // eslint-disable-next-line import/no-restricted-paths
      onItemHover: () => void import(/* webpackChunkName: "engage" */ 'routes/engage/Engage'),
    },
  ],
  [
    NavigationId.Archives,
    {
      path: NavigationPath.Archives,
      icon: { inactiveIcon: Archives, activeIcon: ArchivesFilled },
      trackingTitle: 'Archives section opened',
      label: NavigationLabel.Archives,
    },
  ],
  [
    NavigationId.Tickets,
    {
      path: NavigationPath.Tickets,
      icon: { inactiveIcon: Tickets, activeIcon: TicketsFilled },
      trackingTitle: 'Tickets section opened',
      badge: TicketsBadge,
      label: NavigationLabel.Tickets,
    },
  ],
  [
    NavigationId.Team,
    {
      path: NavigationPath.Team,
      icon: { inactiveIcon: People, activeIcon: PeopleFilled },
      trackingTitle: 'Team: Section visited',
      badge: TeamBadge,
      label: NavigationLabel.Team,
      testId: 'team-navigation-item',
    },
  ],
  [
    NavigationId.Automate,
    {
      path: NavigationPath.Automate,
      icon: { inactiveIcon: Automation, activeIcon: AutomationFilled },
      trackingTitle: 'Automate section opened',
      label: NavigationLabel.Automate,
      badge: AutomateBadge,
      // eslint-disable-next-line import/no-restricted-paths
      onItemHover: () => void import(/* webpackChunkName: "automate" */ 'routes/automate/Automate'),
    },
  ],
  [
    NavigationId.Reports,
    {
      path: NavigationPath.Reports,
      icon: { inactiveIcon: Report, activeIcon: ReportFilled },
      trackingTitle: 'Reports section opened',
      badge: ReportsBadge,
      label: NavigationLabel.Reports,
      onItemHover: () => {
        const isInsightsInstalled = AppStateProvider.selectFromStore(getIsInsightsInstalled);
        if (isInsightsInstalled) {
          void loadScript(getInsightsWebComponentsSrc());
        }
      },
    },
  ],
  [
    NavigationId.Apps,
    {
      path: NavigationPath.Apps,
      icon: { inactiveIcon: Apps, activeIcon: AppsFilled },
      trackingTitle: 'Apps section opened',
      badge: AppsBadge,
      label: NavigationLabel.Apps,
      activityComparator: ({ section, id }) => id.toString() === section?.toString(),
    },
  ],
  [
    NavigationId.HelpDeskInLiveChat,
    {
      path: NavigationPath.HelpDeskInLiveChat,
      icon: { inactiveIcon: HelpDeskMono },
      trackingTitle: 'helpdesk-in-livechat',
      label: NavigationLabel.HelpDesk,
    },
  ],
  [
    NavigationId.Settings,
    {
      path: NavigationPath.Settings,
      icon: { inactiveIcon: Settings, activeIcon: SettingsFilled },
      trackingTitle: 'Settings section opened',
      badge: SettingsBadge,
      label: NavigationLabel.Settings,
      onItemClick: ({ defaultOnItemClick, path, ...params }) => {
        AppStateProvider.dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: Section.Settings }));
        defaultOnItemClick({ path: getSettingsPath(path), ...params });
      },
      // eslint-disable-next-line import/no-restricted-paths
      onItemHover: () => void import(/* webpackChunkName: "settings" */ 'routes/settings/SettingsRouter'),
    },
  ],
  [
    NavigationId.Subscription,
    {
      path: NavigationPath.Subscription,
      icon: { inactiveIcon: CreditCardOutline, activeIcon: CreditCardOutlineFilled },
      trackingTitle: 'Subscription: Section opened',
      badge: SubscriptionBadge,
      label: getSubscriptionLabel(),
    },
  ],
  [
    NavigationId.News,
    {
      icon: { inactiveIcon: Notifications, activeIcon: NotificationsFilled },
      label: NavigationLabel.News,
      onItemClick: noop,
    },
  ],
]);

export const getTopNavigationIds = ({
  shouldShowTickets,
  shouldShowTeam,
}: ITopNavigationConfigurationParams): NavigationId[] => {
  const ids = new Set([
    NavigationId.Home,
    NavigationId.Chats,
    NavigationId.Engage,
    NavigationId.Automate,
    NavigationId.Archives,
    NavigationId.Tickets,
    NavigationId.Team,
    NavigationId.Reports,
    NavigationId.Apps,
  ]);

  if (!shouldShowTickets) {
    ids.delete(NavigationId.Tickets);
  }

  if (!shouldShowTeam) {
    ids.delete(NavigationId.Team);
  }

  return Array.from(ids);
};
