import type { FC } from 'react';

import { Text, DesignToken } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { BillingCycle, ChargeType } from 'constants/one-click-payment';
import { withVar } from 'helpers/styles';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { DeveloperDetails } from '../PurchaseModal/Content/PurchaseItem/DeveloperDetails';
import { PriceFormatter } from '../PurchaseModal/Content/PurchaseItem/PriceFormatter';

import * as styles from './styles';

export const ItemSummary: FC = () => {
  const { paymentIntent } = useOneClickPaymentContext();
  const agentsCount = useSelector(getActiveAgentsCount);

  if (!paymentIntent) {
    return null;
  }

  const unitPrice = paymentIntent.price;
  const isPerAccount = paymentIntent.per_account;
  const totalPrice = isPerAccount ? unitPrice * agentsCount : unitPrice;
  const months = paymentIntent?.metadata.type === ChargeType.RecurrentCharge ? BillingCycle.Monthly : undefined;

  return (
    <div className={styles.itemSummary}>
      <img className={styles.icon} src={paymentIntent.metadata.icon} alt={`${paymentIntent.name} logo`} />

      <div>
        <Text size="md" bold>
          {paymentIntent.name}
        </Text>

        {paymentIntent.metadata?.ownerId && <DeveloperDetails developerId={paymentIntent.metadata?.ownerId} />}
      </div>

      <div className={styles.price}>
        <Text size="md" bold>
          <PriceFormatter value={totalPrice} months={months} />
        </Text>

        <Text size="xs" customColor={withVar(DesignToken.ContentBasicSecondary)}>
          {isPerAccount ? (
            <>
              <PriceFormatter value={unitPrice} />
              {agentsCount > 1 && ` ✕ ${agentsCount} Agents`}
            </>
          ) : (
            'Per organization'
          )}
        </Text>
      </div>
    </div>
  );
};
