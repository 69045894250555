import type { FC } from 'react';

import { PlayerStop } from '@livechat/design-system-icons';
import { Button, Icon, Tooltip, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';
import { getCopilotLatestAgentEventId } from 'store/entities/copilot/selectors';
import { getIsCopilotModalExpanded, getCopilotIsLoading } from 'store/views/copilot/selectors';

import { COPILOT_STOP_GENERATING_BTN_TEST_ID } from '../constants';
import * as styles from '../message-box/styles';

export const StopGenerateButton: FC = () => {
  const isOneLoading = useSelector(getCopilotIsLoading);
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const dispatch = useDispatch();
  const oneLatestAgentEventId = useSelector(getCopilotLatestAgentEventId);

  if (!isOneLoading) {
    return null;
  }

  const handleStopClick = (): void => {
    dispatch(CopilotEntitiesActions.cancelCopilotRequest());

    if (oneLatestAgentEventId) {
      dispatch(
        CopilotEntitiesActions.updateCopilotEvent({
          eventId: oneLatestAgentEventId,
          properties: {
            hasCancelledRequest: true,
          },
        }),
      );
    }

    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'stop generating' });
  };

  return isExpanded ? (
    <Button
      kind="plain"
      iconPosition="right"
      icon={<Icon source={PlayerStop} />}
      className={styles.cancelGenerateButton}
      onClick={handleStopClick}
      data-testid={COPILOT_STOP_GENERATING_BTN_TEST_ID}
    >
      Stop generating
    </Button>
  ) : (
    <Tooltip
      className={styles.stopGeneratingTooltip}
      theme="invert"
      placement="left"
      hoverOnDelay={300}
      triggerRenderer={() => (
        <Button
          kind="high-contrast"
          className={styles.cancelGenerateButtonPopover}
          onClick={handleStopClick}
          data-testid={COPILOT_STOP_GENERATING_BTN_TEST_ID}
        >
          <Icon source={PlayerStop} />
        </Button>
      )}
    >
      <Text>Stop generating</Text>
    </Tooltip>
  );
};
