import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';

import { BillingCyclePicker } from './BillingCyclePicker/BillingCyclePicker';
import { PaymentDescription } from './PaymentDescription';
import { PriceSummary } from './PriceSummary';

import * as styles from './styles';

type Props = {
  hidePicker?: boolean;
};

export const PurchaseItem: FC<Props> = ({ hidePicker }) => {
  const { paymentIntent, charge } = useOneClickPaymentContext();

  if (!paymentIntent) {
    return null;
  }

  const name = charge?.name || paymentIntent.name;
  const { ownerId, description } = paymentIntent.metadata;

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={paymentIntent?.metadata.icon} alt={`${name} logo`} />

      <div className={styles.productDetails}>
        <Text size="md" bold>
          {name}
        </Text>

        <PaymentDescription description={description} developerId={ownerId} />
      </div>

      <div className={styles.price}>
        <PriceSummary />
      </div>

      {!hidePicker && (
        <div className={styles.picker}>
          <BillingCyclePicker />
        </div>
      )}
    </div>
  );
};
