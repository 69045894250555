import { css } from '@emotion/css';
import { DesignToken, RadiusToken } from '@livechat/design-system-react-components';

export const wrapper = css`
  width: 100%;
  padding: 0;
`;

export const cardField = css`
  border: 1px solid var(${DesignToken.BorderBasicPrimary});
  border-radius: var(${RadiusToken[3]});
  height: 34px;
  overflow: hidden;
  transition: border 0.25s ease-in-out;

  .recurly-element-card {
    height: 34px;
  }

  .recurly-element {
    height: 100%;
  }

  :hover {
    border-color: var(${DesignToken.BorderBasicHover});
  }
`;

export const fieldActive = css`
  border-color: var(${DesignToken.ActionPrimaryDefault});

  :hover {
    border-color: var(${DesignToken.ActionPrimaryHover});
  }
`;

export const fieldError = css`
  border-color: var(${DesignToken.BorderBasicNegative});

  :hover {
    border-color: var(${DesignToken.BorderBasicNegative});
  }
`;
