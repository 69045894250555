import { type IAddNewAgentResponse, type AIAgentsListDTO } from 'services/api/ml-gateway/interfaces/ai-agents';

import { type AIAgents } from './interfaces';
const EMPTY_ARRAY: string[] = [];

export const deserializeAIAgents = (data: AIAgentsListDTO[]): AIAgents => {
  const publicSourceIds: string[] = [];
  const privateSourceIds: string[] = [];

  const result = data.reduce((acc, item) => {
    if (!item.agent_template) {
      return acc;
    }

    const isPrivateSource = item.agent_template.name === 'one';
    const isPublicSource = item.agent_template.name === 'reply-suggestions';
    acc[item.agent_template.name] = {
      id: item.id,
      name: item.name,
    };

    item.agent_skills.forEach((skill) => {
      if (isPrivateSource) {
        privateSourceIds.push(skill.id);
      } else if (isPublicSource) {
        publicSourceIds.push(skill.id);
      }
    });

    return acc;
  }, {});

  const filteredSources = privateSourceIds.filter((id) => !publicSourceIds.includes(id));

  return {
    data: result,
    publicSourceIds,
    privateSourceIds: filteredSources,
  };
};

const normalizeString = (input: string): string => {
  return input.toLowerCase().replace(/\s+/g, '-');
};

export const deserializeNewAiAgents = (data: IAddNewAgentResponse[]): AIAgents => {
  const result = data.reduce((acc, item) => {
    acc[normalizeString(item.name)] = {
      id: item.id,
      name: item.name,
    };

    return acc;
  }, {});

  return {
    data: result,
    publicSourceIds: EMPTY_ARRAY,
    privateSourceIds: EMPTY_ARRAY,
  };
};
