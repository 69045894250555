// @ts-strict-ignore
import isEmpty from 'lodash.isempty';
import union from 'lodash.union';

import { removeDuplicates } from 'helpers/array';
import { type KeyMap } from 'helpers/interface';
import { trimObject } from 'helpers/object';
import {
  type ISetThreadsEventsPayload,
  type IFetchChatHistoryCompletedPayload,
  type IFetchChatHistoryPayload,
  type IFetchChatThreadsDetailsFailurePayload,
  type IIncomingChatThreadPayload,
} from 'store/entities/chats/interfaces';

import {
  type IChangeTagReminderVisibilityPayload,
  type IChatTagsOperations,
  type IChatsViewState,
  type IClearScrollPositionPayload,
  type IMarkChatAsNewPayload,
  type IMarkCustomerAsAwaitingNavigationPayload,
  type IRemoveDraftMessagePayload,
  type IRemovePrivateModePayload,
  type IRemoveReplySuggestionsToolbarStatePayload,
  type IRemoveTextEnhancementsToolbarStatePayload,
  type ISaveDraftMessagePayload,
  type ISavePrivateModePayload,
  type ISaveReplySuggestionsToolbarStatePayload,
  type ISaveTextEnhancementsToolbarStatePayload,
  type ISendMessagePayload,
  type ISetChatTagFailurePayload,
  type ISetChatTagRequestPayload,
  type ISetChatTagSuccessPayload,
  type ISetChatsSortOrderPayload,
  type ISetInaccessibleChatIdPayload,
  type ISetReplySuggestionSessionIdPayload,
  type ISetScrollPositionPayload,
  type ISetSelectedIdPayload,
  type ISetTextEnhancementsSessionIdPayload,
  type IUnmarkChatThreadIdsAsNewPayload,
  type IUpdateNotificationsCountPayload,
} from '../interfaces';

import {
  addTagToFailureStatus,
  addTagToInProgressStatus,
  removeTagFromFailureStatus,
  removeTagFromInProgressStatus,
} from './helpers';

const withChatId =
  (chatId: string) =>
  (id: string): boolean =>
    id === chatId;
const withoutChatId =
  (chatId: string) =>
  (id: string): boolean =>
    id !== chatId;

function deleteDraftMessage(state: IChatsViewState, threadId: string): IChatsViewState {
  const { draftMessages: currentDraftMessages } = state;

  const draftMessages = { ...currentDraftMessages };
  delete draftMessages[threadId];

  return {
    ...state,
    draftMessages,
  };
}

function deletePrivateMode(state: IChatsViewState, threadId: string): IChatsViewState {
  const { privateMode: currentPrivateMode } = state;

  const privateMode = { ...currentPrivateMode };
  delete privateMode[threadId];

  return {
    ...state,
    privateMode,
  };
}

export function getStateForMarkCustomerAsAwaitingNavigation(
  state: IChatsViewState,
  payload: IMarkCustomerAsAwaitingNavigationPayload,
): IChatsViewState {
  return {
    ...state,
    customerIdAwatingNavigation: payload.customerId,
  };
}

export function getStateForClearAwaitingNavigation(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    customerIdAwatingNavigation: null,
  };
}

export function getStateForSetSelectedId(state: IChatsViewState, payload: ISetSelectedIdPayload): IChatsViewState {
  return {
    ...state,
    selectedId: payload.threadId,
    visitedThreadIds: union(state.visitedThreadIds, [payload.threadId]),
  };
}

export function getStateForSetThreadsEvents(
  state: IChatsViewState,
  payload: ISetThreadsEventsPayload,
): IChatsViewState {
  const { events } = payload;

  const threadIds = Object.keys(events);

  return {
    ...state,
    visitedThreadIds: union(state.visitedThreadIds, threadIds),
  };
}

function calculateTagsOperationsState(
  state: IChatsViewState,
  threadId: string,
  tagsOperations: IChatTagsOperations,
): KeyMap<IChatTagsOperations> {
  const operations = trimObject({ ...state.tagsOperations[threadId], ...tagsOperations });

  if (!isEmpty(operations)) {
    return {
      ...state.tagsOperations,
      [threadId]: operations,
    };
  } else {
    const { [threadId]: removedKey, ...tagsOperations } = state.tagsOperations;

    return tagsOperations;
  }
}

export function getStateForSetChatTagRequest(
  state: IChatsViewState,
  payload: ISetChatTagRequestPayload,
): IChatsViewState {
  const { threadId, tag } = payload;
  const { tagsOperations } = state;
  const tagsProcessingToUpdate = addTagToInProgressStatus(tagsOperations, threadId, tag);
  const tagsFailureToUpdate = removeTagFromFailureStatus(tagsOperations, threadId, tag);
  const operations = calculateTagsOperationsState(state, threadId, {
    inProgress: removeDuplicates(tagsProcessingToUpdate),
    failure: tagsFailureToUpdate,
  });

  return {
    ...state,
    tagsOperations: operations,
  };
}

export function getStateForSetChatTagSuccess(
  state: IChatsViewState,
  payload: ISetChatTagSuccessPayload,
): IChatsViewState {
  const { threadId, tag } = payload;
  const { tagsOperations } = state;
  const tagsProcessingToUpdate = removeTagFromInProgressStatus(tagsOperations, threadId, tag);

  const operations = calculateTagsOperationsState(state, threadId, {
    inProgress: tagsProcessingToUpdate,
  });

  return {
    ...state,
    tagsOperations: operations,
  };
}

export function getStateForSetChatTagFailure(
  state: IChatsViewState,
  payload: ISetChatTagFailurePayload,
): IChatsViewState {
  const { threadId, tag } = payload;
  const { tagsOperations } = state;
  const tagsProcessingToUpdate = removeTagFromInProgressStatus(tagsOperations, threadId, tag);
  const tagsFailureToUpdate = addTagToFailureStatus(tagsOperations, threadId, tag);

  const operations = calculateTagsOperationsState(state, threadId, {
    inProgress: tagsProcessingToUpdate,
    failure: tagsFailureToUpdate,
  });

  return {
    ...state,
    tagsOperations: operations,
  };
}

export function getStateForChangeTagReminderVisibility(
  state: IChatsViewState,
  payload: IChangeTagReminderVisibilityPayload,
): IChatsViewState {
  const { threadId, visibility } = payload;

  const operations = calculateTagsOperationsState(state, threadId, {
    tagReminderDisplayed: visibility,
  });

  return {
    ...state,
    tagsOperations: operations,
  };
}

export function getStateForSetLoadMoreUnassignedChats(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    isFetchingMoreUnassignedChats: true,
  };
}

export function getStateForUnsetLoadMoreUnassignedChats(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    isFetchingMoreUnassignedChats: false,
  };
}
export function getStateForSendMessage(state: IChatsViewState, payload: ISendMessagePayload): IChatsViewState {
  if (payload.retriedMessageId) {
    return deleteDraftMessage(state, payload.threadId);
  }

  return state;
}

export function getStateForSaveDraftMessage(
  state: IChatsViewState,
  payload: ISaveDraftMessagePayload,
): IChatsViewState {
  const { message, threadId } = payload;
  const { draftMessages } = state;

  return {
    ...state,
    draftMessages: {
      ...draftMessages,
      [threadId]: message,
    },
  };
}

export function getStateForRemoveDraftMessage(
  state: IChatsViewState,
  payload: IRemoveDraftMessagePayload,
): IChatsViewState {
  return deleteDraftMessage(state, payload.threadId);
}

export function getStateForMarkChatAsNew(state: IChatsViewState, payload: IMarkChatAsNewPayload): IChatsViewState {
  const { threadId } = payload;
  const { newChatThreadIds } = state;

  return {
    ...state,
    newChatThreadIds: union(newChatThreadIds, [threadId]),
  };
}

export function getStateForUnmarkChatAsNew(
  state: IChatsViewState,
  payload: IUnmarkChatThreadIdsAsNewPayload,
): IChatsViewState {
  const { threadId } = payload;
  const { newChatThreadIds } = state;

  const updatedNewChatThreadIds = newChatThreadIds.filter((id) => id !== threadId);

  return {
    ...state,
    newChatThreadIds: updatedNewChatThreadIds,
  };
}

function getStateForHistoryIsFetchingUpdate(
  state: IChatsViewState,
  threadId: string,
  isFetching: boolean,
): IChatsViewState {
  return {
    ...state,
    isFetchingHistory: {
      ...state.isFetchingHistory,
      [threadId]: isFetching,
    },
  };
}

export function getStateForFetchChatHistory(
  state: IChatsViewState,
  payload: IFetchChatHistoryPayload,
): IChatsViewState {
  const { threadId } = payload;

  return getStateForHistoryIsFetchingUpdate(state, threadId, true);
}

export function getStateForFetchChatHistoryCompleted(
  state: IChatsViewState,
  payload: IFetchChatHistoryCompletedPayload,
): IChatsViewState {
  const { threadId } = payload;

  return getStateForHistoryIsFetchingUpdate(state, threadId, false);
}

export function getStateForSetScrollPosition(
  state: IChatsViewState,
  payload: ISetScrollPositionPayload,
): IChatsViewState {
  const { threadId, scrollPosition } = payload;

  const currentScrollPosition = { ...state.scrollPosition };

  return {
    ...state,
    scrollPosition: {
      ...currentScrollPosition,
      [threadId]: scrollPosition,
    },
  };
}

export function getStateFormNotificationsCountUpdate(
  state: IChatsViewState,
  payload: IUpdateNotificationsCountPayload,
): IChatsViewState {
  const { count } = payload;

  return {
    ...state,
    notificationsCount: count,
  };
}

export function getStateForRemoveScollPosition(
  state: IChatsViewState,
  payload: IClearScrollPositionPayload,
): IChatsViewState {
  const { threadId } = payload;
  const { scrollPosition: currentScrollPosition } = state;

  const scrollPosition = { ...currentScrollPosition };
  delete scrollPosition[threadId];

  return {
    ...state,
    scrollPosition,
  };
}

export function getStateForSavePrivateMode(state: IChatsViewState, payload: ISavePrivateModePayload): IChatsViewState {
  const { isActive, threadId } = payload;
  const { privateMode } = state;

  return {
    ...state,
    privateMode: {
      ...privateMode,
      [threadId]: isActive,
    },
  };
}

export function getStateForRemovePrivateMode(
  state: IChatsViewState,
  payload: IRemovePrivateModePayload,
): IChatsViewState {
  return deletePrivateMode(state, payload.threadId);
}

export function getStateforSetChatsSortOrder(
  state: IChatsViewState,
  payload: ISetChatsSortOrderPayload,
): IChatsViewState {
  return {
    ...state,
    sortOrderMap: {
      ...state.sortOrderMap,
      [payload.chatType]: payload.sortType,
    },
  };
}

export function getStateForSetData(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    newChatThreadIds: [],
  };
}

export function getStateForFetchThreadDetailsFailure(
  state: IChatsViewState,
  payload: IFetchChatThreadsDetailsFailurePayload,
): IChatsViewState {
  const { isChatInaccessible, chatId } = payload;

  let { inaccessibleChatIds } = state;

  if (isChatInaccessible) {
    inaccessibleChatIds = [...inaccessibleChatIds, chatId];
  }

  return {
    ...state,
    inaccessibleChatIds,
  };
}

export function getStateForInaccessibleChatId(
  state: IChatsViewState,
  payload: ISetInaccessibleChatIdPayload,
): IChatsViewState {
  return {
    ...state,
    inaccessibleChatIds: [...state.inaccessibleChatIds, payload.chatId],
  };
}

export function getStateForIncomingChatThread(
  state: IChatsViewState,
  payload: IIncomingChatThreadPayload,
): IChatsViewState {
  const { inaccessibleChatIds } = state;
  const { thread } = payload;

  return {
    ...state,
    ...(inaccessibleChatIds.some(withChatId(thread.chatId)) && {
      inaccessibleChatIds: inaccessibleChatIds.filter(withoutChatId(thread.chatId)),
    }),
    visitedThreadIds: union(state.visitedThreadIds, [thread.threadId]),
  };
}

export function getStateForSaveTextEnhancementsToolbarState(
  state: IChatsViewState,
  payload: ISaveTextEnhancementsToolbarStatePayload,
): IChatsViewState {
  const { threadId, response, prevValue, isLoading, error } = payload;

  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      toolbarState: {
        ...state.textEnhancements.toolbarState,
        [threadId]: {
          threadId,
          response,
          prevValue,
          isLoading,
          error,
        },
      },
    },
  };
}

export function getStateForRemoveThreadTextEnhancementsToolbarState(
  state: IChatsViewState,
  payload: IRemoveTextEnhancementsToolbarStatePayload,
): IChatsViewState {
  const { threadId } = payload;
  const currentTextEnhancementsToolbarState = { ...state.textEnhancements.toolbarState };
  delete currentTextEnhancementsToolbarState[threadId];

  const currentThreadSessions = { ...state.textEnhancements.currentThreadSessions };
  delete currentThreadSessions[threadId];

  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      toolbarState: currentTextEnhancementsToolbarState,
      currentThreadSessions,
    },
  };
}

export function getStateForRemoveTextEnhancementsToolbarStates(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      toolbarState: {},
      currentThreadSessions: {},
    },
  };
}
export function getStateForToggleTextEnhancementsToolbar(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      isOpen: !state.textEnhancements.isOpen,
    },
  };
}

export function getStateForOpenTextEnhancementsToolbar(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      isOpen: true,
    },
  };
}

export function getStateForCloseTextEnhancementsToolbar(state: IChatsViewState): IChatsViewState {
  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      isOpen: false,
    },
  };
}

export function getStateForSetTextEnhancementRequestId(
  state: IChatsViewState,
  payload: ISetTextEnhancementsSessionIdPayload,
): IChatsViewState {
  const { threadId, sessionId } = payload;

  return {
    ...state,
    textEnhancements: {
      ...state.textEnhancements,
      currentThreadSessions: {
        ...state.textEnhancements.currentThreadSessions,
        [threadId]: sessionId,
      },
    },
  };
}

export function getStateForSaveKnowledgeSuggestionsToolbarState(
  state: IChatsViewState,
  payload: ISaveReplySuggestionsToolbarStatePayload,
): IChatsViewState {
  const { threadId, data, isLoading, error, metadata, isOnboardingSuggestion } = payload;

  return {
    ...state,
    replySuggestions: {
      ...state.replySuggestions,
      toolbarState: {
        ...state.replySuggestions.toolbarState,
        [threadId]: {
          threadId,
          data,
          isLoading,
          error,
          metadata,
          isOnboardingSuggestion,
        },
      },
    },
  };
}

export function getStateForClearKnowledgeSuggestionsToolbarState(
  state: IChatsViewState,
  payload: IRemoveReplySuggestionsToolbarStatePayload,
): IChatsViewState {
  const { threadId } = payload;

  const currentKnowledgeSuggestionsToolbarState = { ...state.replySuggestions.toolbarState };
  delete currentKnowledgeSuggestionsToolbarState[threadId];

  const currentThreadSessions = { ...state.replySuggestions.currentThreadSessions };
  delete currentThreadSessions[threadId];

  return {
    ...state,
    replySuggestions: {
      ...state.replySuggestions,
      toolbarState: currentKnowledgeSuggestionsToolbarState,
      currentThreadSessions,
    },
  };
}

export function getStateForSetKnowledgeSuggestionRequestId(
  state: IChatsViewState,
  payload: ISetReplySuggestionSessionIdPayload,
): IChatsViewState {
  const { threadId, sessionId } = payload;

  return {
    ...state,
    replySuggestions: {
      ...state.replySuggestions,
      currentThreadSessions: {
        ...state.replySuggestions.currentThreadSessions,
        [threadId]: sessionId,
      },
    },
  };
}

export function getStateForChatTransfer(state: IChatsViewState, isTransferringChat: boolean): IChatsViewState {
  return {
    ...state,
    isTransferringChat,
  };
}

export function getStateForSetCurrentMessageboxValue(state: IChatsViewState, message: string): IChatsViewState {
  return {
    ...state,
    currentMessageboxValue: message,
  };
}
