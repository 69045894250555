import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { getCopilotIsLoading } from 'store/views/copilot/selectors';

import { StopGenerateButton } from '../hooks/StopGenerateButton';

import { AskButton } from './AskButton';
import { CharacterLimit } from './CharacterLimit';
import { MessageBoxTip } from './MessageBoxTip';

import * as styles from './styles';

interface IMessageBoxBottomBarProps {
  isExpanded: boolean;
}

export const CopilotMessageBoxBottomBar: FC<IMessageBoxBottomBarProps> = ({ isExpanded }) => {
  const isCopilotLoading = useSelector(getCopilotIsLoading);
  if (!isExpanded) {
    return (
      <>
        {!isCopilotLoading && <AskButton />}
        <StopGenerateButton />
      </>
    );
  }

  return (
    <div className={styles.bottomBar}>
      <div>
        <MessageBoxTip />
      </div>
      <div className={styles.rightNodes}>
        <StopGenerateButton />
        <CharacterLimit />
        <AskButton />
      </div>
    </div>
  );
};
