export const enum ToastContent {
  ADD_APPLICATION_TO_THE_CART_ERROR,
  REMOVE_APPLICATION_FROM_THE_CART_ERROR,
  ASSIGN_CHAT_ERROR,
  BAN_VISITOR_SUCCESS,
  CHANGE_ROLE_SUCCESS,
  CHANGE_OWNER_SUCCESS,
  CHANGE_CHATS_LIMIT_SUCCESS,
  CANNED_SAVE_ERROR,
  CANNED_SAVE_SUCCESS,
  CANNED_UPDATE_SUCCESS,
  CANNED_UPDATE_ERROR,
  CANNED_REMOVE_ERROR,
  CANNED_REMOVE_SUCCESS,
  CANT_START_CHAT_INACCESSIBLE,
  CANT_START_CHAT_THREAD_REMOVED,
  CANT_ASSIGN_CHAT_THREAD,
  CHAT_ALREADY_PICKED,
  CHAT_MENU_INTEGRATION_ERROR,
  CHAT_MENU_INTEGRATION_SUCCESS,
  COPY_CODE_SUCCESS,
  CREATE_TICKET_ERROR,
  CREATE_TICKET_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  DEFAULT_ERROR,
  DOWNLOAD_TRANSCRIPT_ERROR,
  FETCH_SINGLE_ARCHIVE_ERROR,
  MISSING_GROUP_ACCESS,
  NO_CHAT_FOUND_ERROR,
  REPORT_CREATE_ERROR,
  REPORT_CREATE_SUCCESS,
  REPORT_DELETE_ERROR,
  REPORT_DELETE_SUCCESS,
  REPORT_EXISTS_ERROR,
  REPORT_SAVE_ERROR,
  REPORT_SAVE_SUCCESS,
  SEND_TRANSCRIPT_ERROR,
  SEND_TRANSCRIPT_SUCCESS,
  START_SUPERVISE_FAILURE,
  SUBSCRIPTION_CREATED,
  SUBSCRIPTION_UPDATED,
  TAG_ADD_ERROR,
  TAG_UPDATE_ERROR,
  TAG_UPDATE_LIMIT_ERROR,
  TAG_ADD_SUCCESS,
  TAG_DELETE_SUCCESS,
  TAG_EXISTS,
  TAGS_LIMIT_REACHED,
  TAG_EXIST_SETTINGS,
  TICKET_ASSIGNEE_UPDATE_ERROR,
  TICKET_ASSIGNEE_UPDATE_SUCCESS,
  TICKET_BATCH_UPDATE_ERROR,
  TICKET_BATCH_UPDATE_SUCCESS,
  TICKET_CCS_UPDATE_ERROR,
  TICKET_CCS_UPDATE_SUCCESS,
  TICKET_COPY_URL_SUCCESS,
  TICKET_GROUPS_UPDATE_ERROR,
  TICKET_GROUPS_UPDATE_SUCCESS,
  TICKET_REQUESTER_UPDATE_ERROR,
  TICKET_REQUESTER_UPDATE_SUCCESS,
  TICKET_SEND_MESSAGE_ERROR,
  TICKET_SEND_MESSAGE_SUCCESS,
  TICKET_SUBJECT_UPDATE_SUCCESS,
  TICKET_UNSPAM_ERROR,
  TICKET_UNSPAM_SUCCESS,
  TODO_CHECKLIST_FETCH_ERROR,
  TODO_CHECKLIST_SAVE_ERROR,
  TRANSFER_SUCCESS,
  TRANSFER_ERROR,
  UNBAN_VISITOR_SUCCESS,
  GA_INSTALL_ERROR,
  GA_UNINSTALL_ERROR,
  APPLICATION_INSTALL_ERROR,
  APPLICATION_UNINSTALL_ERROR,
  APPLICATION_INSTALL_SUCCESS,
  APPLICATION_UNINSTALL_SUCCESS,
  REPORT_FETCH_ERROR,
  TOAST_ERROR_MESSAGE,
  CREATE_TICKET_FROM_QUEUE_ABANDONMENT_SUCCESS,
  CREATE_TICKET_FROM_QUEUE_ABANDONMENT_ERROR,
  RESEND_INVITATION_SUCCESS,
  AGENT_SUSPEND_SUCCESS,
  AGENT_SUSPEND_ERROR,
  AGENTS_SUSPEND_SUCCESS,
  AGENTS_SUSPEND_ERROR,
  AGENT_ACTIVATE_SUCCESS,
  AGENT_DELETE_SUCCESS,
  AGENT_DELETE_ERROR,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_ERROR,
  GROUP_CREATE_ERROR,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_ERROR,
  AGENT_APPROVE_SUCCESS,
  AGENT_APPROVE_ERROR,
  AGENT_UPDATE_SUCCESS,
  AGENT_UPDATE_ERROR,
  REACTIVATION_EMAIL_SENT,
  REQUEST_ACTIVATE_SUCCESS,
  REQUEST_ACTIVATE_FAILURE,
  INCOMPLETE_THREAD_FETCH_ERROR,
  EMAIL_MAPPING_NAME_SAVE_SUCCESS,
  EMAIL_MAPPING_NAME_CLEAR_SUCCESS,
  EMAIL_MAPPING_FETCH_ERROR,
  EMAIL_MAPPING_SAVE_ERROR,
  GREETING_DELETE_SUCCESS,
  GREETING_DELETE_ERROR,
  GREETING_UPDATE_SUCCESS,
  GREETING_UPDATE_FAILURE,
  GREETING_CREATE_SUCCESS,
  GREETING_CREATE_FAILURE,
  GREETING_DUPLICATE_SUCCESS,
  GREETING_DUPLICATE_FAILURE,
  GREETING_ACTIVATION_SUCCESS,
  GREETING_ACTIVATION_FAILURE,
  GOAL_CREATE_SUCCESS,
  GOAL_UPDATE_SUCCESS,
  GOAL_UPDATE_ERROR,
  GOAL_CREATE_ERROR,
  GOAL_DELETE_SUCCESS,
  GOAL_DELETE_ERROR,
  CHATBOT_ADD_FAILURE,
  CHATBOT_DELETE_SUCCESS,
  CHATBOT_UPDATE_SUCCESS,
  CHATBOT_UPDATE_FAILURE,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_FAILURE,
  INVITATIONS_SENT,
  CUSTOM_SEGMENT_LABEL_UPDATE_SUCCESS,
  CUSTOM_SEGMENT_LABEL_UPDATE_FAILURE,
  CUSTOM_SEGMENT_SAVE_SUCCESS,
  CUSTOM_SEGMENT_SAVE_FAILURE,
  CUSTOM_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS,
  CUSTOM_SEGMENTS_ORDER_UPDATE_SUCCESS,
  CUSTOM_SEGMENTS_ORDER_UPDATE_FAILURE,
  CUSTOM_SEGMENT_DELETE_SUCCESS,
  CUSTOM_SEGMENT_DELETE_FAILURE,
  CUSTOMER_SEGMENT_SAVE_SUCCESS,
  CUSTOMER_SEGMENT_SAVE_FAILURE,
  CUSTOMER_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS,
  CUSTOMER_SEGMENT_DELETE_SUCCESS,
  CUSTOMER_SEGMENT_DELETE_FAILURE,
  CUSTOMER_SEGMENT_LABEL_UPDATE_SUCCESS,
  CUSTOMER_SEGMENT_LABEL_UPDATE_FAILURE,
  CUSTOMER_SEGMENTS_ORDER_CHANGE_FAILURE,
  SUBSCRIPTION_FETCH_ERROR,
  GROUPS_PER_AGENT_LIMIT_EXCEEDED,
  IN_APP_UPGRADE_PURCHASE_TRANSACTION_FAILURE,
  IN_APP_UPGRADE_PURCHASE_TRANSACTION_SUCCESS,
  IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_FAILURE,
  IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_SUCCESS,
  IN_APP_UPGRADE_REGISTRATION_FAILURE,
  SOMETHING_WENT_WRONG,
  SETTINGS_SAVE_ERROR,
  AGENT_STATUS_CHANGE_FAILED,
  INACTIVITY_TIMEOUT_CHANGE_SUCCESS,
  INACTIVITY_TIMEOUT_CHANGE_FAILURE,
  START_CHAT_ERROR,
  AGENT_CUSTOM_PROPERTY_UPDATE_SUCCESS,
  AGENT_CUSTOM_PROPERTY_UPDATE_FAILURE,
  CUSTOMER_DETAILS_WIDGETS_CONFIGURATOR_UPDATE_FAILURE,
  BATCH_AGENTS_UPDATE_FAILURE,
  BATCH_AGENTS_UPDATE_SUCCESS,
  BATCH_BOTS_UPDATE_FAILURE,
  BATCH_BOTS_UPDATE_SUCCESS,
  AGENT_INVITE_FAILURE,
  CHAT_SUMMARY_CREATED,
  CHAT_COULDNT_BE_SUMMARIZED,
  CHAT_SUMMARY_COULDNT_BE_SAVED,
  CHAT_SUMMARY_REMOVED,
  CHAT_SUMMARY_COULDNT_BE_REMOVED,
  CHAT_SUMMARY_COULDNT_BE_RETRIEVED,
  CHAT_SUMMARY_COPIED,
  REPORT_VIEWS_SAVE_SUCCESS,
  REPORT_VIEWS_SAVE_FAILURE,
  REPORT_VIEWS_SAVE_FAILURE_TOO_COMPLEX_FILTERS,
  REPORT_VIEWS_DELETE_SUCCESS,
  REPORT_VIEWS_DELETE_FAILURE,
  AUTOTAGS_CREATE_FAILURE,
  AUTOTAGS_UPDATE_FAILURE,
  KNOWLEDGE_SOURCE_UPDATE_FAILURE,
  KNOWLEDGE_SOURCES_UPDATE_FAILURE,
  KNOWLEDGE_SOURCE_DELETE_SUCCESS,
  KNOWLEDGE_SOURCE_DELETE_FAILURE,
  KNOWLEDGE_SOURCE_ADD_FAILURE,
  CHAT_SENTIMENT_ENABLED,
  KNOWLEDGE_SOURCE_REMOVE_FAILURE,
  KNOWLEDGE_SOURCE_CANCEL_FAILURE,
  KNOWLEDGE_SOURCE_RENAME_FAILURE,
  KNOWLEDGE_SOURCE_RENAME_SUCCESS,
  KNOWLEDGE_SOURCE_ALREADY_LEARNED,
  TARGET_CHANGED_SUCCESS,
  RICH_MESSAGE_SEND_CARDS_ERROR,
  COLLECT_INVOICE_SUCCESS,
  MESSAGE_COPIED_SUCCESS,
  MESSAGE_COPIED_ERROR,
  REVIEW_COPIED_SUCCESS,
  REVIEW_CREATE_ERROR,
  SEND_CODE_INSTRUCTIONS_ERROR,
  SEND_AI_ASSISTANT_MESSAGE_FEEDBACK_ERROR,
  LEGACY_APPLICATION_UPDATE_SUCCESS,
  LEGACY_APPLICATION_UPDATE_FAILURE,
  CHANGES_SAVED,
  SAVING_FAILED,
  CHAT_BUTTON_DELETE_SUCCESS,
  CHAT_BUTTON_DELETE_ERROR,
  CHAT_BUTTON_SAVE_SUCCESS,
  CHAT_BUTTON_IMAGE_UPLOAD_ERROR,
  CHAT_BUTTON_CREATE_SUCCESS,
  CHAT_BUTTON_CODE_COPIED,
  TRUSTED_DOMAIN_ADD_ERROR,
  TRUSTED_DOMAIN_REMOVE_ERROR,
  TRUSTED_DOMAIN_REMOVE_SUCCESS,
  TRUSTED_DOMAIN_ADD_SUCCESS,
  SALES_TRACKER_CREATE_SUCCESS,
  SALES_TRACKER_UPDATE_SUCCESS,
  SALES_TRACKER_DELETE_SUCCESS,
  SALES_TRACKER_CREATE_ERROR,
  SALES_TRACKER_UPDATE_ERROR,
  SALES_TRACKER_DELETE_ERROR,
  SALES_TRACKER_CODE_COPIED,
  ADD_WEBHOOK_SUCCESS,
  ADD_WEBHOOK_FAILURE,
  ADD_WEBHOOK_FAILURE_DUPLICATE,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  EYE_CATCHER_UPDATE_SUCCESS,
  EYE_CATCHER_UPDATE_FAILURE,
  ERROR_NO_TAGS_FOUND,
  ERROR_TAG_ALREADY_EXIST,
  ERROR_TAGS_LIMIT_REACHED,
  ERROR_TAG_NO_GROUP,
  KNOWLEDGE_BASE_ACCOUNT_CREATION_FAILURE,
  YOU_HAVE_BEEN_RE_LOGGED,
  ADD_INACTIVITY_ERROR,
  INVOICE_DETAILS_UPDATE_SUCCESS,
  INVOICE_DETAILS_UPDATE_FAILURE,
  COMPANY_DETAILS_UPDATE_SUCCESS,
  COMPANY_DETAILS_UPDATE_FAILURE,
  CODE_INSTALLATION_SUCCESS,
  CODE_INSTALLATION_FAILURE,
  ONE_CLICK_PAYMENT_SUCCESS,
  ONE_CLICK_PAYMENT_ACTIVATED,
  KEEP_TRIAL_PLAN_SUCCESS,
  NO_AI_AGENTS,
  UPLOADED_FILE_TOO_LARGE,
  ADDING_URL_SOURCE_SUCCESS,
  ADDING_URL_SOURCE_FAILURE,
  KNOWLEDGE_SOURCE_PAGES_DELETE_SUCCESS,
  KNOWLEDGE_SOURCE_PAGES_DELETE_FAILURE,
  UPDATING_URL_SOURCE_TYPE_PUBLIC_SUCCESS,
  UPDATING_URL_SOURCE_TYPE_INTERNAL_SUCCESS,
  UPDATING_URL_SOURCE_TYPE_FAILURE,
  ADDING_FILE_SOURCE_SUCCESS,
  ADDING_FILE_SOURCE_FAILURE,
  KNOWLEDGE_SOURCE_AUTO_SYNC_UPDATE_SUCCESS,
  KNOWLEDGE_SOURCE_AUTO_SYNC_UPDATE_FAILURE,
  KNOWLEDGE_HUB_MAX_LIMIT_REACHED,
  KNOWLEDGE_HUB_SOURCE_ALREADY_ADDED,
}

// Temporary to prevent animation issues from DS
export const enum ActionHandlerExecutionDelay {
  Short = 300,
  Long = 3000,
}

export const enum ToastAutoHideDelay {
  Short = 2500,
  Long = 3500,
  VeryLong = 5000,
}
