import { useEffect, useState, type FC } from 'react';

import { cx } from '@emotion/css';

import { SkeletonLine } from 'components/skeleton/SkeletonLine';

import * as styles from './styles';

interface IProps {
  imageUrl: string;
}

export const CopilotImageEvent: FC<IProps> = ({ imageUrl }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setIsLoaded(true);
    };
  }, [imageUrl]);

  if (!isLoaded) {
    return <SkeletonLine loading className={styles.imageLoader} />;
  }

  return <img className={cx('bubble', styles.imageEvent)} src={imageUrl} alt="image" />;
};
