import { useCallback, useState, useMemo, type FC } from 'react';

import { cx } from '@emotion/css';
import { FormField } from '@livechat/design-system-react-components';
import { CardElement, type CardElementChangeEvent } from '@recurly/react-recurly';
import { useDispatch, useSelector } from 'react-redux';

import { RecurlyFieldType, type CreditCardType } from 'interfaces/recurly';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import { getCurrentCardBrand } from 'store/views/subscription/selectors';

import { recurlyFieldStyles } from '../constants';
import { type ICardField } from '../interfaces';

import { CARD_NUMBER_ELEMENT_ID, NUMBER_FIELD_WRAPPER_ID } from './constants';
import { getValidationMessage } from './helpers';

import * as styles from './styles';

interface IProps {
  error?: string;
  validationEnabled: boolean;
  onBlur(field: RecurlyFieldType): void;
  onFocus(field: RecurlyFieldType): void;
  onChange?(): void;
}

// this is necessary since CardElementChangeEvent type is different from what we get from actual onChange event
interface ICardDetailsState extends Omit<CardElementChangeEvent, 'number'> {
  number: ICardField;
  expiry: ICardField;
  cvv: ICardField;
}

export const NumberField: FC<IProps> = ({ error, validationEnabled, onBlur, onFocus, onChange }) => {
  const [validationError, setValidationError] = useState('');
  const [isActive, setIsActive] = useState(false);

  const currentCardBrand = useSelector(getCurrentCardBrand);
  const dispatch = useDispatch();

  const handleCardBrandSet = useCallback(
    (brand: CreditCardType): void => {
      dispatch(SubscriptionViewActions.setCurrentCardBrand(brand));
    },
    [dispatch],
  );

  const handleFocus = useCallback(() => {
    setIsActive(true);
    onFocus(RecurlyFieldType.CreditCard);
  }, [onFocus]);
  const handleBlur = useCallback(() => {
    setIsActive(false);
    onBlur(RecurlyFieldType.CreditCard);
  }, [onBlur]);

  const handleChange = useCallback(
    (cardElementEvent: CardElementChangeEvent) => {
      if ((cardElementEvent.brand as CreditCardType) !== currentCardBrand) {
        handleCardBrandSet(cardElementEvent.brand as CreditCardType);
      }

      const { number, expiry, cvv } = cardElementEvent as unknown as ICardDetailsState;

      setValidationError(
        getValidationMessage({
          number,
          expiry,
          cvv,
        }),
      );

      if (number.valid && expiry.valid && cvv.valid) {
        onChange?.();
      }
    },
    [currentCardBrand, onChange, handleCardBrandSet],
  );

  const combinedError = useMemo(
    () => (validationEnabled ? validationError : error),
    [validationEnabled, validationError, error],
  );

  return (
    <FormField
      className={styles.wrapper}
      labelText="Card details"
      labelFor={CARD_NUMBER_ELEMENT_ID}
      error={combinedError}
      data-testid={NUMBER_FIELD_WRAPPER_ID}
    >
      <CardElement
        id={CARD_NUMBER_ELEMENT_ID}
        className={cx({
          [styles.cardField]: true,
          [styles.fieldActive]: isActive,
          [styles.fieldError]: !!combinedError,
        })}
        onBlur={handleBlur}
        style={recurlyFieldStyles}
        onFocus={handleFocus}
        onChange={handleChange}
      />
    </FormField>
  );
};
