import { type KyInstance, type KyResponse, type Options } from 'ky';

import { waitBeforeRetry } from '../retry-policy/wait-before-retry';

import { handleError } from './error-handlers';
import { shouldRetryRequest } from './helpers';
import { type ApiClientRequestOptions, type ApiClientResponse, type ApiClientResponseType } from './types';

const MAX_TRIES = 3;

/**
 * Parses the fetch response into the specified format.
 * @param response The response object from the fetch request.
 * @param responseType The format to parse the response into: 'text', 'blob', 'arrayBuffer', 'formData', or 'json'.
 * @returns The parsed response as a promise.
 */
export async function parseResponse<T>(response: KyResponse, responseType: ApiClientResponseType): Promise<T> {
  switch (responseType) {
    case 'text':
      return response.text() as unknown as T;
    case 'blob':
      return response.blob() as unknown as T;
    case 'arrayBuffer':
      return response.arrayBuffer() as unknown as T;
    case 'formData':
      return response.formData() as unknown as T;
    case 'json':
    default:
      return response.json<T>();
  }
}

async function fetchWithRetry<T, E>(
  client: KyInstance,
  url: string,
  kyOptions: Options,
  tryAttempt?: number,
): Promise<KyResponse> {
  try {
    return client(url, kyOptions);
  } catch (error) {
    const retryAttempt = tryAttempt || 0;
    if (shouldRetryRequest(error) && retryAttempt < MAX_TRIES) {
      await waitBeforeRetry(retryAttempt);

      return fetchWithRetry<T, E>(client, url, kyOptions, retryAttempt + 1);
    }

    throw error;
  }
}

/**
 * Performs a fetch request with specified URL and options, managing the response and errors.
 * @param url The URL for the request.
 * @param options Configuration options for the request, including the `ky` client to use.
 * @returns The response object as a promise, containing result or error info.
 */
export async function fetchRequest<T, E>(
  url: string,
  options: ApiClientRequestOptions & {
    client: KyInstance;
  },
): Promise<ApiClientResponse<T | null, E | null>> {
  const { responseType = 'json', client, onError, dontRetry, ...kyOptions } = options;

  try {
    const response = dontRetry ? await client(url, kyOptions) : await fetchWithRetry(client, url, kyOptions);
    const data = await parseResponse<T>(response, responseType);

    return { response, result: data, error: null };
  } catch (error: unknown) {
    const parsedError = await handleError<E>(error);

    if (onError) {
      const method = kyOptions.method as string;
      const requestBody = kyOptions.json;
      const timestamp = Date.now();
      onError(error, { endpoint: url, method, timestamp, parsedError, requestBody });
    }

    return parsedError;
  }
}
