// @ts-strict-ignore
import TraceKit from 'tracekit';

import '@livechat/design-system-react-components/dist/style.css';
import { TrackingEvent } from 'components/shortcuts/constants';
import { App } from 'config/setup';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventNames, type Events } from 'constants/event-bus-events';
import { GlobalModal } from 'constants/global-modal';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { KeyCodeEnum, KeyEnum } from 'constants/key-code';
import { type LanguageCode } from 'constants/languages-available';
import { BaseRouteName } from 'constants/routes';
import { SPOTLIGHT_MEDIA_QUERY, SpotlightTrackEvent } from 'constants/spotlight';
import { EventPlace, setDataLayerCustomDimensions, trackGTMEvent } from 'helpers/analytics';
import { isMacOS, isPercyUserAgent } from 'helpers/browser';
import { getConfig } from 'helpers/config';
import { deleteAllCookiesExceptRoot } from 'helpers/cookies';
import { setHighchartTheme } from 'helpers/dark-mode';
import { shouldForceDesktopView } from 'helpers/device';
import { downloadBugReport } from 'helpers/download-bug-report';
import { downloadState } from 'helpers/download-state';
import { trackEventOncePerUniqueKey } from 'helpers/event-saver';
import { isDevelopmentEnvironment } from 'helpers/feature-toggle';
import { getIsInputFocused } from 'helpers/focused-element';
import { isGhostLogin } from 'helpers/ghost';
import { redirectTo } from 'helpers/redirect-to';
import { extractRedirectTo, redirectToAccounts, redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import { navigate } from 'helpers/routing';
import { isSpotlightEnabled } from 'helpers/spotlight';
import { capitalizeFirstLetter, uniqueId } from 'helpers/string';
import { getAppRoot, getHashParam, getUrlParam } from 'helpers/url';
import type { IReportsViewUpdateFiltersPayload } from 'interfaces/reports';
import { SignoutReason, type ISession } from 'interfaces/session';
import type { IAppApi, IApplicationCore } from 'interfaces/types/application-core';
import { getLastActivityInDays, isWebsiteActivityStored } from 'services/activity/website-last-activity';
import { AmplitudeUserProperty, setUserIdForAmplitude, setUserPropertiesForAmplitude } from 'services/amplitude';
import { ApiManager } from 'services/api/api-manager';
import { loadAppFont } from 'services/app-font-loader';
import { AppStateProvider } from 'services/app-state-provider';
import { getAccessToken, loadCredentialsFromCookies, storeCredentials } from 'services/auth/auth-storage-manager';
import ChatWidget from 'services/chat-widget';
import { consoleLogHistoryManager } from 'services/console-log-history-manager';
import { DesktopApplication } from 'services/desktop-application';
import { loadDevTools } from 'services/dev-tools/load-dev-tools';
import { EventBus } from 'services/event-bus';
import { enableBusinessEventsLogging, trackEvent } from 'services/event-tracking';
import { renderGlobalModals } from 'services/global-modals';
import { initializeQueryClient } from 'services/query-client/initialize';
import { isMainLayoutRendered, renderMainLayout } from 'services/render-main-layout';
import { saveLog } from 'services/save-log';
import { sentryContext } from 'services/sentry/context';
import { connectAndLogin } from 'services/server/connect-and-login';
import { fetchAndStoreAccountsAndRoles } from 'services/server/fetch-accounts-and-roles';
import { logout as logoutFromServer } from 'services/server/logout';
import { endSession, removeAllSessions, removeSession } from 'services/session';
import { clearSocketActivity } from 'services/socket-activity-logger';
import { getAgentPermission, getLoggedInAgent, getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { ExperimentsActions } from 'store/entities/experiments/actions';
import { getGroupLanguageCode } from 'store/entities/groups/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { getChatsListSentimentVisible, getCurrentTheme } from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { SessionActions } from 'store/features/session/actions';
import { getIsPaidLicense, getLicenseId, getPlanType, getSubscriptionType } from 'store/features/session/selectors';
import { runAppSagas } from 'store/sagas/app-sagas';
import { store } from 'store/store';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';
import { SettingsViewActions } from 'store/views/settings/actions';
import { LanguageViewActions } from 'store/views/settings/language/actions';
import { TicketsViewActions } from 'store/views/tickets/actions';
import { injectGlobalStyles } from 'styles/global-styles';

import { PlatformProtocolParser } from './platform-protocol-parser';
import './polyfills/from-entries';
import './polyfills/structured-clone';
import { ServerAccessPlatform } from './server-access-platform';

consoleLogHistoryManager.startCapture();

/* eslint-disable @typescript-eslint/no-require-imports */
// #if process.env.AA_MOCKED === 'true'
// require('fake-api/src/mock-hook');
// #endif
/* eslint-enable @typescript-eslint/no-require-imports */

const config = getConfig();

runAppSagas();
injectGlobalStyles();

App.config = config;

function handleKeyDown(event: KeyboardEvent): void {
  const isInputFocused = getIsInputFocused();
  const osFirstKey = isMacOS() ? event.metaKey : event.ctrlKey;
  const isOnboardingFinished = AppStateProvider.selectFromStore(getIsOnboardingFinished);

  if (!isOnboardingFinished) {
    return;
  }

  if (event.shiftKey && (event.code as KeyEnum) === KeyEnum.Slash && !isInputFocused) {
    store.dispatch(GlobalModalActions.showModal(GlobalModal.KeyboardShortcuts));
    trackEventOncePerUniqueKey(TrackingEvent.ShortcutUsed, TrackingEvent.ShortcutUsed, EventPlace.KeyboardShortcuts, {
      shortcut: ['shiftKey', event.code].join(' + '),
    });
  }

  const isSpotlightVisible = window.matchMedia(`(min-width: ${SPOTLIGHT_MEDIA_QUERY}px)`).matches;

  const shouldOpenSpotlightModal =
    isSpotlightEnabled && isSpotlightVisible && osFirstKey && (event.keyCode as KeyCodeEnum) === KeyCodeEnum.KeyK;

  if (shouldOpenSpotlightModal) {
    event.preventDefault();
    store.dispatch(GlobalModalActions.showModal(GlobalModal.Spotlight));
    trackEvent(SpotlightTrackEvent.SpotlightTrigger, null, {
      source: 'keyboard shortcut',
    });
  }

  const isCopilotEnabled = AppStateProvider.selectFromStore(getCanUseCopilotPopover);
  const shouldOpenCopilotModal = isCopilotEnabled && osFirstKey && (event.keyCode as KeyCodeEnum) === KeyCodeEnum.KeyG;

  if (shouldOpenCopilotModal) {
    event.preventDefault();
    store.dispatch(CopilotViewActions.toggleModal());
    trackEvent(CopilotTrackEvent.ChatOpened, EventPlace.One, { type: 'shortcut' });
  }
}

App.render = function render(this: IApplicationCore): void {
  if (document.body.id !== 'lc') {
    saveLog('error', 'No #wrapper present!');

    return;
  }

  if (isPercyUserAgent()) {
    document.body.classList.add('no-animations');
  }

  if (shouldForceDesktopView()) {
    document.body.classList.add('force-desktop-view');
  }

  renderMainLayout();

  renderGlobalModals();

  this.isRendered = true;

  window.addEventListener('keydown', handleKeyDown);
};

// track google analytics page views with 2-sec intervals
// - we can't easily do it because
//   some URLs should not be tracked (they are used as redirection)
//
//   For example:
//     @navigate '/agents'           <--- this would be tracked in Router
//     @navigate '/agents/john@....' <--- this would be tracked in Router
//
//   We don't want both URLs to be tracked, just the last one
App.initGoogleAnalytics = function initGoogleAnalytics(this: IApplicationCore): void {
  this.lastGAUrl = null;
  setInterval(() => {
    if (!this.isRendered) {
      return;
    }

    let url = window.location.pathname.replace(/^\//, '');

    // don't track too many similar URLs
    // such as /archives/<ID> or /chats/<ID>
    url = url.replace(/^archives\/.*/, 'archives');
    url = url.replace(/^chats\/.*/, 'chats');

    if (url === this.lastGAUrl) {
      return;
    }

    this.lastGAUrl = url;

    // if app models are inited, we can store some information
    // in Google Analytics dimensions
    if (this.INITED) {
      const state = store.getState();

      // data may not be available due to unsuccessful startup
      const lastActivityInDays = isWebsiteActivityStored() ? getLastActivityInDays() : undefined;
      const licenseId = getLicenseId(state);
      const licenseType = getSubscriptionType(state);

      window.ga('set', {
        dimension1: licenseId,
        dimension2: licenseType,
        dimension3: getPlanType(state),
        dimension4: lastActivityInDays < 3 ? '1' : '0',
      });
      window.ga('global.set', {
        dimension1: licenseId,
        dimension2: licenseType,
        dimension3: getPlanType(state),
        dimension4: lastActivityInDays < 3 ? '1' : '0',
      });
    }

    let ua = navigator.userAgent.toString();

    if (App.SmartClient.isDetected()) {
      // put "LiveChatSmartClient" at the beginning of the string to make sure
      // it won't be truncated when storing in Google Analytics
      const scVersion = App.SmartClient.getVersion();
      ua = ua.replace(/LiveChatSmartClient(\/[0-9.]+)?/g, '');
      if (scVersion !== null) {
        ua = `LiveChatSmartClient/${scVersion} ${ua}`;
      } else {
        ua = `LiveChatSmartClient ${ua}`;
      }
    }

    // customvar's name & key combined must not exceed 128 chars
    ua = ua.substring(0, 126);
    window.ga('set', 'dimension5', ua);
    window.ga('send', 'pageview', `/${url}`);

    window.ga('global.set', 'dimension5', ua);
    window.ga('global.send', 'pageview', `/${url}`);
  }, 2000);
};

App.setupGTM = function setupGTM(this: IApplicationCore): void {
  this.lastGTMUrl = null;
  setInterval(() => {
    if (!this.isRendered) {
      return;
    }

    const url = window.location.pathname.replace(/^\//, '');

    if (url === this.lastGTMUrl) {
      return;
    }

    this.lastGTMUrl = url;

    if (this.INITED && window.dataLayer != null) {
      const state = store.getState();
      const lastActivityInDays = isWebsiteActivityStored() ? getLastActivityInDays() : undefined;

      let ua = navigator.userAgent.toString();
      if (App.SmartClient.isDetected()) {
        const scVersion = App.SmartClient.getVersion();
        ua = ua.replace(/LiveChatSmartClient(\/[0-9.]+)?/g, '');
        if (scVersion !== null) {
          ua = `LiveChatSmartClient/${scVersion} ${ua}`;
        } else {
          ua = `LiveChatSmartClient ${ua}`;
        }
      }
      // customvar's name & key combined must not exceed 128 chars
      ua = ua.substring(0, 126);

      const licenseId = getLicenseId(state);
      const licenseType = getSubscriptionType(state);
      const role = getAgentPermission(state, getLoggedInAgentLogin(state));

      setDataLayerCustomDimensions({
        license: licenseId,
        licenseType,
        agentRole: role,
        salesPlan: getPlanType(state),
        codeInstalled: lastActivityInDays > 0 ? '1' : '0',
        ua,
      });

      trackGTMEvent('VirtualPageview', {
        virtualPageURL: `/${url}`,
        virtualPageTitle: document.title,
      });
    }
  }, 2000);
};

App.setupAmplitudeUserProperties = function setupAmplitudeUserProperties(): void {
  const state = store.getState();
  const myProfile = getLoggedInAgent(state);
  const currentTheme = getCurrentTheme(state);
  const isChatListSentimentVisible = getChatsListSentimentVisible(state);
  const licenseId = getLicenseId(state);
  const licenseType = getSubscriptionType(state);
  const plan = getPlanType(state);

  if (!myProfile) {
    return;
  }

  setUserIdForAmplitude(myProfile.login);

  setUserPropertiesForAmplitude({
    [AmplitudeUserProperty.AccountType]: licenseType,
    [AmplitudeUserProperty.License]: licenseId,
    [AmplitudeUserProperty.Permission]: myProfile.permission,
    [AmplitudeUserProperty.Plan]: plan,
    [AmplitudeUserProperty.Sentiment]: isChatListSentimentVisible,
    [AmplitudeUserProperty.Theme]: currentTheme,
  });
};

App.login = async function login(this: IApplicationCore): Promise<void> {
  if (this.tokenInUrlFragment()) {
    const accessToken = getHashParam('access_token');
    const scopes = getHashParam('scope');
    const expiresIn = getHashParam('expires_in');
    const isGhost = getHashParam('frenzied') === '1';

    const shouldSaveCookies = !isGhost;
    storeCredentials({ token: accessToken, scopesString: scopes, shouldSaveCookies, expiresIn });

    await this.loginWithAccessToken(accessToken, isGhost);

    return;
  }

  if (!(await this.loginWithCookies())) {
    redirectToAccounts();
  }
};

App.preInit = function preInit(this: IApplicationCore): void {
  saveLog('info', 'Bonjour, le monde!');

  deleteAllCookiesExceptRoot();
  enableBusinessEventsLogging();
  loadAppFont();

  // generate random string as ID of current instance of application
  window.instanceID = uniqueId();

  this.platformProtocolParser = new PlatformProtocolParser(config);

  this.server = new ServerAccessPlatform(this.platformProtocolParser, config);

  TraceKit.report.subscribe((stack) => {
    if (this.SmartClient) {
      this.SmartClient.saveAdvancedLog(stack as unknown as string);
    }
  });

  this.SmartClient = new DesktopApplication(config);

  if (App.SmartClient.isDetected()) {
    config.serverSocketOptions.customParams += `&application=SmartClient/${capitalizeFirstLetter(
      App.SmartClient.getPlatform(),
    )}`;

    const desktopApplicationVersion = App.SmartClient.getVersion();
    if (desktopApplicationVersion !== null) {
      config.serverSocketOptions.customParams += `&version=${desktopApplicationVersion}`;
    }
  }

  this.initGoogleAnalytics();
  this.setupGTM();

  // Support chat is now loaded via script tag in index.ejs view
  // placing script tag there simplifies JS API usage
  ChatWidget.setUpCallbacks();
};

App.init = async function init(this: IApplicationCore): Promise<void> {
  const state = store.getState();
  const currentAgentLogin = getLoggedInAgentLogin(state);
  const licenseId = getLicenseId(state)?.toString();

  sentryContext.setContext({
    login: currentAgentLogin,
    license: licenseId,
    plan: getPlanType(state),
  });

  if (this.INITED) {
    return;
  }

  this.INITED = true;

  const api: IAppApi = {
    support: {
      getStore: () => store,
      downloadCurrentState: downloadState,
      downloadBugReport,
    },
    navigate: (pathname: string) => {
      navigate(pathname);
    },
    getLicense() {
      return getLicenseId(store.getState())?.toString();
    },
    isPaidLicense() {
      return getIsPaidLicense(store.getState());
    },
    getSalesPlan() {
      return getPlanType(store.getState());
    },
    getConfig,
    isGhostLogin,
    setReportsFilters: (filters: IReportsViewUpdateFiltersPayload) => {
      store.dispatch(ReportsViewActions.setFilters(filters));
    },
  };
  this.api = api;

  void fetchAndStoreAccountsAndRoles();

  ChatWidget.updateCustomerDetails();

  store.dispatch(AgentCustomPropertiesActions.fetchMertics());

  const groupId = getUrlParam('group') || GENERAL_GROUP_ID;
  const language = getGroupLanguageCode(store.getState(), groupId);

  store.dispatch(SettingsViewActions.setSelectedGroupId(groupId || GENERAL_GROUP_ID));
  store.dispatch(LanguageViewActions.setCurrentLanguageCode({ languageCode: language as LanguageCode }));
  store.dispatch(ApplicationsActions.fetchCollection({}));

  initializeQueryClient();
  setHighchartTheme();

  const { result } = await ApiManager.ticketApi.fetchCounters();
  const itemId = BaseRouteName.Tickets;

  if (result) {
    const badge = { numeric: result.badge_count };

    store.dispatch(
      TicketsViewActions.updateTicketCounters({
        unassigned: result.unassigned_tickets,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'my-open': result.open_tickets,
      }),
    );

    store.dispatch(
      badge.numeric
        ? NavigationViewActions.setNavigationItemBadge({ itemId, badge })
        : NavigationViewActions.clearNavigationItemBadge({ itemId }),
    );
  }

  this.render();

  this.removeTicketParamsFromUrl();
  store.dispatch({ type: 'APP_READY' });

  if (isDevelopmentEnvironment()) {
    /**
     * The 'load' event is sometimes not detected by Playwright or Percy. This causes tests to hang indefinitely until test timeout is reached.
     * Fire event maunally to avoid this issue.
     */
    dispatchEvent(new Event('load'));
  }
};

App.tokenInUrlFragment = (): boolean => !!getHashParam('access_token');

App.loginWithCookies = async function loginWithCookies(this: IApplicationCore): Promise<boolean> {
  loadCredentialsFromCookies();

  const accessToken = getAccessToken();
  if (accessToken) {
    const isGhost = false;
    await this.loginWithAccessToken(accessToken, isGhost);

    return true;
  }

  return false;
};

App.removeTicketParamsFromUrl = function removeTicketParamsFromUrl(): void {
  const queryBefore = window.location.search;

  let queryAfter = queryBefore;
  queryAfter = queryAfter.replace(/&?login=([^&]$|[^&]*)/i, '');
  queryAfter = queryAfter.replace(/&?ticket=([^&]$|[^&]*)/i, '');
  queryAfter = queryAfter.replace(/&?authority=([^&]$|[^&]*)/i, '');
  queryAfter = queryAfter.replace(/^\?$/, '');

  if (queryAfter !== queryBefore) {
    navigate(window.location.pathname + queryAfter);
  }
};

App.visitInfoPage = function visitInfoPage(url: string): void {
  if (!isMainLayoutRendered()) {
    renderMainLayout();
  }

  App.isRendered = true;

  const targetUrl = url.endsWith('/') ? url.substring(-1) : url;

  navigate(targetUrl);
};

App.destroy = async function destroy(
  this: IApplicationCore,
  options: Partial<{
    remote: boolean;
    licenseBlocked: boolean;
    manual: boolean;
    currentOnly: boolean;
    sessionsList: ISession[];
    currentSession: { sessionId: string };
  }> = {},
): Promise<void> {
  clearSocketActivity();

  const getSignoutReason = (): SignoutReason | null => {
    if (options.remote) {
      return SignoutReason.SignedOutRemotely;
    }
    if (options.licenseBlocked) {
      return SignoutReason.LicenseBlocked;
    }

    return null;
  };

  saveLog('info', `App reset! State: ${getSignoutReason()}`);

  const redirectOutUnexpectedly = (): void => {
    redirectToAccountsSignout(getSignoutReason());
  };

  const redirectOutNormally = (): void => {
    endSession();
    const rootUrl = getAppRoot();
    const rootPath = rootUrl.startsWith('/') ? rootUrl : `/${rootUrl}`;
    redirectTo(`${rootPath}keep-on-chatting-with-customers`);
  };

  if (options.manual) {
    this.manualLogout = true;

    await logoutFromServer();
    if (options.currentOnly && options.sessionsList) {
      if (!options.currentSession) {
        redirectOutNormally();
      } else {
        try {
          await removeSession(options.currentSession.sessionId);
          redirectOutNormally();
        } catch {
          redirectOutUnexpectedly();
        }
      }
    } else {
      try {
        await removeAllSessions();
        redirectOutNormally();
      } catch {
        redirectOutUnexpectedly();
      }
    }
  }
  // redirect to signout only if user is not logging out manually
  // - in that case skip it because other redirection is already in place
  if (!this.manualLogout) {
    redirectOutUnexpectedly();
  }
};

App.loginWithAccessToken = async function loginWithAccessToken(
  this: IApplicationCore,
  accessToken: string,
  isGhost: boolean,
): Promise<void> {
  store.dispatch(SessionActions.updateRights());
  store.dispatch(SessionActions.saveGhostStatus({ isGhost }));
  store.dispatch(ExperimentsActions.fetchExperiments());

  await connectAndLogin({
    accessToken,
    isGhost,
  });
};

App.getAccessToken = function (this: IApplicationCore): string {
  return getAccessToken();
};

App.logout = function logout(this: IApplicationCore): void {
  document.removeEventListener('keydown', handleKeyDown);
  void logoutFromServer();
};

App.loggedOut = function loggedOut(this: IApplicationCore): void {
  EventBus.emit(EventNames.UserLoggedOut);
};

document.addEventListener('DOMContentLoaded', () => {
  App.preInit();

  const path = window.location.pathname;
  loadCredentialsFromCookies();
  const accessToken = getAccessToken();

  if (
    (!accessToken && /\/keep-on-chatting-with-customers$/.test(path)) ||
    /\/ticket_rating\/(.*)\/(.*)\/(.*)$/.test(path)
  ) {
    App.visitInfoPage(path);
  } else {
    void App.login();
  }

  const redirectToUrl = extractRedirectTo(getHashParam('state'));
  if (redirectToUrl) {
    redirectTo(`${window.location.protocol}//${window.location.host}${redirectToUrl}`);

    return;
  }

  void loadDevTools();

  EventBus.on(EventNames.UserLoggedOut, (options: Events[EventNames.UserLoggedOut] = {}) => {
    void App.destroy({ remote: !options.manual, ...options });
  });
});

window.App = App;
