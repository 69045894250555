import type { FC } from 'react';

import { Modal, ModalPortal } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { CannotBuyAlert } from './Alert';
import { RequestBuyModalFooter } from './Footer';
import { RequestBuyModalHeader } from './Header';
import { ItemSummary } from './ItemSummary';

import * as styles from './styles';

export const RequestBuyModal: FC = () => {
  const { closeModal } = useOneClickPaymentContext();

  return (
    <ModalPortal zIndex={StackingContextLevel.FullOverlay}>
      <Modal
        className={styles.modal}
        fullSpaceContent
        onClose={closeModal}
        heading={<RequestBuyModalHeader />}
        footer={<RequestBuyModalFooter />}
      >
        <CannotBuyAlert />
        <ItemSummary />
      </Modal>
    </ModalPortal>
  );
};
