import { type PickRequired } from 'helpers/interface';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type SetCopilotSessionIdPayload,
  type CopilotEvent,
  type CopilotOnboardingActionPayload,
  type CopilotAddConversationPayload,
} from './interfaces';

export enum CopilotEntitiesActionNames {
  ADD_COPILOT_MESSAGE = 'ENTITIES/CHATS/ADD_COPILOT_MESSAGE',
  ADD_COPILOT_ERROR = 'ENTITIES/CHATS/ADD_COPILOT_ERROR',
  CLEAR_COPILOT_ERROR = 'ENTITIES/CHATS/CLEAR_COPILOT_ERROR',
  SEND_COPILOT_MESSAGE = 'ENTITIES/CHATS/SEND_COPILOT_MESSAGE',
  SET_COPILOT_SESSION_ID = 'ENTITIES/CHATS/SET_COPILOT_SESSION_ID',
  CANCEL_COPILOT_REQUEST = 'ENTITIES/CHATS/CANCEL_COPILOT_REQUEST',
  SET_FAILED_COPILOT_MESSAGE = 'ENTITIES/CHATS/SET_FAILED_COPILOT_MESSAGE',
  CLEAR_FAILED_COPILOT_MESSAGE = 'ENTITIES/CHATS/CLEAR_FAILED_COPILOT_MESSAGE',
  RETRY_FAILED_COPILOT_MESSAGE = 'ENTITIES/CHATS/RETRY_FAILED_COPILOT_MESSAGE',
  CONTINUE_COPILOT_ONBOARDING = 'ENTITIES/CHATS/CONTINUE_COPILOT_ONBOARDING',
  UPDATED_COPILOT_ACTION_BUTTONS = 'ENTITIES/CHATS/UPDATED_COPILOT_ACTION_BUTTONS',
  COPILOT_MESSAGE_SPOTLIGHT = 'ENTITIES/CHATS/COPILOT_MESSAGE_SPOTLIGHT',
  COPILOT_ONBOARDING_ACTION = 'ENTITIES/CHATS/COPILOT_ONBOARDING_ACTION',
  COPILOT_ADD_CONVERSATION = 'ENTITIES/CHATS/COPILOT_ADD_CONVERSATION',
  SET_LATEST_AGENT_EVENT_ID = 'ENTITIES/CHATS/SET_LATEST_AGENT_EVENT_ID',
}

export const CopilotEntitiesActions = {
  addCopilotMessage: (payload: CopilotEvent) => createAction(CopilotEntitiesActionNames.ADD_COPILOT_MESSAGE, payload),
  addCopilotError: (payload: boolean) => createAction(CopilotEntitiesActionNames.ADD_COPILOT_ERROR, payload),
  clearCopilotError: () => createAction(CopilotEntitiesActionNames.CLEAR_COPILOT_ERROR),
  sendCopilotMessage: (payload: string) => createAction(CopilotEntitiesActionNames.SEND_COPILOT_MESSAGE, payload),
  setCopilotSessionId: (payload: SetCopilotSessionIdPayload) =>
    createAction(CopilotEntitiesActionNames.SET_COPILOT_SESSION_ID, payload),
  cancelCopilotRequest: () => createAction(CopilotEntitiesActionNames.CANCEL_COPILOT_REQUEST),
  setFailedCopilotMessage: (payload: string) =>
    createAction(CopilotEntitiesActionNames.SET_FAILED_COPILOT_MESSAGE, payload),
  clearFailedCopilotMessage: () => createAction(CopilotEntitiesActionNames.CLEAR_FAILED_COPILOT_MESSAGE),
  retryFailedCopilotMessage: () => createAction(CopilotEntitiesActionNames.RETRY_FAILED_COPILOT_MESSAGE),
  continueCopilotOnboarding: (step?: string) =>
    createAction(CopilotEntitiesActionNames.CONTINUE_COPILOT_ONBOARDING, step),
  copilotOnboardingAction: (payload: CopilotOnboardingActionPayload) =>
    createAction(CopilotEntitiesActionNames.COPILOT_ONBOARDING_ACTION, payload),
  updateCopilotEvent: (payload: PickRequired<CopilotEvent, 'eventId'>) =>
    createAction(CopilotEntitiesActionNames.UPDATED_COPILOT_ACTION_BUTTONS, payload),
  setCopilotMessageSpotlight: (payload: boolean) =>
    createAction(CopilotEntitiesActionNames.COPILOT_MESSAGE_SPOTLIGHT, payload),
  addCopilotConversation: (payload: CopilotAddConversationPayload) =>
    createAction(CopilotEntitiesActionNames.COPILOT_ADD_CONVERSATION, payload),
  setLatestAgentEventId: (payload: PickRequired<CopilotEvent, 'eventId'>) =>
    createAction(CopilotEntitiesActionNames.SET_LATEST_AGENT_EVENT_ID, payload),
};

export type CopilotEntitiesAction = ActionsUnion<typeof CopilotEntitiesActions>;
