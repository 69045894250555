import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

import { mobileDeviceMediaQuery } from 'helpers/media-queries';

export const fieldGroup = css`
  margin: 0;
  align-items: flex-start;
  gap: var(${SpacingToken.Spacing4});
`;

export const responsiveGroup = css`
  ${fieldGroup};

  gap: var(${SpacingToken.Spacing4});

  @media (max-width: 432px) {
    gap: 0;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const actionsRow = css`
  display: flex;
  justify-content: space-between;
`;

export const toggleExtendedFormButton = css`
  display: flex;
  align-items: center;
  ${mobileDeviceMediaQuery} {
    display: none;
  }
`;

export const buttonsRow = css`
  display: flex;
  justify-content: end;
  gap: var(${SpacingToken.Spacing2});
`;

export const invoiceDetailsTitle = css`
  margin: var(${SpacingToken.Spacing5}) 0px;
`;

export const cardForm = css`
  width: 100%;
  flex-shrink: 0;

  [class*='field-error'] {
    > span {
      display: none;
    }
  }

  .extra-fields-enter {
    height: 0;
    opacity: 0;
  }
  .extra-fields-enter-active {
    height: 381px;
    opacity: 1;
    transition: all 200ms ease-out;
  }
  .extra-fields-exit {
    height: 381px;
    opacity: 1;
  }
  .extra-fields-exit-active {
    height: 0;
    opacity: 0;
    transition: all 200ms ease-out;
  }
`;
