import { useState, type FC } from 'react';

import { DesignToken, Picker, Text, type IPickerListItem } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'components/one-click-payment/hooks/use-one-click-payment-context';
import { ChargeType } from 'constants/one-click-payment';
import { withVar } from 'helpers/styles';

import { BILLING_OPTIONS } from './constants';

const optionsList: IPickerListItem[] = Object.values(BILLING_OPTIONS);

const getDefaultOption = (months: number): IPickerListItem[] => {
  const option = optionsList.find((item) => Number(item.key) === months) || BILLING_OPTIONS.monthly;

  return [option];
};

export const BillingCyclePicker: FC = () => {
  const { charge, isUpdating, paymentIntent, updateBillingCycle } = useOneClickPaymentContext();

  const chargeMonths = charge?.months || 1;
  const isDirectCharge = paymentIntent?.metadata.type === ChargeType.DirectCharge;

  const [selectedOption, setSelectedOption] = useState(getDefaultOption(chargeMonths));

  if (!charge || isDirectCharge) {
    return null;
  }

  const handleBillingCycleChange = (selectedItems: IPickerListItem[] | null): void => {
    if (!selectedItems || selectedItems[0].key === selectedOption[0].key) {
      return;
    }

    const months = selectedItems[0].key === BILLING_OPTIONS.annually.key ? 12 : 1;

    setSelectedOption(selectedItems);
    updateBillingCycle(months);
  };

  return (
    <div>
      <Picker
        id="billing-cycle"
        hideClearButton
        searchDisabled
        options={optionsList}
        selected={selectedOption}
        onSelect={handleBillingCycleChange}
        disabled={isUpdating}
      />
      {selectedOption[0].key === BILLING_OPTIONS.monthly.key && (
        <Text size="sm" customColor={withVar(DesignToken.ActionPositiveDefault)}>
          Save 15% with annual
        </Text>
      )}
    </div>
  );
};

export default BillingCyclePicker;
