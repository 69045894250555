// @ts-strict-ignore
import { createSelector } from 'reselect';

import { Platform } from 'constants/app-platform';
import { Permission } from 'constants/permission';
import { getAppPlatform } from 'helpers/browser';
import { shouldHaveMobileOnboardingSteps } from 'helpers/device';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { type IApplication } from 'store/entities/applications/interfaces';
import { getInstalledIntegrations } from 'store/entities/applications/selectors';
import { getOnboardingIntegrations } from 'store/entities/integration-license-properties/selectors';
import { getPaymentErrorOnAccessBlockedForLicense } from 'store/entities/license-custom-properties/selectors';
import {
  getPaymentErrorOnAccessBlockedForAgent,
  getUtmSource,
  isDesktopAppDisallowedByLicenseSource,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';
import { getIsOneSeatStarter, getIsPPSBusinessModel } from 'store/features/session/selectors';

import { supportedIntegrations } from './constants';
import { prepareOnboardingSteps } from './helpers';
import type { IOnboardingState } from './interfaces';

interface WithOnboardingState {
  views: {
    onboarding: IOnboardingState;
  };
}

export const getIsSupportedIntegration = (state: IWithAgentCustomPropertiesState): boolean =>
  supportedIntegrations.includes(getUtmSource(state));

export const getIsOnboardingFinished = (state: WithOnboardingState): boolean =>
  state.views.onboarding.isOnboardingFinished;

export const getIsShowingInviteDeveloperForm = (state: WithOnboardingState): boolean =>
  state.views.onboarding.isShowingInviteDeveloperForm;

export const getIsCompanySizeDetected = (state: WithOnboardingState): boolean =>
  state.views.onboarding.isCompanySizeDetected;

export const getIsInstallationStepSkipped = (state: WithOnboardingState): boolean =>
  state.views.onboarding.isInstallationStepSkipped;

export const getIsRefetchTechnologies = (state: WithOnboardingState): boolean =>
  state.views.onboarding.isRefetchTechnologies;

let initialIntegrations: IApplication[] | string | null = null;

export const getSteps = createSelector(
  getIsSupportedIntegration,
  getLoggedInAgent,
  isDesktopAppDisallowedByLicenseSource,
  getIsPPSBusinessModel,
  getIsCompanySizeDetected,
  getIsInstallationStepSkipped,
  getIsOneSeatStarter,
  getPaymentErrorOnAccessBlockedForLicense,
  getPaymentErrorOnAccessBlockedForAgent,
  getInstalledIntegrations,
  getOnboardingIntegrations,
  () => location.pathname,
  (
    isSupportedIntegration,
    loggedInUser,
    isDesktopAppDisallowed,
    isPPS,
    isCompanySizeDetected,
    isInstallationStepSkipped,
    isOneSeatStarter,
    paymentErrorOnAccessBlocked,
    paymentErrorOnAccessBlockedForAgent,
    installedIntegrations,
    onboardingIntegrations,
    currentRoute,
  ) => {
    if (initialIntegrations === null) {
      initialIntegrations = installedIntegrations || onboardingIntegrations;
    }

    const platform = getAppPlatform();
    const isSupportedPlatform = platform === Platform.Mac || platform === Platform.Windows;

    const isOwner = loggedInUser.permission === Permission.Owner;
    const isAgent = loggedInUser.permission === Permission.Normal;
    const isPaymentError = paymentErrorOnAccessBlocked || paymentErrorOnAccessBlockedForAgent;

    const includeIntegrationsStep =
      !isAgent && !isSupportedIntegration && (initialIntegrations.length === 0 || initialIntegrations === '');

    const includeDownloadDesktopAppStep =
      isSupportedIntegration && !shouldHaveMobileOnboardingSteps() && !isDesktopAppDisallowed && isSupportedPlatform;

    const includeDownloadMobileAppStep = shouldHaveMobileOnboardingSteps();

    const includeDownloadStep = includeDownloadDesktopAppStep || includeDownloadMobileAppStep;

    const includeInstallStep = !isSupportedIntegration && !isInstallationStepSkipped;
    const includeTeamStep = !(isAgent && isPPS) && !isOneSeatStarter && !isPaymentError;
    const includeCompanySizeStep = isOwner && !isCompanySizeDetected;
    const includeWebsiteStep = isOwner;

    const steps = prepareOnboardingSteps(
      includeDownloadStep,
      includeInstallStep,
      includeTeamStep,
      includeIntegrationsStep,
      includeWebsiteStep,
      includeCompanySizeStep,
    );

    return steps.map((step, index) => ({
      ...step,
      completed: steps.findIndex((s) => currentRoute.includes(s.route)) >= index,
    }));
  },
);
