// @ts-strict-ignore
import { App } from 'config/setup';
import { LoginErrorEvent } from 'constants/login-error-event';
import { type PlanFeature } from 'constants/plan-features';
import { DisplayPlanType, type PlanType } from 'constants/plan-type';
import { PostMessageEvent } from 'constants/post-message-event';
import { Section } from 'constants/section';
import { EventPlace, trackGTMEvent } from 'helpers/analytics';
import { updateQueryString } from 'helpers/url';
import { AppStateProvider } from 'services/app-state-provider';
import { trackEvent } from 'services/event-tracking';
import { historyService, type NavigateOptions } from 'services/history';
import { StartupErrorActions } from 'store/views/startup-error/actions';

import { isDesktopDevice } from './device';
import { errorToString } from './error-to-string';
import { isNonProfit } from './is-non-profit';
import { sendPostMessageToMarketplace } from './post-message';
import { TrackingEvent } from './tracking-events';

export function navigate(path: string, options?: NavigateOptions): void {
  void historyService.navigate(path, options);
}

/**
 * Redirect to the StartupError page
 */
export function redirectToStartupError(error: unknown): void {
  /*
   * There are 4 places from where we redirect to StartupError. We don't need to track each place where redirect happened (less amplitude events generated).
   * Therefore I place trackEvent in this function and set StartupError as event place.
   * This way we will track only moments when page is opened due to an error and not manually by entering URL (also, less amplitude events).
   */
  const url = '/startup-error';
  const message = errorToString(error);

  trackEvent(TrackingEvent.UserRedirected, EventPlace.StartupError, { message });
  trackGTMEvent(TrackingEvent.UserRedirected, { eventPlace: EventPlace.StartupError });

  AppStateProvider.dispatch(StartupErrorActions.setUnexpectedError({ isVisible: true }));

  navigate(message ? updateQueryString(url, 'error', message) : url);
}

export function redirectToSessionAbuse(): void {
  navigate('/access-blocked');
}

export function redirectToSignOut(reason: string): void {
  trackEvent(TrackingEvent.UserRedirected, EventPlace.SignOut, { reason });
  trackGTMEvent(TrackingEvent.UserRedirected, { eventPlace: EventPlace.SignOut, reason });
  navigate(updateQueryString('/signout', 'reason', reason));
}

export function redirectToLicenseExpired(): void {
  trackEvent(LoginErrorEvent.LoginError, null, {
    reason: 'license_expired',
    type: 'licenseExpired',
  });

  trackGTMEvent(LoginErrorEvent.LoginError, {
    reason: 'license_expired',
    type: 'licenseExpired',
  });

  App.visitInfoPage('/license-expired');
}

export function redirectToSubscription(): void {
  App.visitInfoPage(`/subscription${window.location.search}`);
}

const openLink = (url: string, { targetBlank = true }): void => {
  const temporaryAnchor = document.createElement('a');
  temporaryAnchor.setAttribute('href', url);

  if (targetBlank) {
    temporaryAnchor.setAttribute('target', '_blank');
  }

  temporaryAnchor.setAttribute('rel', 'nofollow noreferrer noopener');
  temporaryAnchor.click();
};

export const openInCard = (url: string): void => openLink(url, { targetBlank: false });
export const openInNewCard = (url: string): void => openLink(url, { targetBlank: true });

export function getSectionFromPathname(pathname: string): Section | null {
  const sanitizedPathname = pathname.replace(/^\/+/g, '');

  return Object.values(Section).reduce(
    (previousSection, section) =>
      sanitizedPathname === section.toString() ||
      (sanitizedPathname.startsWith(section) && section.length > (previousSection?.length || 0))
        ? section
        : previousSection,
    null,
  );
}

export function extractRouteFromPathname(pathname: string): string {
  if (!pathname) {
    return null;
  }

  const pathItems = pathname.split('/').filter(Boolean);

  if (!pathItems.length) {
    return null;
  }

  return pathItems.pop().split('?')[0].split('#')[0];
}

export function setTitle(title: string): void {
  const documentTitle = isNonProfit() ? `${title} | LiveChat for Nonprofits` : title;
  document.title = documentTitle;
}

export function getHomePage(): string {
  return isDesktopDevice() ? '/home' : '/chats';
}

export const prepareUpgradeFeatureUrl = (plan: PlanType, feature?: PlanFeature | PlanFeature[]): string => {
  const prefix = '/subscription/upgrade';

  if (feature && feature.length > 0) {
    if (Array.isArray(feature)) {
      const features = feature.join(',');

      return `${prefix}?recommended=${DisplayPlanType[plan]}&highlighted=${features}`;
    }

    return `${prefix}?recommended=${DisplayPlanType[plan]}&highlighted=${feature}`;
  }

  return `${prefix}?recommended=${DisplayPlanType[plan]}`;
};

export function navigateToUpgradeFeature(plan: PlanType, feature?: PlanFeature | PlanFeature[]): void {
  const url = prepareUpgradeFeatureUrl(plan, feature);
  navigate(url);
}

export const redirectoSalesTaxHelpCenter = (): void => openInNewCard('https://www.livechat.com/help/sales-tax/');

export const normalizeMarketplacePath = (path: string): string => {
  // Ensure the path starts with /apps/marketplace and remove extra slashes manually
  const normalizedPath = path.startsWith('/apps/marketplace') ? path : `/apps/marketplace${path}`;

  return normalizedPath.replace('//', '/');
};

export const navigateToMarketplace = (path = '/apps/marketplace/apps'): void => {
  const normalizedPath = normalizeMarketplacePath(path);

  if (!window.location.pathname.includes('/apps/marketplace')) {
    sendPostMessageToMarketplace(PostMessageEvent.Navigate, { pathname: normalizedPath, replace: true });

    return navigate(normalizedPath);
  }

  sendPostMessageToMarketplace(PostMessageEvent.Navigate, { pathname: normalizedPath });
};
