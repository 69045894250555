import { type ServerError } from 'constants/server-error';

import { type StartupErrorViewState } from './interfaces';

export interface WithStartupErrorViewState {
  views: {
    startupError: StartupErrorViewState;
  };
}

export function getIsUnexpectedStartupErrorVisible(state: WithStartupErrorViewState): boolean {
  return state.views.startupError.isUnexpectedErrorVisible;
}

export function getSignInError(state: WithStartupErrorViewState): ServerError | undefined {
  return state.views.startupError.signInError;
}
