import { useCallback, useEffect, useRef, useState } from 'react';

import { MARKETPLACE_IFRAME_ID } from 'constants/apps/marketplace';
import { PaymentStatus } from 'constants/billing';
import { ChargeType } from 'constants/one-click-payment';
import { IncomingOneClickPaymentEvent, OutgoingOneClickPaymentEvent } from 'constants/post-message-event';
import { JSONParse } from 'helpers/json';
import { sendPostMessageToIframe } from 'helpers/post-message';
import { useDeclineDirectCharge } from 'hooks/api/billing/use-decline-direct-charge';
import { useDeclineRecurrentCharge } from 'hooks/api/billing/use-decline-recurrent-charge';
import { type Charge, type PaymentIntent } from 'interfaces/one-click-payment';
import type { OneClickPaymentIncomingPostMessage, OneClickPaymentOutgoingPostMessage } from 'interfaces/post-message';
import { type IConnection } from 'services/widget/connection/connection';
import { type IInboxMessage } from 'services/widget/message/inbox/inbox-message';

import { getWidgetConnection } from '../helpers';

type Props = {
  onModalOpen: () => void;
  onModalClose: () => void;
  targetOrigin: string;
  iframeId: string;
};

type UseOneClickPaymentPostMessageEvents = {
  isLoading: boolean;
  isUpdating: boolean;
  isProcessable: boolean;
  error: string | null;
  paymentIntent: PaymentIntent | null;
  charge: Charge | null;
  closeModal: () => void;
  onPaymentModalClose: () => void;
  updateBillingCycle: (billingCycle: number) => void;
  sendPostMessage: (
    eventName: OutgoingOneClickPaymentEvent,
    data?: OneClickPaymentOutgoingPostMessage['event_data'],
  ) => void;
};

export const useOneClickPaymentPostMessageEvents = ({
  onModalOpen,
  onModalClose,
  targetOrigin,
  iframeId,
}: Props): UseOneClickPaymentPostMessageEvents => {
  const connectionRef = useRef<IConnection | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null);
  const [charge, setCharge] = useState<Charge | null>(null);

  const { declineRecurrentCharge } = useDeclineRecurrentCharge();
  const { declineDirectCharge } = useDeclineDirectCharge();

  const declineCharge = useCallback(() => {
    if (!paymentIntent || !charge || charge?.status !== PaymentStatus.Pending) {
      return;
    }

    if (paymentIntent.metadata.type === ChargeType.RecurrentCharge) {
      declineRecurrentCharge(charge.id);
    } else {
      declineDirectCharge(charge.id);
    }
  }, [charge, paymentIntent, declineRecurrentCharge, declineDirectCharge]);

  const sendPostMessage = useCallback(
    (eventName: OutgoingOneClickPaymentEvent, data?: OneClickPaymentOutgoingPostMessage['event_data']) => {
      sendPostMessageToIframe<OutgoingOneClickPaymentEvent, OneClickPaymentOutgoingPostMessage['event_data']>({
        eventName,
        data,
        iframeId,
        targetOrigin,
      });

      if (connectionRef?.current) {
        void connectionRef.current.send({ title: eventName, data, pluginId: connectionRef.current.connectionId });
      }
    },
    [iframeId, targetOrigin],
  );

  const onPaymentModalClose = useCallback(() => {
    setPaymentIntent(null);
    setCharge(null);
    setError(null);
    setIsLoading(false);

    onModalClose();
  }, [onModalClose]);

  const closeModal = useCallback(() => {
    onPaymentModalClose();

    sendPostMessage(OutgoingOneClickPaymentEvent.DeclineTransaction);
    declineCharge();
  }, [onPaymentModalClose, sendPostMessage, declineCharge]);

  const updateBillingCycle = useCallback(
    (billingCycle: number) => {
      setIsLoading(true);

      if (paymentIntent) {
        sendPostMessage(OutgoingOneClickPaymentEvent.UpdateBillingCycle, {
          paymentIntent,
          billingCycle,
        });
      }
    },
    [paymentIntent, sendPostMessage],
  );

  const handleEvents = useCallback(
    (data: OneClickPaymentIncomingPostMessage): void => {
      switch (data.event_name) {
        case IncomingOneClickPaymentEvent.RegisterTransactionPending: {
          const { paymentIntent } = data.event_data;

          setError(null);
          setIsLoading(true);
          setPaymentIntent(paymentIntent);
          onModalOpen();
          break;
        }

        case IncomingOneClickPaymentEvent.RegisterTransactionSuccess: {
          const { charge } = data.event_data;

          setError(null);
          setCharge(charge);
          setIsLoading(false);
          break;
        }

        case IncomingOneClickPaymentEvent.RegisterTransactionFailure: {
          const { error } = data.event_data;

          setError(error);
          setIsLoading(false);
          break;
        }
      }
    },
    [onModalOpen],
  );

  useEffect(() => {
    if (iframeId === MARKETPLACE_IFRAME_ID) {
      return;
    }

    let connection: IConnection | null = null;

    const handleEventsSubscriber = (event: IInboxMessage): void => {
      handleEvents({
        /* eslint-disable @typescript-eslint/naming-convention */
        event_name: event.title,
        event_data: event.data,
        /* eslint-enable @typescript-eslint/naming-convention */
      } as OneClickPaymentIncomingPostMessage);
    };

    const connectToWidgetEvents = async (): Promise<void> => {
      connection = await getWidgetConnection(iframeId);
      if (!connection) return;

      connectionRef.current = connection;
      connection.subscribe(handleEventsSubscriber);
    };

    if (!connectionRef?.current) {
      void connectToWidgetEvents();
    }

    return () => {
      if (connection) {
        connection.unsubscribe(handleEventsSubscriber);
        connectionRef.current = null;
      }
    };
  }, [iframeId, handleEvents]);

  useEffect(() => {
    const receivePostMessage = (event: MessageEvent<string>): void => {
      if (event.origin !== targetOrigin) return;
      const data = JSONParse<OneClickPaymentIncomingPostMessage>(event?.data);
      if (!data) return;
      handleEvents(data);
    };

    window.addEventListener('message', receivePostMessage);

    return () => {
      window.removeEventListener('message', receivePostMessage);
    };
  }, [targetOrigin, handleEvents]);

  return {
    isLoading: isLoading && !charge,
    isUpdating: isLoading && !!charge,
    isProcessable: charge?.status === PaymentStatus.Pending,
    error,
    paymentIntent,
    charge,
    closeModal,
    onPaymentModalClose,
    updateBillingCycle,
    sendPostMessage,
  };
};
