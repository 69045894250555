// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-return */

import debug from 'debug';
import map from 'lodash.map';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type PlanType } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { Route } from 'constants/tickets/navigation-items';
import type { ITicket } from 'interfaces/entities/ticket';
import { calculateFirstChat } from 'services/activity/helpers/calculate-first-chat';
import { updateFirstChatDate } from 'services/chat-widget';
import { BillingProperty, StatisticsProperty } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { IncomingMulticastEventName } from 'services/socket-lc3/chat/event-handling/constants';
import { handleJSONIncomingEvent as handleLC3JSONIncomingEvent } from 'services/socket-lc3/socket-event-handler';
import { ApplicationsActions } from 'store/features/applications/actions';
import { SessionActions } from 'store/features/session/actions';
import { store } from 'store/store';
import { TicketsViewActions } from 'store/views/tickets/actions';
import type { ITicketsViewUpdateTicketCountersPayload } from 'store/views/tickets/interfaces';
import { TrafficActions } from 'store/views/traffic/actions';

const log = debug(DebugLogsNamespace.AppLc3JsonProtocol);

export class JsonProtocol {
  config: any;

  constructor(config) {
    this.config = config;
  }

  performCommand(response): any {
    log(`Incoming command ${response.name}`);

    handleLC3JSONIncomingEvent(response.name, response);

    switch (response.name) {
      case IncomingMulticastEventName.AccountBlocked:
        return this.accountBlocked();
      case IncomingMulticastEventName.IntegrationEnable:
        return this.integrationEnable(response.integration);
      case IncomingMulticastEventName.IntegrationDisable:
        return this.integrationDisable(response.integration);
      case IncomingMulticastEventName.TicketUpdate:
        return JsonProtocol.ticketUpdate(response.tickets);
      case IncomingMulticastEventName.TicketSummaryUpdate:
        return JsonProtocol.ticketSummaryUpdate(response);
      case IncomingMulticastEventName.LicensePropertiesUpdate:
        return this.licensePropertiesUpdate(response.properties);
      case IncomingMulticastEventName.BanListUpdate:
        return JsonProtocol.banListUpdated();
    }
  }

  accountBlocked(): void {
    void App.destroy({ licenseBlocked: true });
    App.logout();
  }

  integrationEnable(integrationJSON) {
    store.dispatch(ApplicationsActions.installApplicationSuccessful(integrationJSON));
  }

  integrationDisable(integrationJSON) {
    store.dispatch(ApplicationsActions.uninstallApplicationSuccessful({ id: integrationJSON.id }));
  }

  decodePrechatSurvey(prechat) {
    if (prechat.decodedAlready) {
      return prechat;
    }

    return map(prechat, function (fieldObject: any) {
      fieldObject.value = (function () {
        try {
          return decodeURIComponent(fieldObject.value);
        } catch (error) {
          return fieldObject.value;
        }
      })();

      return fieldObject;
    });
  }

  static ticketUpdate(ticketsJSON: ITicket[]): void {
    store.dispatch(TicketsViewActions.onTicketsUpdated(ticketsJSON.map((ticket) => ticket.id)));
  }

  static ticketSummaryUpdate(response): void {
    if (response.unassigned_tickets != null || response.open_tickets != null) {
      const summary: any = {};
      const actionPayload: ITicketsViewUpdateTicketCountersPayload = {};
      if (response.unassigned_tickets != null) {
        summary.unassigned_tickets = response.unassigned_tickets;
        actionPayload[Route.Unassigned] = response.unassigned_tickets;
      }
      if (response.open_tickets != null) {
        summary.open_tickets = response.open_tickets;
        actionPayload[Route.MyOpen] = response.open_tickets;
      }

      store.dispatch(TicketsViewActions.updateTicketCounters(actionPayload));
    }
  }

  licensePropertiesUpdate(properties) {
    // Configuration API does not support push events for license properties, therefore, we're still using
    // legacy REST API push implementation to save sales plan license property when it's updated
    // TODO: move these updates to the new "license property updated" push that will be created by api team at some point
    const salesPlanLicenseProperty: PlanType = properties[BillingProperty.SalesPlan];

    if (salesPlanLicenseProperty) {
      store.dispatch(SessionActions.saveLicensePlan(salesPlanLicenseProperty));
    }

    const firstChatDate = properties[StatisticsProperty.FirstChatDate];

    if (firstChatDate) {
      updateFirstChatDate(calculateFirstChat(firstChatDate));
    }
  }

  static async banListUpdated(): Promise<void> {
    const queryClient = getQueryClient();
    // This could be changed to invalidateQueries when we fully switch to react
    // at this moment we are using queryCache to gat data in backbone views and models
    // so it's necessary to trigger refetch here (in react we can use hooks that subscribe for changes by design)
    await queryClient.refetchQueries([QueryKey.BannedCustomers]);

    store.dispatch(TrafficActions.bannedCustomersListUpdate());
  }
}
