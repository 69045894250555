// @ts-strict-ignore
import { createRef, useContext, useEffect, useState, type FC, type RefObject, type SyntheticEvent } from 'react';

import { cx } from '@emotion/css';
import { ChevronUp, ChevronDown } from '@livechat/design-system-icons';
import { Button, FieldGroup, FormField, Heading, Icon } from '@livechat/design-system-react-components';
import { useRecurly } from '@recurly/react-recurly';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { Divider } from 'components/divider/Divider';
import { SubscriptionRouteEvent } from 'constants/subscription/event';
import { EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import { mapRecurlyError } from 'helpers/recurly';
import { useEffectOnce } from 'hooks/use-effect-once';
import { RecurlyErrorType, RecurlyFieldType } from 'interfaces/recurly';
import { trackEvent } from 'services/event-tracking';
import { type IRecurlyValidationError } from 'services/recurly';
import { type IBillingInfo } from 'store/entities/billing/interfaces';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import { getRecurlyError } from 'store/views/subscription/selectors';

import { CardFormContext } from './CardFormContext';
import { CARD_FORM_EXPANDER_TEST_ID, CARD_FORM_TEST_ID, FIELD_VALIDATION_MAP } from './constants';
import { CountrySelectField } from './country-select-field/CountrySelectField';
import { ErrorBanner } from './error-banner/ErrorBanner';
import { InputField } from './input-field/InputField';
import { NumberField } from './number-field/NumberField';
import { SupportedCards } from './supported-cards/SupportedCards';

import * as styles from './styles';

export interface Props {
  billingInfo?: IBillingInfo | null;
  initialCountry?: string;
  className?: string;
  creditCardError?: any;
  threeDSecureError?: string | null;
  isSaving?: boolean;
  expandable?: boolean;
  showButtons?: boolean;
  buttonsDisabled?: boolean;
  hideCancelButton?: boolean;
  onCancel?();
  onSubmitSuccess(tokenId: string): void;
  onCountryChange?(value: string);
  onZipCodeChange?(value: string);
  hideInvoiceDetails?: boolean;
}

interface State {
  expanded: boolean;
  isSaving: boolean;
  validationError?: IRecurlyValidationError | string;
  clientSideValidations: KeyMap<boolean>;
}

export const CardForm: FC<Props> = ({
  creditCardError: nextError,
  threeDSecureError,
  isSaving: nextIsSaving,
  billingInfo,
  initialCountry,
  className,
  expandable,
  showButtons,
  buttonsDisabled,
  onCancel,
  onSubmitSuccess,
  onCountryChange,
  onZipCodeChange,
  hideCancelButton,
  hideInvoiceDetails,
}) => {
  const dispatch = useDispatch();
  const paymentError = useSelector(getRecurlyError);
  const formReference: RefObject<HTMLFormElement> = createRef();
  const context = useContext(CardFormContext);
  const recurly = useRecurly();
  const [state, setState] = useState<State>({
    expanded: false,
    isSaving: false,
    validationError: null,
    clientSideValidations: {},
  });

  useEffectOnce(() => {
    if (formReference.current) {
      const numberDiv: HTMLElement | null = formReference.current.querySelector('#card-number div');

      if (billingInfo?.firstName && billingInfo?.lastName) {
        setTimeout(() => {
          numberDiv?.click();
        }, 600);
      }
    }
  });

  useEffect(() => {
    const { isSaving: prevIsSaving, validationError: prevError } = state;
    if (nextIsSaving && !prevIsSaving) {
      setState({ ...state, isSaving: nextIsSaving });
    }

    if (prevIsSaving && !nextIsSaving && nextError !== prevError) {
      if (nextError) {
        trackEvent(SubscriptionRouteEvent.SubscriptionFailed, EventPlace.Subscription, {
          error: mapRecurlyError(nextError)[RecurlyErrorType.Recurly],
        });
      }

      setState({
        ...state,
        isSaving: nextIsSaving,
        validationError: nextError,
        clientSideValidations: {},
      });
    }
  }, [nextError, nextIsSaving, state.isSaving, state.validationError]);

  const setValidationError = (validationError: IRecurlyValidationError): void => {
    const mappedErrors = mapRecurlyError(validationError);
    if (!mappedErrors) {
      return;
    }

    const { clientSideValidations } = state;
    Object.keys(mappedErrors).forEach((field) => {
      clientSideValidations[field] = false;
    });
    setState({ ...state, validationError, clientSideValidations });
  };

  const setFieldValidations = (fields: RecurlyFieldType[]): void => {
    fields.forEach((field) => {
      if (state.clientSideValidations[field]) {
        return;
      }

      setState({
        ...state,
        clientSideValidations: {
          ...state.clientSideValidations,
          [field]: true,
        },
      });
    });
  };

  const handleToggleExpandForm = (): void => {
    trackEvent(`Card form - "${state.expanded ? 'less' : 'add more details'}" button clicked`, EventPlace.Subscription);
    setState({ ...state, expanded: !state.expanded });
  };

  const trimRecurlyFromInputs = (): void => {
    const inputs = formReference.current.querySelectorAll('[data-recurly]');

    Array.from(inputs).forEach((input: HTMLInputElement) => {
      if (input.value) {
        input.value = input.value.trim();
      }
    });
  };

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();
    trimRecurlyFromInputs();

    recurly.token(formReference.current, (error, token) => {
      if (error) {
        setValidationError(error as any);
        trackEvent('Card form - form failed', EventPlace.Subscription, { failedFields: (error as any).fields });
      } else {
        setValidationError(null);
        onSubmitSuccess(token.id);
      }
    });
  };

  // Enable validations for previous fields
  const handleFieldFocus = (field: RecurlyFieldType) => {
    if (state.clientSideValidations[field]) {
      return;
    }

    setFieldValidations(FIELD_VALIDATION_MAP[field]);
  };

  // Enable validation on current field
  const handleFieldBlur = (field: RecurlyFieldType) => {
    if (state.clientSideValidations[field]) {
      return;
    }

    setFieldValidations([field]);
  };

  const enableSubscribeButton = (): void => {
    dispatch(SubscriptionViewActions.setIsSubscribeButtonDisabled(false));
  };

  const handleCountryChange = (value: string): void => {
    if (typeof onCountryChange === 'function') {
      onCountryChange?.(value);
    }
    enableSubscribeButton();
  };

  const handleZipCodeChange = (value: string): void => {
    if (typeof onZipCodeChange === 'function') {
      onZipCodeChange?.(value);
    }
    enableSubscribeButton();
  };

  const { expanded, validationError, clientSideValidations } = state;
  const extraFieldsHidden = hideInvoiceDetails || (expandable && !expanded);
  const recurlyError = mapRecurlyError(validationError);
  const error = threeDSecureError || (recurlyError && recurlyError[RecurlyErrorType.Recurly]) || paymentError;
  context.submit = handleSubmit;

  return (
    <form
      className={cx(styles.cardForm, className)}
      data-testid={CARD_FORM_TEST_ID}
      ref={formReference}
      onSubmit={handleSubmit}
    >
      {!!error && <ErrorBanner error={error} />}
      <FormField>
        <SupportedCards />
      </FormField>
      <FieldGroup className={styles.fieldGroup} inline>
        <InputField
          type={RecurlyFieldType.FirstName}
          initialValue={billingInfo?.firstName}
          error={recurlyError && recurlyError[RecurlyFieldType.FirstName]}
          validationEnabled={clientSideValidations[RecurlyFieldType.FirstName]}
          onBlur={handleFieldBlur}
          onFocus={handleFieldFocus}
          onChange={enableSubscribeButton}
          required
          autoFocus={!billingInfo?.firstName}
        />
        <InputField
          type={RecurlyFieldType.LastName}
          initialValue={billingInfo?.lastName}
          error={recurlyError && recurlyError[RecurlyFieldType.LastName]}
          validationEnabled={clientSideValidations[RecurlyFieldType.LastName]}
          onBlur={handleFieldBlur}
          onFocus={handleFieldFocus}
          onChange={enableSubscribeButton}
          required
          autoFocus={billingInfo?.firstName && !billingInfo?.lastName}
        />
      </FieldGroup>
      <FieldGroup className={styles.fieldGroup} inline>
        <NumberField
          error={recurlyError && recurlyError[RecurlyFieldType.CreditCard]}
          validationEnabled={clientSideValidations[RecurlyFieldType.CreditCard]}
          onBlur={handleFieldBlur}
          onFocus={handleFieldFocus}
          onChange={enableSubscribeButton}
        />
      </FieldGroup>
      <FieldGroup className={styles.responsiveGroup} inline>
        <CountrySelectField
          type={RecurlyFieldType.Country}
          initialValue={initialCountry}
          error={recurlyError && recurlyError[RecurlyFieldType.Country]}
          onChange={handleCountryChange}
          required
        />
        <InputField
          type={RecurlyFieldType.PostalCode}
          initialValue={billingInfo?.zip}
          error={recurlyError && recurlyError[RecurlyFieldType.PostalCode]}
          validationEnabled={clientSideValidations[RecurlyFieldType.PostalCode]}
          onChange={handleZipCodeChange}
          onBlur={handleFieldBlur}
          onFocus={handleFieldFocus}
          required
        />
      </FieldGroup>
      <CSSTransition in={!extraFieldsHidden} timeout={200} classNames="extra-fields">
        <div>
          {!extraFieldsHidden && (
            <>
              <Divider />
              <Heading size="sm" className={styles.invoiceDetailsTitle}>
                Add invoice details
              </Heading>
            </>
          )}
          <FieldGroup className={styles.fieldGroup} inline>
            <InputField
              type={RecurlyFieldType.Company}
              initialValue={billingInfo?.company}
              error={recurlyError && recurlyError[RecurlyFieldType.Company]}
              hidden={extraFieldsHidden}
            />
          </FieldGroup>

          <FieldGroup className={styles.fieldGroup} inline>
            <InputField
              type={RecurlyFieldType.Address}
              initialValue={billingInfo?.address}
              error={recurlyError && recurlyError[RecurlyFieldType.Address]}
              hidden={extraFieldsHidden}
            />
          </FieldGroup>

          <FieldGroup className={styles.fieldGroup} inline>
            <InputField
              type={RecurlyFieldType.City}
              initialValue={billingInfo?.city}
              error={recurlyError && recurlyError[RecurlyFieldType.City]}
              hidden={extraFieldsHidden}
            />
            <InputField
              type={RecurlyFieldType.State}
              initialValue={billingInfo?.state}
              error={recurlyError && recurlyError[RecurlyFieldType.State]}
              hidden={extraFieldsHidden}
            />
          </FieldGroup>

          <FieldGroup className={styles.fieldGroup} inline>
            <InputField
              type={RecurlyFieldType.VatNumber}
              initialValue={billingInfo?.vatNumber}
              error={recurlyError && recurlyError[RecurlyFieldType.VatNumber]}
              hidden={extraFieldsHidden}
            />
          </FieldGroup>
        </div>
      </CSSTransition>
      <input type="hidden" name="recurly-token" data-recurly="token" />
      {expandable && (
        <Button
          kind="link"
          className={styles.toggleExtendedFormButton}
          onClick={handleToggleExpandForm}
          data-testid={CARD_FORM_EXPANDER_TEST_ID}
          icon={<Icon source={expanded ? ChevronUp : ChevronDown} />}
          iconPosition="right"
        >
          <span>{expanded ? 'Hide' : 'Add invoice details'}</span>
        </Button>
      )}
      {showButtons && (
        <div className={styles.buttonsRow}>
          {!hideCancelButton && (
            <Button kind="secondary" size="medium" onClick={onCancel} disabled={buttonsDisabled}>
              Cancel
            </Button>
          )}
          <Button kind="primary" type="submit" disabled={buttonsDisabled}>
            Save changes
          </Button>
        </div>
      )}
    </form>
  );
};
