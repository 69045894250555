import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { DeveloperDetails } from './DeveloperDetails';

type Props = {
  developerId?: string;
  description?: string;
};

export const PaymentDescription: FC<Props> = ({ developerId, description }) => {
  if (description) {
    return <Text size="sm">{description}</Text>;
  }

  if (developerId) {
    return <DeveloperDetails developerId={developerId} />;
  }

  return null;
};
