import { css } from '@emotion/css';
import { DesignToken, RadiusToken, ShadowToken, SpacingToken } from '@livechat/design-system-react-components';

import { TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';
import { NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN } from 'constants/notifications-bar';
import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';
import { CONTENT_PAGE_MARGIN, SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { resetLegacyLcBorderBox } from 'styles/reset-border-box';
import { StackingContextLevel } from 'styles/stacking-context-level';

import { glowLightAnimation, glowDarkAnimation } from './animations';
import { COPILOT_DARK_BOX_SHADOW, COPILOT_LIGHT_BOX_SHADOW } from './constants';

const SIDE_PADDING = 16;
const SIDE_MARGIN = 8;
const MESSAGE_BOX_BOTTOM_MARGIN = '16px';
const SCROLLER_TOP_PADDING = '6px';
const SCROLLER_PADDING = '16px';
const MESSAGE_BOX_HEIGHT = '133px';
const HEADER_HEIGHT = '64px';
const SMALL_MESSAGE_BOX_HEIGHT = '64px';
const AVATAR_DIMENSION = '32px';
const COMMAND_SUGGESTIONS_POPOVER_HEIGHT = '290px';

const expandedHeight = `calc(100% - ${MESSAGE_BOX_HEIGHT} - ${TOP_BAR_HEIGHT} - ${HEADER_HEIGHT} - ${MESSAGE_BOX_BOTTOM_MARGIN})`;
const popoverHeight = `calc(75vh - ${HEADER_HEIGHT} - ${SCROLLER_TOP_PADDING} - ${SCROLLER_PADDING} - ${TOP_BAR_HEIGHT} - ${SMALL_MESSAGE_BOX_HEIGHT})`;

const COPILOT_CHAT_CONTENT_WIDTH = '768px';

export const loaderText = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(${DesignToken.ContentBasicDisabled});
`;

export const relativeContainer = css`
  position: relative;
`;

export const typingIndicator = css`
  position: absolute;
  background-color: transparent;
  left: 43px;
  height: 15px;

  width: 40px;
  bottom: 0px;

  span {
    width: 4px;
    height: 4px;
    background-color: var(${DesignToken.ContentBasicDisabled});
  }
`;

export const selected = css`
  background-color: var(${DesignToken.SurfacePrimaryActive});
`;

export const wrapper = css`
  ${resetLegacyLcBorderBox};
  box-sizing: border-box;
  min-height: 72px;
  margin: var(${SpacingToken.Spacing2}) ${SIDE_MARGIN}px;
  padding: ${SIDE_PADDING}px;
  display: flex;
  align-items: center;
  width: calc(100% - 2 * ${SIDE_MARGIN}px);
  border-radius: var(${RadiusToken[3]});

  img {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    border-radius: 50%;
  }

  &:hover {
    background-color: var(${DesignToken.SurfacePrimaryHover});
    cursor: pointer;
  }
`;

export const textWrapper = css`
  width: calc(100% - 2 * ${SIDE_PADDING}px - 2 * ${SIDE_MARGIN}px);
  color: var(${DesignToken.ContentBasicPrimary});
`;

export const lastEventWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const text = css`
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: var(${DesignToken.ContentBasicSecondary});
`;

export const chatFeed = css`
  display: flex;
  flex-flow: column;
  min-height: 300px;
`;

export const virtuosoList = css`
  height: 100%;
`;

const undreadMessageCount = css`
  width: 18px;
  min-width: 18px;
  font-size: 12px;
  border-radius: 50%;
  color: var(${DesignToken.ContentLockedWhite});
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighNegative});
  margin: 0 6px;
  text-align: center;
  font-weight: bold;
`;

const unreadMessageText = css`
  color: var(${DesignToken.ContentBasicSecondary});
  flex-grow: 1;
  font-size: 13px;
`;

export const unreadMessagesBadge = (isExpanded = false): string => css`
  position: absolute;
  bottom: ${isExpanded ? '220px' : '64px'};
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;
  left: ${isExpanded ? '50%' : '16px'};
  transform: ${isExpanded ? 'translateX(-50%)' : 'none'};
  width: ${isExpanded ? '100%' : 'calc(100% - 32px)'};
  max-width: ${isExpanded ? COPILOT_CHAT_CONTENT_WIDTH : 'calc(100% - 32px)'};
  margin-bottom: 6px;
  box-shadow: var(${ShadowToken.Float});
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: var(${DesignToken.ContentBasicPrimary});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  > div {
    ${undreadMessageCount};
  }

  > span {
    ${unreadMessageText};
  }
`;

export const loaderItem = css`
  display: flex;
  align-items: flex-start;
  padding: 0 var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing4});
`;

export const loaderItemExpanded = css`
  ${loaderItem};
  padding: 0 0 var(${SpacingToken.Spacing6});
  gap: var(${SpacingToken.Spacing3});
`;

export const loaderContainerExpanded = css`
  height: ${expandedHeight};
  max-height: ${expandedHeight};
  max-width: ${COPILOT_CHAT_CONTENT_WIDTH};
  margin: var(${SpacingToken.Spacing6}) auto 0;
`;

export const loaderEmptyPlaceholder = css`
  height: ${AVATAR_DIMENSION};
  width: ${AVATAR_DIMENSION};
`;

export const closeButton = css`
  span {
    display: flex;
    justify-content: center;
  }
`;

export const container = (isDarkMode = false, hasNotifications = false): string => css`
  width: 360px;
  height: auto;
  max-height: 75%;
  position: fixed;
  right: 12px;
  top: ${hasNotifications
    ? `calc(${TOP_BAR_HEIGHT} + ${SCROLLER_TOP_PADDING} + ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`
    : `calc(${TOP_BAR_HEIGHT} + ${SCROLLER_TOP_PADDING})`};
  z-index: ${StackingContextLevel.Popover};
  color: var(${DesignToken.ContentBasicPrimary});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: ${isDarkMode ? COPILOT_DARK_BOX_SHADOW : COPILOT_LIGHT_BOX_SHADOW};
`;

export const containerExpanded = (hasNotifications = false): string => css`
  width: calc(100% - 54px - ${CONTENT_PAGE_MARGIN}px);
  height: ${hasNotifications
    ? `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px - ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`
    : `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px)`};
  max-height: ${hasNotifications
    ? `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px - ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`
    : `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px)`};
  right: ${CONTENT_PAGE_MARGIN}px;
  left: initial;
  top: ${hasNotifications ? `calc(${TOP_BAR_HEIGHT} + ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})` : TOP_BAR_HEIGHT};
  padding: 0 0 var(${SpacingToken.Spacing4});
  animation: none;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
    width: 100%;
    height: calc(100% - 56px);
    max-height: calc(100% - 56px);
    animation: none;
    top: 0;
    border-radius: 0;
    right: 0;
  }
`;

export const copilotAnimation = (isDarkMode: boolean): string => css`
  ${isDarkMode ? glowDarkAnimation : glowLightAnimation};
`;

export const headerWrapper = css`
  display: flex;
  justify-content: space-between;
  padding: var(${SpacingToken.Spacing3});

  > div {
    display: flex;
    align-items: center;
    gap: var(${SpacingToken.Spacing1});
  }

  > div:first-of-type {
    box-sizing: border-box;
    padding: 0 8px;
    gap: 8px;
  }

  > div:last-child {
    @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
      display: none;
    }
  }

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-right: 4px;
  }
`;

export const copilotPopoverHeaderButtons = css`
  display: flex;
  align-items: center;
`;

export const feedbackIcon = css`
  width: var(${SpacingToken.Spacing5});
  height: var(${SpacingToken.Spacing5});
  padding: 0 var(${SpacingToken.Spacing2});
`;

export const headerWrapperExpanded = css`
  ${headerWrapper};
  box-sizing: border-box;
  height: 64px;
  border-bottom: 1px solid var(${DesignToken.BorderBasicSecondary});

  > div:first-of-type {
    padding: 0 4px;
  }
`;

export const copilotLogo = css`
  background-color: initial;
  display: inline-flex;
`;

export const activeDetailsButton = css`
  background-color: var(${DesignToken.SurfaceOpacityBasicActive});
`;

export const icon = css`
  color: white;
`;

export const chatMessagesWrapper = (isExpanded: boolean): string => css`
  max-width: ${isExpanded ? COPILOT_CHAT_CONTENT_WIDTH : '100%'};
  margin: ${isExpanded ? '0 auto' : '0'};
`;

export const scroller = (isExpanded = false, isWithCommandSuggestions = false): string => css`
  padding: ${isExpanded ? `var(${SpacingToken.Spacing6})` : `var(${SpacingToken.Spacing2})`}
    var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing4});
  max-height: ${isExpanded ? expandedHeight : popoverHeight};
  height: ${isExpanded ? expandedHeight : 'auto'};
  overflow: auto;
  min-height: ${!isExpanded && isWithCommandSuggestions ? COMMAND_SUGGESTIONS_POPOVER_HEIGHT : 'unset'};
`;

export const messageBoxWrapper = css`
  padding: 0 var(${SpacingToken.Spacing4});
`;

export const popoverMessageBox = css`
  border-top: 1px solid var(${DesignToken.BorderBasicSecondary});
`;

export const messageBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(${SpacingToken.Spacing4});
`;

export const copilotGrid = css`
  display: flex;
  height: 100%;
`;

export const copilotChat = css`
  flex: 1;
`;

export const emptyStateContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  padding-bottom: var(${SpacingToken.Spacing6});
  align-self: stretch;
`;

export const emptyStateHeader = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const emptyStateDescription = css`
  font-size: 14px;
`;

export const emptyStateText = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-align: center;
`;

export const emptyState = css`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${MESSAGE_BOX_HEIGHT} - ${TOP_BAR_HEIGHT} - ${HEADER_HEIGHT});
  margin-bottom: 22px;
  padding: var(${SpacingToken.Spacing6}) var(${SpacingToken.Spacing6}) 0;
  overflow: auto;
  box-sizing: border-box;
`;

export const chatBody = css`
  width: 100%;
  position: relative;
`;

export const chatContainer = (isExpanded = false): string => css`
  display: ${isExpanded ? 'flex' : 'block'};
  width: 100%;
  height: ${isExpanded ? '100%' : 'auto'};
`;

export const feedbackText = css`
  color: var(${DesignToken.ContentBasicPrimary});
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  align-self: stretch;
`;

export const feedbackTooltipContent = css`
  display: flex;
  padding: var(${SpacingToken.Spacing2});
  flex-direction: column;
  align-items: flex-start;
  gap: var(${SpacingToken.Spacing3});
  align-self: stretch;
`;

export const feedbackTooltip = css`
  width: 270px;
`;

export const feedbackTooltipContainer = css`
  height: 20px;
`;

export const aiSvg = css`
  height: 20px;
  width: 74px;
  padding: 1px 0;
`;

export const aiSvgFullwidth = css`
  height: 21px;
  width: 80px;
  padding: 1px 0;
`;

export const toastContainer = (isDarkMode = false, hasVisibleNotifications = false): string => css`
  width: 248px;
  height: auto;
  position: fixed;
  right: var(${SpacingToken.Spacing3});
  top: ${hasVisibleNotifications
    ? `calc(${TOP_BAR_HEIGHT} + 6px + ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`
    : `calc(${TOP_BAR_HEIGHT} + 6px)`};
  z-index: ${StackingContextLevel.Popover};
  color: var(${DesignToken.ContentBasicPrimary});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: ${isDarkMode ? COPILOT_DARK_BOX_SHADOW : COPILOT_LIGHT_BOX_SHADOW};

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    display: none;
  }
`;

export const toastWrapper = css`
  display: flex;
  justify-content: space-between;
  padding: var(${SpacingToken.Spacing2});

  > div {
    display: flex;
    align-items: center;
  }

  > div:first-of-type {
    box-sizing: border-box;
    padding: 0 var(${SpacingToken.Spacing2});
    gap: var(${SpacingToken.Spacing2});
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &:hover {
    cursor: pointer;
  }
`;
