/* eslint-disable @typescript-eslint/no-unsafe-argument */
import debug from 'debug';
import isEmpty from 'lodash.isempty';
import uniqBy from 'lodash.uniqby';
import type { SagaIterator } from 'redux-saga';
import { call, debounce, delay, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { COPILOT_NO_AI_AGENTS_MESSAGE, PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE } from 'constants/copilot';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { FAILED_TO_FETCH_ERROR } from 'constants/failed-to-fetch';
import { QueryKey } from 'constants/query-key';
import { type ReportType, reportViewsDetails } from 'constants/reports/report-type';
import { Section } from 'constants/section';
import { TeamSubPaths } from 'constants/team/routes';
import { EventPlace } from 'helpers/analytics';
import { mapSources } from 'helpers/copilot/copilot';
import { uniqueId } from 'helpers/string';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type Features } from 'services/api/feature-control/interfaces';
import type {
  IGenerateReplyError,
  IGenerateReplyResponse,
  ISendMessageRequest,
} from 'services/api/ml-gateway/interfaces/ai-agents';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { RequestAction } from 'store/entities/actions';
import type { IAgent } from 'store/entities/agents/interfaces';
import { getLoggedInAgent, getLoggedInAgentName } from 'store/entities/agents/selectors';
import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getCopilotMessageSentCount,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { getCurrentSection } from 'store/features/routing/selectors';
import type { IActionWithPayload } from 'store/helper';
import { MAXIMUM_REQUESTS_FOR_GENERATE_REPLY } from 'store/views/chats/constants';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';
import { OnboardingActionsEnum } from 'store/views/onboarding/actions';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';
import { getCurrentView } from 'store/views/reports/selectors';
import { getPageTitle, getRecentPage } from 'store/views/settings/selectors';
import { getCurrentTeamPage } from 'store/views/team/selectors';

import { AIAgent } from '../ai-agents/constants';
import { getAIAgentId, getIsAIAgentAvailableByName } from '../ai-agents/selectors';

import { CopilotEntitiesActionNames, CopilotEntitiesActions } from './actions';
import { isAccountCreatedBeforeCopilotPopoverRelease, setUnreadMessagesCount } from './helper-sagas';
import { formatTitle, getSettingsCategoryName, getUserContextString } from './helpers';
import { type CopilotAddConversationPayload } from './interfaces';
import { COPILOT_ONBOARDING_DELAY, MAX_COPILOT_ONBOARDING_MESSAGES_COUNT } from './onboarding/constants';
import {
  type OnboardingConfig,
  COPILOT_ONBOARDING_CONFIG,
  COPILOT_ONBOARDING_CONFIG_OLD_USERS,
} from './onboarding/copilot-onboarding-config';
import {
  getOnboardingToStart,
  handleCopilotOnboardingAction,
  handleCopilotPromo,
  startCopilotOnboarding,
  startKnowledgeHubOnboarding,
} from './onboarding/helper-sagas';
import { getFailedCopilotMessage, getCopilotAllEvents, getCopilotCurrentSessionId } from './selectors';

const log = debug(DebugLogsNamespace.AppDebug);

/**
 * It's a simple long-polling mechanism. This is a helper function, which retries a call to AI Agent due to long response time.
 */
function* regenerateResponse(payload: ISendMessageRequest, currentCount = 0): SagaIterator {
  const response: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    ApiManager.mlGatewayApi.sendMessage,
    payload,
  );
  const currentSessionId = yield select(getCopilotCurrentSessionId);

  if (currentSessionId !== payload.session_id || currentCount >= MAXIMUM_REQUESTS_FOR_GENERATE_REPLY) {
    return {};
  }

  // If "Failed to fetch" is present we can be sure to re-fetch such call
  if (response.error?.message === FAILED_TO_FETCH_ERROR) {
    yield call(regenerateResponse, payload, currentCount + 1);
  }

  return response;
}

function* generateCopilotMessage(message: string, copilotAIAgentId: string, context?: string): SagaIterator {
  const sessionId = uniqueId();
  const params: ISendMessageRequest = {
    /* eslint-disable @typescript-eslint/naming-convention */
    messages: [
      {
        value: message,
      },
    ],
    session_id: sessionId,
    user_context: context,
    agent_id: copilotAIAgentId,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  yield put(
    CopilotEntitiesActions.setCopilotSessionId({
      sessionId,
    }),
  );

  const { result, error }: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    regenerateResponse,
    params,
  );

  const currentRequestId = yield select(getCopilotCurrentSessionId);

  if (error) {
    yield put(CopilotViewActions.setCopilotIsLoading(false));
    if (error?.innerError?.session_id !== sessionId) {
      return;
    }

    yield put(CopilotEntitiesActions.addCopilotError(true));

    yield put(CopilotEntitiesActions.setFailedCopilotMessage(message));

    trackEvent(CopilotTrackEvent.MessageReceived, EventPlace.One, { result: 'error' });

    return;
  }

  if (!result || result.session_id !== currentRequestId) {
    return;
  }

  yield put(CopilotViewActions.setCopilotIsLoading(false));

  const sources = result?.sources ? mapSources(result.sources) : [];

  const uniqueSources = uniqBy(sources, 'url');

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      sessionId: result.session_id,
      traceId: result.trace_id,
      text: result.response,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        sources: uniqueSources,
      },
    }),
  );
  yield put(CopilotEntitiesActions.clearFailedCopilotMessage());

  trackEvent(CopilotTrackEvent.MessageReceived, EventPlace.One, { result: 'success' });

  yield call(setUnreadMessagesCount);
}

function* getAppContext(): SagaIterator {
  const currentSection: Section | undefined = yield select(getCurrentSection);
  switch (currentSection) {
    case Section.EngageTraffic: {
      const userSections = [currentSection, 'traffic'];

      return getUserContextString(userSections);
    }

    case Section.EngageCampaigns: {
      const userSections = [currentSection, 'campaigns'];

      return getUserContextString(userSections);
    }

    case Section.EngageGoals: {
      const userSections = [currentSection, 'goals'];

      return getUserContextString(userSections);
    }

    case Section.Team: {
      const currentTeamView: TeamSubPaths = yield select(getCurrentTeamPage);
      const teamView = currentTeamView === TeamSubPaths.Agents ? 'agents' : currentTeamView.split(`/`)[1];

      const userSections = [currentSection, formatTitle(teamView)];

      return getUserContextString(userSections);
    }

    case Section.Reports: {
      const currentReportView: ReportType = yield select(getCurrentView);
      const reportCategory = reportViewsDetails[currentReportView]?.category;
      const userSections = [currentSection, formatTitle(currentReportView), reportCategory];

      return getUserContextString(userSections);
    }

    case Section.Settings: {
      const currentPageTitle: string = yield select(getPageTitle);
      const recentSettingsPage: string = yield select(getRecentPage);
      const userSections = [currentSection, getSettingsCategoryName(recentSettingsPage), currentPageTitle];

      return getUserContextString(userSections);
    }

    default: {
      return getUserContextString([currentSection as string]);
    }
  }
}

function* sendCopilotMessage(action: IActionWithPayload<string, string>): SagaIterator {
  const canUseCopilot = yield select(getCanUseCopilotPopover);
  const copilotMessageSentCount = yield select(getCopilotMessageSentCount);
  const copilotAIAgentId: string | null = yield select(getAIAgentId, AIAgent.One);

  if (!canUseCopilot || !copilotAIAgentId) {
    return;
  }

  const currentAgent: IAgent | null = yield select(getLoggedInAgent);

  if (!currentAgent) {
    return;
  }

  const uniqueAgentEventId = uniqueId();

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: currentAgent.login,
      authorType: 'agent',
      eventId: uniqueAgentEventId,
      text: action.payload,
      timestampInMs: Date.now(),
      type: 'text-message',
    }),
  );

  yield put(
    CopilotEntitiesActions.setLatestAgentEventId({
      eventId: uniqueAgentEventId,
    }),
  );

  yield put(CopilotViewActions.setCopilotIsLoading(true));
  yield put(CopilotEntitiesActions.clearCopilotError());

  if (copilotMessageSentCount < MAX_COPILOT_ONBOARDING_MESSAGES_COUNT) {
    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.OneMessageSentCount]: copilotMessageSentCount + 1,
      }),
    );
  }

  const contextString = yield call(getAppContext);

  yield call(generateCopilotMessage, action.payload, copilotAIAgentId, contextString);
  yield call(handleCopilotPromo);
}

function* retryGenerateOnLastAgentMessage(): SagaIterator {
  const failedMessageContent: string = yield select(getFailedCopilotMessage);
  const copilotAIAgentId: string | null = yield select(getAIAgentId, AIAgent.One);

  if (!failedMessageContent || !copilotAIAgentId) {
    return;
  }

  yield put(CopilotViewActions.setCopilotIsLoading(true));
  yield put(CopilotEntitiesActions.clearCopilotError());
  yield call(generateCopilotMessage, failedMessageContent, copilotAIAgentId);
}

function* waitForFeaturesQuerySuccess(): SagaIterator {
  const queryClient = getQueryClient();
  let queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

  log('[Features] waitForFeaturesQuerySuccess queryState', queryState?.data?.customerIntentEnabled);

  while (queryState?.fetchStatus === 'fetching') {
    yield delay(200);

    queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

    log('[Features] waitForFeaturesQuerySuccess queryState assigned', queryState?.data?.customerIntentEnabled);
  }
}

function* waitForAgentCustomProperties(): SagaIterator {
  const hasFetchedCustomProperties = yield select(hasFetchedAgentCustomProperties);

  if (!hasFetchedCustomProperties) {
    yield race({
      success: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]),
      failure: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.FAILURE]),
    });
  }
}

function* sendNoAIAgentsError(): SagaIterator {
  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      text: `${COPILOT_NO_AI_AGENTS_MESSAGE.header}\n\n${COPILOT_NO_AI_AGENTS_MESSAGE.text}`,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );
}

function* sendWelcomeMessage(): SagaIterator {
  const loggedAgentName: string = yield select(getLoggedInAgentName);

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      text: `Hey ${loggedAgentName} 👋. ${PARTIAL_COPILOT_ASSISTANT_WELCOME_MESSAGE}`,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );
}

function* getHasCopilotAnyEvents(): SagaIterator {
  const copilotAllEvents = yield select(getCopilotAllEvents);

  return !isEmpty(copilotAllEvents);
}

function* triggerCopilotStart(): SagaIterator {
  const { timeoutOccured } = yield race({
    success: call(function* () {
      yield call(waitForFeaturesQuerySuccess);
      yield call(waitForAgentCustomProperties);
    }),
    timeoutOccured: delay(15000),
  });

  if (timeoutOccured) {
    const hasCopilotAnyEvents = yield call(getHasCopilotAnyEvents);

    if (!hasCopilotAnyEvents) {
      yield call(sendWelcomeMessage);
    }

    return;
  }

  const canUseCopilot: boolean = yield select(getCanUseCopilotPopover);
  const hasCopilotAnyEvents = yield call(getHasCopilotAnyEvents);
  const hasOneAIAgent = yield select(getIsAIAgentAvailableByName, 'one');
  const isOnboardingFinished = yield select(getIsOnboardingFinished);

  if (!canUseCopilot || hasCopilotAnyEvents) {
    return;
  }

  if (!isOnboardingFinished) {
    yield take(OnboardingActionsEnum.ONBOARDING_FINISHED);
  }

  if (!hasOneAIAgent) {
    yield call(sendNoAIAgentsError);

    return;
  }

  const onboardingToStart = yield call(getOnboardingToStart);

  if (!onboardingToStart) {
    const hasCopilotAnyEvents = yield call(getHasCopilotAnyEvents);

    if (hasCopilotAnyEvents) {
      return;
    }

    yield call(sendWelcomeMessage);

    return;
  }

  const isOldUser = yield call(isAccountCreatedBeforeCopilotPopoverRelease);
  const copilotOnboardingConfig: OnboardingConfig = isOldUser
    ? COPILOT_ONBOARDING_CONFIG_OLD_USERS
    : COPILOT_ONBOARDING_CONFIG;

  switch (onboardingToStart) {
    case 'copilot-onboarding':
      yield call(startCopilotOnboarding, copilotOnboardingConfig);

      return;
    case 'knowledge-hub-onboarding':
      yield call(startKnowledgeHubOnboarding);

      return;
    default:
      return;
  }
}

function* handleCopilotConversation(action: IActionWithPayload<string, CopilotAddConversationPayload>): SagaIterator {
  const payload = action.payload;

  for (const message of payload) {
    yield put(CopilotEntitiesActions.addCopilotMessage(message.event));

    if (message.delay) {
      yield put(CopilotViewActions.setCopilotIsLoading(true));
      yield delay(message.delay);
      yield put(CopilotViewActions.setCopilotIsLoading(false));
    }
  }
}

export function* copilotSaga(): SagaIterator {
  yield debounce(COPILOT_ONBOARDING_DELAY, ['APP_READY'], triggerCopilotStart);
  yield takeEvery(CopilotEntitiesActionNames.SEND_COPILOT_MESSAGE, sendCopilotMessage);
  yield takeEvery(CopilotEntitiesActionNames.COPILOT_ADD_CONVERSATION, handleCopilotConversation);
  yield takeEvery(CopilotEntitiesActionNames.RETRY_FAILED_COPILOT_MESSAGE, retryGenerateOnLastAgentMessage);
  yield takeEvery(CopilotEntitiesActionNames.COPILOT_ONBOARDING_ACTION, handleCopilotOnboardingAction);
}
