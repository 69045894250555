import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { CannedResponseAutotagsEvent } from 'constants/canned-responses/autotags-event';
import { ChatEventSubType } from 'constants/chat-event-type';
import { ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import { uniqueId } from 'helpers/string';
import { getToastContent } from 'helpers/toast';
import { type CannedResponse } from 'interfaces/canned-responses';
import { type SendSystemMessagePayload } from 'interfaces/web-socket-events';
import { type IAutotagsEvent } from 'services/api/ml-gateway/interfaces/event-collector';
import { threadTagsClient } from 'services/connectivity/agent-chat-api/thread-tags/client';
import { type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { autotagsClient } from 'services/connectivity/ml-gateway-api/autotags/client';
import {
  type UpdateCannedResponseAutotagsResponse,
  type CreateCannedResponseAutotagsResponse,
  type GetCannedResponseAutotagsForOrganization,
  type RemoveCannedResponseAutotagsResponse,
  type RemoveCannedResponseAutotagResponse,
} from 'services/connectivity/ml-gateway-api/autotags/types';
import { eventCollectorClient } from 'services/connectivity/ml-gateway-api/event-collector/client';
import { type GenericMlGatewayError } from 'services/connectivity/ml-gateway-api/types';
import { trackEvent } from 'services/event-tracking';
import { sendSystemMessage } from 'services/web-socket/actions/send-system-message';
import { getChatIdByThreadId } from 'store/entities/chats/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';
import { getAreAutotagsAvailable, getIsOnTrial, getLicenseId, getPlanType } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { type IApplyAutotags } from 'store/views/chats/interfaces';
import { getThreadTags } from 'store/views/chats/selectors';

import { CannedResponseActionNames, CannedResponseActions } from '../actions';
import {
  getAutotagsByCannedList,
  getCannedResponseAutotagsByGroupId,
  getIsAutotagConnectedWithCannedResponse,
} from '../computed';
import type {
  IAutotagRemovePayload,
  ICannedResponseAutotags,
  ICannedResponseAutotagsCreatePayload,
  ICannedResponseAutotagsRemovePayload,
  ICannedResponseAutotagsUpdatePayload,
} from '../interfaces';
import { getCannedResponse, getCannedResponses, getIsCannedResponseConnectedWithAutotags } from '../selectors';

function* fetchAutotags(): SagaIterator {
  const isAvailable = yield select(getAreAutotagsAvailable);
  if (!isAvailable) {
    yield put(CannedResponseActions.fetchCannedResponseAutotagsFailure());

    return;
  }

  const { result, error }: ApiClientResponse<GetCannedResponseAutotagsForOrganization, GenericMlGatewayError> =
    yield call(() => autotagsClient.getAutotagsConfig());

  if (error) {
    yield put(CannedResponseActions.fetchCannedResponseAutotagsFailure());

    return;
  }

  const cannedResponses: KeyMap<CannedResponse> = yield select(getCannedResponses);
  const parsedResult = Object.entries(result).reduce<KeyMap<ICannedResponseAutotags>>((acc, [cannedId, data]) => {
    const cannedResponse = cannedResponses[+cannedId];

    if (!cannedResponse) {
      return acc;
    }

    acc[cannedId] = {
      tags: data.tags,
      groupId: cannedResponse.groupId,
    };

    return acc;
  }, {});

  yield put(CannedResponseActions.fetchCannedResponseAutotagsSuccess(parsedResult));
}

function* createAutotags(action: IActionWithPayload<string, ICannedResponseAutotagsCreatePayload>): SagaIterator {
  const { autotags, cannedId, groupId } = action.payload;

  const cannedResponse: CannedResponse | null = yield select(getCannedResponse, +cannedId);
  if (!cannedResponse) {
    return;
  }

  const { result }: ApiClientResponse<CreateCannedResponseAutotagsResponse, GenericMlGatewayError> = yield call(() =>
    autotagsClient.createCannedResponseAutotags({
      /* eslint-disable @typescript-eslint/naming-convention */
      canned_id: cannedId,
      group_id: groupId,
      /* eslint-enable @typescript-eslint/naming-convention */
      tags: autotags,
    }),
  );

  if (result) {
    yield put(
      CannedResponseActions.createCannedResponseAutotagsSuccess({
        cannedId,
        autotags,
        groupId,
      }),
    );

    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.AUTOTAGS_CREATE_FAILURE),
      kind: ToastVariant.Warning,
    }),
  );
}

function* updateAutotags(action: IActionWithPayload<string, ICannedResponseAutotagsUpdatePayload>): SagaIterator {
  const { autotags, cannedId, groupId } = action.payload;

  const { result }: ApiClientResponse<UpdateCannedResponseAutotagsResponse, GenericMlGatewayError> = yield call(() =>
    autotagsClient.updateCannedResponseAutotags({
      /* eslint-disable @typescript-eslint/naming-convention */
      canned_id: cannedId,
      group_id: groupId,
      /* eslint-enable @typescript-eslint/naming-convention */
      tags: autotags,
    }),
  );

  if (result) {
    yield put(
      CannedResponseActions.updateCannedResponseAutotagsSuccess({
        autotags,
        cannedId,
        groupId,
      }),
    );

    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.AUTOTAGS_UPDATE_FAILURE),
      kind: ToastVariant.Warning,
    }),
  );
}

function* removeCannedResponseAutotags(
  action: IActionWithPayload<string, ICannedResponseAutotagsRemovePayload>,
): SagaIterator {
  const { cannedId } = action.payload;
  const isCannedResponseConnectedWithAutotags: boolean = yield select(
    getIsCannedResponseConnectedWithAutotags,
    +cannedId,
  );

  if (!isCannedResponseConnectedWithAutotags) {
    return;
  }

  const { result }: ApiClientResponse<RemoveCannedResponseAutotagsResponse, GenericMlGatewayError> = yield call(() =>
    autotagsClient.deleteCannedResponseAutotags({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      canned_id: cannedId,
    }),
  );

  if (result) {
    yield put(
      CannedResponseActions.removeCannedResponseAutotagsSuccess({
        cannedId: result.canned_id,
      }),
    );

    return;
  }
}

export function* deleteCannedResponseAutotags(groupId, tagName): SagaIterator {
  const { result }: ApiClientResponse<RemoveCannedResponseAutotagResponse, GenericMlGatewayError> = yield call(() =>
    autotagsClient.deleteCannedResponseAutotag({
      /* eslint-disable @typescript-eslint/naming-convention */
      group_id: groupId,
      tag_name: tagName,
      /* eslint-enable @typescript-eslint/naming-convention */
    }),
  );

  return result;
}

export function* removeTag(action: IActionWithPayload<string, IAutotagRemovePayload>): SagaIterator {
  const { tag, groupId } = action.payload;
  const isTagConnected: boolean = yield select(getIsAutotagConnectedWithCannedResponse, tag);

  if (!isTagConnected) {
    return;
  }

  const result: RemoveCannedResponseAutotagResponse = yield call(deleteCannedResponseAutotags, groupId, tag);

  if (!result) {
    return;
  }

  const cannedAutotags: KeyMap<ICannedResponseAutotags> = yield select(getCannedResponseAutotagsByGroupId, groupId);
  const updatedCannedAutotgs = Object.entries(cannedAutotags).reduce<KeyMap<ICannedResponseAutotags>>(
    (acc, [cannedId, data]) => {
      acc[cannedId] = {
        groupId: data.groupId,
        tags: data.tags.filter((autotag) => autotag !== tag),
      };

      return acc;
    },
    {},
  );

  yield put(CannedResponseActions.removeAutotagFromCannedResponseSuccess(updatedCannedAutotgs));
}

function* sendOneAutotagsMessage(chatId: string): SagaIterator {
  yield call(sendSystemMessage, {
    chatId,
    isWhisper: true,
    messageText: `Hey there! 👋 Copilot will keep you posted whenever an automated task happens during the chat.`,
    systemMessageType: ChatEventSubType.AutomationBotTextMessage,
  });

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.FirstAutomationBotMessageSeen]: 1,
    }),
  );
}

function* applyAutotags({ payload }: IActionWithPayload<string, IApplyAutotags>): SagaIterator {
  const { threadId, cannedList } = payload;

  const areAutotagsAvailable = yield select(getAreAutotagsAvailable);

  if (!areAutotagsAvailable) {
    return;
  }

  const chatId: string = yield select(getChatIdByThreadId, threadId);
  const autotags: string[] = yield select(getAutotagsByCannedList, cannedList);
  const currentTagsByThread: string[] = yield select(getThreadTags, threadId);

  const filteredAutotags = autotags.filter((tag) => !currentTagsByThread.includes(tag));

  const results: AgentChatApiResponse<void>[] = yield all(
    filteredAutotags.map((tag) =>
      call(threadTagsClient.tag, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        chat_id: chatId,
        tag,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        thread_id: threadId,
      }),
    ),
  );

  const addedTags = filteredAutotags.filter((_, index) => !results[index].error);

  if (!addedTags.length) {
    return;
  }

  trackEvent(CannedResponseAutotagsEvent.ChatAutotagged, EventPlace.Chats, {
    autotagsCount: addedTags.length,
  });

  const isFirstAutomationMessageSeen = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.FirstAutomationBotMessageSeen,
  );

  if (!isFirstAutomationMessageSeen) {
    yield call(sendOneAutotagsMessage, chatId);
  }

  const tagsString = addedTags.map((tag) => `"${tag}"`).join(', ');
  const systemMessagePayload: SendSystemMessagePayload = {
    chatId,
    messageText: `I tagged this chat as ${tagsString} based on the canned response you sent`,
    isWhisper: true,
    systemMessageType: ChatEventSubType.AutomationBotTextMessage,
  };

  yield call(sendSystemMessage, systemMessagePayload);

  const isTrial = yield select(getIsOnTrial);
  const planType = yield select(getPlanType);
  const licenseId = yield select(getLicenseId);

  /* eslint-disable @typescript-eslint/naming-convention */
  const event: IAutotagsEvent = {
    project: 'autotags',
    template_version: '1.0.0',
    type: 'chat_autotagged',
    request_id: uniqueId(),
    data: {
      chat_id: chatId,
      is_trial: isTrial,
      plan_type: planType,
      license_id: licenseId,
      version: '1.0.0',
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention */
  yield call(() => eventCollectorClient.sendEvent(event));
}

export function* autotagsSaga(): SagaIterator {
  yield takeLatest(CannedResponseActionNames.CREATE_CANNED_RESPONSE_AUTOTAGS, createAutotags);
  yield takeLatest(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_AUTOTAGS, updateAutotags);
  yield takeLatest(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_AUTOTAGS, removeCannedResponseAutotags);
  yield takeLatest(CannedResponseActionNames.REMOVE_AUTOTAG_FROM_CANNED_RESPONSE, removeTag);
  yield takeLatest(CannedResponseActionNames.FETCH_CANNED_RESPONSE_AUTOTAGS_REQUEST, fetchAutotags);
  yield takeEvery(CannedResponseActionNames.APPLY_AUTOTAGS, applyAutotags);
}
