import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

import { CONTENT_PAGE_MARGIN } from 'constants/spotlight';

export const modal = css`
  width: 95vw;
  max-width: 600px;
  max-height: calc(100% - ${2 * CONTENT_PAGE_MARGIN}px);

  > div {
    padding: var(${SpacingToken.Spacing5}) var(${SpacingToken.Spacing7});
  }

  > div:first-of-type {
    border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
    width: 100%;

    > div {
      width: 100%;
    }
  }

  > div:last-of-type {
    border-top: 1px solid var(${DesignToken.BorderBasicTertiary});
    margin-top: var(${SpacingToken.Spacing3});
    width: 100%;

    > div {
      width: 100%;
    }
  }

  p {
    margin: 0;
  }
`;

export const content = css`
  overflow: auto;
`;

export const divider = css`
  margin-block: var(${SpacingToken.Spacing5});
  min-height: 1px;
`;
