// @ts-strict-ignore
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isBefore,
  isDate,
  isEqual,
  isValid,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import isArray from 'lodash.isarray';
import isNil from 'lodash.isnil';
import propertyOf from 'lodash.propertyof';
import uniq from 'lodash.uniq';
import memoizeOne from 'memoize-one';

import { DateFormat } from 'constants/date';
import { AgentAssignmentFilterKey } from 'constants/filters/agent-assignment-filter';
import {
  CustomDateRangeFilterId,
  type CustomDateRange,
  type CustomDateRangeFilter,
  type DateRangeFilter,
} from 'constants/filters/date-range-filter';
import { Filter, type FiltersOperators } from 'constants/filters/filter';
import { PriorityFilter } from 'constants/filters/priority-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { NavigationPath } from 'constants/navigation';
import { ReportDistribution } from 'constants/reports/distribution';
import { reportsFilters, type IReportFiltersConfig } from 'constants/reports/filters';
import { NavigationItems } from 'constants/reports/navigation-items';
import { recursLabels } from 'constants/reports/recurs';
import { ReportType } from 'constants/reports/report-type';
import { sourceLabels } from 'constants/reports/source';
import { getConfig } from 'helpers/config';
import { extractOperatorsToQueryParams, mapDateRangeToQueryString, mapDateToQueryString } from 'helpers/filters';
import { type KeyMap, type KeysOfType } from 'helpers/interface';
import { round } from 'helpers/numbers';
import { deepMerge } from 'helpers/object';
import { getFormattedNumberValue } from 'helpers/string';
import type {
  ChartData,
  IChartSeries,
  IChartSeriesPoint,
  IChartSummary,
  IHeatmapFilter,
  PeriodDataBase,
  IRatings,
  ReportDataSet,
  ReportsViewFilters,
  ISerializedFilters,
  ISerializedFiltersWithRawDates,
} from 'interfaces/reports';
import type { SerializedJSONFilters } from 'interfaces/reports/api-v3';
import type { ISideNavigationItem } from 'interfaces/side-navigation-item';
import type { IStoreState } from 'interfaces/store/store-state';
import { SHOW_SENTIMENT_QUERY_PARAM_NAME } from 'routes/reports/chats/ChatTopics/constants';
import { HEATMAP_DAYS_LIMIT } from 'routes/reports/components/Heatmap/constants';
import { AppStateProvider } from 'services/app-state-provider';
import { browserHistory } from 'services/browser-history';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';
import { HeatmapPeriod } from 'store/entities/reports/interfaces';
import { getFilters as getArchivesFilters } from 'store/views/archives/selectors';
import {
  getSelectedReportViewId,
  getFilters,
  getCurrentView,
  getDistribution,
  getPage,
  getBenchmarkEnabled,
  getFiltersOperators,
} from 'store/views/reports/selectors';

import { ensureArray, ensureFlat } from './data';
import { parseDate } from './date';
import { extractRouteFromPathname } from './routing';
import { type IKeyValue, type ParamValue, stringifyQueryParams } from './url';
const ACCOUNT_ID = getConfig().accountsClientId;

const MAXIMUM_NUMBER_OF_DAYS_FOR_DAILY_DISTRIBUTION = 183;

interface ReportParamsProvider {
  getReportType: () => ReportType;
  getFilters: () => ReportsViewFilters;
  getFiltersOperators: () => FiltersOperators;
  getDistribution: () => ReportDistribution;
  getPage: () => number;
  getBenchmarkEnabled: () => boolean;
  getReportViewId: () => string | null;
}

function getRoutesTitleMap(items: ISideNavigationItem[] = NavigationItems): KeyMap<string> {
  return items.reduce(
    (acc: KeyMap<string>, current: ISideNavigationItem) => ({
      ...acc,
      [current.id]: current.name,
      ...(current.children && getRoutesTitleMap(current.children)),
    }),
    {},
  );
}

function getRoutesPathMap(items: ISideNavigationItem[] = NavigationItems): KeyMap<string> {
  return items.reduce(
    (acc: KeyMap<string>, current: ISideNavigationItem) => ({
      ...acc,
      ...(current.path && { [current.id]: current.path }),
      ...(current.children && getRoutesPathMap(current.children)),
    }),
    {},
  );
}

const routesPathMap = getRoutesPathMap();
const routesTitleMap = getRoutesTitleMap();

export function getRouteByPathname(pathname: string): string {
  if (pathname?.includes('/reports/scheduled-reports/edit/')) {
    return extractRouteFromPathname('/reports/scheduled-reports');
  }

  if (pathname?.includes('/reports/agent-performance')) {
    return extractRouteFromPathname('/reports/agents-performance');
  }

  if (pathname?.includes('/reports/campaigns')) {
    return extractRouteFromPathname('/reports/greetings');
  }

  const path = extractRouteFromPathname(pathname);

  return routesPathMap[path] === pathname ? path : null;
}

export function getRouteBackButtonUrl(pathname: string): string {
  if (pathname.includes('/reports/scheduled-reports/edit/')) {
    return '/reports/scheduled-reports';
  }

  return null;
}

export function getRouteTitleByPathname(pathname: string): string {
  const route = getRouteByPathname(pathname);

  if (pathname && pathname.includes('/reports/scheduled-reports/edit/')) {
    const urlParams = pathname.split('edit/');
    const [type, recurs] = urlParams[1].split('/');

    return `${recursLabels[recurs]} ${type} report`;
  }

  if (!route) return '';

  return routesTitleMap[route] || '';
}

export const getFiltersByReportType = memoizeOne(
  (reportType: ReportType): IReportFiltersConfig => reportsFilters[reportType] || { filters: [] },
);

export function getReportUrl(reportType: ReportType, params?: IKeyValue<ParamValue>): string {
  const path = routesPathMap[reportType] || null;

  return `${path}?${stringifyQueryParams(params)}`;
}

export function getReportFromLast7Days(reportType: ReportType, params: IKeyValue<ParamValue>): string {
  return getReportUrl(reportType, {
    ...params,
    /* eslint-disable @typescript-eslint/naming-convention */
    date_from: format(subDays(new Date(), 6), DateFormat.ISO8601Date),
    date_to: format(new Date(), DateFormat.ISO8601Date),
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}

export const getTrafficPath = (): NavigationPath => {
  return NavigationPath.Engage;
};

function getShouldUseDateRangeFromFilterOnTooltip(reportType: ReportType): boolean {
  // reports with custom x axis should use date range from filter on every chart tooltip
  // cause on X axis we don't display dates
  return ReportType.ChatTopics === reportType;
}

export const getParsedReportsQueryParams = (
  point: IChartSeriesPoint,
  distribution: ReportDistribution,
  queryParamsParser = (params: IKeyValue, _ctx?: unknown) => params,
): string | undefined => {
  const state = AppStateProvider.getState();
  const { benchmark, disableLink } = point.series.userOptions;

  if (benchmark || disableLink || distribution === ReportDistribution.Hour) {
    return;
  }

  const pointDateFrom = point.series?.userOptions.filters.dateFrom;
  const chartType = point?.series?.initialType;
  let dateFrom = '';

  if (chartType === 'line') {
    dateFrom = format(addDays(pointDateFrom, point.x), DateFormat.ISO8601Date);
  } else {
    dateFrom = format(
      distribution === ReportDistribution.Month ? addMonths(pointDateFrom, point.x) : addDays(pointDateFrom, point.x),
      DateFormat.ISO8601Date,
    );
  }

  if (isValid(new Date(dateFrom))) {
    const filters = getArchivesFilters(state);
    const page = getCurrentView(state);
    const { filters: availableFilters } = getFiltersByReportType(page);
    const reportsViewQueryParams = getReportsViewQueryParams(state);
    const shouldUseDateRangeFromFilter = getShouldUseDateRangeFromFilterOnTooltip(page);

    if (!shouldUseDateRangeFromFilter) {
      reportsViewQueryParams.date_from = dateFrom;
      reportsViewQueryParams.date_to = dateFrom;
    }

    if (distribution === ReportDistribution.Month) {
      reportsViewQueryParams.date_to = format(endOfMonth(parseDate(dateFrom)), DateFormat.ISO8601Date);
    }

    // AA-5751: fix for "No tagged" filters
    if (filters.tag && availableFilters.includes(Filter.Tag)) {
      reportsViewQueryParams.tag = ensureArray(filters.tag);
    }

    const labelIndex = point.series.index;
    const seriesFilters = point.series.chart.userOptions.series[labelIndex].filters;

    if (seriesFilters) {
      if (seriesFilters.groups) {
        reportsViewQueryParams.group = seriesFilters.groups;
      }

      if (seriesFilters.agents) {
        reportsViewQueryParams.agent = seriesFilters.agents;
      }
      if (seriesFilters?.properties?.[ACCOUNT_ID]?.[ThreadCustomPropertyName.PriorityChat]?.values?.[0] === true) {
        reportsViewQueryParams.priority = PriorityFilter.Yes;
      } else if (seriesFilters?.properties?.[ACCOUNT_ID]?.[ThreadCustomPropertyName.PriorityChat]?.exists === false) {
        reportsViewQueryParams.priority = PriorityFilter.No;
      }
    }

    const parsedQuery = queryParamsParser(reportsViewQueryParams, point);

    return stringifyQueryParams(parsedQuery);
  }
};

/**
 * Format time to max 3 first units Ex. 3d 20h 50m, 5h 20m 30s
 * @param hoursSource hours with seconds and minutes in float - ex. 3.5 = 3h 30m
 */
export function formatTime(hoursSource: number): string {
  let days = Math.floor(hoursSource / 24);
  let hours = Math.floor(hoursSource % 24);
  const minutesPart = Number((hoursSource % 1).toFixed(6));
  let minutes = Math.floor(minutesPart * 60);
  const secondsPart = Number(((minutesPart * 60) % 1).toFixed(6));
  let seconds = Math.round(secondsPart * 60);
  const elements: string[] = [];

  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (hours === 24) {
    days += 1;
    hours = 0;
  }

  if (days) {
    elements.push(`${days}d`);
  }

  if (hours || days) {
    elements.push(`${hours}h`);
  }

  if (minutes || (minutes && seconds)) {
    elements.push(`${minutes}min`);
  }

  if (seconds || !hoursSource) {
    elements.push(`${round(seconds, 2)}s`);
  }

  return elements.slice(0, 3).join(' ');
}

/**
 * Format time to max hours and minutes
 * @param hoursSource hours with seconds and minutes in float - ex. 3.5 = 3h 30m
 */
export function formatTimeToHours(hoursSource: number): string {
  let hours = Math.floor(hoursSource);
  const minutesPart = Number((hoursSource % 1).toFixed(6));
  let minutes = Math.floor(minutesPart * 60);
  const secondsPart = Number(((minutesPart * 60) % 1).toFixed(6));
  const seconds = Math.round(secondsPart * 60);
  const elements: string[] = [];

  if (seconds > 30) {
    minutes += 1;
  }

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (hours || (!hours && !minutes)) {
    elements.push(`${hours}h`);
  }

  if (minutes) {
    elements.push(`${minutes}min`);
  }

  return elements.join(' ');
}

export function calculateSatisfaction(good: number, bad: number): number | null {
  const avg = Math.round((good / (good + bad)) * 100);

  return !isNaN(avg) ? avg : null;
}

export function getFormattedPercent(value: number, precision = 0): string {
  if (Number.isNaN(value) || !value) {
    return '0%';
  }

  if (value === 1) {
    return '100%';
  }

  return `${getFormattedNumberValue(round(value * 100, precision), precision)}%`;
}

export function sumValueFromPeriod(period: KeyMap, ...keys: string[]): number {
  return Object.keys(period).reduce<number>(
    (acc, curr) => acc + keys.reduce((sum, key) => sum + ((propertyOf(period[curr])(key) as number) || 0), 0),
    0,
  );
}

export function avgValueFromPeriod(period: KeyMap, key: string): number | null {
  const principles = Object.keys(period).reduce(
    (acc, curr) => {
      if ((period[curr] as KeyMap)[key]) {
        acc.value += (period[curr] as KeyMap)[key] || 0;
        acc.count += 1;
      }

      return acc;
    },
    { value: 0, count: 0 },
  );

  return principles.value / principles.count || null;
}

export function getRatingsFromPeriod(period: KeyMap): IRatings {
  return {
    good: sumValueFromPeriod(period, 'good'),
    bad: sumValueFromPeriod(period, 'bad'),
  };
}

export function getRecursFromPeriod(period: string): string {
  return recursLabels[period] ? recursLabels[period] : period;
}

/**
 * A utility function that retrieves data for a series of periods based on the provided keys. This function is typically used to extract specific data from a period map, which can be used for data visualization or analysis in a React application using react-redux, redux-saga, and @tanstack/query.
 * @param {KeyMap} periodMap - An object where each key represents a period and the value is another object containing various data points for that period.
 * @param {...string[]} keys - The keys to extract data from each period. If no keys are provided or the only key is null or undefined, the function returns the values of the period map.
 * @returns {T[]} - An array of data for the series of periods. The type of the data depends on the values in the period map and the provided keys.
 * @example
 * ```
 * const periodMap = {
 *   '2022-01-01': { sales: 100, profit: 50 },
 *   '2022-01-02': { sales: 200, profit: 100 },
 * };
 * const salesData = getSeriesDataFromPeriod(periodMap, 'sales');
 * console.log(salesData); // [100, 200]
 * ```
 * @remarks
 * This function uses lodash's `isNil` and `propertyOf` functions for null-safe property access and lodash's `reduce` function for efficient array creation. It adheres to the principle of immutability by creating new arrays instead of modifying the existing ones.
 * @see `KeyMap` - The type of the `periodMap` parameter.
 */
export function getSeriesDataFromPeriod<T>(periodMap: KeyMap, ...keys: string[]): T[] {
  const periodKeys = Object.keys(periodMap);

  if (!keys.length || (keys.length === 1 && isNil(keys[0]))) {
    return periodKeys.reduce<T[]>((acc, key) => {
      const value = periodMap[key];

      return [...acc, isNil(value) ? [] : value] as T[];
    }, []);
  }

  return periodKeys.reduce<T[]>((acc, key) => {
    const value = propertyOf(periodMap[key])(keys);

    return [...acc, isNil(value) ? 0 : value] as T[];
  }, []);
}

export function getSourceFromType(type: string): string {
  return sourceLabels[type] ? sourceLabels[type] : type;
}

export function getDateRangeFromPeriod(period: CustomDateRangeFilterId): {
  dateFrom: string;
  dateTo: string;
  value: string;
  label?: string;
} {
  const dateRange = {
    dateFrom: '',
    dateTo: '',
    value: '',
    label: '',
  };
  const now = new Date();
  const dateFormat = DateFormat.ISO8601Date;

  if (period === CustomDateRangeFilterId.Yesterday) {
    const yesterday = subDays(now, 1);
    dateRange.dateFrom = format(yesterday, dateFormat);
    dateRange.dateTo = format(yesterday, dateFormat);
    dateRange.value = CustomDateRangeFilterId.Yesterday;
  }

  if (period === CustomDateRangeFilterId.Last7Days) {
    const startWeek = subDays(now, 7);
    const endWeek = subDays(now, 1);
    const dateFrom = format(startWeek, DateFormat.ShortDateWithDayOfWeek);
    const dateTo = format(endWeek, DateFormat.ShortDateWithDayOfWeek);
    dateRange.dateFrom = format(startWeek, dateFormat);
    dateRange.dateTo = format(endWeek, dateFormat);
    dateRange.value = CustomDateRangeFilterId.Last7Days;
    dateRange.label = `${dateFrom} - ${dateTo}`;
  }

  if (period === CustomDateRangeFilterId.LastMonth) {
    const startMonth = startOfMonth(subMonths(now, 1));
    const endMonth = endOfMonth(subMonths(now, 1));
    dateRange.dateFrom = format(startMonth, dateFormat);
    dateRange.dateTo = format(endMonth, dateFormat);
    dateRange.value = CustomDateRangeFilterId.LastMonth;
    dateRange.label = `${format(startMonth, 'MMM')} ${startMonth.getFullYear()}`;
  }

  if (period === CustomDateRangeFilterId.CurrentMonth) {
    const startMonth = startOfMonth(now);
    dateRange.dateFrom = format(startMonth, dateFormat);
    dateRange.dateTo = format(now, dateFormat);
    dateRange.value = CustomDateRangeFilterId.CurrentMonth;
    dateRange.label = `${format(startMonth, 'MMM')} ${startMonth.getFullYear()}`;
  }

  return dateRange;
}

export function getNextReportFromPeriod(period: string): string {
  if (period === 'day') {
    return 'tomorrow';
  }

  let nextReport = '';
  const now = new Date();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (period === 'week') {
    const week = startOfWeek(addWeeks(now, 1), { weekStartsOn: 1 });
    nextReport = `${week.getDate()} ${months[week.getMonth()]} ${week.getFullYear()}`;
  }

  if (period === 'month') {
    const month = startOfMonth(addMonths(now, 1));
    nextReport = `${month.getDate()} ${months[month.getMonth()]} ${month.getFullYear()}`;
  }

  return nextReport;
}

export function getReportId(type: string, period: string): string {
  if (!type || !period) {
    return '';
  }

  return `${type}-${period}`;
}

function isChartData(data: unknown): data is ChartData {
  const { summary, series, labels } = (data || {}) as ChartData;

  return isArray(summary) && isArray(series) && isArray(labels);
}

/**
 * Merge multiple series x labels for chart and make a unique values of them
 * to avoid duplication of the same period labels
 * @param series
 */
function labelsMerge(allSeries: (string[] | string)[][]): string[][] {
  const series = uniq(allSeries.map((s) => s.toString())).map((s) => s.split(','));

  return series
    .map<string[][]>((labels) => labels.map((label) => (Array.isArray(label) ? label : [label])))
    .reduce((acc, arr) => {
      arr.forEach((el, index) => {
        if (!acc[index]) {
          acc[index] = [];
        }

        acc[index].push(...el);
      });

      return acc;
    }, []);
}

function parseSummariesFromDataSets(dataSets: ReportDataSet[], fieldKey: string): IChartSummary[] {
  return dataSets.reduce<IChartSummary[]>((summaries, set) => {
    const newSummaries: IChartSummary[] = set.data[fieldKey].summary.map((summary) => ({
      ...summary,
      filters: set.filters,
    }));

    return summaries.concat(...newSummaries);
  }, []);
}

function parseLabelsFromDataSets(dataSets: ReportDataSet[], fieldKey: string): string[][] {
  return labelsMerge(dataSets.map((set) => set.data[fieldKey].labels));
}

function parseSeriesFromDataSets(dataSets: ReportDataSet[], fieldKey: string): IChartSeries[] {
  return dataSets.reduce<IChartSeries[]>(
    (seriesList, set) =>
      seriesList.concat(
        ...set.data[fieldKey].series.map((series) => ({
          ...series,
          filters: set.filters,
        })),
      ),
    [],
  );
}

function parseOthersFromDataSets(dataSets: ReportDataSet[], fieldKey: string): KeyMap<ChartData> {
  const otherkeys = uniq(
    dataSets.reduce<string[]>((seriesList, set) => {
      seriesList.push(...Object.keys(set.data[fieldKey]));

      return seriesList;
    }, []),
  );

  return otherkeys.reduce((acc, key) => {
    acc[key] = dataSets.reduce((_, set) => {
      return set.data[fieldKey][key] || null;
    }, null);

    return acc;
  }, {});
}

export function parseChartData<Return = unknown>(dataSets: ReportDataSet[]): Return {
  const chartKeys: string[] = uniq(
    dataSets.reduce<string[]>((acc, set) => {
      acc.push(...Object.keys(set.data).filter((key) => isChartData(set.data[key])));

      return acc;
    }, []),
  );

  const chartData = chartKeys.reduce((acc, key) => {
    acc[key] = {
      ...parseOthersFromDataSets(dataSets, key),
      summary: parseSummariesFromDataSets(dataSets, key),
      labels: parseLabelsFromDataSets(dataSets, key),
      series: parseSeriesFromDataSets(dataSets, key),
    };

    return acc;
  }, {});

  return deepMerge({}, ...dataSets.map((set) => set.data).reverse(), chartData) as Return;
}

type ParsedFilters = DateRangeFilter & { distribution: ReportDistribution };

export const parseFilters = (filters: DateRangeFilter, distribution: ReportDistribution): ParsedFilters | null => {
  if (!filters || !distribution) {
    return null;
  }

  return {
    from: filters.from,
    to: filters.to,
    distribution,
  };
};

/**
 * Returns distribution based on date range and suggested distribution.
 * Can be checked if the distribution was calculated properly.
 *
 * If the suggested distribution is Hour, then if the date range is valid, it returns Hour distribution
 *
 * @param dateRange filter range object with possible comparison
 * @param distribution current distribution
 */
export const getProperDistribution = (
  dateRange: CustomDateRangeFilter,
  distribution: ReportDistribution,
): ReportDistribution | null => {
  if (!dateRange || !isDate(dateRange.from) || !isDate(dateRange.to)) {
    return null;
  }

  if (distribution === ReportDistribution.Hour) {
    return ReportDistribution.Hour;
  }

  let diff = Math.abs(differenceInDays(dateRange.from, dateRange.to));

  if (dateRange.compare && isDate(dateRange.compare.from) && isDate(dateRange.compare.to)) {
    diff = Math.max(
      Math.abs(differenceInDays(dateRange.from, dateRange.to)),
      Math.abs(differenceInDays(dateRange.compare.from, dateRange.compare.to)),
    );
  }

  return diff > MAXIMUM_NUMBER_OF_DAYS_FOR_DAILY_DISTRIBUTION ? ReportDistribution.Month : ReportDistribution.Day;
};

export function isReportAvailableMarketplace(reportType: ReportType): boolean {
  const { reportsApplications } = getConfig();

  return Object.keys(reportsApplications).some((r) => ReportType[r] === reportType);
}

export function getAverageSeconds<T extends PeriodDataBase>(detailsData: T[], key: KeysOfType<T, number>): number {
  const averageDetails = detailsData.reduce(
    (acc, curr) => {
      const count = curr.count || 0;
      const seconds = (curr[key] || 0) as number;
      acc.sum += count * seconds;
      acc.count += count;

      return acc;
    },
    { sum: 0, count: 0 },
  );

  return averageDetails.sum / (averageDetails.count || 1);
}

export function getPeriodValuesFilled<T extends PeriodDataBase>(
  detailsData: T[],
  key: KeysOfType<T, number>,
): number[] {
  return detailsData.map((details) => (details[key] || 0) as number);
}

export const prepareRequestsForLast7Days = (
  filters: ISerializedFilters,
  dateFormat: DateFormat,
  distribution: ReportDistribution = ReportDistribution.Hour,
): ISerializedFiltersWithRawDates[] => {
  // TODO: take distribution from parameter after API change
  const now = new Date();

  const requests = new Array(7)
    .fill(null)
    .map((element, index) => {
      const dateFrom = format(startOfDay(subDays(now, index)), dateFormat);
      const dateTo = format(endOfDay(subDays(now, index)), dateFormat);

      return { ...filters, dateFrom, dateTo, distribution };
    })
    .reverse();

  return requests;
};

const getShouldDivideHeatmapToChunks = (heatmapFilters: IHeatmapFilter): boolean => {
  if (!heatmapFilters) {
    return false;
  }

  const { from, to, selectedFrom, selectedTo } = heatmapFilters;

  return !isEqual(selectedFrom, from) || !isEqual(selectedTo, to);
};

export const prepareHeatmapDateRange = ({
  dateRangeFilters,
  heatmapFilters,
  period = null,
}: {
  dateRangeFilters: CustomDateRange;
  heatmapFilters: IHeatmapFilter;
  period?: HeatmapPeriod;
}): Pick<IHeatmapFilter, 'from' | 'to'> => {
  const parseDate = (dateString: string | Date, start = true): Date => {
    const date = new Date(dateString);

    return start ? startOfDay(date) : endOfDay(date);
  };

  const defaultFrom = parseDate(heatmapFilters?.from || dateRangeFilters.from);
  const defaultTo = parseDate(heatmapFilters?.to || dateRangeFilters.to, false);

  const diff = differenceInDays(defaultTo, defaultFrom);
  const shouldDivideHeatmapToChunks = getShouldDivideHeatmapToChunks(heatmapFilters);

  if (diff < HEATMAP_DAYS_LIMIT && !shouldDivideHeatmapToChunks) {
    return { from: formatISO(defaultFrom), to: formatISO(defaultTo) };
  }

  let dateFrom = defaultFrom;
  let dateTo = endOfDay(addDays(defaultFrom, HEATMAP_DAYS_LIMIT - 1));

  switch (period) {
    case HeatmapPeriod.Previous:
      dateFrom = subDays(defaultFrom, HEATMAP_DAYS_LIMIT);
      dateTo = endOfDay(subDays(defaultTo, HEATMAP_DAYS_LIMIT));
      if (isAfter(new Date(heatmapFilters.selectedFrom), dateFrom)) {
        dateFrom = parseDate(heatmapFilters.selectedFrom);
        dateTo = endOfDay(addDays(dateFrom, HEATMAP_DAYS_LIMIT - 1));
      }
      break;
    case HeatmapPeriod.Next:
      dateFrom = startOfDay(addDays(defaultFrom, HEATMAP_DAYS_LIMIT));
      dateTo = addDays(defaultTo, HEATMAP_DAYS_LIMIT);
      if (isBefore(new Date(heatmapFilters.selectedTo), dateTo)) {
        dateTo = parseDate(heatmapFilters.selectedTo, false);
        dateFrom = startOfDay(subDays(dateTo, HEATMAP_DAYS_LIMIT - 1));
      }
      break;
    default:
      // Keep the default range
      break;
  }

  return { from: formatISO(dateFrom), to: formatISO(dateTo) };
};

export const prepareHeatmapRequestV3 = (
  filters: SerializedJSONFilters,
  dateRange: Pick<IHeatmapFilter, 'from' | 'to'>,
  distribution: ReportDistribution = ReportDistribution.Hour,
): SerializedJSONFilters => {
  const { from, to } = dateRange;

  return { ...filters, from, to, distribution };
};

export function parseLegacySerializedFilters({
  dateFrom,
  dateTo,
  ...rest
}: ISerializedFilters): ISerializedFiltersWithRawDates {
  const legacySerializedFilters: ISerializedFiltersWithRawDates = {
    ...rest,
  };

  if (dateFrom) {
    legacySerializedFilters.dateFrom = format(dateFrom, DateFormat.ISO8601Date);
  }

  if (dateTo) {
    legacySerializedFilters.dateTo = format(dateTo, DateFormat.ISO8601Date);
  }

  return legacySerializedFilters;
}

export function createReportsQueryParams(provider: ReportParamsProvider): IKeyValue {
  const reportType = provider.getReportType();

  if ([ReportType.Last7days, 'dashboard'].includes(reportType)) {
    return {};
  }

  const {
    agent,
    dateRange,
    goal,
    greeting,
    group,
    saleGoal,
    surveyType,
    tag,
    date,
    weekday,
    channel,
    agentAssignment,
    availability,
    countryISO,
    priority,
  } = provider.getFilters();

  const distribution = provider.getDistribution();
  const benchmarkEnabled = provider.getBenchmarkEnabled();
  const operators = provider.getFiltersOperators();

  const { filters: availableFilters, customConfig } = getFiltersByReportType(reportType);
  const dateRangeParams = mapDateRangeToQueryString(dateRange);
  const dateParam = mapDateToQueryString(date);
  let queryParams: IKeyValue<string> = {};

  if (agent && agent.agents && agent.agents.length && availableFilters.includes(Filter.Agent)) {
    queryParams.agent = ensureArray(agent.agents);

    if (agent.compare && agent.compare.length) {
      queryParams.compare_agent = ensureArray(agent.compare);
    }
  }

  if (dateRangeParams && availableFilters.includes(Filter.DateRange)) {
    queryParams = {
      ...queryParams,
      ...dateRangeParams,
    };
  }

  if (dateParam && dateParam.date && availableFilters.includes(Filter.Date)) {
    queryParams.date = dateParam.date;
  }

  if (weekday && availableFilters.includes(Filter.Weekday)) {
    queryParams.weekday = ensureFlat(weekday);
  }

  if (group && group.groups && group.groups.length && availableFilters.includes(Filter.Group)) {
    queryParams.group = ensureArray(group.groups);

    if (group.compare && group.compare.length) {
      queryParams.compare_group = ensureArray(group.compare);
    }
  }

  if (tag && availableFilters.includes(Filter.Tag)) {
    if (tag.includes(TagFilter.NotTagged)) {
      queryParams.tagged = '0';
    } else {
      queryParams.tag = ensureArray(tag);
    }
  }

  if (goal && availableFilters.includes(Filter.Goal)) {
    queryParams.goal = ensureArray(goal);
  }

  if (saleGoal && availableFilters.includes(Filter.SaleGoal)) {
    queryParams.sale = ensureArray(saleGoal);
  }

  if (surveyType && availableFilters.includes(Filter.SurveyType)) {
    queryParams.survey = ensureFlat(surveyType);
  }

  if (greeting && availableFilters.includes(Filter.Greeting)) {
    queryParams.greeting = ensureArray(greeting);
  }

  if (distribution !== ReportDistribution.Day && distribution !== ReportDistribution.Month) {
    queryParams.group_by = distribution;
  }

  if (channel && availableFilters.includes(Filter.Channel)) {
    queryParams.channel = ensureArray(channel);
  }

  if (agentAssignment && availableFilters.includes(Filter.AgentAssignment)) {
    queryParams[AgentAssignmentFilterKey] = ensureFlat(agentAssignment);
  }

  if (availability && availableFilters.includes(Filter.Availability)) {
    queryParams.availability = ensureFlat(availability);
  }

  if (benchmarkEnabled) {
    queryParams.benchmark = 'on';
  }

  if (operators) {
    queryParams = { ...queryParams, ...extractOperatorsToQueryParams(operators, customConfig) };
  }

  if (countryISO?.length && availableFilters.includes(Filter.CountryISO)) {
    queryParams.countries = ensureArray(countryISO);
  }

  const selectedReportViewId = provider.getReportViewId();
  if (selectedReportViewId) {
    queryParams.selected_view = selectedReportViewId;
  }

  if (priority && availableFilters.includes(Filter.Priority)) {
    queryParams.priority = priority;
  }

  const customParams = getCustomParams(reportType);

  return { ...queryParams, ...customParams };
}

export function getReportsViewQueryParams(state: IStoreState): IKeyValue {
  return createReportsQueryParams({
    getReportType: () => getCurrentView(state),
    getFilters: () => getFilters(state),
    getFiltersOperators: () => getFiltersOperators(state),
    getBenchmarkEnabled: () => getBenchmarkEnabled(state),
    getDistribution: () => getDistribution(state),
    getPage: () => getPage(state),
    getReportViewId: () => getSelectedReportViewId(state),
  });
}

export function buildReportsViewQueryString(state: IStoreState): string {
  return stringifyQueryParams(getReportsViewQueryParams(state));
}

// The following params are not part of filters but are used in specific reports
// example: "show sentiment" toggle in ChatTopics report
// initial value of toggle is determined based on the presence of this param in the URL
function getCustomParams(reportType: ReportType): IKeyValue<string> {
  const browserQueryParams = browserHistory.queryParams;
  const params: IKeyValue<string> = {};

  if (reportType === ReportType.ChatTopics) {
    const showSentimentParam = browserQueryParams[SHOW_SENTIMENT_QUERY_PARAM_NAME];
    if (showSentimentParam) {
      params[SHOW_SENTIMENT_QUERY_PARAM_NAME] = showSentimentParam;
    }
  }

  return params;
}
