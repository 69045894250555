import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { getCartItemsCount } from 'store/entities/product-cart/selectors';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const AppsBadge: FC = () => {
  const cartItemsCount = useSelector(getCartItemsCount);
  const isTrial = useSelector(getIsSubscriptionInTrialAndNotSubscribed);
  const shouldDisplayCartItemsBadge = cartItemsCount > 0 && !isTrial;

  if (!shouldDisplayCartItemsBadge) {
    return null;
  }

  return <NavigationBadge count={cartItemsCount} />;
};
