import { useMutation } from '@tanstack/react-query';

import { ApiManager } from 'services/api/api-manager';
import { type AcknowledgeNotificationsRequest } from 'services/api/ml-gateway/interfaces/ai-agents';

type AcknowledgeNotificationsData = {
  notificationIds: string[];
};

type UseAcknowledgeNotifications = {
  acknowledgeNotifications: (data: AcknowledgeNotificationsData) => void;
};

export const useAnnotateNotifications = (): UseAcknowledgeNotifications => {
  const { mutate } = useMutation<void, Error, AcknowledgeNotificationsData>({
    mutationFn: async ({ notificationIds }) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const request: AcknowledgeNotificationsRequest = { notification_ids: notificationIds };
      /* eslint-enable @typescript-eslint/naming-convention */
      const { error } = await ApiManager.mlGatewayApi.acknowledgeNotifications(request);
      if (error) {
        throw error;
      }
    },
  });

  return {
    acknowledgeNotifications: mutate,
  };
};
